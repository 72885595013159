import { Button, InputDatetime, Popover, StatelessAccordion, StatelessAccordionState } from "#/atoms";
import { ChangeEvent, FC, memo, useCallback, useState } from "react";
import {
  OnDatesChangeProps,
  START_DATE,
  useDatepicker,
} from "@datepicker-react/hooks";
import {
  StyledCalendar,
  StyledCalendarHeader,
  StyledYearSelector,
} from "./Datepicker.styled";

import Calendar from "./Calendar";
import DatepickerContext from "./Datepicker.context";
import { DatepickerProps } from "./Datepicker.interface";
import Months from "./Months";
import TodayButton from "./TodayButton";
import { formatDate } from "@/helpers";

const Datepicker: FC<DatepickerProps> = ({ date, label, onChange }) => {
  const [focus, setFocus] = useState(false);
  const [seletedDate, setSelectedDate] = useState<OnDatesChangeProps>({
    endDate: null,
    focusedInput: START_DATE,
    startDate: date,
  });
  const { startDate, focusedInput } = seletedDate;
  const today = new Date(Date.now());

  const handleDateChange = useCallback((data: OnDatesChangeProps) => {
    if (!data.focusedInput) {
      setSelectedDate({ ...data, focusedInput: START_DATE });
    } else {
      setSelectedDate(data);
    }
  }, []);

  const handleToggleClick = (state: StatelessAccordionState) => {
    setFocus(state === 'open');
    console.log(focus);
  };

  const {
    activeMonths,
    firstDayOfWeek,
    focusedDate,
    goToDate,
    isDateBlocked,
    isDateFocused,
    isDateHovered,
    isDateSelected,
    isFirstOrLastSelectedDate,
    onDateFocus,
    onDateHover,
    onDateSelect,
  } = useDatepicker({
    endDate: null,
    exactMinBookingDays: true,
    firstDayOfWeek: 0,
    focusedInput: focusedInput,
    minBookingDays: 1,
    numberOfMonths: 1,
    onDatesChange: handleDateChange,
    startDate,
  });

  const handleYearChange = useCallback(() => (ev: ChangeEvent<HTMLInputElement>) => {
    const currentDate = startDate || today;
    const currentYear = currentDate.getFullYear();
    const newYear = Number(ev.target.value);

    if (currentYear === newYear) {
      return;
    }

    const newDate = new Date(currentDate.setFullYear(newYear));
    goToDate(newDate)
  }, [startDate, today]);

  const handleMonthChange = useCallback((newMonth: number) => {
    const currentDate = startDate || today;
    const currentMonth = currentDate.getMonth();

    if (currentMonth === newMonth) {
      return;
    }

    const newDate = new Date(currentDate.setMonth(newMonth));
    goToDate(newDate)
  }, [startDate, today]);

  const handleApplySelectedDate = useCallback(() => {
    if (startDate) {
      onChange(startDate);
      handleToggleClick('close');
    }
  }, [startDate]);

  const formatedDate = startDate ? formatDate(startDate) : '';

  return (
    <DatepickerContext.Provider
      value={{
        focusedDate,
        isDateBlocked,
        isDateFocused,
        isDateHovered,
        isDateSelected,
        isFirstOrLastSelectedDate,
        onDateFocus,
        onDateHover,
        onDateSelect,
      }}
    >
      <StatelessAccordion
        header={<InputDatetime datetime={date} iconType="calendar" focus={focus} label={label} />}
        state={focus ? 'open' : 'close'}
        onToggle={handleToggleClick}
      >
        <Popover
          header={formatedDate}
          footer={(
            <Button fullWidth onClick={handleApplySelectedDate}>Apply Selection</Button>
          )}
          onClose={() => handleToggleClick('close')}
        >
          <StyledCalendarHeader>
            <StyledYearSelector
              type="number"
              value={startDate ? startDate.getFullYear() : today.getFullYear()}
              onChange={handleYearChange}
            />
            <TodayButton date={today} />
          </StyledCalendarHeader>
          <StyledCalendar>
            <Months
              activeMonth={startDate ? startDate.getMonth() : today.getMonth()}
              show={focus}
              onSelectMonth={handleMonthChange}
            />
            {activeMonths.map((activeMonth) => {
              return (
                <Calendar
                  key={`${activeMonth.year}-${activeMonth.month}`}
                  firstDayOfWeek={firstDayOfWeek}
                  month={activeMonth.month}
                  year={activeMonth.year}
                />
              );
            })}
          </StyledCalendar>
        </Popover>
      </StatelessAccordion>
    </DatepickerContext.Provider>
  );
}

export default memo(Datepicker);