import { FC, memo, useCallback, useState } from "react";
import { StyledNav, StyledNavigation, StyledNavigationNav, StyledSandwitchIcon, StyledSupportDesktop, StyledSupportMobile } from "./Navigation.styled";

import { Menu } from "#/molecules";
import { NavigationProps } from "./Navigation.interface";
import { PhotoplanLogo } from "#/atoms";

const Navigation: FC<NavigationProps> = ({ menus, support }) => {
  const [mobileShowMenu, toggleMobileShowMenu] = useState(false);

  const handleSandwichClick = useCallback(() => {
    toggleMobileShowMenu(!mobileShowMenu)
  }, [mobileShowMenu]);

  const handleSupportClick = useCallback(() => {
    if (!support?.onClick) {
      return;
    }
    
    support.onClick()
    toggleMobileShowMenu(!mobileShowMenu)
  }, [mobileShowMenu]);


  return (
    <StyledNavigation>
      <PhotoplanLogo />
      <StyledNav>
        <StyledSandwitchIcon onClick={handleSandwichClick}><span /></StyledSandwitchIcon>
        <StyledNavigationNav mobileShow={mobileShowMenu}>
          {menus.map((menuProps) => (
            <Menu
              key={menuProps.id}
              {...menuProps}
              onClick={handleSandwichClick}
            />
          ))}
          {support && (
            <StyledSupportMobile>
              <Menu {...support} onClick={handleSupportClick} />
            </StyledSupportMobile>
          )}
        </StyledNavigationNav>
      </StyledNav>
      
      {support && <StyledSupportDesktop><Menu {...support} /></StyledSupportDesktop>}
    </StyledNavigation>
  );
}

export default memo(Navigation);
