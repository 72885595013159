import { Button, ButtonsGroup, Grid, InputText, Toggle } from "#/atoms";
import { FC, memo, useCallback } from "react";
import { useInput, useToggle } from "@/hooks";

import { DESKTOP_SPACING } from "@/theme";
import { Modal } from "#/molecules";
import { WorkerModalProps } from "./Worker.interface";
import { getBoleanFromYesOrNo } from "@/helpers";

const Worker: FC<WorkerModalProps> = ({ onClose, onSubmit }) => {
  const active = useToggle('active', true);
  const admin = useToggle('admin');
  const email = useInput();
  const firstName = useInput();
  const lastName = useInput();
  const login = useInput();
  const inOffice = useToggle('inOffice');
  const password = useInput();
  const confirmPassword = useInput();

  const handleCreate = useCallback(() => {
    onSubmit({
      active: getBoleanFromYesOrNo(active.value) || false,
      email: email.value,
      name: '',
      postCode: '',
      workerTypeId: '',
    })
  }, [active, admin, confirmPassword, email, firstName, inOffice, lastName, login, password]);

  return (
    <Modal onClose={onClose} title="Create New Worker">
      <InputText type="text" label="First Name" value={firstName.value} onChange={firstName.onChange} />
      <InputText type="text" label="Last Name" value={lastName.value} onChange={lastName.onChange} />
      <InputText type="text" label="E-mail Address" value={email.value} onChange={email.onChange} />
      <InputText type="password" label="Password" value={password.value} onChange={password.onChange} />
      <InputText type="password" label="Confirm Password" value={confirmPassword.value} onChange={confirmPassword.onChange} />

      <InputText type="text" label="Phone Number" value={firstName.value} onChange={firstName.onChange} />
      <InputText type="text" label="House Name" value={firstName.value} onChange={firstName.onChange} />
      <Grid columnGap={DESKTOP_SPACING.XL} gridTemplateColumns="repeat(2, 50%)" margin={`${DESKTOP_SPACING.XXL}px 0 0`}>
        <InputText type="text" label="Number" value={firstName.value} onChange={firstName.onChange} />
        <InputText type="text" label="Road" value={firstName.value} onChange={firstName.onChange} />
        <InputText type="text" label="Area" value={firstName.value} onChange={firstName.onChange} />
        <InputText type="text" label="Town" value={firstName.value} onChange={firstName.onChange} />
        <InputText type="text" label="Country" value={firstName.value} onChange={firstName.onChange} />
        <InputText type="text" label="Postcode" value={firstName.value} onChange={firstName.onChange} />
      </Grid>
      <InputText type="text" label="Notes" value={firstName.value} onChange={firstName.onChange} />

      <Grid rowGap={DESKTOP_SPACING.XXL} gridTemplateColumns="repeat(1, 100%)">
        <Toggle checked={active.checked} id={active.value} label="Active" labelPosition="left" onToggle={active.onChange} />
        <Toggle checked={admin.checked} id={admin.value} label="Send e-mail notification" labelPosition="left" onToggle={admin.onChange} />

        <Toggle checked={inOffice.checked} id={inOffice.value} label="Full Time Worker" labelPosition="left" onToggle={inOffice.onChange} />

        <Toggle checked={inOffice.checked} id={inOffice.value} label="Photos" labelPosition="left" onToggle={inOffice.onChange} />
        <Toggle checked={inOffice.checked} id={inOffice.value} label="EPC" labelPosition="left" onToggle={inOffice.onChange} />
        <Toggle checked={inOffice.checked} id={inOffice.value} label="4K Slideshow Video" labelPosition="left" onToggle={inOffice.onChange} />
        <Toggle checked={inOffice.checked} id={inOffice.value} label="Slideshow Video" labelPosition="left" onToggle={inOffice.onChange} />
        <Toggle checked={inOffice.checked} id={inOffice.value} label="2D Floor Plan" labelPosition="left" onToggle={inOffice.onChange} />
        <Toggle checked={inOffice.checked} id={inOffice.value} label="360 Virtual Tour" labelPosition="left" onToggle={inOffice.onChange} />
        <Toggle checked={inOffice.checked} id={inOffice.value} label="Photo Editing" labelPosition="left" onToggle={inOffice.onChange} />
        <Toggle checked={inOffice.checked} id={inOffice.value} label="Resi Leaseplan" labelPosition="left" onToggle={inOffice.onChange} />
        <Toggle checked={inOffice.checked} id={inOffice.value} label="Com Leaseplan" labelPosition="left" onToggle={inOffice.onChange} />
        <Toggle checked={inOffice.checked} id={inOffice.value} label="Property Brochure" labelPosition="left" onToggle={inOffice.onChange} />
      </Grid>
      <ButtonsGroup fullWidth margin={`${DESKTOP_SPACING.EL}px 0 0`}>
        <Button onClick={handleCreate}>Create</Button>
      </ButtonsGroup>
    </Modal>
  );
};

export default memo(Worker);
