import { DESKTOP_SPACING, HPOSITION } from "@/theme";

import { ButtonsGroupProps } from "./ButtonsGroup.interface";
import styled from "styled-components";

const getPosition = (align?: HPOSITION) => {
  switch (align) {
    case HPOSITION.LEFT: {
      return 'flex-start';
    }
    case HPOSITION.CENTER: {
      return 'center';
    }
    case HPOSITION.RIGHT:
    default: {
      return 'flex-end';
    }
  }
}

export const StyledButtonsGroup = styled.div<ButtonsGroupProps>`
  display: ${({ fullWidth }) => fullWidth ? 'flex' : 'inline-flex'};
  justify-content: ${({ align }) => getPosition(align)};
  gap: ${DESKTOP_SPACING.XL}px;
  margin: ${({ margin }) => margin || '0'};
`;
