import { Box, Card, Grid, Heading, Link, PageHeader, PageWrapper, Typo } from "#/atoms";
import { DESKTOP_SPACING, FONT_SIZE, FONT_WEIGHT, NATURAL, PRIMARY_BLUE } from "@/theme";
import { FC, memo } from "react";

import BarChart from "react-bar-chart";
import { DashboardProps } from "./Dashboard.interface";
import { ORDER_STATE } from "@/types";
import { PieChart } from 'react-minimal-pie-chart';
import { getOrderStateEnum } from "@/helpers";
import { useNavigate } from "react-router-dom";

import "./charts.css";

const Dashboard: FC<DashboardProps> = ({
  dashboardData, firstName
}) => {
  const navigate = useNavigate();

  let bookingInProgress, bookingPlaced, contentSelected, workComplete;
  bookingInProgress = bookingPlaced = contentSelected = workComplete = 0;

  dashboardData?.orderCountsByStatus.forEach(oc => {
    switch(getOrderStateEnum(oc.orderState)){
      case ORDER_STATE.AWAITING_DATE:
      case ORDER_STATE.BOOKING_PLACED:
        bookingPlaced += oc.count;
        break;
      case ORDER_STATE.BOOKING_IN_PROGRESS:
      case ORDER_STATE.DRAFT:
      case ORDER_STATE.RETURN_TO_WORK:
        bookingInProgress += oc.count;
        break;
      case ORDER_STATE.COMPLETE:
        workComplete += oc.count;
        break;
      case ORDER_STATE.AWAITING_PAYMENT:
      case ORDER_STATE.CONTENT_SELECTED:
      case ORDER_STATE.ORDER_PAID:
        contentSelected += oc.count;
        break;
    }
  });

  const orderCounts = dashboardData?.orderCountsByStatus.reduce((p, c) => p + c.count, 0);

  const invoicesByMonth = dashboardData?.invoicesByMonth ?? [{month: 1, total:0}];
  const lastInvoice = invoicesByMonth[invoicesByMonth?.length - 1];

  for (let i = lastInvoice.month; i < 12; i++) {
    invoicesByMonth.push({month: i + 1, total:0})
  }

  const data = dashboardData?.invoicesByMonth.map(i => ({ 
    text: new Date(2022, i.month -1, 1).toLocaleString('default', { month: 'short' }), 
    value: i.total / 1000  }) ) ?? [{text:'0', value: 0}];

  return (
    <PageWrapper>
      <PageHeader>
      <Heading scale="h1" fontColor={NATURAL.K}>Welcome {firstName}!</Heading>
      </PageHeader>
      <Grid columnGap={DESKTOP_SPACING.XXL} gridTemplateColumns="repeat(3, 1fr)" maxWidth="80%" margin="2px 2px 40px">
        <Box background="#00E4FF" borderRadius="8px" display="inline" padding="40px 20px 20px">
          <Typo fontSize={FONT_SIZE.MD} fontColor={NATURAL.B} margin="10px 0px">This month</Typo>
          <Typo fontSize={FONT_SIZE.XL} fontColor={NATURAL.B} weight={FONT_WEIGHT.BOLD}>{dashboardData?.newInvoices} Invoices</Typo>
        </Box>
        <Box background="#7189FF" borderRadius="8px" display="inline" padding="40px 20px 20px">
          <Typo fontSize={FONT_SIZE.MD} fontColor={NATURAL.B} margin="10px 0px">This month</Typo>
          <Typo fontSize={FONT_SIZE.XL} fontColor={NATURAL.B} weight={FONT_WEIGHT.BOLD}>{dashboardData?.newOrders} New Orders</Typo>
        </Box>
        <Box background="#B6C649" borderRadius="8px" display="inline" padding="40px 20px 20px">
          <Typo fontSize={FONT_SIZE.MD} fontColor={NATURAL.B} margin="10px 0px">This month</Typo>
          <Typo fontSize={FONT_SIZE.XL} fontColor={NATURAL.B} weight={FONT_WEIGHT.BOLD}>{dashboardData?.newCustomers} New Customers</Typo>
        </Box>
      </Grid>
      <Card>
        <Heading scale="h2" fontColor={NATURAL.K}>Billing Amount</Heading>
        <Typo fontSize={FONT_SIZE.MD}>Amount of billed order per month for current year</Typo>
        <BarChart ylabel='Amount (1000 GBP)'
                    width={1000}
                    height={500}
                    margin={{bottom: 30, left: 40, right: 20, top: 20}}
                    data={data}  />
      </Card>
      <Card width="49%" marginLeft="10px" display="inline-block">
          <Box display="inline-block" position="relative" width="40%" height="40%">
          <PieChart
            data={[
              { color: '#7188FE', style: { strokeWidth: 9 }, title: 'Booking Placed', value: bookingPlaced, },
              { color: '#FF8552', style: { strokeWidth: 9 }, title: 'Booking in Progress', value: bookingInProgress },
              { color: '#00E3FE', style: { strokeWidth: 9 }, title: 'Work Completed', value: workComplete },
              { color: '#B2C249', style: { strokeWidth: 9 }, title: 'Content Selected', value: contentSelected },
            ]}
            labelPosition={50}
            lineWidth={20}
            viewBoxSize={[100, 100]}
            radius={50}
            style={{position: "relative" }}
          />
          <div className="chart-inner-text">
            <p>{orderCounts}</p>
            <p>Orders</p>
          </div>
        </Box>
        <div className="chart-legend">
            <div>
              <div className="chart-legend-block" style={{ backgroundColor: '#7188FE' }}></div>
              <label>{bookingPlaced} Booking Placed</label>
            </div>
            <div>
              <div className="chart-legend-block" style={{ backgroundColor: '#FF8552' }}></div>
              <label>{bookingInProgress} Booking in Progress</label>
            </div>
            <div>
              <div className="chart-legend-block" style={{ backgroundColor: '#00E3FE' }}></div>
              <label>{workComplete} Work Completed</label>
            </div>
            <div>
              <div className="chart-legend-block" style={{ backgroundColor: '#B2C249' }}></div>
              <label>{contentSelected} Content Selected</label>
            </div>
        </div>
        <Box textAlign="right">
          <Link fontColor={PRIMARY_BLUE.DARK} onClick={() => navigate('/order-find') } >View All Orders</Link>
        </Box>
      </Card>
      <Card width="49%" marginLeft="10px"  display="inline-block">
        <Box display="inline-block" position="relative" width="40%" height="40%">
          <PieChart
            data={[
              { color: '#7189FF', style: { strokeWidth: 9 }, title: 'Paid', value: dashboardData?.invoiceCounts.invoicePaidCount ?? 0, },
              { color: '#FF8552', style: { strokeWidth: 9 }, title: 'Unpaid', value: dashboardData?.invoiceCounts.invoiceUnpaidCount ?? 0 }
            ]}
            labelPosition={50}
            lineWidth={20}
            viewBoxSize={[100, 100]}
            radius={50}
            style={{position: "relative" }}
          />
          <div className="chart-inner-text">
            <p>{(dashboardData?.invoiceCounts.invoicePaidCount ?? 0) + (dashboardData?.invoiceCounts.invoiceUnpaidCount ?? 0)} </p>
            <p>Invoices</p>
          </div>
        </Box>
        <div className="chart-legend">
            <div>
              <div className="chart-legend-block" style={{ backgroundColor: '#FF8552' }}></div>
              <label>{dashboardData?.invoiceCounts.invoiceUnpaidCount} Unpaid</label>
            </div>
            <div>
              <div className="chart-legend-block" style={{ backgroundColor: '#7189FF' }}></div>
              <label>{dashboardData?.invoiceCounts.invoicePaidCount} Paid</label>
            </div>
        </div>
        <Box textAlign="right">
          <Link fontColor={PRIMARY_BLUE.DARK} onClick={() => navigate('/invoices-find') }>View All Invoices</Link>
        </Box>
      </Card>

      <Card width="49%" marginLeft="10px"  display="inline-block">
        <Box display="inline-block" position="relative" width="40%" height="40%">
        <PieChart
          data={[
            { color: '#FF8552', style: { strokeWidth: 9 }, title: 'Active', value: dashboardData?.customerCounts.customerActiveCount ?? 0 },
            { color: '#E7E7EB', style: { strokeWidth: 9 }, title: 'Inactive', value: dashboardData?.customerCounts.customerInactiveCount ?? 0 },
          ]}
          labelPosition={50}
          lineWidth={20}
          viewBoxSize={[100, 100]}
          radius={50}
          style={{position: "relative" }}
        />
        <div className="chart-inner-text">
          <p>{(dashboardData?.customerCounts.customerActiveCount ?? 0) + (dashboardData?.customerCounts.customerInactiveCount ?? 0)}</p>
          <p>Customers</p>
        </div>
      </Box>
      <div className="chart-legend">
          <div>
            <div className="chart-legend-block" style={{ backgroundColor: '#FF8552' }}></div>
            <label>{dashboardData?.customerCounts.customerActiveCount} Active</label>
          </div>
          <div>
            <div className="chart-legend-block" style={{ backgroundColor: '#E7E7EB' }}></div>
            <label>{dashboardData?.customerCounts.customerInactiveCount} Inactive</label>
          </div>
      </div>
      <Box textAlign="right">
        <Link fontColor={PRIMARY_BLUE.DARK} onClick={() => navigate('/customer-find') }>View All Customers</Link>
      </Box>
      </Card>
      <Card width="49%" marginLeft="10px"  display="inline-block">
      <Box display="inline-block" position="relative" width="40%" height="40%">
        <PieChart
          data={[
            { color: '#00E3FE', style: { strokeWidth: 9 }, title: 'Active', value: dashboardData?.workerCounts.workerActiveCount ?? 0 },
            { color: '#E7E7EB', style: { strokeWidth: 9 }, title: 'Inactive', value: dashboardData?.workerCounts.workerInactiveCount ?? 0 }
          ]}
          labelPosition={50}
          lineWidth={20}
          viewBoxSize={[100, 100]}
          radius={50}
          style={{position: "relative" }}
        />
        <div className="chart-inner-text">
          <p className="first-row">{(dashboardData?.workerCounts.workerActiveCount ?? 0) + (dashboardData?.workerCounts.workerInactiveCount ?? 0)}</p>
          <p className="second-row">Photographers</p>
        </div>
      </Box>
      <div className="chart-legend">
          <div>
            <div className="chart-legend-block" style={{ backgroundColor: '#00E3FE' }}></div>
            <label>{dashboardData?.workerCounts.workerActiveCount} Active</label>
          </div>
          <div>
            <div className="chart-legend-block" style={{ backgroundColor: '#E7E7EB' }}></div>
            <label>{dashboardData?.workerCounts.workerInactiveCount} Inactive</label>
          </div>
      </div>
      <Box textAlign="right">
        <Link fontColor={PRIMARY_BLUE.DARK}  onClick={() => navigate('/workers') }>View All Photographers</Link>
      </Box>
      </Card>
    </PageWrapper>
  );
}

export default memo(Dashboard);
