import { Session, User } from "@/types";

import { ApiLogin } from "./interface";

export const mapLoginResponse = (res: ApiLogin): { session: Session, user: User } => {
  return {
    session: {
      expiresIn: `${res.token.expires_in}`,
      token: res.token.access_token,
    },
    user: {
      active: res.isActive,
      admin: res.Roles && res.Roles.includes('Admin'),
      dayToPayInvoices: `${res.DaysToPayInvoices}`,
      email: res.Email,
      firstName: res.FirstName,
      id: res.Id,
      inOffice: false,
      joinDate: res.JoinDate,
      lastName: res.LastName,
      login: res.UserName,
      phoneNumber: res.AccountPhoneNumber,
      roles: res.Roles,
      userUpsellPrice: res.UserUpsellPrice,
    },
  }
};