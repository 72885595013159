import { SIDEBAR_WIDTH, media } from "@/theme";

import { StyledUserMenu } from "#/molecules/UserMenu/UserMenu.styled";
import styled from "styled-components";

export const StyledMain = styled.div`
  display: flex;
  height: 100vh;
  position: relative;
`

export const StyledMainSection = styled.section`
  border-radius: 20px 0 0 20px;
  flex: 1;
  margin-left: ${SIDEBAR_WIDTH};
  padding: 30px 60px 30px 20px;
  width: 100%;

  ${StyledUserMenu} {
    position: absolute;
    right: 60px;
    top: 15px;
  }

  ${media.laptop} {
    margin-left: 0;
    margin-top: 60px;
    padding: 30px 20px;

    ${StyledUserMenu} {
      position: absolute;
      right: 20px;
      top: 75px;
    }
  }
`