import { BORDER_RADIUS, DESKTOP_SPACING, NATURAL } from "@/theme";

import styled from "styled-components";

export const StyledPagination = styled.div`
  align-items: center;
  display: flex;
  gap: ${DESKTOP_SPACING.MD}px;
  justify-content: end;
  margin: ${DESKTOP_SPACING.XXXL}px 0;
`;

export const StyledCurrentPage = styled.div`
  border: 1px solid ${NATURAL.C};
  border-radius: ${BORDER_RADIUS.MD};
  height: 36px;
  line-height: 36px;
  text-align: center;
  width: 36px;
`;
