import { BORDER_RADIUS, DESKTOP_SPACING, FONT_SIZE, FONT_WEIGHT, PRIMARY_BLUE } from "@/theme";

import { StyledIcon } from "#/atoms/Icon";
import styled from "styled-components";

export const StyledPackageName = styled.div`
  align-items: center;
  display: flex;
  font-size: ${FONT_SIZE.LG};
  font-weight: ${FONT_WEIGHT.SEMIBOLD};

  ${StyledIcon} {
    background: ${PRIMARY_BLUE.LIGHT};
    border-radius: ${BORDER_RADIUS.LG};
    color: ${PRIMARY_BLUE.DARK};
    fill: ${PRIMARY_BLUE.DARK};
    height: 40px;
    width: 40px;
  }
`;

export const StyledPackageIcons = styled.div`
  display: flex;
  gap: ${DESKTOP_SPACING.MD}px;
  margin-left: ${DESKTOP_SPACING.XXL}px;
`;
