import { ChangeEvent, FC, memo, useCallback, useEffect, useState } from "react";
import { StyledSelect, StyledSelectArrow, StyledSelectWrapper } from "./Select.styled";

import ChevronDownIcon from "@/assets/icons/ChevronDown";
import { ErrorMessage } from "../ErrorMessage";
import { InputLabel } from "../InputLabel";
import { SelectProps } from "./Select.interface";
import { StyledFieldGroup } from "../InputLabel/InputLabel";

const Select: FC<SelectProps> = ({
  autofocus = false,
  errorMessage,
  fullWidth = true,
  label,
  onBlur,
  onChange,
  onClick,
  onFocus,
  onMouseEnter,
  onMouseLeave,
  options,
  selected = '',
  state = 'idle',
  ...selectProps
}) => {
  const [selectedOption, setSelectedOption] = useState(selected);
  const [shrink, setShrink] = useState<boolean>(!!selected);
  const [focus, setFocus] = useState<boolean>(autofocus);

  useEffect(() => {
    setSelectedOption(selected);
    setShrink(focus || !!selected);
  }, [selected])

  const onMouseEnterHandler = useCallback((ev: React.MouseEvent<HTMLSelectElement>) => {
    if (onMouseEnter) {
      onMouseEnter(ev);
    }
  }, []);

  const onMouseLeaveHandler = useCallback((ev: React.MouseEvent<HTMLSelectElement>) => {
    if (onMouseLeave) {
      onMouseLeave(ev);
    }
  }, []);

  const onFocusHandler = useCallback((ev: React.FocusEvent<HTMLSelectElement>) => {
    if (onFocus) {
      onFocus(ev);
    }
    setShrink(true);
    setFocus(true);
  }, []);

  const onBlurHandler = useCallback((ev: React.FocusEvent<HTMLSelectElement>) => {
    if (onBlur) {
      onBlur(ev);
    }
    
    if (!selectedOption) {
      setShrink(false);
    }
    setFocus(false);
  }, [selectedOption]);

  const onChangeHandler = useCallback((ev: ChangeEvent<HTMLSelectElement>) => {
    const { value } = ev.target;

    if (onChange) {
      onChange(ev);
    }

    if (value !== selectedOption) {
      setSelectedOption(value);
    }
  }, [selectedOption]);

  const onClickHandler = useCallback((ev: React.MouseEvent<HTMLSelectElement>) => {
    ev.stopPropagation();

    if (onClick) {
      onClick(ev);
    }
  }, []);

  const showError = state === 'error' && errorMessage;

  return (
    <StyledFieldGroup fullWidth={fullWidth}>
      {label && <InputLabel shrink={shrink}>{label}</InputLabel>}
      <StyledSelectWrapper hasLabel={label != null}>
        <StyledSelect
          {...selectProps}
          fullWidth={fullWidth}
          onBlur={onBlurHandler}
          onChange={onChangeHandler}
          onClick={onClickHandler}
          onFocus={onFocusHandler}
          onMouseEnter={onMouseEnterHandler}
          onMouseLeave={onMouseLeaveHandler}
          state={state}
          value={selectedOption}
        >
          {options.map((option) => (
            <option key={option.id} value={option.id}>{option.label}</option>
          ))}
        </StyledSelect>
        <StyledSelectArrow>
          <ChevronDownIcon />
        </StyledSelectArrow>
      </StyledSelectWrapper>
      {showError && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </StyledFieldGroup>
  );
}

export default memo(Select);
