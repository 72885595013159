import { FONT_FAMILY, FONT_SIZE, FONT_WEIGHT, NATURAL } from "@/theme";

import { createGlobalStyle } from "styled-components";

interface GlobalStylesProps {
  backgroundColor?: string;
}

export const GlobalStyles = createGlobalStyle<GlobalStylesProps>`
  html, body {
    height: 100%;
    margin: 0px;
    padding: 0;
  }

  body {
    background-color: ${({ backgroundColor }) => backgroundColor|| NATURAL.B};
    /* Set font defaults */
    color: ${NATURAL.I};
    font-family: ${FONT_FAMILY};
    font-size: ${FONT_SIZE.MD};
    font-weight: ${FONT_WEIGHT.REGULAR};
  
    /* Normalize.css: Correct the line height in all browsers */
    line-height: 1.15;
  
    /* Normalize.css: Prevent adjustments of font size after orientation changes in iOS */
    -webkit-text-size-adjust: 100%;
    -webkit-overflow-scrolling: touch;

    position: relative;
  }
  
  *,
  *:after,
  *:before {
    /* Use border-box for everything so working with margin/padding/width is easier */
    box-sizing: border-box;
  }

  /* Normalize.css: Add the correct display in IE 10 */
  [hidden] {
    display: none;
  }

  a {
    font-weight: normal;
  }

  button,
  [type='button'],
  [type='reset'],
  [type='submit'] {
    cursor: pointer;
    font-family: inherit;
    line-height: inherit;
    transition: background-color 0.2s ease-in-out;
  }

  /* Normalize.css: Remove the border on images inside links in IE 10 */
  img {
    border-style: none;
  }
`;

export default GlobalStyles;
