import { useCallback, useState } from "react";

import { OrderNote } from "@/types";

export interface NotesReturn {
  notes: OrderNote[];
  onAddNote: (note: OrderNote) => void;
  setNotes: (notes: OrderNote[]) => void;
}

const useNotes = (): NotesReturn => {
  const [notes, setNotes] = useState<OrderNote[]>([]);
  
  const onAddNote = useCallback((value: OrderNote) => {
    const existingNote = notes.find(({ id }) => id === value.id);

    if (!existingNote) {
      setNotes((state) => [...state, value]);
    } else {
      setNotes((state) => state.map((nt) => {
        if (nt.id === value.id) {
          return value
        }

        return nt;
      }));
    }
  }, [notes]);

  return {
    notes,
    onAddNote,
    setNotes,
  }
}

export default useNotes;