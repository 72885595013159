import { Alert, GlobalStyles, Loading } from "#/atoms";
import {
  Outlet,
  Route,
  Routes,
} from "react-router-dom";

import { BigCalendarView } from "../BigCalendar";
import { CalendarView } from "../Calendar";
import { CustomerDetailsView } from "../CustomerDetails";
import { CustomerOrdersView } from "../CustomerOrder";
import { CustomerView } from "../Customer";
import { CustomersView } from "../Customers";
import { DashboardView } from "../Dashboard";
import { ExpiredVirtualToursView } from "../ExpiredVirtualTours";
import { FindOrdersView } from "../FindOrders";
import { GenerateInvoicesView } from "../GenerateInvoices";
import { InvoicesView } from "../Invoices";
import { LoginView } from "../Login";
import { OrderSMSsView } from "../OrderSMSs";
import { OrderTypesView } from "../OrderTypes";
import { OrderView } from "../Order";
import { OrdersReportView } from "../OrdersReport";
import { OrdersView } from "../Orders";
import { OuttakesChosenView } from "../OuttakesChosen";
import RequireAdmin from "./RequireAdmin";
import RequireAuth from "./RequireAuth";
import { SettingsView } from "../Settings";
import { SignupView } from "../Signup";
import { TidyGuideView } from "../TidyGuide";
import { UsersView } from "../Users";
import { WorkerDetailsView } from "../WorkerDetails";
import { WorkersView } from "../Workers";
import { useApi } from "@/api";

const App = () => {
  const { errorMessage, loading, successMessage } = useApi();
  
  return (
    <>
      <GlobalStyles />
      {loading && <Loading />}
      {errorMessage && <Alert type="error">{errorMessage}</Alert>}
      {successMessage && <Alert type="success">{successMessage}</Alert>}
      <Routes>
        <Route
          path="/"
          element={<RequireAuth><DashboardView /></RequireAuth>}
        />
        <Route
          path="order-find"
          element={<RequireAuth><FindOrdersView /></RequireAuth>}
        />
        <Route
          path="orders-unmaintained"
          element={<RequireAuth><OrdersView /></RequireAuth>}
        />
        <Route
          path="customer-orders"
          element={<RequireAuth><CustomerOrdersView /></RequireAuth>}
        />
        <Route
          path="order-view-backend/order=:order"
          element={<RequireAuth><OrderView /></RequireAuth>}
        />
        <Route
          path="order-create"
          element={<RequireAdmin><OrderView /></RequireAdmin>}
        />
        <Route
          path="order-types"
          element={<RequireAdmin><OrderTypesView /></RequireAdmin>}
        />
        <Route
          path="customer-find"
          element={<RequireAdmin><CustomersView /></RequireAdmin>}
        />
        <Route
          path="customer-create"
          element={<RequireAdmin><CustomerView /></RequireAdmin>}
        />
        <Route
          path="account-customer/id=:id"
          element={<RequireAuth><CustomerDetailsView /></RequireAuth>}
        />
        <Route
          path="generate-invoices"
          element={<RequireAdmin><GenerateInvoicesView /></RequireAdmin>}
        />
        <Route
          path="invoices-find"
          element={<RequireAdmin><InvoicesView /></RequireAdmin>}
        />
        <Route index
          element={<RequireAuth><DashboardView /></RequireAuth>}
        />
        <Route
          path="users"
          element={<RequireAdmin><UsersView /></RequireAdmin>}
        />
        <Route
          path="workers"
          element={<RequireAdmin><WorkersView /></RequireAdmin>}
        />
        <Route
          path="account-worker/id=:id"
          element={<RequireAuth><WorkerDetailsView /></RequireAuth>}
        />
        <Route
          path="settings"
          element={<RequireAdmin><SettingsView /></RequireAdmin>}
        />
        <Route
          path="login"
          element={<LoginView />}
        />
        <Route
          path="register"
          element={<SignupView />}
        />
        <Route
          path="expired-virtual-tours"
          element={<RequireAuth><ExpiredVirtualToursView /></RequireAuth>}
        />
        <Route
          path="orders-notifications"
          element={<RequireAuth><OrderSMSsView /></RequireAuth>}
        />
        <Route
          path="orders-outtakes"
          element={<RequireAuth><OuttakesChosenView /></RequireAuth>}
        />
        <Route
          path="orders-report"
          element={<RequireAuth><OrdersReportView /></RequireAuth>}
        />
        <Route
          path="worker-calendar"
          element={<RequireAuth><CalendarView /></RequireAuth>}
        />
        <Route
          path="tidy-guide"
          element={<TidyGuideView />}
        />
        <Route
          path="big-calendar"
          element={<RequireAuth><BigCalendarView /></RequireAuth>}
        />
      </Routes>
      <Outlet />
    </>
  );
}

export default App;
