import { Card, PageWrapper, StyledUser } from "#/atoms";
import { DESKTOP_SPACING, NATURAL, PRIMARY_BLUE } from "@/theme";

import { StyledProgressBarProps } from "./Profile.interface";
import styled from "styled-components";

export const StyledProfile = styled(PageWrapper)`
  ${StyledUser} {
    margin-bottom: ${DESKTOP_SPACING.LG}px;
  }

  ${Card} {
    margin-bottom: ${DESKTOP_SPACING.XXXL}px;
  }
`;

export const StyledProgressBar = styled.div<StyledProgressBarProps>`
  background-color: ${NATURAL.C};
  height: 12px;
  border-radius: 6px;
  display: block;
  margin-top: ${DESKTOP_SPACING.LG}px;
  width: 100%;
  
  :before {
    background-color: ${PRIMARY_BLUE.DARK};
    border-radius: 6px;
    content: '';
    display: block;
    height: 12px;
    width: ${({ size }) => `${size}%`};
  }
`;
