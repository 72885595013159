import { Accordion, Button, Card } from "@/components/atoms";
import { FC, memo, useCallback } from "react";
import { StyledFileUpload, StyledImage } from "./CustomerLogoCard.styled";
//import { Heading, Icon, OrderState, Price } from "#/atoms";
import { CustomerLogoCardProps } from "./CustomerLogoCard.interface";
import { DESKTOP_SPACING } from "@/theme";
import { HeadingIcon } from "@/components/molecules";


const CustomerLogoCard: FC<CustomerLogoCardProps> = ({logoUrl, onFileUpload }) => {
    const handleClick = useCallback(() => {
        document.getElementById('logoUpload')?.click();
      }, []);

    const handleFileUpload = useCallback((ev) => {
        onFileUpload?.(ev.target.files[0]);
    }, []);

    return (
        <Card>
            <Accordion
                header={<HeadingIcon icon="logo" title="Watermark Logo" />}
                headerMargin={`${DESKTOP_SPACING.XXXL}px`}
                showArrow
                state={'open'}>
            {logoUrl && <div><StyledImage src={logoUrl} /></div>}
            <Button onClick={handleClick}>Upload New Logo</Button>
            <StyledFileUpload id="logoUpload"  type="file" accept="image/png" multiple={false} onChange={handleFileUpload} />
            </Accordion>
        </Card>
    );
}

export default memo(CustomerLogoCard);