import { FONT_SIZE, FONT_WEIGHT, NATURAL } from "@/theme";

import { StyledLinkProps } from "./Link.interface";
import styled from "styled-components";

export const StyledLink = styled.a<StyledLinkProps>`
  color: ${({ fontColor }) => fontColor || NATURAL.I};
  cursor: pointer;
  font-size: ${({ fontSize }) => fontSize || FONT_SIZE.MD};
  font-weight: ${({ fontWeight }) => fontWeight || FONT_WEIGHT.REGULAR};
  text-decoration: ${({ underline }) => underline ? 'underline' : 'none'};
`;
