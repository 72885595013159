import { FilterCustomer, FilterCustomers } from "@/components/organisms";
import { useCallback, useEffect, useState } from "react";

import { ExpiredVirtualTours } from "#/pages";
import { ListExpiredToursParams } from "@/api/notifications/interface";
import { VirtualTourExtensionItem } from "@/types";
import { useApi } from "@/api";
import { useFilter } from "@/hooks";

const ExpiredVirtualToursView = () => {
  const { notifications } = useApi();
  const {
    filter,
    numberOfFilters,
    onOpenFilter,
    shouldFilter,
    setShouldFilter,
    showFilter,
    ...filterProps
  } = useFilter<FilterCustomer>();
  const [loadingCustomers, setLoadingCustomers] = useState(false);
  const [called, setCalled] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const getVirtualTourList = async (filterData?: ListExpiredToursParams) => {
    setLoadingCustomers(true);
    await notifications.listExpiredTours(filterData);
    setLoadingCustomers(false);
  }

  useEffect(() => {
    if (notifications.virtualTourExtensionItems.length === 0 && !called) {
      setCalled(true);
      getVirtualTourList();
    }
  }, [notifications.virtualTourExtensionItems, called]);

  useEffect(() => {
    if (shouldFilter) {
      if (filter) {
        getVirtualTourList({
          page: currentPage,
        });
      } else {
        getVirtualTourList();
      }
      setShouldFilter(false);
    }
  }, [filter, currentPage, shouldFilter]);

  const handleShowMore = useCallback(() => {
    setCurrentPage(cp => cp + 1);
  }, [currentPage]);

  const createParams = (orderServiceId: string, virtualTourExtensionId?: string, abandoned?: boolean) : VirtualTourExtensionItem  => {
    const params: VirtualTourExtensionItem = {};
            
    if (virtualTourExtensionId) {
        params.virtualTourExtensionId = virtualTourExtensionId;
    } else {
        params.orderServiceId = orderServiceId;
    }
    params.abandoned = abandoned;

    return params;
  }

  const handleExtend = useCallback(async (orderServiceId: string, virtualTourExtensionId?: string) => {
    const item = createParams(orderServiceId, virtualTourExtensionId);
    await notifications.updateExpiredTours({
      virtualTourExtensionItems: [item]
    });
    await getVirtualTourList();
  }, []);

  const handleRemove = useCallback(async (orderServiceId: string, virtualTourExtensionId?: string) => {
    const item = createParams(orderServiceId, virtualTourExtensionId, true);
    await notifications.updateExpiredTours({
      virtualTourExtensionItems: [item]
    });
    await getVirtualTourList();
  }, []);

  return (
    <>
      <ExpiredVirtualTours
        expiredVirtualTours={notifications.virtualTourExtensionItems}
        loadingExpiredVirtualTours={loadingCustomers}
        numberOfFilters={numberOfFilters}
        onOpenFilter={onOpenFilter}
        onExtend={handleExtend}
        onRemove={handleRemove}
        onShowMore={handleShowMore}
      />
      {showFilter && <FilterCustomers {...filterProps} filter={filter} numberOfFilters={numberOfFilters} />}
    </>
  );
}

export default ExpiredVirtualToursView;