import { StyledFooter, StyledFooterContent } from "./Footer.styled";

import { FC } from "react";
import { FooterProps } from "./Footer.interface";

const Footer: FC<FooterProps> = ({ children }) => {
  return (
    <StyledFooter>
      <StyledFooterContent>
        {children}
      </StyledFooterContent>
    </StyledFooter>
  );
}

export default Footer;
