import { CustomerContacts } from "./customer";

export enum ORDER_STATE {
  AWAITING_DATE = -1, // Awaiting confirmation of date and time
  BOOKING_IN_PROGRESS = 0,
  BOOKING_PLACED = 1,
  COMPLETE = 2, // Work completed
  CONTENT_SELECTED = 3,
  AWAITING_PAYMENT = 4,
  ORDER_PAID = 5,
  RETURN_TO_WORK = 6,
  DRAFT = 99,
};

export interface Service {
  id: string;
  image: string;
  name: string;
  price: number;
}

export interface Order {
  badge: string;
  companyName: string;
  date: string;
  location: string;
  logoUrl?: string;
  id: string;
  price: number;
  services: Service[];
  state: ORDER_STATE;
  status: number;
  title: string;
}

export type CustomOrder = Pick<Order, 'id' | 'services' | 'title'>;

export enum CONTACT_TYPE {
  VENDOR,
  TENANT,
  AGENT,
}

export interface Contact {
  email: string;
  name: string;
  phone1: string;
  phone2: string | null;
  type?: CONTACT_TYPE;
}

export type OrderNewContact = Omit<CustomerContacts, 'contactId' | 'customerId'>;

export interface OrderContact extends Contact {
  amendedDateTime: string;
  createdDateTime: string;
  email: string;
  name: string;
  id: number;
  orderId: number;
  phone1: string;
  phone2: string | null;
  type: CONTACT_TYPE;
}

export interface OrderOfferedService {
  amendedDateTime: string;
  createdDateTime: string;
  files: number;
  imageURL: string;
  name: string;
  offeredServiceId: string;
  price: number;
  type: number;
  uploadFiles: OrderFile[];
}

export interface PropertyAddress {
  addressId: number;
  addressString: string | null;
  amendedDateTime: string;
  area: string | null;
  country: string | null;
  createdDateTime: string;
  doorNumber: string | null;
  flat: string | null;
  houseName: string | null;
  houseNumber: string | null;
  postcode: string | null;
  road: string | null;
  town: string | null;
}

export interface OrderHdrFile {
  fullURL: string;
  hdrSourceFileId: string;
  thumbURL: string;
}

export interface OrderFile {
  filePaymentStatus: number;
  fileStatus: number;
  fullURL: string;
  isUpsell: boolean;
  thumbURL: string;
  uploadFileId: string;
  upsellPrice: number;
}

export interface OrderService {
  amendedDateTime: string;
  amount: number;
  applicationUserId: string;
  applicationUserName: string;
  appointmentDate: string;
  appointmentDuration: number;
  complete: boolean;
  createdDateTime: string;
  description: string;
  draftFiles: number;
  draftUploadFiles: OrderFile[];
  externalLink: string | null;
  files: number;
  hdrSourceFiles: OrderHdrFile[];
  imageURL: string;
  isExtraService: boolean;
  label: string | null;
  name: string;
  notificationMessage: string | null;
  orderServiceId: string;
  price: number;
  rejectReason: string | null;
  rejected: boolean;
  serviceAdditionalFields: string | null;
  showConfirm: boolean;
  showSendMessage: boolean;
  sourceFiles: OrderFile[];
  type: number;
  uploadFiles: OrderFile[];
}

export interface OrderNote {
  created?: string;
  id: string;
  content: string;
  owner?: string;
}

export interface OrderDetails {
  accountDetails: {
    accountPassword?: string | null;
    accountPhoneNumber: string;
    active: boolean;
    accountsEmailAddress: string;
    daysToPayInvoices: number;
    firstName: string;
    lastName: string;
    name: string;
    sendEmailNotification?: boolean | null;
  }
  bedrooms: number;
  branchId: number;
  common: {
    appointmentDateTime: string;
    appointmentDuration: number;
    contactVendorDirectly: boolean;
    notifyViaSMS: boolean;
  }
  companyName: string;
  contacts: OrderContact[]
  createdDateTime: string;
  customerId: string | null;
  customerUserId: string;
  defaultAmount: number;
  description: string;
  floorArea: number;
  hasRejections: boolean;
  id: string;
  keys: boolean;
  keysTime: string;
  keysTimeOption: string;
  keysWith: string;
  keysWithDetail: string;
  maintainer: string | null;
  notes: string;
  offeredServices: OrderOfferedService[];
  orderNotes: OrderNote[];
  orderPrice: number;
  orderState: number;
  parking: string;
  propertyAddress: PropertyAddress;
  services: OrderService[];
  sizeProperty: number;
  trustedCustomer: boolean;
  upsellPrice: number;
}

export interface Maintainer {
  email: string;
  firstName: string;
  id: string;
  lastName: string;
}