import { FilterOrder, FilterOrders } from "#/organisms";
import { useCallback, useEffect, useMemo, useState } from "react";

import { OrderFilterParams } from "@/api/order/interface";
import { Orders } from "@/components/pages";
import { useApi } from "@/api";
import { useFilter } from "@/hooks";
import { useNavigate } from "react-router-dom";

const OrdersView = () => {
  const { customer, session } = useApi();
  const {
    filter,
    numberOfFilters,
    onOpenFilter,
    setShouldFilter,
    shouldFilter,
    showFilter,
    ...filterProps
  } = useFilter<FilterOrder>();
  const [loadingOrders, setLoadingOrders] = useState(false);
  const [called, setCalled] = useState(false);
  const navigate = useNavigate();

  const isAdmin = useMemo(() => session.user?.admin || false, [session.user]);
  
  const getOrders = async (filterData?: OrderFilterParams) => {
    setLoadingOrders(true);

    if (!session.user) {
      return
    }
    const branches = await customer.getBranches(session.user.id);

    if (branches.length > 0) {
      await customer.getOrderList({
        customerCode: filterData?.customerCode || '',
        customerEmail: filterData?.customerEmail || '',
        customerId: `${branches[0].BranchId}`,
        customerName: filterData?.customerName || '',
        dateFrom: filterData?.dateFrom || null,
        dateOrderTaken: filterData?.dateOrderTaken || null,
        dateTo: filterData?.dateTo || null,
        dateWorkToBeDone: filterData?.dateWorkToBeDone || null,
        maintainerId: filterData?.maintainerId || '',
        orderState: filterData?.orderState || null,
        propertyAddress: filterData?.propertyAddress || '',
      });
    }
    setLoadingOrders(false);
  }

  useEffect(() => {
    if (customer.customerOrders.length === 0 && !called) {
      setCalled(true);
      getOrders();
    }
  }, [customer.customerOrders, called]);

  useEffect(() => {
    if (shouldFilter) {
      if (filter) {
        getOrders(filter && {
          customerCode: filter.customerCode,
          customerEmail: filter.customerEmail,
          customerName: filter.companyName,
          dateFrom: filter.dateFrom,
          dateTo: filter.dateTo,
          orderState: filter.state || null,
          propertyAddress: filter.location,
        });
      } else {
        getOrders();
      }
      setShouldFilter(false);
    }
  }, [filter, shouldFilter]);

  const handleCreateNewOrder = useCallback(() => {
    navigate('/order-create', { replace: true });
  }, []);

  const handleSelectOrder = useCallback((orderId: string) => {
    navigate(`/order-view-backend/order=${orderId}`, { replace: true });
  }, []);

  return (
    <>
      <Orders
        isAdmin={isAdmin}
        loading={loadingOrders}
        numberOfFilters={numberOfFilters}
        orders={customer.customerOrders}
        onCreateNewOrder={handleCreateNewOrder}
        onOpenFilter={onOpenFilter}
        onSelectOrder={handleSelectOrder}
      />
      {showFilter && <FilterOrders {...filterProps} filter={filter} numberOfFilters={numberOfFilters} />}
    </>
  );
}

export default OrdersView;