import { useCallback, useState } from "react";

import { ApiHooksProps } from "../interface";
import { DashboardData } from "@/types";
import { request } from "../request";

const hook = ({ serverPath, token }: ApiHooksProps) => {
    const [dashboardData, setDashboardData] = useState<DashboardData | null>(null);
  
    const getDashboardData = useCallback(async () => {
      const path = `${serverPath}/dashboard/getData/`;
      const res = await request<never, DashboardData>(path, { method: 'GET', token });
      setDashboardData(res);
    }, [serverPath, token]);
  
    return {
        dashboardData,
        getDashboardData
    }
  }
  
  export default hook;