import { AccountApi, RegisterAccountParams, SendActivationCodeParams, UpdateAccountParams } from "./interface";

import { ApiHooksProps } from "../interface";
import { removeSpacesFromString } from "../utils";
import { request } from "../request";
import { useCallback } from "react";

const hook = ({ setError, setLoading, setSuccess, serverPath, token }: ApiHooksProps): AccountApi => {
  const getAccountDetails = useCallback(async (id: string) => {
    const path = `${serverPath}/accounts/user/${id}`;
    request(path, { method: 'GET', token });
  }, [serverPath, token]);

  const register = useCallback(async (data: RegisterAccountParams) => {
    const path = `${serverPath}/accounts/register/`;
    const body = {
      AccountType: data.type,
      ConfirmPassword: data.passwordConfirm,
      Email: data.email,
      FirstName: data.firstName,
      LastName: data.lastName,
      Password: data.password,
      UserName: data.email,
    };

    setLoading(true);
    try {
      await request(path, { body, token });
      setSuccess('User registered!')
    } catch (error) {
      if (typeof error === 'string') {
        setError(error);
      }
    }
    setLoading(false);

    return;
  }, [serverPath, token]);

  const sendActivationCode = useCallback(async (body: SendActivationCodeParams) => {
    const path = `${serverPath}/accounts/activate/`;

    setLoading(true);
    try {
      await request(path, { body, token });
      setSuccess('Activation code sent!')
    } catch (error) {
      if (typeof error === 'string') {
        setError(error);
      }
    }
    setLoading(false);

    return;
  }, [serverPath, token]);

  const updateAccountDetails = useCallback(async (id: string, data: UpdateAccountParams) => {
    const path = `${serverPath}/accounts/user/`;
    const body = {
      AccountPhoneNumber: removeSpacesFromString(data.accountPhoneNumber),
      DaysToPayInvoices: data.daysToPayInvoices || 0,
      DefaultPhotos: data.defaultPhotos || 0,
      Email: data.accountsEmailAddress || data.email,
      FirstNAme: data.firstName,
      Id: id,
      LastName: data.lastName,
      UserName: data.name || data.userName,
      UserUpsellPrice: data.userUpsellPrice || 0,
    };
    
    setLoading(true);
    try {
      await request(path, { body, token });
      setSuccess('User account updated!')
    } catch (error) {
      if (typeof error === 'string') {
        setError(error);
      }
    }
    setLoading(false);

    return;
  }, [serverPath, token]);

  return {
    getAccountDetails,
    register,
    sendActivationCode,
    updateAccountDetails,
  }
}

export default hook;