import { FC, memo } from "react";
import { StyledAlert, StyledCloseButton } from "./Alert.styled";

import { AlertProps } from "./Alert.interface";
import CheckIcon from "@/assets/icons/Check";
import CloseIcon from "@/assets/icons/Close";

const Alert: FC<AlertProps> = ({ children, type }) => {
  return (
    <StyledAlert type={type}>
      <CheckIcon />
      {children}
      <StyledCloseButton><CloseIcon /></StyledCloseButton>
    </StyledAlert>
  );
}

export default memo(Alert);
