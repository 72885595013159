import { DESKTOP_SPACING } from "@/theme";
import { StyledButton } from "../Button";
import { StyledButtonsGroup } from "../ButtonsGroup";
import { StyledHeading } from "../Typography/Heading";
import { StyledTypo } from "../Typography/Typo";
import styled from "styled-components";

export const StyledPageHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: ${DESKTOP_SPACING.XXL}px;

  ${StyledHeading} {
    order: 1;
    flex: 1;
  }

  ${StyledButton} {
    order: 2;
    width: auto;
  }

  ${StyledButtonsGroup} {
    order: 2;
    width: auto;
  }

  ${StyledTypo} {
    order: 3;
    width: 100%;
  }
`;
