import { OrderTemplate, OrderTemplateService } from "@/types";

import { ApiOrderTemplate } from "./interface.api";

export const mapApiOrderTemplate = (api: ApiOrderTemplate): OrderTemplate => {
  return {
    dea: api.DEA,
    description: api.Description,
    hip: api.HIP,
    id: `${api.OrderTemplateId}`,
    price: api.PriceExVAT,
    services: api.Services.reduce((acc: OrderTemplateService[], cur) => {
      if (cur.Checked) {
        return acc;
      }

      return [
        ...acc,
        {
          // checked: cur.Checked,
          // checkedTxt: cur.CheckedTxt,
          // defaultAmount: cur.DefaultAmount,
          imageUrl: cur.ImageURL,
          name: cur.Name,
          orderTypeId: cur.OrderTypeId,
        }
      ]
    }, []),
  }
}

export const mapApiOrderTemplates = (api: ApiOrderTemplate[]): OrderTemplate[] => {
  return api.map((orderTemplate) => mapApiOrderTemplate(orderTemplate));
}