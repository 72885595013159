import { useCallback, useState } from "react";

export interface DatepickerHookProps {
  value: Date | null;
  onChange: (date: Date) => void;
  setValue: (date: Date | null) => void;
}

const useDatepicker = (initialDate?: Date | null): DatepickerHookProps => {
  const [value, setValue] = useState<Date | null>(initialDate || null);

  const onChange = useCallback((date: Date) => {
    setValue(date);
  }, []);

  return {
    onChange,
    setValue,
    value,
  }
};


export default useDatepicker;
