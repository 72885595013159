import { Button, ButtonsGroup, InputText, Typo } from "#/atoms";
import { FC, memo, useCallback, useState } from "react";
import { StyledPerson, StyledPersonEmail, StyledPersonInfo, StyledPersonName } from "./FindPerson.styled";

import ChevronRightIcon from "@/assets/icons/ChevronRight";
import { DESKTOP_SPACING } from "@/theme";
import { FindPersonProps } from "./FindPerson.interface";
import { Modal } from "#/molecules";
import { useInput } from "@/hooks";

const FindPerson: FC<FindPersonProps> = ({ onClose, onFindPerson, onSelectPerson, persons }) => {
  const [isSearching, setIsSearching] = useState(false);
  const search = useInput();

  const handleSelectPerson = useCallback((id: string) => {
    onSelectPerson(id);
    onClose();
  }, []);

  const handleFindPerson = useCallback(() => {
    setIsSearching(true);
    onFindPerson(search.value)
  }, [search]);

  return (
    <Modal onClose={onClose} title="Find Person">
      <InputText label="Search Criteria - Name, Email, Login or Postcode" type="text" value={search.value} onChange={search.onChange} />
      {isSearching && persons.length === 0 && (
        <Typo>Could not find a person with this criteria.</Typo>
      )}
      {persons.map(({ id, name, email}) => {

        return (
          <StyledPerson key={id} onClick={() => handleSelectPerson(id)}>
            <StyledPersonInfo>
              <StyledPersonName>{name}</StyledPersonName>
              <StyledPersonEmail>{email}</StyledPersonEmail>
            </StyledPersonInfo>
            <ChevronRightIcon />
          </StyledPerson>
        );
      })}
      <ButtonsGroup fullWidth margin={`${DESKTOP_SPACING.EL}px 0 0`}>
        <Button actionType="outline" onClick={onClose}>Cancel</Button>
        <Button onClick={handleFindPerson}>Find</Button>
      </ButtonsGroup>
    </Modal>
  );
}

export default memo(FindPerson);
