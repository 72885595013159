import { useCallback, useEffect, useState } from "react";

import { OrderSMSs } from "#/pages";
import { SendMessageModal } from "#/organisms";
import { useApi } from "@/api";
import { useModal } from "@/hooks";

const OrderSMSsView = () => {
  const { notifications } = useApi();
  const { showModal, onCloseModal, onOpenModal } = useModal();
  const [replyMessageId, setReplyMessageId] = useState<string>();
  const [loadingMessages, setLoadingMessages] = useState(false);
  const [called, setCalled] = useState(false);

  const getMessageList = async () => {
    setLoadingMessages(true);
    await notifications.getMessages();
    setLoadingMessages(false);
  }

  useEffect(() => {
    if (notifications.smsMessages.length === 0 && !called) {
      setCalled(true);
      getMessageList();
    }
  }, [notifications.smsMessages, called]);

  useEffect(() => {
    getMessageList();
  }, []);

  const handleOpenReplyModal = useCallback((messageId: string) => {
    setReplyMessageId(messageId);
    onOpenModal();
  }, []);

  const handleConfirmInvite = useCallback(() => {
    onCloseModal();
  }, []);

  return (
    <>
      <OrderSMSs
        smsMessages={notifications.smsMessages}
        loadingMessages={loadingMessages}
        onOpenReplyModal={handleOpenReplyModal}
      />
      {showModal && (
        <SendMessageModal
          onClose={handleConfirmInvite}
          messageId={replyMessageId}
        />
      )}
    </>
  );
}

export default OrderSMSsView;