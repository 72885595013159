import { ExportFormat, ImageFormat, PhotoEditorSDKUI, UIEvent  } from "photoeditorsdk";
import { FC, memo } from "react";

import { EditPhotoProps } from './EditPhoto.interface';
import { Modal } from "#/molecules";

const EditPhoto: FC<EditPhotoProps> = ({
  imageUrl,
  onClose,
  onSavePhoto
}) => {
PhotoEditorSDKUI.init({
    assetBaseUrl: 'https://unpkg.com/photoeditorsdk/assets',
    container: '#editor',
    custom: {
        languages: {
            en: {
                mainCanvasActions :{
                    buttonExport: 'Save'
                }
            }
        },
        themes: {
            light: {
                button: {
                    activeForeground: '#0AAEC2',
                    containedPrimaryBackground: '#0AAEC2',
                    textPrimaryForeground: '#0AAEC2'
                },
                card: {
                    activeBorderColor: '#0AAEC2',
                },
                checkbox :{
                    activeBackground: '#0AAEC2',
                },
                primary : "#0AAEC2",
                selectColor: {
                    activeBorderColor: '#0AAEC2',
                },
                slider: {
                    activeTrackColor: '#0AAEC2',
                    thumbBorderColor: '#0AAEC2'
                },
                tabTitle: {
                    activeBorderColor: '#0AAEC2'
                },
                toolbar: {
                    activeForeground: '#0AAEC2'
                }
            }
        }
    },
    export: {
        image: {
          enableDownload: false,
          exportType: ExportFormat.BLOB,
          format: ImageFormat.JPEG
        }
    },
    image: imageUrl,
    license: '{"api_token":"kTjEgie46Hz9kneYvc0Nhw","app_identifiers":["test.photoplanbookings.com","photoplanbookings.com"],"available_actions":[],"domains":["https://api.photoeditorsdk.com"],"enterprise_license":false,"expires_at":null,"features":["camera","library","export","customassets","whitelabel","adjustment","brush","filter","focus","frame","overlay","sticker","text","textdesign","transform"],"issued_at":1650532404,"minimum_sdk_version":"1.0","owner":"Photoplan Limited","platform":"HTML5","products":["pesdk"],"version":"2.4","signature":"dVtSAcNvRwtAnvVqXEDYUKOrnvb8aTL5z5d7KJbUZjCZRPPggYnzQ6D69r0yhIuN0AzjuAAgeRQADVyoYpqxT6O0Jk5wdFiWav0EjXDUqvN8Nl5UIcdgXadwRzb7AkA6iSBcKJoq3L20xuff9dVl++DJAOIxHp6JqlH1qlIs88BypUVDTdpvyDKPKugpGcJp3IMylr1o1uPyQ2f5XYgQTMNKDl0Ar9MnPeAxm6ckGBU9DiLY0h9FlMRGD9qjKTA1OpBqcGrpPT+HJn0YLIgNP8LvaQhe882RsVtV/8PkTg3EdK4taAAZRUy9XH9B+Zt2ocI7QLS0W2Kl+WpLGK8X1R5DP9SEXDqh1hvGa+A7kKQunIuPHTmFy3rWzYZ15Tes3Bkql3+uBPmT2LxyXO1fejPlcegTUfW0ICftstlOL6wCYFEZRzFsMiwkBYdH9auyGgVCXY8a464O7wBSW9usu8kqT6/1ky1aUxKoDpwlnkz4+HtR3L0rrRssO+Wv4jyHflz1fWCdQ0HzRgHF/NTs54MZT2q9MAzwfi188yH/7QCkik5iZzTrtt3D70AV0NaX7Fs9lymvAnSrWlUr7uOA70rnSWwgAu4dZhKrcIQ8xJV/Yn3RBBWcPdRM7AIVHdbMpsiz7WHZmGkMRwVk/gamh4sqDaGnlGztz67gRAIMe8o="}',
    theme: 'light'
  }).then((editor) => {
    editor.on(UIEvent.EXPORT, async(image) =>{
        onSavePhoto(image);
      onClose();
    });
    editor.on(UIEvent.CLOSE, async () => {
        onClose();
    });
});
   


    return (
        <Modal onClose={onClose} title="Find Customers" width="1000px">
        <div
            id="editor"
            style={{ height: "100vh", width: "100vw" }} />
        </Modal>
    );
}

export default memo(EditPhoto);