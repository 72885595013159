import { FC, memo, useCallback, useState } from "react";
import { Icon, Notification } from "#/atoms";
import { MENU_ICON_COLOR, NATURAL } from "@/theme";
import {
  StyledArrowIcon,
  StyledMenu,
  StyledMenuHead,
  StyledMenuLink,
  StyledNavLink,
  StyledSubMenu,
  StyledSubMenuItem,
  StyledlinkLabel,
} from "./Menu.styled";

import { MenuProps } from "./Menu.interface";

import { useApi } from "@/api";
import { useInterval } from "@/hooks/useInterval";

const Menu: FC<MenuProps> = ({ icon, id, label, notification, onClick, path, state = 'closed', subMenu }) => {
  const { order, invoice } = useApi();

  const [open, setOpen] = useState(state !== 'closed');
  const [notificationState, setNotificationState] = useState(notification);

  if (id === 'orders')
  {
    useInterval(async () => {
      await order.getUnmaintainedList();
      setNotificationState(order.unmaintainedOrdersCount.toString());
     }, 5e3);
  }

  if (id === 'notifications')
  {
    useInterval(async () => {
      await order.getOrderOuttakesCount();
      setNotificationState(order.orderOuttakesCount.toString());
     }, 6e3);
  }
  
  if (id === 'invoices')
  {
    useInterval(async () => {
      await invoice.getInvoiceCount();
      setNotificationState(invoice.invoiceCount.toString());
     }, 9e3);
  }

  const handleMenuClick = useCallback((ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    ev.stopPropagation();
    ev.preventDefault();
    setOpen(!open)
  }, [open]);

  const handleNavLinkClick = useCallback((ev: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    ev.stopPropagation();

    if (onClick) {
      onClick();
    }
    
    setOpen(!open);
  }, [open]);
  
  return (
    <StyledMenu>
      <StyledMenuHead onClick={handleMenuClick}>
        {path ? (
          <StyledNavLink to={path} activeclassname="active" onClick={onClick}>
            <Icon fillColor={MENU_ICON_COLOR} type={icon} />
            <StyledlinkLabel>{label}</StyledlinkLabel>
          </StyledNavLink>
        ) : (
          <StyledMenuLink fontColor={NATURAL.C} onClick={onClick}>
            <Icon fillColor={MENU_ICON_COLOR} type={icon} />
            <StyledlinkLabel>{label}</StyledlinkLabel>
          </StyledMenuLink>
        )}
        {notificationState && <Notification>{notificationState}</Notification>}
        {subMenu && open && (
          <StyledArrowIcon><Icon fillColor={NATURAL.C} type="chevronDown" /></StyledArrowIcon>
        )}
        {subMenu && !open && (
          <StyledArrowIcon>
            <Icon fillColor={NATURAL.C} type="chevronUp" />
          </StyledArrowIcon>
        )}
      </StyledMenuHead>
      {subMenu && open && (
        <StyledSubMenu show={open}>
          {subMenu.map((sub) => {
            if (!sub.path) {
              return null
            };

            return (
              <StyledSubMenuItem key={sub.id}>
                <StyledNavLink to={sub.path} activeclassname="active" onClick={handleNavLinkClick}>{sub.label}</StyledNavLink>
                {sub.notification && <Notification>{sub.notification}</Notification>}
              </StyledSubMenuItem>
            )
          })}
        </StyledSubMenu>
      )}
    </StyledMenu>
  );
};

export default memo(Menu);