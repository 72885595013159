import { FC } from "react";
import { Spinner } from "@/assets";
import { StyledLoading } from "./Loading.styled";

const Loading: FC = () => {
  return (
    <StyledLoading>
      <Spinner />
    </StyledLoading>
  );
}

export default Loading;
