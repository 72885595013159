import { Accordion, Button, ButtonsGroup, Card, Heading, PageHeader, Typo } from "#/atoms";
import { FC, memo } from "react";
import { FONT_SIZE, HPOSITION, NATURAL, PRIMARY_BLUE } from "@/theme";

import { OrderPaymentProps } from "./Payment.interface";
import { StyledPayment } from "./Payment.styled";
import { SuccessIllustration } from "@/assets";

const Payment: FC<OrderPaymentProps> = () => {
  return (
    <>
      <PageHeader>
        <Heading scale="h3" fontColor={NATURAL.K}>Payment</Heading>
      </PageHeader>
      <Card padding="0">
        <StyledPayment>
          <SuccessIllustration />
          <Heading scale="h3" fontColor={NATURAL.K}>Payment is completed.</Heading>
          <Typo fontColor={NATURAL.G} fontSize={FONT_SIZE.MD}>Payment for 12 Photos and 1 Floor plan has been completed successfully.</Typo>
          <ButtonsGroup align={HPOSITION.CENTER}>
            <Button fullWidth={false}>View Client’s Invoice</Button>
          </ButtonsGroup>
        </StyledPayment>
      </Card>
      <Card>
        <Accordion
          header={<Heading scale="h4" fontColor={PRIMARY_BLUE.DARK}>Order Summary</Heading>}
          onToggle={() => null}
          showArrow
        >
          Summary details
        </Accordion>
      </Card>
    </>
  );
}

export default memo(Payment);
