import { Button, Card, Typo } from "#/atoms";
import { DESKTOP_SPACING, FONT_SIZE } from "@/theme";
import { FC, memo } from "react";

import FloorPlan from "./FloorPlan";
import { HeadingIcon } from "#/molecules";
import { OrderService } from "@/types/order";
import { OrderServiceCardProps } from "./OrderService.interface";
import Photo from "./Photo";
import { StyledOrderService } from "./OrderService.styled";
import VirtualTour from "./VirtualTour";

const OrderServiceCard: FC<OrderServiceCardProps> = ({
  offeredServices,
  onAskToChangeWorker,
  selectedWorker,
  services,
  ...events
}) => {
  let service: OrderService | undefined;
  let floorPlanService: OrderService | undefined;
  let virtualTourService: OrderService | undefined;
  const remainingServices: OrderService[] = [];

  services.forEach(s => {
    //TODO enum    
    switch(s.type) {
      case 1:
        service = s;
        break;
      case 2:
        floorPlanService = s;
        break;
      case 3:
        virtualTourService = s;
        break;
      default:
        remainingServices.push(s);
        break;
    }
  })

  const filteredOfferedServices = offeredServices.filter((value, index, self) => {
    return index === self.findIndex((t) => t.name === value.name)
  });

  return (
    <>
      <Card>
        <HeadingIcon
          icon="contacts"
          mb={`${DESKTOP_SPACING.XXL}px`}
          title="Photographer"
        />
        <StyledOrderService>
          <Typo fontSize={FONT_SIZE.SM} margin="0">Photographer: <strong>{selectedWorker ? selectedWorker.name : '--'}</strong></Typo>
          <Button buttonSize="md" actionType="ghost" onClick={onAskToChangeWorker}>Change Photographer</Button>
        </StyledOrderService>

        {service && (
          <Photo
            {...events}
            amount={service.amount}
            draftFiles={service.draftUploadFiles}
            name={service.name}
            serviceId={service.orderServiceId}
            uploadedFiles={service.uploadFiles}
          />
        )}

        {floorPlanService && (
          <FloorPlan
            {...events}
            amount={floorPlanService.amount}
            draftFiles={floorPlanService.draftUploadFiles}
            name={floorPlanService.name}
            serviceId={floorPlanService.orderServiceId}
            uploadedFiles={floorPlanService.uploadFiles}
          />
        )}

        {virtualTourService && (
          <VirtualTour
            {...events}
            amount={virtualTourService.amount}
            draftFiles={virtualTourService.draftUploadFiles}
            name={virtualTourService.name}
            serviceId={virtualTourService.orderServiceId}
            uploadedFiles={virtualTourService.uploadFiles}
          />
        )}
      </Card>
      {remainingServices.map((item) => {
        return (
          <Card key={item.orderServiceId}>
            <Photo
            {...events}
              amount={item.amount}
              draftFiles={item.draftUploadFiles}
              name={item.name}
              serviceId={item.orderServiceId}
              uploadedFiles={item.uploadFiles}
            />
          </Card>
        );
      })}
      {filteredOfferedServices.map((item) => {
        return (
          <Card key={item.offeredServiceId}>
            <Photo
              {...events}
              isOffered
              name={item.name}
              serviceId={item.offeredServiceId}
              uploadedFiles={item.uploadFiles}
            />
          </Card>
        );
      })}
    </>
  );
};

export default memo(OrderServiceCard);