import { Button, ButtonsGroup, Heading, InputText, Textarea } from "#/atoms";
import { FC, memo, useCallback } from "react";

import { Modal } from "#/molecules";
import { StyledSuport } from "./Support.styled";
import { SupportIllustration } from "@/assets";
import { SupportModalProps } from "./interface";
import { useInput } from "@/hooks";

const Support: FC<SupportModalProps> = ({ onClose, onSend }) => {
  const subject = useInput();
  const comment = useInput();

  const handleAsk = useCallback(() => {
    onSend(subject.value, comment.value)
  }, []);

  return (
    <Modal onClose={onClose}>
      <StyledSuport>
        <SupportIllustration />
        <Heading textAlign="center" scale="h3">Ask Question to Support</Heading>
        <InputText type="text" label="Subject" value={subject.value} onChange={subject.onChange} />
        <Textarea label="Comment" value={comment.value} onChange={comment.onChange} />
        <ButtonsGroup fullWidth>
          <Button onClick={handleAsk}>Ask</Button>
        </ButtonsGroup>
      </StyledSuport>
    </Modal>
  );
}

export default memo(Support);