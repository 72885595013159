export * from './colors';
export * from './media';
export * from './position';
export * from './sizes';
export * from './spacings';
export * from './typography';

export enum BORDER_RADIUS {
  XL = '20px',
  LG = '8px',
  MD = '4px',
  SM = '2px',
  NONE = '0'
}