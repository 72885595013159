import { Accordion, AccordionState } from "#/atoms";
import { FC, memo, useCallback, useState } from "react";

import OrderTemplate from "./OrderTemplate";
import PackageHeader from "./PackageHeader";
import { PackageProps } from "./Package.interface";
import { StyledPackage } from "./Package.styled";

const Package: FC<PackageProps> = (props) => {
  const [accordionState, setAccordionState] = useState<AccordionState>('open')
  const { orderTemplates, onClick } = props;

  const handleOnToggle = useCallback((state: AccordionState) => {
    setAccordionState(state)
  }, []);

  return (
    <StyledPackage>
      <Accordion
        bodyDirection="row"
        header={<PackageHeader {...props} />}
        onToggle={handleOnToggle}
        state={accordionState}
        showArrow
      >
        {orderTemplates.map((item) => {
          return (
            <OrderTemplate key={item.id} {...item} onClick={onClick} />
          );
        })}
      </Accordion>
    </StyledPackage>
  );
}

export default memo(Package);
