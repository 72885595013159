import { useEffect, useState } from "react";

import { SelectedCustomer } from "@/types";
import { useApi } from "@/api";

export interface CustomerReturn {
  selectedCustomer: SelectedCustomer | null;
  setSelectedCustomer: (customer: SelectedCustomer | null) => void;
}

const useNotes = (): CustomerReturn => {
  const [selectedCustomer, setSelectedCustomer] = useState<SelectedCustomer | null>(null);
  const [selectedOrder, setSelectedOrder] = useState<string | null>(null);

  const { customer, order, session } = useApi();

  const getBranchCustomer = async (customerUserId: string) => {
    const branches = await customer.getBranches( customerUserId);

    if (branches.length > 0 && branches[0].BranchId) {
      await customer.getData(`${branches[0].BranchId}`, customerUserId);
    }
  }

  const getCustomer = async (branchId: string, customerUserId: string) => {
    await customer.getData(branchId, customerUserId);
  }

  useEffect(() => {
    if (session.user && !session.user.admin) {
      getBranchCustomer(session.user.id);
    }
  }, [session.user])

  useEffect(() => {
    if (order.orderDetails && !selectedOrder) {
      const { branchId, customerUserId, id } = order.orderDetails;
      setSelectedOrder(id);
      getCustomer(`${branchId}`, `${customerUserId}`)
    }
  }, [order.orderDetails, selectedOrder]);

  useEffect(() => {
    const { customerData } = customer;
    const { orderDetails } = order;

    if (customerData && orderDetails) {
      setSelectedCustomer({
        branchId: `${orderDetails.branchId}`,
        firstName: customerData.accountDetails.firstName,
        id: `${orderDetails.customerUserId}`,
        lastName: customerData.accountDetails.lastName,
      });
    }
  }, [customer.customerData, order.orderDetails]);

  return {
    selectedCustomer,
    setSelectedCustomer,
  }
}

export default useNotes;