import { FC, memo } from "react";
import { PRIMARY_BLUE, TERTIARY_ORANGE, TERTIARY_PURPLE } from "@/theme";
import { StyledOrderState, StyledOrderStateText } from "./OrderState.styled";

import { ORDER_STATE } from "@/types";
import { OrderStateProps } from "./OrderState.interface";
import StateIcon from '@/assets/icons/Status';

const getStateText = (state: ORDER_STATE): string => {
  switch (state) {
    case ORDER_STATE.AWAITING_DATE: {
      return 'Awaiting confirmation of date and time';
    }
    case ORDER_STATE.BOOKING_IN_PROGRESS: {
      return 'Booking in Progress';
    }
    case ORDER_STATE.BOOKING_PLACED: {
      return 'Booking placed';
    }
    case ORDER_STATE.COMPLETE: {
      return 'Complete and Awaiting for Payment (Content Selected)';
    }
    case ORDER_STATE.CONTENT_SELECTED: {
      return 'Content selected';
    }
    case ORDER_STATE.AWAITING_PAYMENT: {
      return 'Awaiting payment';
    }
    case ORDER_STATE.ORDER_PAID: {
      return 'Order paid';
    }
    case ORDER_STATE.RETURN_TO_WORK: {
      return 'Return for Additional Work';
    }
    case ORDER_STATE.DRAFT: {
      return 'Draft completed'
    }
  }
}



const getStateColor = (state: ORDER_STATE) => {
  switch (state) {
    case ORDER_STATE.RETURN_TO_WORK:
    case ORDER_STATE.BOOKING_IN_PROGRESS: {
      return TERTIARY_ORANGE.DEFAULT;
    }
    case ORDER_STATE.BOOKING_PLACED: 
    case ORDER_STATE.AWAITING_DATE:
    case ORDER_STATE.AWAITING_PAYMENT: {
      return TERTIARY_PURPLE.DEFAULT;
    }
    case ORDER_STATE.CONTENT_SELECTED:
    case ORDER_STATE.ORDER_PAID:
    case ORDER_STATE.COMPLETE:
    case ORDER_STATE.DRAFT: {
      return PRIMARY_BLUE.DARK
    }
  }
}

const OrderState: FC<OrderStateProps> = ({ state }) => {
  const stateMessage = getStateText(state);
  const stateColor = getStateColor(state);

  return (
    <StyledOrderState color={stateColor}>
      <StateIcon />
      <StyledOrderStateText>{stateMessage}</StyledOrderStateText>
    </StyledOrderState>
  );
}

export default memo(OrderState);
