import { DESKTOP_SPACING, FONT_SIZE, NATURAL, PRIMARY_BLUE } from '@/theme';

import { StyledTextareaProps } from './Textarea.interface';
import styled from 'styled-components';

export const StyledTextarea = styled.textarea<StyledTextareaProps>`
  background: ${NATURAL.A};
  border-radius: 4px;
  border: 1px solid ${NATURAL.C};
  box-shadow: none;
  caret-color: ${PRIMARY_BLUE.DARK};
  color: ${NATURAL.I};
  display: block;
  font-size: ${FONT_SIZE.SM};
  line-height: 22px;
  margin: ${DESKTOP_SPACING.XXL}px 0;
  outline: none;
  padding: ${DESKTOP_SPACING.LG}px;
  width: ${({ fullWidth }) => fullWidth ? '100%' : 'auto'};

  &:focus {
    border: 1px solid ${PRIMARY_BLUE.DARK};
    box-shadow: 0px 0px 0px 2px ${PRIMARY_BLUE.LIGHT};
  }

  ::placeholder {
    opacity: 0;
  }

  :focus::placeholder {
    opacity: 1;
  }
`;
