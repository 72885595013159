import { Button, ButtonsGroup, InputText } from "#/atoms";
import { CheckboxGroup, Modal } from "#/molecules";
import { DESKTOP_SPACING, DIRECTION } from "@/theme";
import { FC, memo } from "react";
import { SendMessageProps } from "./SendMessage.interface";

const SendMessage: FC<SendMessageProps> = ({ messageId, onClose }) => {
  return (
    <Modal onClose={onClose} title="Send Message">
      <InputText label="Subject" type="text" />
      <InputText label="Message Text" type="text" />
      <CheckboxGroup direction={DIRECTION.COL} groupName="message" inputs={[
        { id: '0', label: 'Duplicate via SMS', name: 'duplicate', value: 'duplicate' },
        { id: '1', label: 'Send to Vendor', name: 'vendor', value: 'vendor' },
        { id: '2', label: 'Send to Tenant', name: 'tenant', value: 'tenant' },
        { id: '3', label: 'Send to Customer', name: 'customer', value: 'customer' },
      ]} />
      <ButtonsGroup fullWidth margin={`${DESKTOP_SPACING.EL}px 0 0`}>
        <Button actionType="solid" buttonColor="secondary">Send Message {messageId}</Button>
      </ButtonsGroup>
    </Modal>
  );
};

export default memo(SendMessage);