import { FC, memo } from "react";
import { StyledCloseButton, StyledModal, StyledModalBackdrop, StyledModalBodyScroll } from "./Modal.styled";

import CrossIcon from "@/assets/icons/Cross";
import { Heading } from "#/atoms";
import { ModalProps } from "./Modal.interface";

const Modal: FC<ModalProps> = ({ children, onClose, title, width }) => {
  return (
    <>
      <StyledModal width={width}>
        <StyledCloseButton onClick={onClose}>
          <CrossIcon />
        </StyledCloseButton>
        {title && <Heading scale="h3">{title}</Heading>}
        <StyledModalBodyScroll>
          {children}
        </StyledModalBodyScroll>
      </StyledModal>
      <StyledModalBackdrop onClick={onClose} />
    </>
  );
}

export default memo(Modal);
