import { DESKTOP_SPACING, NATURAL, SIDEBAR_PADDING, ZINDEX } from "@/theme";

import { StyledHeading } from "#/atoms/Typography";
import { StyledLink } from "#/atoms/Link";
import styled from "styled-components";

export const StyledSidebar = styled.section`
  background: ${NATURAL.A};
  bottom: 0;
  box-shadow: -10px 0px 20px rgba(42, 12, 78, 0.12);
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: ${SIDEBAR_PADDING};
  position: fixed;
  right: 0;
  top: 0;
  width: 320px;
  
  ${StyledHeading} {
    margin-bottom: ${DESKTOP_SPACING.SM}px;
  }

  ${StyledLink} {
    margin-left: ${DESKTOP_SPACING.MD}px;
  }
`;

export const StyledSidebarBackdrop = styled.div`
  background: ${NATURAL.A};
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  z-index: ${ZINDEX.SIDEBAR_INDEX};
`;

export const StyleFilterForm = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const StyleFilterFormFields = styled.div`
  flex: 1;
`;

export const StyledCloseButton = styled.button`
  align-items: center;
  background-color: ${NATURAL.A};
  border: 0;
  border-radius: 18px;
  color: ${NATURAL.G};
  display: flex;
  fill: ${NATURAL.G};
  height: 36px;
  position: absolute;
  right: 30px;
  top: 30px;
  width: 36px;
`;