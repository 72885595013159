import { useCallback, useState } from "react";

import { ApiHooksProps } from "../interface";
import { ApiUpsell } from "./interface.api";
import { Upsell } from "@/types";
import { UpsellApi } from "./interface";
import { request } from "../request";
import { upsellMapper } from './mappers';

const hook = ({ setError, setLoading, setSuccess, serverPath, token }: ApiHooksProps): UpsellApi => {
  const [upsell, setUpsell] = useState<Upsell | null>(null);
  
  const get = useCallback(async () => {
    const path = `${serverPath}/upsell/get/`;
    const res = await request<never, ApiUpsell>(path, { token });
    setUpsell(upsellMapper(res));
  }, [serverPath, token]);

  const set = useCallback(async (data: Upsell) => {
    const path = `${serverPath}/upsell/set/`;
    const body: ApiUpsell = {
      DefaultPhotos: data.defaultPhotos,
      Price: data.price,
      UplsellPriceId: data.id,
    };

    setLoading(true);
    try {
      await request(path, { body: JSON.stringify(body), token });
      setUpsell(data);
      setSuccess('Upsell updated!');
    } catch (error) {
      if (typeof error === 'string') {
        setError(error);
      }
    }
    setLoading(false);

    return;
  }, [serverPath, token, upsell]);

  return {
    get,
    set,
    upsell,
  }
}

export default hook;