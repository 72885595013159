import { DESKTOP_SPACING, FONT_SIZE, NATURAL } from "@/theme";

import { StyledOrderStateProps } from "./OrderState.interface";
import styled from "styled-components";

export const StyledOrderState = styled.div<StyledOrderStateProps>`
  align-items: center;
  color: ${({ color }) => color};
  display: flex;
  gap: ${DESKTOP_SPACING.SM}px;
  fill: ${({ color }) => color};
`;

export const StyledOrderStateText = styled.span`
  color: ${NATURAL.G};
  font-size: ${FONT_SIZE.SM};
`