import { Datepicker, FilterSidebar } from "#/molecules";
import { FC, memo, useCallback } from "react";
import { InputText, Select } from "#/atoms";
import { useDatepicker, useInput, useSelect } from "@/hooks";

import { FilterOrdersProps } from "./Orders.interface";
import { ORDER_STATE } from "@/types";
import { getOrderStateText } from "@/helpers";

const Orders: FC<FilterOrdersProps> = ({ filter, numberOfFilters, onCloseFilter, onClearFilter, onFilter }) => {
  const companyName = useInput(filter?.companyName);
  const customerCode = useInput(filter?.customerCode);
  const customerEmail = useInput(filter?.customerEmail);
  const dateFrom = useDatepicker(filter?.dateFrom ? new Date(filter?.dateFrom) : null);
  const dateTo = useDatepicker(filter?.dateTo ? new Date(filter?.dateTo) : null);
  const jobNumber = useInput(filter?.jobNumber);
  const location = useInput(filter?.location);
  const orderRef = useInput(filter?.orderRef);
  const state = useSelect(getOrderStateText(filter?.state));
  const workerId = useInput(filter?.workerId);

  const handleFilter = useCallback(() => {
    onFilter({
      companyName: companyName.value,
      customerCode: customerCode.value,
      customerEmail: customerEmail.value,
      dateFrom: dateFrom.value?.toString() || '',
      dateTo: dateTo.value?.toString() || '',
      jobNumber: jobNumber.value,
      location: location.value,
      orderRef: orderRef.value,
      state: state.value as unknown as ORDER_STATE,
      workerId: workerId.value
    });
  }, [
    companyName,
    customerCode,
    customerEmail,
    dateFrom,
    dateTo,
    jobNumber,
    location,
    orderRef,
    state,
    workerId
  ]);

  return (
    <FilterSidebar
      numberOfFilters={numberOfFilters}
      onClearFilters={onClearFilter}
      onClose={onCloseFilter}
      onFilter={handleFilter}
    >
      <InputText type="text" label="Job Number" fullWidth value={jobNumber.value} onChange={jobNumber.onChange} />
      <InputText type="text" label="Customer Code" fullWidth value={customerCode.value} onChange={customerCode.onChange} />
      <InputText type="text" label="Company Name" fullWidth value={companyName.value} onChange={companyName.onChange} />
      <InputText type="text" label="Customer E-mail" fullWidth value={customerEmail.value} onChange={customerEmail.onChange} />
      <InputText type="text" label="Photoplanner " fullWidth value={workerId.value} onChange={workerId.onChange} />
      
      <InputText type="text" label="Package" fullWidth value={orderRef.value} onChange={orderRef.onChange} />
      <InputText type="text" label="Address Details" fullWidth value={location.value} onChange={location.onChange} />
      <Select label="Order Status" options={[]} selected={state.value} onChange={state.onChange} />
      <Datepicker label="Date Order Taken" date={dateFrom.value} onChange={dateFrom.onChange} />
      <Datepicker label="Date Work To Be Done" date={dateTo.value} onChange={dateTo.onChange} />
    </FilterSidebar>
  );
}

export default memo(Orders);
