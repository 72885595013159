import {
  Calendar,
  Views,
  momentLocalizer,
} from 'react-big-calendar'
import { FC, memo, useMemo } from "react";
import { Heading, PageHeader, PageWrapper } from "#/atoms";
import { BigCalendarProps } from "./BigCalendar.interface";
import { NATURAL } from "@/theme";
import React from 'react';
import moment from 'moment';
import * as dates from 'react-big-calendar/lib/utils/dates'

import "./hack.css";

const BigCalendar: FC<BigCalendarProps> = ({
  calendarId
}) => {

  const localizer = momentLocalizer(moment) // or globalizeLocalizer

  const ColoredDateCellWrapper = ({ children }) =>
  React.cloneElement(React.Children.only(children), {
    style: {
      backgroundColor: 'lightblue',
    },
  })

  const myEventsList = [
    {
      end: '2022-10-12T14:00:00-05:00',
      start: '2022-10-12T13:45:00-05:00',
      title: 'My Event'
    }
  ]

  
  const { components, defaultDate, max, views } = useMemo(
    () => ({
      components: {
        timeSlotWrapper: ColoredDateCellWrapper,
      },
      defaultDate: new Date(2022, 10, 10),
      max: dates.add(dates.endOf(new Date(2022, 10, 10), 'day'), -1, 'hours'),
      views: Object.keys(Views).map((k) => Views[k]),
    }),
    []
  )

  return (
    <PageWrapper>
      <PageHeader>
        <Heading scale="h1" fontColor={NATURAL.K}>Calendar {calendarId}</Heading>
      </PageHeader>
      <div className="height600">
        <Calendar
          components={components}
          defaultDate={defaultDate}
          localizer={localizer}
          events={myEventsList}
          max={max}
          startAccessor="start"
          endAccessor="end"
          showMultiDayTimes
          step={60}
          views={views}
          />
        </div>
    </PageWrapper>
  );
};

export default memo(BigCalendar);