import { CreateUserParams, GetUsersParams } from "@/api/user/interface";
import { FilterUser, FilterUsers, UserModal } from "#/organisms";
import { useCallback, useEffect, useState } from "react";
import { useFilter, useModal } from "@/hooks";

import { Users } from "#/pages";
import { useApi } from "@/api";

const UsersView = () => {
  const { user } = useApi();
  const {
    filter,
    numberOfFilters,
    onOpenFilter,
    setShouldFilter,
    shouldFilter,
    showFilter,
    ...filterProps
  } = useFilter<FilterUser>();
  const { showModal, onCloseModal, onOpenModal } = useModal();
  const [loadingUser, setLoadingUsers] = useState(false);
  const [currentUserId, setCurrentUserId] = useState<string | undefined>();
  const [called, setCalled] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const getUsersList = async (filterData?: GetUsersParams) => {
    setLoadingUsers(true);
    await user.getUsers(filterData);
    setLoadingUsers(false);
  }

  useEffect(() => {
    if (user.users.length === 0 && !called) {
      setCalled(true)
      getUsersList();
    }
  }, [user.users, called]);

  useEffect(() => {
    if (shouldFilter) {
      if (filter) {
        getUsersList({
          displayName: filter.name,
          isActive: filter.active || null,
          isAdmin: filter.admin || null,
          loginName: filter.email,
          page: '',
        });
      } else {
        getUsersList();
      }
      setShouldFilter(false);
    }
  }, [filter, shouldFilter]);

  const handleNewUser = useCallback(async (newUser: CreateUserParams) => {
    if (isEdit) {
      await user.createUser(newUser);
    } else {
      await user.createUser(newUser);
    }
  
    onCloseModal();
  }, []);

  const handleEditUser = useCallback(async (userId: string) => {
    setIsEdit(true);
    setCurrentUserId(userId);
    onOpenModal();
  }, []);

  const handleDeleteUser = useCallback(async (userId: string) => {
    console.log(userId);
    onCloseModal();
  }, []);

  return (
    <>
      <Users loading={loadingUser} users={user.users} numberOfFilters={numberOfFilters}
          onNewUser={onOpenModal} onOpenFilter={onOpenFilter} onOpenEditModal={handleEditUser} onDelete={handleDeleteUser}  />
      {showFilter && <FilterUsers {...filterProps} filter={filter} numberOfFilters={numberOfFilters} />}
      {showModal && (
        <UserModal onClose={onCloseModal} onSubmit={handleNewUser} type={isEdit ? 'edit' : 'create'} userId={currentUserId} />
      )}
    </>
  );
}

export default UsersView;