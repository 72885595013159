import { Calendar } from "@/components/pages/Calendar";


const CalendarView = () => {

    return (
        <Calendar></Calendar>
    );
}

export default CalendarView;