import { FC, memo, useCallback } from "react";
import { InputText, Select } from "#/atoms";
import { getBoleanFromYesOrNo, getYesOrNo } from "@/helpers";
import { useInput, useSelect } from "@/hooks";

import { FilterSidebar } from "#/molecules";
import { FilterUsersProps } from "./Users.interface";

const Users: FC<FilterUsersProps> = ({ filter, numberOfFilters, onCloseFilter, onClearFilter, onFilter }) => {
  const active = useSelect(getYesOrNo(filter?.active));
  const admin = useSelect(getYesOrNo(filter?.admin));
  const name = useInput(filter?.name);
  const email = useInput(filter?.email);
  const location = useInput(filter?.location);

  const handleFilter = useCallback(() => {
    onFilter({
      active: getBoleanFromYesOrNo(active.value),
      admin: admin.value === 'yes' ? true : admin.value === 'no' ? false : null,
      email: email.value,
      location: location.value,
      name: name.value,
    });
  }, [active, name, admin, email, location]);

  return (
    <FilterSidebar
      numberOfFilters={numberOfFilters}
      onClearFilters={onClearFilter}
      onClose={onCloseFilter}
      onFilter={handleFilter}
    >
     <InputText type="text" label="Display Name" fullWidth value={name.value} onChange={name.onChange} />
      <InputText type="text" label="E-mail Address" fullWidth value={email.value} onChange={email.onChange} />
      <InputText type="text" label="Postcode" fullWidth value={location.value} onChange={location.onChange} />
      <Select
        label="System Administrator"
        selected={admin.value}
        options={[
          { id: '', label: '' },
          { id: 'no', label: 'No' },
          { id: 'yes', label: 'Yes' },
        ]}
        onChange={admin.onChange}
      />
      <Select
        label="Is Active"
        selected={active.value}
        options={[
          { id: '', label: '' },
          { id: 'no', label: 'No' },
          { id: 'yes', label: 'Yes' },
        ]}
        onChange={active.onChange}
      />
    </FilterSidebar>
  );
}

export default memo(Users);
