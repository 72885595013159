import { ApiNewOrderType, ApiOrderType, ApiOrderTypeResponse } from "./interface.api";
import { mapOrderTypeResponse, mapOrderTypesSimpleResponse } from "./mappers";
import { request, sendFile } from "../request";
import { useCallback, useState } from "react";

import { ApiHooksProps } from "../interface";
import { NewOrderTypeParam } from "./interface";
import { OrderType } from "@/types";

const hook = ({ setError, setLoading, setSuccess, serverPath, token }: ApiHooksProps) => {
  const [orderTypes, setOrderTypes] = useState<OrderType[]>([]);

  const deleteOrderType = useCallback(async (id: string) => {
    const path = `${serverPath}/order-types/delete/`;
    const body = {
      'OrderTypeId' : id
    };

    setLoading(true);
    try {
      await request(path, { body, token });
      setOrderTypes(orderTypes.filter((orderType) => orderType.id !== id));
      setSuccess('Order Type deleted!');
    } catch (error) {
      if (typeof error === 'string') {
        setError(error);
      }
    }
    setLoading(false);

    return;
  }, [serverPath, token, orderTypes]);

  const getEditList = useCallback(async (customerId?: string) => {
    const path = `${serverPath}/order-types/edit-list/`;
    const body = { CustomerApplicationUserId: customerId || '' };

    try {
      const res = await request<{ CustomerApplicationUserId: string }, ApiOrderType[]>(path, { body, token });
      setOrderTypes(mapOrderTypesSimpleResponse(res));
    } catch (error) {
      if (typeof error === 'string') {
        setError(error);
      }
    }
  }, [serverPath, token]);

  // const getList = useCallback(async () => {
  //   const path =  `${serverPath}/order-types/list/`;

  //   try {
  //     const res = await request<never, ApiOrderType[]>(path, { token });
  
  //     console.log(res)
  //     setOrderTypes(mapOrderTypesResponse(res));
  //   } catch (error) {
  //     if (typeof error === 'string') {
  //       setError(error);
  //     }
  //   }
  // }, [serverPath, token]);

  const createOrderType = useCallback(async (data: NewOrderTypeParam) => {
    const body: ApiNewOrderType = {
      ImageURL: data.imageUrl,
      Name: data.name,
      Price: data.price,
    }
    const path = `${serverPath}/order-types/set/`;

    setLoading(true);
    try {
      const res = await request<ApiNewOrderType, ApiOrderTypeResponse>(path, { body, token });
      const newOrderType = mapOrderTypeResponse(res);
      setOrderTypes([...orderTypes, newOrderType]);
      setSuccess('Order Type created!');

      return newOrderType.id;
    } catch (error) {
      if (typeof error === 'string') {
        setError(error);
      }
    }
    setLoading(false);

    return '';
  }, [serverPath, token, orderTypes]);

  const setOrderType = useCallback(async (data: OrderType) => {
    const path = `${serverPath}/order-types/set/`;
    const body: ApiNewOrderType = {
      ImageURL: data.imageUrl,
      Name: data.name,
      OrderTypeId: data.id,
      Price: data.price,
    }
    
    setLoading(true);
    
    const res = await request<ApiNewOrderType, ApiOrderTypeResponse>(path, { body, token });
    const newOrderType = mapOrderTypeResponse(res);
    setOrderTypes(orderTypes.map((orderType) => {
      if (orderType.id === data.id) {
        return newOrderType;
      }

      return orderType;
    }));
    setSuccess('Order Type updated!');
    
    setLoading(false);

    return newOrderType;

  }, [serverPath, token, orderTypes]);

  const uploadImage = useCallback(async (image: Blob, orderTypeId: string) => {
    const path = `${serverPath}/order-types/upload-image/${orderTypeId}`;

    await sendFile<ApiOrderType>(path, image, token);
  }, [serverPath, token]);

  return {
    createOrderType,
    deleteOrderType,
    getEditList,
    // getList,
    orderTypes,
    setOrderType,
    uploadImage
  }
}

export default hook;