import { Alert, Button, ButtonsGroup, GlobalStyles, Heading, InputSelect, InputText, Typo } from "#/atoms";
import { DESKTOP_SPACING, HPOSITION, NATURAL } from "@/theme";
import { FC, memo, useCallback, useState } from "react";
import GoogleLogin, { GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login';
import { StyledGoogleButton, StyledGoogleIcon, StyledGoogleText, StyledLogin, StyledLoginForm, StyledLogo, StyledSignup, StyledSignupForm, StyledSignupImage } from "./Login.styled";
import { useInput, useInputSelect } from "@/hooks";

import Google from "@/assets/icons/Google";
import { LoginProps } from "./Login.interface";
import { PhotoplanLogo } from "#/atoms";
import bgImage from "@/assets/bg.jpeg";

const googleClientId = '582449076537-k670t2ahjn2kt559aoiphl0pgbq0n3qv.apps.googleusercontent.com';

const Login: FC<LoginProps> = ({ onGoogleSuccess, onSignup, onSubmit }) => {
  const [error, setError] = useState<string | null>(null);
  const login = useInput();
  const password = useInput();
  const rememberMe = useInputSelect('remember')

  const handleLoginClick = useCallback(() => {
    setError(null);

    if (!login.value) {
      login.setError('Field email is required!');
      
      return;
    }

    if (!password.value) {
      login.setError('Field password is required!');
      
      return;
    }

    onSubmit(login.value, password.value, rememberMe.checked)
  }, [login, password, rememberMe]);

  const handleGoogleFailure = useCallback((response: { details: string }) => {
    setError(response.details);
  }, [login, password, rememberMe]);

  const handleGoogleSuccess = useCallback((response: GoogleLoginResponse | GoogleLoginResponseOffline) => {
    setError(null)
    onGoogleSuccess(response);
  }, [login, password, rememberMe]);
  
  return (
    <>
      <GlobalStyles backgroundColor={NATURAL.A} />
      {error && <Alert type="error">{error}</Alert>}
      <StyledLogin>
        <StyledLogo>
          <PhotoplanLogo color={NATURAL.K} />
        </StyledLogo>
        <StyledLoginForm>
          <Heading scale="h1" fontColor={NATURAL.K}>Login to Your Account</Heading>
          <InputText type="email" label="Login" name="email" {...login} />
          <InputText type="password" label="Password" name="password" {...password} />
          <InputSelect
            checked={rememberMe.checked}
            type="checkbox"
            label="Remember me?"
            value={rememberMe.value}
            onChangeValue={rememberMe.onChange}
          />
          <ButtonsGroup align={HPOSITION.CENTER} fullWidth margin={`${DESKTOP_SPACING.XXL}px 0 0`}>
            <GoogleLogin
              clientId={googleClientId}
              render={renderProps => (
                <StyledGoogleButton
                  disabled={renderProps.disabled}
                  onClick={renderProps.onClick}
                >
                  <StyledGoogleIcon>
                    <Google />
                  </StyledGoogleIcon>
                  <StyledGoogleText>
                    Sign in with Google
                  </StyledGoogleText>
                  
                </StyledGoogleButton>
              )}
              onSuccess={handleGoogleSuccess}
              onFailure={handleGoogleFailure}
            />
            <Button onClick={handleLoginClick}>Login</Button>
          </ButtonsGroup>
        </StyledLoginForm>
      </StyledLogin>
      <StyledSignup>
        <StyledSignupForm>
          <Heading scale="h1" fontColor={NATURAL.A}>New Here?</Heading>
          <Typo fontColor={NATURAL.A}>Text to explaining the reasons to Sign.</Typo>
          <ButtonsGroup align={HPOSITION.CENTER} fullWidth>
            <Button onClick={onSignup}>Sign Up</Button>
          </ButtonsGroup>
        </StyledSignupForm>
        <StyledSignupImage src={bgImage} />
      </StyledSignup>
    </>
  );
}

export default memo(Login);
