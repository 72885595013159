import styled from "styled-components";

export const StyledImage = styled.img`
height: 40px;
`;

export const StyledFileUpload = styled.input`
  left: -90000px;
  position: absolute;
`;

