import { DESKTOP_SPACING, FONT_SIZE, NATURAL } from "@/theme";
import styled, { css } from "styled-components";

export const StyledFieldGroup = styled.div<{ fullWidth?: boolean }>`
  display: inline-flex;
  flex-direction: column;
  position: relative;
  vertical-align: top;
  width: ${({ fullWidth = true }) => fullWidth ? '100%' : 'auto'};
`

const InputLabel = styled.label<{ shrink: boolean }>`
  color: ${NATURAL.E};
  font-size: ${FONT_SIZE.XS};
  left: 0;
  margin-top: 0;
  pointer-events: none;
  position: absolute;
  top: 2px;
  transform-origin: top left;
  transition: color 0.25s cubic-bezier(0, 0, 0.2, 1) 0ms;
  z-index: 3;

  ${({ shrink }) =>
    shrink
      ? css`
          left: 0;
          top: 2px;
          transform-origin: top left;
        `
      : css`
          font-size: ${FONT_SIZE.SM};
          left: ${DESKTOP_SPACING.LG}px;
          top: 34px;
          transform-origin: top left;
        `
  }
`;

export default InputLabel;