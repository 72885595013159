import { DESKTOP_SPACING, FONT_SIZE, NATURAL, PRIMARY_BLUE, SECONDARY_RED } from "@/theme";
import { StyledSelectProps, StyledSelectWrapperProps } from "./Select.interface";

import styled from "styled-components";

export const StyledSelectLabel = styled.label`
  font-size: 14px;
  line-height: 2;
  margin-bottom: 5px;
`;

export const StyledSelectWrapper = styled.div<StyledSelectWrapperProps>`
  display: flex;
  margin: ${({ hasLabel}) => hasLabel ? `${DESKTOP_SPACING.XXL}px 0` : '0'};
  position: relative;
  width: 100%;
`;

export const StyledSelect = styled.select<StyledSelectProps>`
  appearance: none;
  background: ${NATURAL.A};
  border: 1px solid ${({ state }) => state === 'error' ? SECONDARY_RED.DEFAULT : NATURAL.C};
  border-radius: 4px;
  color: ${NATURAL.I};
  cursor: pointer;
  display: flex;
  font-size: ${FONT_SIZE.SM};
  height: 44px;
  line-height: 20px;
  outline: none;
  padding: ${DESKTOP_SPACING.LG}px ${DESKTOP_SPACING.XXL}px ${DESKTOP_SPACING.LG}px ${DESKTOP_SPACING.LG}px;
  position: relative;
  width: ${({ fullWidth }) => fullWidth ? '100%' : 'auto'};
  z-index: 1;

  ${({ state }) => state === 'error' ? `box-shadow: 0px 0px 0px 2px ${SECONDARY_RED.LIGHTER};` : ''}

  &:focus {
    border: 1px solid ${PRIMARY_BLUE.DARK};
    box-shadow: 0px 0px 0px 2px ${PRIMARY_BLUE.LIGHT};
  }
`;

export const StyledSelectArrow = styled.span`
  position: absolute;
  right: 16px;
  top: 30%;
  z-index: 2;
`;