import { ChangeEvent, useCallback, useState } from "react";

export interface AddressReturnProps {
  address: string;
  mapsAddress: string;
  onBlur: () => void;
  onChange: (ev: ChangeEvent<HTMLInputElement>) => void;
  setAddress: (address: string) => void;
}

const useMaps = (initialAddress?: string): AddressReturnProps => {
  const [address, setAddress] = useState(initialAddress || '');
  const [mapsAddress, setMapsAddress] = useState('');

  const onChange = useCallback((ev: ChangeEvent<HTMLInputElement>) => {
    setAddress(ev.target.value)
  }, []);

  const onBlur = useCallback(() => {
    if (address) {
      setMapsAddress(address);
    }
  }, [address]);

  return {
    address,
    mapsAddress,
    onBlur,
    onChange,
    setAddress,
  }
};


export default useMaps;
