import {
  AccountDetailsCard,
  AdditionalInfoCard,
  ContactsCard,
  DateAndTimeCard,
  KeysCard,
  NotesCard,
  OrderServiceCard,
  OrderStatusCard,
  PackageCard,
  PropertyAddressCard
} from "../Cards";
import { Button, ButtonsGroup, Heading, PageHeader, Typo } from "#/atoms";
import { FC, memo } from "react";
import { FONT_SIZE, NATURAL } from "@/theme";

import { ORDER_STEP } from "../Order/Order.interface";
import { OrderBookingAndProgressProps } from "./BookingAndProgress.interface";

const Progress: FC<OrderBookingAndProgressProps> = ({
  activeStep,
  addressString,
  appointmentDate,
  appointmentDuration,
  appointmentTime,
  bedrooms,
  coordinator,
  daysToPayInvoices,
  email,
  firstName,
  lastName,
  login,
  phoneNumber,
  contacts,
  contactVendorDirectly,
  doorNumber,
  flat,
  floorArea,
  keyNotes,
  keys,
  keysDate,
  keysTime,
  keysTimeOption,
  keysWith,
  keysWithDetail,
  notes,
  notifyViaSMS,
  offeredServices,
  orderState,
  onAddCustomerContact,
  onAddNote,
  onAskToChangeWorker,
  onAskToEditContact,
  onAskToRemoveContact,
  onAskToAddNewContact,
  onChangePackage,
  onChangeRole,
  onDeleteFiles,
  onDownloadFiles,
  onTakeRole,
  onNotifyCustomer,
  onUploadFiles,
  parking,
  selectedOrderTemplate,
  selectedWorker,
  services
}) => {
  const title = activeStep === ORDER_STEP.PROGRESS ? 'Progress' : 'Fill in Information to Book Your Time';
  const text = activeStep === ORDER_STEP.PROGRESS ? 'Your progress will be updated here.' : 'Please provide us information about your order.';
  const accordionState = 'open';

  return (
    <>
      <PageHeader>
        <Heading scale="h1" fontColor={NATURAL.K}>{title}</Heading>
        <Typo fontColor={NATURAL.G} fontSize={FONT_SIZE.SM}>{text}</Typo>
        <ButtonsGroup>
          <Button actionType='outline' onClick={onChangePackage}>Change Package</Button>
          {activeStep === ORDER_STEP.PROGRESS && <Button onClick={onAskToChangeWorker}>Assign Photographer for All Services</Button>}
        </ButtonsGroup>
      </PageHeader>
      
      <PropertyAddressCard
        accordionState={accordionState}
        addressString={addressString}
        flat={flat}
        doorNumber={doorNumber}
      />
      <DateAndTimeCard
        accordionState={accordionState}
        appointmentDate={appointmentDate}
        appointmentTime={appointmentTime}
        appointmentDuration={appointmentDuration}
        contactVendorDirectly={contactVendorDirectly}
        notifyViaSMS={notifyViaSMS}
      />

      {activeStep === ORDER_STEP.PROGRESS && (
        <OrderStatusCard
          coordinator={coordinator}
          onChangeRole={onChangeRole}
          onTakeRole={onTakeRole}
          orderState={orderState} 
        />
      )}

      {activeStep === ORDER_STEP.PROGRESS && selectedOrderTemplate && (
        <PackageCard
          onNotifyCustomer={onNotifyCustomer}
          order={selectedOrderTemplate}
        />
      )}
      {activeStep === ORDER_STEP.PROGRESS && (
        <OrderServiceCard
          offeredServices={offeredServices}
          onAskToChangeWorker={onAskToChangeWorker}
          onDeleteFiles={onDeleteFiles}
          onDownloadFiles={onDownloadFiles}
          onUploadFiles={onUploadFiles}
          selectedWorker={selectedWorker}
          services={services}
        />
      )}
      {activeStep === ORDER_STEP.PROGRESS && (
        <AccountDetailsCard
          accordionState={accordionState}
          daysToPayInvoices={daysToPayInvoices}
          email={email}
          firstName={firstName}
          lastName={lastName}
          login={login}
          phoneNumber={phoneNumber}
        />
      )}

      <AdditionalInfoCard
        accordionState={accordionState}
        bedrooms={bedrooms}
        floorArea={floorArea}
        parking={parking}
      />
      <KeysCard
        accordionState={accordionState}
        keys={keys}
        keysDate={keysDate}
        keysTime={keysTime}
        keysTimeOption={keysTimeOption}
        keysWith={keysWith}
        keysWithDetail={keysWithDetail}
        keyNotes={keyNotes}
      />
      <ContactsCard
        accordionState={accordionState}
        contacts={contacts}
        onAddCustomerContact={onAddCustomerContact}
        onAskToEditContact={onAskToEditContact}
        onAskToRemoveContact={onAskToRemoveContact}
        onNewContact={onAskToAddNewContact}
      />
      <NotesCard
        accordionState={accordionState}
        notes={notes}
        onAddNote={onAddNote}
      />
    </>
  );
};

export default memo(Progress);
