import { FC, MouseEvent, memo, useCallback } from "react";
import { StyledStatelessAccordion, StyledStatelessAccordionArrow, StyledStatelessAccordionBody, StyledStatelessAccordionHeader } from "./StatelessAccordion.styled";

import ChevronDownIcon from "@/assets/icons/ChevronDown";
import ChevronUpIcon from "@/assets/icons/ChevronUp";
import { StatelessAccordionProps } from "./StatelessAccordion.interface";

const StatelessAccordion: FC<StatelessAccordionProps> = ({
  bodyDirection,
  children,
  header,
  headerMargin,
  onToggle,
  showArrow = false,
  state
}) => {

  const handleAccordionToggle = useCallback((ev: MouseEvent<HTMLDivElement>) => {
    ev.stopPropagation();
    ev.preventDefault();

    const newState = state === 'close' ? 'open' : 'close';
    
    if (onToggle) {
      onToggle(newState);
    }
    state = newState;
  }, [state]);

  return (
    <StyledStatelessAccordion bodyDirection={bodyDirection}>
      <StyledStatelessAccordionHeader
        mb={state === 'open' && headerMargin ? headerMargin : ''}
        onClick={handleAccordionToggle}
      >
        {header}
        {showArrow && (
          <StyledStatelessAccordionArrow>
            {state === 'close' ? <ChevronUpIcon /> : <ChevronDownIcon />}
          </StyledStatelessAccordionArrow>
        )}
      </StyledStatelessAccordionHeader>
      <StyledStatelessAccordionBody state={state}>{children}</StyledStatelessAccordionBody>
    </StyledStatelessAccordion>
  );
};

export default memo(StatelessAccordion);
