import { StyledTypo } from './Typo.styled'
import { TypoProps } from './Typo.interface'
import { memo } from 'react';

const Typo: React.FC<TypoProps> = ({ tag = 'p', children, ...restProps }) => (
  <StyledTypo
    as={tag}
    tag={tag}
    {...restProps}
  >
    {children}
  </StyledTypo>
);

export default memo(Typo);
