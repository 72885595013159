import { StyledButtonsGroup, StyledCard, StyledPageHeader } from "#/atoms";

import { DESKTOP_SPACING } from "@/theme";
import { StyledPackage } from "#/organisms";
import styled from "styled-components";

export const StyledOrder = styled.div<{ showFooter: boolean }>`
  padding-bottom: ${({ showFooter }) => showFooter ? '100px' : '30px'};

  ${StyledPageHeader} {
    padding-top: ${DESKTOP_SPACING.LG}px;
  }

  ${StyledCard} ${StyledButtonsGroup} {
    margin-top: ${DESKTOP_SPACING.XL}px;
  }

  ${StyledPackage} {
    margin-top: ${DESKTOP_SPACING.XL}px;

    :first-child {
      margin-top: 0;
    }
  }
`;
