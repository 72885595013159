import { FilterOrder, FilterOrders } from "#/organisms";
import { useCallback, useEffect, useState } from "react";

import { GenerateInvoices } from "@/components/pages";
import { OrderFilterParams } from "@/api/order/interface";
import { useApi } from "@/api";
import { useFilter } from "@/hooks";

const GenerateInvoicesView = () => {
  const { order } = useApi();
  const {
    filter,
    numberOfFilters,
    onOpenFilter,
    setShouldFilter,
    shouldFilter,
    showFilter,
    ...filterProps
  } = useFilter<FilterOrder>();
  const [loadingOrders, setLoadingOrders] = useState(false);
  const [called, setCalled] = useState(false);

  const getOrders = async (filterData?: OrderFilterParams) => {
    setLoadingOrders(true);
    await order.getOrderListForInvoice(filterData);
    setLoadingOrders(false);
  }

  useEffect(() => {
    if (order.ordersForInvoice.length === 0 || !called) {
      setCalled(true);
      getOrders();
    }
  }, [order.ordersForInvoice, called]);

  useEffect(() => {
    if (shouldFilter) {
      if (filter) {
        getOrders(filter && {
          customerCode: filter.customerCode,
          customerEmail: filter.customerEmail,
          customerName: filter.companyName,
          dateFrom: filter.dateFrom,
          dateTo: filter.dateTo,
          orderState: filter.state || null,
          propertyAddress: filter.location,
        });
      } else {
        getOrders({
        });
      }
      setShouldFilter(false);
    }
  }, [filter, shouldFilter]);

  const handleGenerate = useCallback(() => {
    console.log('generate')
  }, []);

  return (
    <>
      <GenerateInvoices
        loading={loadingOrders}
        numberOfFilters={numberOfFilters}
        orders={order.ordersForInvoice}
        onGenerate={handleGenerate}
        onOpenFilter={onOpenFilter}
      />
      {showFilter && <FilterOrders {...filterProps} filter={filter} numberOfFilters={numberOfFilters} />}
    </>
  );
}

export default GenerateInvoicesView;