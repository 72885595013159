import { FC, useCallback, useEffect, useState } from "react";
import { StyledTime, StyledTimeButton, StyledTimeDisplay } from "./TimeSelect.styled";

import ArrowDownIcon from '@/assets/icons/ChevronDown';
import ArrowUpIcon from '@/assets/icons/ChevronUp';
import { TimerProps } from "./TimeSelect.interface";
import { padTo2Digits } from "@/helpers";

const Timer: FC<TimerProps> = ({ max, onChange, range, value }) => {
  const [selectedTime, setTime] = useState(value);

  useEffect(() => {
    setTime(value);
  }, [value]);

  const handleTimeUpClick = useCallback(() => {
    let newValue = 0;

    if (selectedTime === max) {
      setTime(newValue)
    } else {
      newValue = selectedTime + range;
      setTime(newValue)
    }

    onChange(newValue);
  }, [range, selectedTime]);

  const handleTimeDownClick = useCallback(() => {
    let newValue = max;

    if (selectedTime === 0) {
      setTime(newValue)
    } else {
      newValue = selectedTime - range;
      setTime(newValue)
    }

    onChange(newValue);
  }, [max, range, selectedTime]);

  return (
    <StyledTime>
      <StyledTimeButton onClick={handleTimeUpClick}><ArrowUpIcon /></StyledTimeButton>
      <StyledTimeDisplay>{padTo2Digits(selectedTime)}</StyledTimeDisplay>
      <StyledTimeButton onClick={handleTimeDownClick}><ArrowDownIcon /></StyledTimeButton>
    </StyledTime>
  );
};

export default Timer;