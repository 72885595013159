import { Button, Heading, PageHeader, PageWrapper, Typo } from "#/atoms";
import { FC, memo } from "react";
import { FONT_SIZE, NATURAL } from "@/theme";
import { FilterAction, OrderCard } from "#/molecules";

import { OrdersProps } from "./Orders.interface";
import { StyledOrders } from "./Orders.styled";

const Orders: FC<OrdersProps> = ({ isAdmin, isOuttakes, loading, numberOfFilters, onCreateNewOrder, onOpenFilter, onSelectOrder, orders }) => {
  const title = isAdmin ? (isOuttakes ? 'Orders with Outtakes Selected' : 'Orders Requiring Verification') 
      : 'My Orders';
  const subTitle = isAdmin ? (isOuttakes ? 'Processing selected outtakes' : 'Select photographes and confirm orders') 
      : 'Description text here.';
  
  return (
    <PageWrapper>
      <StyledOrders>
        <PageHeader>
          <Heading scale="h1" fontColor={NATURAL.K}>{title}</Heading>
          <Typo fontColor={NATURAL.G} fontSize={FONT_SIZE.SM}>{subTitle}</Typo>
          <Button onClick={onCreateNewOrder}>Create New Order</Button>
        </PageHeader>

        <FilterAction numberOfFilters={numberOfFilters} onOpen={onOpenFilter} />
        {loading ? (
          <div>Loading</div>
        ) : orders.map((order) => {
          return (
            <OrderCard {...order} key={order.id} onClick={() => onSelectOrder(order.id)} />
          );
        })}
      </StyledOrders>
    </PageWrapper>
  );
};

export default memo(Orders);