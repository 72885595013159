import { useCallback, useMemo, useState } from "react";

import { ORDER_STEP } from "@/components/pages/Order/Order/Order.interface";
import { useApi } from "@/api";

export interface NotesReturn {
  activeStep: ORDER_STEP;
  setActiveStep: (step: ORDER_STEP) => void;
  gotoNextStep: () => void;
}

const useNotes = (id?: string): NotesReturn => {
  const { session } = useApi();
  const [activeStep, setActiveStep] = useState<ORDER_STEP>(id ? ORDER_STEP.PROGRESS : ORDER_STEP.CUSTOMER_AND_PACKAGE);

  const isAdmin = useMemo(() => session.user?.admin || false, [session.user]);
  
  const gotoNextStep = useCallback(() => {
    if (isAdmin) {
      switch (activeStep) {
        case ORDER_STEP.CUSTOMER_AND_PACKAGE: {
          setActiveStep(ORDER_STEP.BOOKING);
          break;
        }
        case ORDER_STEP.BOOKING: {
          setActiveStep(ORDER_STEP.PROGRESS);
          break;
        }
        case ORDER_STEP.PROGRESS: {
          setActiveStep(ORDER_STEP.PAYMENT);
          break;
        }
      }
    } else {
      switch (activeStep) {
        case ORDER_STEP.CUSTOMER_AND_PACKAGE: {
          setActiveStep(ORDER_STEP.BOOKING);
          break;
        }
        case ORDER_STEP.BOOKING: {
          setActiveStep(ORDER_STEP.PROGRESS);
          break;
        }
      }
    }
  }, [activeStep, isAdmin]);

  return {
    activeStep,
    gotoNextStep,
    setActiveStep,
  }
}

export default useNotes;