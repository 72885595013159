import { createContext } from "react";

export interface DatepickerContextProps {
  focusedDate: Date | null;
  isDateBlocked: (date: Date) => boolean;
  isDateFocused: (date: Date) => boolean;
  isDateHovered: (date: Date) => boolean;
  isDateSelected: (date: Date) => boolean;
  isFirstOrLastSelectedDate: (date: Date) => boolean;
  onDateFocus: (date: Date) => void;
  onDateHover: (date: Date) => void;
  onDateSelect: (date: Date) => void;
}

const DatepickerContext = createContext<DatepickerContextProps>({
  focusedDate: null,
  isDateBlocked: () => false,
  isDateFocused: () => false,
  isDateHovered: () => false,
  isDateSelected: () => false,
  isFirstOrLastSelectedDate: () => false,
  onDateFocus: () => null,
  onDateHover: () => null,
  onDateSelect: () => null,
});

export default DatepickerContext;
