import { ORDER_STATE } from "@/types";

export const padTo2Digits = (num: number): string => {
  return num.toString().padStart(2, '0');
}

export const formatDate = (date: Date): string => {
  return [
    padTo2Digits(date.getDate()),
    padTo2Digits(date.getMonth() + 1),
    date.getFullYear(),
  ].join('.');
}

export const formatShortDate = (d : Date): string => {
  const day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
  const month = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
  const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);

  return day + month + year;
}

export const formatShortDateString = (dateString : string): string => {
  if (!dateString) return dateString;
  const d = new Date(dateString);
  
  return formatShortDate(d);
}

export const getOrderStateEnum = (state: number): ORDER_STATE => {
  switch (state) {
    case -1: {
      return ORDER_STATE.AWAITING_DATE;
    }
    case 0: {
      return ORDER_STATE.BOOKING_IN_PROGRESS;
    }
    case 1: {
      return ORDER_STATE.BOOKING_PLACED;
    }
    case 2: {
      return ORDER_STATE.COMPLETE;
    }
    case 3: {
      return ORDER_STATE.CONTENT_SELECTED;
    }
    case 4: {
      return ORDER_STATE.AWAITING_PAYMENT;
    }
    case 5: {
      return ORDER_STATE.ORDER_PAID;
    }
    case 6: {
      return ORDER_STATE.RETURN_TO_WORK;
    }
    case 99:
    default: {
      return ORDER_STATE.DRAFT;
    }
  }
}

export const getOrderStateNumber = (state: ORDER_STATE): number => {
  switch (state) {
    case ORDER_STATE.AWAITING_DATE: {
      return -1;
    }
    case ORDER_STATE.BOOKING_IN_PROGRESS: {
      return 0;
    }
    case ORDER_STATE.BOOKING_PLACED: {
      return 1;
    }
    case ORDER_STATE.COMPLETE: {
      return 2;
    }
    case ORDER_STATE.CONTENT_SELECTED: {
      return 3;
    }
    case ORDER_STATE.AWAITING_PAYMENT: {
      return 4;
    }
    case ORDER_STATE.ORDER_PAID: {
      return 5;
    }
    case ORDER_STATE.RETURN_TO_WORK: {
      return 6;
    }
    case ORDER_STATE.DRAFT: {
      return 99;
    }
  }
}


export const getOrderStateText = (state?: ORDER_STATE) => {
  switch (state) {
    case ORDER_STATE.AWAITING_DATE: {
      return 'Awaiting confirmation of date and time';
    }
    case ORDER_STATE.BOOKING_IN_PROGRESS: {
      return 'Booking in progress';
    }
    case ORDER_STATE.BOOKING_PLACED: {
      return 'Booking places';
    }
    case ORDER_STATE.COMPLETE: {
      return 'Work completed';
    }
    case ORDER_STATE.CONTENT_SELECTED: {
      return 'Content selected';
    }
    case ORDER_STATE.AWAITING_PAYMENT: {
      return 'Complete and waiting for payment';
    }
    case ORDER_STATE.ORDER_PAID: {
      return 'Order paid';
    }
    case ORDER_STATE.RETURN_TO_WORK: {
      return 'Return for additional work';
    }
    case ORDER_STATE.DRAFT: {
      return 'Draft completed';
    }
    default: {
      return ''
    }
  }
}

export const getYesOrNo = (value?: boolean | null): string => {
  if (value == null) {
    return ''
  }

  if (value) {
    return 'yes';
  }

  return 'no';
}

export const getBoleanFromYesOrNo = (value?: string): boolean | null => {
  if (value === 'yes') {
    return true;
  }

  if (value === 'no') {
    return false;
  }

  return null;
}