import { FilterInvoice, FilterInvoices } from "#/organisms";
import {useEffect, useMemo, useState } from "react";

import { Invoices } from "#/pages";
import { useApi } from "@/api";
import { useFilter } from "@/hooks";

const InvoicesView = () => {
  const { invoice } = useApi();
  const {
    filter,
    numberOfFilters,
    // shouldFilter,
    // setShouldFilter,
    showFilter,
    onOpenFilter,
    ...filterProps
  } = useFilter<FilterInvoice>();
  const [loadingInvoices, setLoadingInvoices] = useState(false);
  const [called, setCalled] = useState(false);

  const userId = 'own';

  const getInvoiceList = async () => {
    setLoadingInvoices(true);
    await invoice.listOwnInvoices(userId);
    setLoadingInvoices(false);
  }

  useEffect(() => {
    if (invoice.invoices.length === 0 && !called) {
      setCalled(true);
      getInvoiceList();
    }
  }, [invoice.invoices, called]);

  const pendingInvoices = useMemo(() => {
    return invoice.invoices.filter((item) => {
      return !item.paid
    });
  }, [invoice.invoices]);

  return (
    <>
      <Invoices
        invoices={pendingInvoices}
        loading={loadingInvoices}
        numberOfFilters={numberOfFilters}
        onMarkAsPaid={invoice.setPaidManual}
        onOpenFilter={onOpenFilter}
      />
      {showFilter && <FilterInvoices {...filterProps} filter={filter} numberOfFilters={numberOfFilters} />}
    </>
  );
}

export default InvoicesView;