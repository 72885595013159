import { FC, useContext, useRef } from "react";

import DatepickerContext from "./Datepicker.context";
import { DayProps } from "./Datepicker.interface";
import { StyledDay } from "./Day.styled";
import { useDay } from "@datepicker-react/hooks";

const Day: FC<DayProps> = ({ dayLabel, date }) => {
  const dayRef = useRef(null);
  const {
    focusedDate,
    isDateBlocked,
    isDateFocused,
    isDateHovered,
    isDateSelected,
    isFirstOrLastSelectedDate,
    onDateFocus,
    onDateHover,
    onDateSelect,
  } = useContext(DatepickerContext);
  const {
    isSelectedStartOrEnd: isSelected,
    onClick,
    onKeyDown,
    onMouseEnter,
    tabIndex
  } = useDay({
    date,
    dayRef,
    focusedDate,
    isDateBlocked,
    isDateFocused,
    isDateHovered,
    isDateSelected,
    isFirstOrLastSelectedDate,
    onDateFocus,
    onDateHover,
    onDateSelect,
  });

  if (!dayLabel) {
    return null;
  }

  const today = new Date();

  const isToday = today.getMonth() === date.getMonth()
    && today.getDate() === date.getDate()
    && today.getFullYear() === date.getFullYear();

  return (
    <StyledDay
      isSelected={isSelected}
      isToday={isToday}
      onClick={onClick}
      onKeyDown={onKeyDown}
      onMouseEnter={onMouseEnter}
      ref={dayRef}
      tabIndex={tabIndex}
      type="button"
    >
      {dayLabel}
    </StyledDay>
  );
}

export default Day;
