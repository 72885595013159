import { BORDER_RADIUS, DESKTOP_SPACING, FONT_SIZE, FONT_WEIGHT, NATURAL } from "@/theme";

import { StyledButton } from "#/atoms";
import styled from "styled-components";

export const StyledContact = styled.div`
  border-radius: ${BORDER_RADIUS.MD};
  border: 1px solid ${NATURAL.C};
  display: flex;
  gap: 16px;
  margin-top: ${DESKTOP_SPACING.XL}px;
  padding: ${DESKTOP_SPACING.LG}px;

  :last-child {
    margin-bottom: ${DESKTOP_SPACING.LG}px;
  }

  ${StyledButton} {
    padding: 0;
  }
`;

export const StyledContactInfo = styled.ul`
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  flex-direction: column;
  margin: 0;
  padding: 0;
`;

export const StyledContactInfoItem = styled.li`
  border-bottom: 1px solid ${NATURAL.C};
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  margin: 0;
  padding: ${DESKTOP_SPACING.LG}px 0;

  :first-child {
    padding-top: 0;
  }

  :last-child {
    border-bottom: 0;
    padding-bottom: 0;
  }
`;

export const StyledContactTerm = styled.div`
  font-size: ${FONT_SIZE.SM};
  font-weight: ${FONT_WEIGHT.REGULAR};
  width: 150px;
`;

export const StyledContactData = styled.div`
  display: block;
  flex: 1;
  font-size: ${FONT_SIZE.SM};
  font-weight: ${FONT_WEIGHT.SEMIBOLD};
  margin: 0;
`;