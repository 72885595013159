import { BORDER_RADIUS, DESKTOP_SPACING, FONT_SIZE, FONT_WEIGHT, NATURAL, PRIMARY_BLUE, media } from "@/theme";

import { StyledHeading } from "@/components/atoms/Typography/Heading";
import { StyledTypo } from "@/components/atoms/Typography/Typo";
import styled from "styled-components";

export const StyledSignup = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;

  ${media.laptop} {
    width: 100%;
  }
`;

export const StyledLogo = styled.div`
  margin: ${DESKTOP_SPACING.EL}px 0 0 ${DESKTOP_SPACING.EL}px;
  z-index: 2;
`;

export const StyledSignupForm = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: ${DESKTOP_SPACING.EL}px 20% 0;

  ${StyledHeading} {
    margin-bottom: ${DESKTOP_SPACING.XXL}px;
  }

  ${media.laptop} {
    margin: ${DESKTOP_SPACING.XXL} 10% 0;
  }
`;

export const StyledImage = styled.div`
  bottom: 0;
  position: absolute;
  overflow: hidden;
  top: 0;
  right: 0;
  width: 40%;

  ${media.laptop} {
    margin-top: ${DESKTOP_SPACING.XXL}px;
    position: relative;
    width: 100%;
  }
`;

export const StyledSignupImage = styled.img`
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: auto;
  z-index: 1;

  ${media.laptop} {
    height: auto;
    width: 100%;
  }
`;

export const StyledLoginForm = styled.div`
  margin-top: 140px;
  padding: ${DESKTOP_SPACING.XXL}px;
  position: relative;
  text-align: center;
  z-index: 2;

  ${StyledHeading} {
    margin-bottom: ${DESKTOP_SPACING.XL}px;
  }

  ${StyledTypo} {
    margin-bottom: ${DESKTOP_SPACING.XXXL}px;
  }

  ${media.laptop} {
    margin-top: 30px;
  }
`;

export const StyledTabGroup = styled.nav`
  display: flex;
  gap: ${DESKTOP_SPACING.XXL}px;
  margin-bottom: ${DESKTOP_SPACING.XXL}px;
`;

export const StyledTab = styled.div<{ active?: boolean; bgInactive: string; bgActive: string; }>`
  background: ${({ active }) => active ? PRIMARY_BLUE.DEFAULT : NATURAL.C} url(${({ active, bgActive, bgInactive }) => active ? bgActive : bgInactive});
  background-size: cover;
  border-radius: ${BORDER_RADIUS.LG};
  color: ${({ active }) => active ? NATURAL.A : NATURAL.F};
  cursor: pointer;
  font-size: ${FONT_SIZE.LG};
  font-weight: ${FONT_WEIGHT.BOLD};
  padding: 56px ${DESKTOP_SPACING.XXXL}px ${DESKTOP_SPACING.XXXL}px;
  flex: 1;
`;