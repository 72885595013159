import { BORDER_RADIUS, DESKTOP_SPACING, MODAL_WIDTH, NATURAL, ZINDEX } from "@/theme";

import { StyledHeading } from "#/atoms/Typography/Heading";
import styled from "styled-components";

export const StyledModalBackdrop = styled.div`
  background: ${NATURAL.K};
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.5;
  position: fixed;
  top: 0;
  z-index: ${ZINDEX.MODAL_INDEX};
`;

export const StyledModal = styled.div<{ width?: string }>`
  background-color: ${NATURAL.A};
  border-radius: ${BORDER_RADIUS.XL};
  box-shadow: 0px 10px 10px rgba(42, 12, 78, 0.05), 0px 0px 10px rgba(42, 12, 78, 0.05);
  display: flex;
  flex-direction: column;
  left: 50%;
  max-height: 80%;
  max-width: 100%;
  padding: 30px;
  position: fixed;
  overflow: hidden;
  top: 10%;
  transform: translateX(-50%);
  width: ${({ width = MODAL_WIDTH }) => width};
  z-index: ${ZINDEX.MODAL_INDEX + 1};

  ${StyledHeading} {
    margin-bottom: ${DESKTOP_SPACING.XXXL}px;
    margin-right: 56px;
  }
`;

export const StyledCloseButton = styled.button`
  align-items: center;
  background-color: ${NATURAL.C};
  border: 0;
  border-radius: 18px;
  color: ${NATURAL.G};
  display: flex;
  fill: ${NATURAL.G};
  height: 36px;
  position: absolute;
  right: 30px;
  top: 30px;
  width: 36px;
`;

export const StyledModalBodyScroll = styled.div`
  overflow: auto;
  width: 100%;
`;
