import { FONT_SIZE, NATURAL } from "@/theme";

import styled from "styled-components";

export const StyledNote = styled.div`
  position: relative;
`;

export const StyledTime = styled.div`
  color: ${NATURAL.E};
  font-size: ${FONT_SIZE.XS};
  position: absolute;
  right: 0;
  top: 0;
`;