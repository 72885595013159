import { BORDER_RADIUS, DESKTOP_SPACING, NATURAL } from "@/theme";

import styled from "styled-components";

export const StyledUserMenu = styled.div`
  border-radius: ${BORDER_RADIUS.LG};
  cursor: pointer;
  padding: ${DESKTOP_SPACING.MD}px;

  &:hover {
    background-color: ${NATURAL.A};
  }
`;

export const StyledSubmenu = styled.div`
  border-top: 1px solid ${NATURAL.A};
  padding: ${DESKTOP_SPACING.MD}px 0;
  margin-top: ${DESKTOP_SPACING.MD}px;
`;
