import { FONT_SIZE, FONT_WEIGHT } from '@/theme';
import { HeadingProps, TypographyScaleHeadline } from './Heading.interface';

import { StyledHeading } from './Heading.styled';
import { TypoProps } from '../Typo/Typo.interface';
import { memo } from 'react';

const getHeadingPresets = (scale: TypographyScaleHeadline): Omit<TypoProps, 'children'> => {
  switch (scale) {
    case 'h1': {
      return {
        fontSize: FONT_SIZE.XXL,
        tag: 'h1',
        weight: FONT_WEIGHT.BOLD,
      }
    }
    case 'h2': {
      return {
        fontSize: FONT_SIZE.XL,
        tag: 'h2',
        weight: FONT_WEIGHT.BOLD,
      }
    }
    case 'h3': {
      return {
        fontSize: FONT_SIZE.LG,
        tag: 'h3',
        weight: FONT_WEIGHT.SEMIBOLD,
      }
    }
    case 'h4': {
      return {
        fontSize: FONT_SIZE.MD,
        tag: 'h4',
        weight: FONT_WEIGHT.SEMIBOLD,
      }
    }
  }
}

const Heading: React.FC<HeadingProps> = ({
  children,
  uppercase = false,
  scale,
  ...restProps
}) => {
  const presets = getHeadingPresets(scale);

  return (
    <StyledHeading {...restProps} {...presets} as={scale} tag={scale} uppercase={uppercase}>
      {children}
    </StyledHeading>
  )
}

export default memo(Heading);
