import { FC, memo, useCallback } from "react";
import { InputText, Select } from "#/atoms";
import { getBoleanFromYesOrNo, getYesOrNo } from "@/helpers";
import { useInput, useSelect } from "@/hooks";

import { FilterCustomersProps } from "./Customers.interface";
import { FilterSidebar } from "#/molecules";

const Customers: FC<FilterCustomersProps> = ({ filter, numberOfFilters, onCloseFilter, onClearFilter, onFilter }) => {
  const active = useSelect(getYesOrNo(filter?.active));
  const companyName = useInput(filter?.companyName);
  const customerCode = useInput(filter?.customerCode);
  const customerEmail = useInput(filter?.customerEmail);
  const location = useInput(filter?.location);
  const login = useInput(filter?.login);

  const handleFilter = useCallback(() => {
    onFilter({
      active: getBoleanFromYesOrNo(active.value),
      companyName: companyName.value,
      customerCode: customerCode.value,
      customerEmail: customerEmail.value,
      location: location.value,
      login: login.value,
    });
  }, [active, companyName, customerCode, customerEmail, location, login]);

  return (
    <FilterSidebar
      numberOfFilters={numberOfFilters}
      onClearFilters={onClearFilter}
      onClose={onCloseFilter}
      onFilter={handleFilter}
    >
      <InputText type="text" label="Customer Code" fullWidth value={customerCode.value} onChange={customerCode.onChange} />
      <InputText type="text" label="Company Name" fullWidth value={companyName.value} onChange={companyName.onChange} />
      <InputText type="text" label="Address" fullWidth value={location.value} onChange={location.onChange} />
      <InputText type="text" label="Customer E-mail" fullWidth value={customerEmail.value} onChange={customerEmail.onChange} />
      <InputText type="text" label="Login" fullWidth value={login.value} onChange={login.onChange} />
      <Select
        label="Is Active"
        selected={active.value}
        options={[
          { id: '', label: '' },
          { id: 'no', label: 'No' },
          { id: 'yes', label: 'Yes' },
        ]}
        onChange={active.onChange}
      />
    </FilterSidebar>
  );
}

export default memo(Customers);
