import { useCallback, useMemo, useState } from "react";

export interface FilterProps<T> {
  filter: T | null;
  numberOfFilters: number;
  onClearFilter: () => void;
  onCloseFilter: () => void;
  onFilter: (data: T) => void;
}

interface FilterReturn<T> extends FilterProps<T> {
  onOpenFilter: () => void;
  setShouldFilter: (val: boolean) => void;
  shouldFilter: boolean;
  showFilter: boolean;
}

const useFilter = <T,>(): FilterReturn<T> => {
  const [filter, setFilter] = useState<T | null>(null);
  const [showFilter, setShowFilter] = useState(false);
  const [shouldFilter, setShouldFilter] = useState(false);

  const numberOfFilters = useMemo(() => {
    return filter ? Object.keys(filter).reduce((acc: string[], cur) => {
      const value = filter[cur];

      if (!value) {
        return acc;
      }

      return [...acc, cur]
    }, []).length : 0;
  }, [filter])

  const onClearFilter = useCallback(() => {
    setShouldFilter(true);
    setFilter(null);
  }, []);

  const onOpenFilter = useCallback(() => {
    setShowFilter(true);
  }, []);

  const onCloseFilter = useCallback(() => {
    setShowFilter(false);
  }, []);

  const onFilter = useCallback((data: T) => {
    setShouldFilter(true);
    setFilter(data);
    setShowFilter(false);
  }, []);

  return {
    filter,
    numberOfFilters,
    onClearFilter,
    onCloseFilter,
    onFilter,
    onOpenFilter,
    setShouldFilter,
    shouldFilter,
    showFilter,
  }
};


export default useFilter;
