import { DESKTOP_SPACING, FONT_WEIGHT } from "@/theme";

import styled from "styled-components";

export const StyledDl = styled.dl`
  display: flex;
  flex-flow: row wrap;
`;

export const StyledDt = styled.dt`
  flex-basis: 25%;
  font-weight: ${FONT_WEIGHT.SEMIBOLD};
  margin: ${DESKTOP_SPACING.MD}px 0;
  padding-right: ${DESKTOP_SPACING.EL}px;
  text-align: right;
  width: auto;
`;

export const StyledDd = styled.dd`
  flex-basis: 70%;
  flex-grow: 1;
  margin: ${DESKTOP_SPACING.MD}px 0;
`;
