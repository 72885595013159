import { FC, memo, useCallback, useEffect, useState } from 'react';
import { StyledToggle, StyledToggleCheckbox, StyledToggleIcon, StyledToggleLabel, StyledToggleText } from './Toggle.styled';

import { ToggleProps } from './Toggle.interface';

const Toggle: FC<ToggleProps> = ({
  checked = false,
  disabled = false,
  id,
  label,
  labelPosition = 'right',
  onToggle,
  ...toggleProps
}) => {
  const [toggleState, setToggleState] = useState(checked);

  useEffect(() => {
    if (checked !== toggleState) {
      setToggleState(checked);
    }
  }, [checked]);

  const onToggleHandler = useCallback((ev: React.MouseEvent<HTMLInputElement>): void => {
      ev.stopPropagation();

      if (disabled) {
        return;
      }

      setToggleState(!toggleState);
      onToggle(!toggleState, id);
  }, [disabled, id, toggleState]);

  return (
    <StyledToggle
      aria-disabled={disabled}
      aria-checked={toggleState}
      aria-readonly={disabled}
      disabled={disabled}
      labelPosition={labelPosition}
      {...toggleProps}
    >
      <StyledToggleLabel>
        <StyledToggleCheckbox type="checkbox" disabled={disabled} onClick={onToggleHandler} />
        <StyledToggleIcon id={id} role="switch" checked={toggleState} />
      </StyledToggleLabel>
      {label && (
        <StyledToggleText disabled={disabled}>
          {label}
        </StyledToggleText>
      )}
    </StyledToggle>
  );
};

export default memo(Toggle);
