import { OrdersReport } from "@/components/pages/OrdersReport";
import { useApi } from "@/api";
import { useCallback } from "react";


const OrdersReportView = () => {
    const { order } = useApi();

    const handleDownloadReport = useCallback(async (from: string, to: string) => {
        await order.ordersReport(from, to);
    }, []);

    return (
        <OrdersReport onDownload={handleDownloadReport}></OrdersReport>
    );
}

export default OrdersReportView;