import { Button, Heading, PageHeader, PageWrapper } from "#/atoms";
import { FC, memo } from "react";
import { FilterAction, OrderCard } from "#/molecules";
import { NATURAL } from "@/theme";

import { OrdersProps } from "../Orders/Orders.interface";
import { StyledOrders } from "../Orders/Orders.styled";

const FindOrders: FC<OrdersProps> = ({ loading, numberOfFilters, onCreateNewOrder, onOpenFilter, onSelectOrder, orders }) => {
  const title ='Find Orders';
  
  return (
    <PageWrapper>
      <StyledOrders>
        <PageHeader>
          <Heading scale="h1" fontColor={NATURAL.K}>{title}</Heading>
          <Button onClick={onCreateNewOrder}>Create New Order</Button>
        </PageHeader>

        <FilterAction numberOfFilters={numberOfFilters} onOpen={onOpenFilter} />
        {loading ? (
          <div>Loading</div>
        ) : orders.map((order) => {
          return (
            <OrderCard {...order} key={order.id} onClick={() => onSelectOrder(order.id)} />
          );
        })}
      </StyledOrders>
    </PageWrapper>
  );
};

export default memo(FindOrders);