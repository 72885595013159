import { AwaitingConfirmation, Order } from "#/pages";
import { CONTACT_TYPE, Maintainer, Worker } from "@/types";
import { CreateOrderParam, UpdateOrderParam } from "@/api/order/interface";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  useContacts,
  useCustomer,
  useFields,
  useModal,
  useNotes,
  useOrderTemplate,
  useStep,
} from "@/hooks";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import BookingAndProgressView from "./Steps/BookingAndProgress";
import { ConfirmModal } from "#/organisms";
import CustomerAndPackageView from "./Steps/CustomerAndPackage";
import { ORDER_STEP } from "#/pages/Order/Order/Order.interface";
import PaymentView from "./Steps/Payment";
import { useApi } from "@/api";

const OrderView = () => {
  const { order } = useParams<'order'>();
  const navigate = useNavigate();
  const location = useLocation();
  const [id, setId] = useState(order);
  const type = useMemo(() => location.pathname === '/order-create' ? 'create' : 'edit', [location.pathname]);
  const { order: orderEntity, session, worker } = useApi();
  const {
    showModal: showConfirmModal,
    onCloseModal: onCloseConfirmModal,
    onOpenModal: onOpenConfirmModal
  } = useModal();

  const isAdmin = useMemo(() => session.user?.admin || false, [session.user]);

  const {activeStep, gotoNextStep, setActiveStep} = useStep(id);
  
  const [selectedWorker, setSelectedWorker] = useState<Worker | null>(null);
  const [selectedMaintainer, setSelectedMaintainer] = useState<Maintainer | null>(null);
  const contactsProps = useContacts();
  const { selectedCustomer, setSelectedCustomer } = useCustomer();
  const { contacts, onAddCustomerContact } = contactsProps;
  const { notes, onAddNote, setNotes } = useNotes();
  const {
    getOrderTemplateDetails,
    selectedOrderTemplate,
    setSelectedOrderTemplate
  } = useOrderTemplate();
  const {
    fieldsHaveChanges,
    storeFieldsData,
    updateFields,
    ...fields
  } = useFields();

  const getOrderDetails = async (value: string) => {
    orderEntity.getDetailedOrderData(value);
  }

  const getOrderData = (customerId?: string | null): CreateOrderParam => {
    const {
      addressString,
      appointmentDate,
      appointmentDuration,
      appointmentTime,
      bedrooms,
      contactVendorDirectly,
      doorNumber,
      flat,
      floorArea,
      keyNotes,
      keys,
      keysTime,
      keysTimeOption,
      keysWith,
      keysWithDetail,
      notifyViaSMS,
      parking,
    } = fields;
    const dateTime = appointmentDate.value;
    const time = appointmentTime.value ? appointmentTime.value.split(':') : [];

    if (time.length > 0 && dateTime) {
      dateTime.setHours(Number(time[0]));
      dateTime.setMinutes(Number(time[1]));
    }

    return {
      bedrooms: Number(bedrooms.value),
      common: {
        appointmentDateTime: dateTime?.toDateString() || '',
        appointmentDuration: Number(appointmentDuration.value) || 0,
        contactVendorDirectly: contactVendorDirectly.checked,
        notifyViaSMS: notifyViaSMS.checked,
      },
      contacts: contacts.map((item) => ({
        email: item.email,
        name: item.name,
        phone1: item.phone1,
        phone2: item.phone2,
        type: CONTACT_TYPE.AGENT,
      })),
      customerId: customerId || '',
      floorArea: Number(floorArea.value),
      keys: keys.checked,
      keysTime: keysTime.value || '',
      keysTimeOption: keysTimeOption.value,
      keysWith: keysWith.value,
      keysWithDetail: keysWithDetail.value,
      notes: keyNotes.value,
      orderNotes: notes.map((item) => ({ content: item.content })),
      parking: parking.value,
      propertyAddress: {
        addressString: addressString.value,
        doorNumber: doorNumber.value,
        flat: flat.value,
      },
    }
  }

  const getUpdatedOrderData = (orderIdToUpdate: string): UpdateOrderParam => {
    const { orderState } = fields;

    return {
      ...getOrderData(selectedCustomer?.id),
      maintainer: {
        applicationUserId: selectedMaintainer?.id || '',
        firstName: selectedMaintainer?.firstName || '',
        id: selectedMaintainer?.id || '',
        lastName: selectedMaintainer?.lastName || '',
      },
      orderId: orderIdToUpdate,
      orderState: Number(orderState.value),
      services: orderEntity.orderDetails?.services || [],
    }
  }

  const placeOrder = async (templateId: string) => {
    const orderData = getOrderData(selectedCustomer?.id);
    // onCloseConfirmModal();

    if (!fieldsHaveChanges(orderData)) {
      return;
    }

    orderEntity.createOrder(templateId, orderData).then((response) => {
      if (response.orderId) {
        setId(response.orderId);
        getOrderDetails(response.orderId);
      }
      storeFieldsData(orderData);

      if (isAdmin) {
        onOpenConfirmModal();
      } else {
        setActiveStep(ORDER_STEP.AWAITING_CONFIRMATION);
      }
    });
  }

  const updateOrder = async () => {
    if (!order) {
      return;
    }
    const orderData = getUpdatedOrderData(order);

    if (!fieldsHaveChanges(orderData)) {
      return;
    }

    await orderEntity.setDetailedOrderData(orderData);
    storeFieldsData(orderData)
  }

  // EDIT PAGE
  useEffect(() => {
    if (id && orderEntity.orderDetails === null) {
      getOrderDetails(id);
    }
  }, [id, orderEntity.orderDetails]);

  useEffect(() => {
    const { orderDetails } = orderEntity;

    if (orderDetails) {
      const { customerId, description, orderPrice, services } = orderDetails;

      updateFields();
      storeFieldsData(getOrderData(customerId))

      if (orderDetails.contacts.length > 0) {
        contactsProps.setContacts(orderDetails.contacts.map((item) => ({
          contactId: `${item.id}`,
          customerId: '',
          email: item.email,
          name: item.name,
          phone1: item.phone1 || '',
          phone2: item.phone2 || '',
          type: item.type,
        })));
      }

      if (orderDetails.orderNotes && orderDetails.orderNotes.length > 0) {
        setNotes(orderDetails.orderNotes);
      }

      setSelectedOrderTemplate({
        description,
        id: orderDetails.id,
        price: orderPrice,
        services: services.map((item) => ({
          imageUrl: item.imageURL,
          name: item.name,
          orderTypeId: item.orderServiceId,
          price: item.price,
        })),
      })
    }
  }, [orderEntity.orderDetails]);

  const handleCancelOrder = useCallback(() => {
    if (orderEntity.orderDetails?.id) {
      orderEntity.deleteOrder(orderEntity.orderDetails.id);
      navigate('/orders', { replace: true });
    }
  }, [orderEntity.orderDetails]);

  const handleDeleteOrder = useCallback(() => {
    if (orderEntity.orderDetails?.id) {
      orderEntity.deleteOrder(orderEntity.orderDetails.id);
      navigate('/orders', { replace: true });
    }
  }, [orderEntity.orderDetails]);

  const handleSaveOrder = async () => {
    if (activeStep === ORDER_STEP.BOOKING && selectedOrderTemplate) {
      await placeOrder(selectedOrderTemplate.id);
    }

    if (activeStep === ORDER_STEP.PROGRESS) {
      await updateOrder();
    }

    gotoNextStep();
  };

  const handleChangePackage = useCallback(() => {
    setActiveStep(ORDER_STEP.CUSTOMER_AND_PACKAGE);
  }, [activeStep]);

  const handleSelectOrderTemplate = useCallback(async (orderTypeId: string) => {
    await getOrderTemplateDetails(orderTypeId)
    setActiveStep(ORDER_STEP.BOOKING);
  }, [activeStep]);

  const handleAddCustomerContact = useCallback(() => {
    if (selectedCustomer) {
      onAddCustomerContact(selectedCustomer.branchId, selectedCustomer.id)
    }
  }, [selectedCustomer]);

  const handleNotifyCustomer = useCallback(() => {
    if (orderEntity.orderDetails?.id) {
      orderEntity.notifyCustomer(orderEntity.orderDetails.id)
    }
  }, [orderEntity.orderDetails]);

  const handleOnTakeRole = useCallback(() => {
    const { orderDetails } = orderEntity;

    if (orderDetails) {
      setSelectedMaintainer({
        email: orderDetails.accountDetails.accountsEmailAddress,
        firstName: orderDetails.accountDetails.firstName,
        id: orderDetails.customerUserId,
        lastName: orderDetails.accountDetails.lastName,
      });
    }
  }, [orderEntity.orderDetails]);

  const handleUploadFiles = useCallback(async (serviceId: string, files: File[]) => {
    if (id) {
      await worker.uploadPhotos(files, serviceId);

      return getOrderDetails(id);
    }
  }, [id]);

  const handleDeleteFiles = useCallback(async (serviceId: string, files: string[], isOffered: boolean) => {
    if (id) {
      await worker.deletePhotos(files, serviceId, isOffered);

      return getOrderDetails(id);
    }
  }, [id]);

  const handleDownloadFiles = useCallback((serviceId: string, filesId: string[]) => {
    if (id) {
      orderEntity.downloadOrderFiles(id, serviceId, [{ name: 'fullSize' }], filesId, false);
    }
  }, [id]);

  const getStepComponent = () => {
    switch(activeStep) {
      case ORDER_STEP.CUSTOMER_AND_PACKAGE: {
        return (
          <CustomerAndPackageView
            isAdmin={isAdmin}
            selectedCustomer={selectedCustomer}
            selectedOrderTemplate={selectedOrderTemplate}
            setSelectedCustomer={setSelectedCustomer}
            setSelectedOrderTemplate={handleSelectOrderTemplate}
          />
        )
      }
      case ORDER_STEP.BOOKING:
        case ORDER_STEP.PROGRESS: {
        return (
          <BookingAndProgressView
            {...contactsProps}
            {...fields}
            isAdmin={isAdmin}
            notes={notes}
            activeStep={activeStep}
            coordinator={selectedMaintainer}
            offeredServices={orderEntity.orderDetails?.offeredServices || []}
            onAddCustomerContact={handleAddCustomerContact}
            onAddNote={onAddNote}
            onChangePackage={handleChangePackage}
            onDeleteFiles={handleDeleteFiles}
            onDownloadFiles={handleDownloadFiles}
            onNotifyCustomer={handleNotifyCustomer}
            onSelectMaintainer={setSelectedMaintainer}
            onSelectWorker={setSelectedWorker}
            onTakeRole={handleOnTakeRole}
            onUploadFiles={handleUploadFiles}
            selectedOrderTemplate={selectedOrderTemplate}
            selectedMaintainer={selectedMaintainer}
            selectedWorker={selectedWorker}
            services={orderEntity.orderDetails?.services || []}
          />
        )
      }
      case ORDER_STEP.AWAITING_CONFIRMATION: {
        return (
          <AwaitingConfirmation orderDetails={orderEntity.orderDetails} selectedOrderTemplate={selectedOrderTemplate} />
        );
      }
      case ORDER_STEP.PAYMENT: {
        return (
          <PaymentView />
        )
      }
    }
  }

  return (
    <>
      <Order
        activeStep={activeStep}
        isAdmin={isAdmin}
        selectedOrder={selectedOrderTemplate}
        onCancelOrder={handleCancelOrder}
        onDeleteOrder={handleDeleteOrder}
        onSaveOrder={handleSaveOrder}
        type={type}
      >
        {getStepComponent()}
      </Order>
      {showConfirmModal && (
        <ConfirmModal
          description="Your order has now been sent to our amazing bookings team and we will process your booking A.S.A.P."
          onClose={onCloseConfirmModal}
          onConfirm={onCloseConfirmModal}
          title="Order Sent!"
        />
      )}
    </>
  );
}

export default OrderView;
