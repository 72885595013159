import { DESKTOP_SPACING } from "@/theme";
import { StyledToggle } from "#/atoms";
import styled from "styled-components";

export const StyledUserModal = styled.div`
  ${StyledToggle} {
    margin-bottom: ${DESKTOP_SPACING.XL}px;

    :last-child {
      margin-bottom: 0;
    }
  }
`
