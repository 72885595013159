import { Accordion, Card, Grid, InputSelect, InputText } from "#/atoms";
import { Datepicker, Timepicker } from "#/molecules";
import { FC, memo } from "react";

import { DESKTOP_SPACING } from "@/theme";
import { DateAndTimeCardProps } from "./DateAndTime.interface";
import { HeadingIcon } from "#/molecules/HeadingIcon";

const DateAndTimeCard: FC<DateAndTimeCardProps> = ({
  accordionState = 'open',
  appointmentDate,
  appointmentTime,
  appointmentDuration,
  contactVendorDirectly,
  notifyViaSMS,
}) => {
  const description = 'Please note that appointment dates & times requested are not guaranteed. We will need to confirm our availability when we allocate a photoplanner to the job.';
  
  return (
    <Card>
      <Accordion
        header={(
          <HeadingIcon
            description={accordionState === 'open' ? description : ''}
            icon="calendar"
            title="Date & Time"
          />
        )}
        headerMargin={`${DESKTOP_SPACING.XXXL}px`}
        showArrow
        state={accordionState}
      >
        <InputSelect
          type="checkbox"
          checked={contactVendorDirectly.checked}
          label="Contact Vendor or Tenant direclt for the most convenient date and time."
          name="direct-contact"
          value="direct-contact"
          onChangeValue={contactVendorDirectly.onChange}
        />
        <Grid columnGap={DESKTOP_SPACING.XL} gridTemplateColumns="repeat(2, 40%)" margin={`${DESKTOP_SPACING.XXL}px 0 0`}>
          <Datepicker
            label="Appointment Date"
            date={appointmentDate.value}
            onChange={appointmentDate.onChange}
          />
          <InputText type="text" label="Timezone" disabled value="Europe/London" />
          <Timepicker
            label="Appointment Time"
            time={appointmentTime.value}
            onChange={appointmentTime.onChange}
          />
          <InputText
            type="number"
            label="Appointment Duration (minutes)"
            value={appointmentDuration.value}
            onChange={appointmentDuration.onChange}
          />
        </Grid>
        <InputSelect
          type="checkbox"
          checked={notifyViaSMS.checked}
          id="notify-sms"
          label="Notify my about progress via SMS"
          name="notify-sms"
          value="notify-sms"
          onChangeValue={notifyViaSMS.onChange}
        />
      </Accordion>
    </Card>
  );
};

export default memo(DateAndTimeCard);