import { Meta, Story } from '@storybook/react';

import Component from './Textarea';
import { TextareaProps } from './Textarea.interface';

export default {
  argTypes: {
    inputRef: {
      table: {
        disable: true,
      },
    },
    name: {
      table: {
        disable: true,
      },
    },
  },
  component: Component,
  title: 'Atoms/Textarea',
} as Meta<typeof Component>;

const Template: Story<TextareaProps> = (args) => <Component {...args} />;

export const Textarea = Template.bind({});
Textarea.args = {
  label: 'Label',
};
