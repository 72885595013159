import { Accordion, Box, Card, Grid, InputText, Maps } from "#/atoms";
import { FC, memo, useCallback, useEffect } from "react";

import { DESKTOP_SPACING } from "@/theme";
import { HeadingIcon } from "#/molecules/HeadingIcon";
import { PropertyAddressCardProps } from "./PropertyAddress.interface";
import { useMaps } from "@/hooks";

const PropertyAddressCard: FC<PropertyAddressCardProps> = ({
  accordionState = 'open',
  addressString,
  flat,
  doorNumber,
}) => {
  const { address, mapsAddress, onBlur, onChange, setAddress } = useMaps(addressString.value);

  useEffect(() => {
    if (addressString.value) {
      setAddress(addressString.value);
    }
  }, [addressString.value]);

  const handleAddressChange = useCallback((ev: React.ChangeEvent<HTMLInputElement>) => {
    addressString.onChange(ev)
    onChange(ev);
  }, []);

  const description = 'Start typing the address or postcode to search.';
  
  return (
    <Card padding="0">
      <Box padding={`${DESKTOP_SPACING.EL}px`}>
        <Accordion
          header={
            <HeadingIcon
              description={accordionState === 'open' ? description : ''}
              icon="location"
              title="Address Details"
            />
          }
          headerMargin={`${DESKTOP_SPACING.XXXL}px`}
          showArrow
          state={accordionState}
        >
          <Grid gridTemplateColumns="repeat(2, 1fr)">
            <Box>
              <InputText
                type="text"
                label="Address or Postcode"
                value={address}
                onBlur={onBlur}
                onChange={handleAddressChange}
              />
              <InputText
                type="text"
                label="Flat number or letter"
                value={flat.value}
                onChange={flat.onChange}
              />
              <InputText
                type="text"
                label="Door Number"
                value={doorNumber.value}
                onChange={doorNumber.onChange}
              />
            </Box>
            <Box>
              <Maps address={mapsAddress} />
            </Box>
          </Grid>
        </Accordion>
      </Box>
    </Card>
  );
};

export default memo(PropertyAddressCard);