import { mapApiOrderTemplate, mapApiOrderTemplates } from "./mappers";
import { useCallback, useState } from "react";

import { ApiBranch } from "../customer/interface.api";
import { ApiHooksProps } from "../interface";
import { ApiOrderTemplate } from "./interface.api";
import { OrderTemplate } from "@/types";
import { OrderTemplateApi } from "./interface";
import { request } from "../request";

const hook = ({ setError, setLoading, serverPath, token }: ApiHooksProps): OrderTemplateApi => {
  const [orderTemplates, setOrderTemplates] = useState<OrderTemplate[]>([]);
  
  const activateItem = useCallback(async (id: string) => {
    const path = `${serverPath}/order-templates/activate/${id}`;
    request(path, { token });
  }, [serverPath, token]);
  
  const cloneItem = useCallback(async (id: string) => {
    const path = `${serverPath}/order-templates/clone-template/${id}`;
    request(path, { token });
  }, [serverPath, token]);
  
  const deactivateItem = useCallback(async (id: string) => {
    const path = `${serverPath}/order-templates/deactivate/${id}`;
    request(path, { token });
  }, [serverPath, token]);

  const deleteItem = useCallback(async (id: string) => {
    const path = `${serverPath}/order-templates/delete/${id}`;
    request(path, { token });
  }, [serverPath, token]);

  const details = useCallback(async (id: string) => {
    setLoading(true);
    const path = `${serverPath}/order-templates/details/${id}`;
    const res = await request<never, ApiOrderTemplate>(path, { token });
    setLoading(false);
    
    return mapApiOrderTemplate(res)
  }, [serverPath, token]);

  const getList = useCallback(async (branches: ApiBranch[]) => {
    const path = `${serverPath}/order-templates/list/`;

    try {
      const res = await request<ApiBranch[], ApiOrderTemplate[]>(path, { body: branches, token });
      setOrderTemplates(mapApiOrderTemplates(res))
    } catch (error) {
      if (typeof error === 'string') {
        setError(error);
      }
    };
  }, [serverPath, token]);

  const getServices = useCallback(async (id: string) => {
    const path = `${serverPath}/order-templates/list/`;
    const body = { OrderTemplateId: id };
    request(path, { body, token });
  }, [serverPath, token]);

  // const set = useCallback(async (body) => {
  //   const path = `${serverPath}/order-templates/set/`;
  //   request(path, { body, token });
  // }, [serverPath, token]);

  return {
    activateItem,
    cloneItem,
    deactivateItem,
    deleteItem,
    details,
    getList,
    getServices,
    orderTemplates,
    // set,
  }
}

export default hook;