import { Box, Heading, PageHeader, PageWrapper, Typo } from "#/atoms";
import { FC, memo, } from "react";
import { FONT_SIZE, NATURAL } from "@/theme";
import { CalendarProps } from "./Calendar.interface";
import { formatShortDate } from "@/helpers";

const Calendar: FC<CalendarProps> = ({
  calendarId
}) => {

  const currentDate = formatShortDate(new Date());

  const googleCalendarUrl = `https://calendar.google.com/calendar/embed?title=&mode=DAY&bgcolor=%23FFFFFF&showPrint=0&ctz=Europe%2FLondon&dates=${currentDate}%2F${currentDate}&src=${calendarId}`;

//   function setContainerSize() {
//     var self = this;

//     var calendarFrame = self.content.find('.google-calendar iframe');
//     var calendarContainer = self.content.find('.google-calendar');
//     var calendarContainerWidth = calendarContainer.width();

//     var screenHeight = $(window).height();
//     var calendarNewHeight = screenHeight - calendarFrame.offset().top - 15;
//     calendarFrame.attr('width', calendarContainerWidth + 'px');
//     calendarFrame.attr('height', calendarNewHeight + 'px');
// }

  return (
    <PageWrapper>
      <PageHeader>
        <Heading scale="h1" fontColor={NATURAL.K}>Calendar</Heading>
      </PageHeader>
      { calendarId || <Typo fontColor={NATURAL.G} fontSize={FONT_SIZE.SM}>There is no Google Calendar. Please add Google Calendar data on the profile settings page to show your events.</Typo>
      }
      <Typo fontColor={NATURAL.G} fontSize={FONT_SIZE.SM}>Please unlink Google Calendar and add again on the profile settings page to show your events.</Typo>
      { calendarId &&<Box>
        <iframe src={googleCalendarUrl} frameBorder="0" scrolling="no"></iframe>
      </Box> }
    </PageWrapper>
  );
};

export default memo(Calendar);