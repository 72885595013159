import { FC, memo, useCallback } from "react";
import { FONT_SIZE, FONT_WEIGHT } from "@/theme";
import { Heading, Icon, OrderState, Price } from "#/atoms";
import {
  StyledOrderCard,
  StyledOrderContent,
  StyledOrderDate,
  StyledOrderFooter,
  StyledOrderHeader,
  StyledOrderLocation,
  StyledOrderLogo
} from "./OrderCard.styled";

import ChevronRight from '@/assets/icons/ChevronRight';
import LogoIcon from '@/assets/icons/Logo';
import { OrderCardProps } from "./OrderCard.interface";

const OrderCard: FC<OrderCardProps> = ({ date, id, location, logoUrl, onClick, price, state, title, companyName }) => {
  const handleClick = useCallback(() => {
    if (onClick) {
      onClick(id)
    }
  }, [id]);

  return (
    <StyledOrderCard onClick={handleClick}>
      <StyledOrderLogo>
        {logoUrl ? < img src={logoUrl} /> : <LogoIcon />}
      </StyledOrderLogo>
      <StyledOrderContent>
        <StyledOrderHeader>
          <Heading scale="h3">{id} / {title} / {companyName}</Heading>
          <OrderState state={state} />
        </StyledOrderHeader>
        <StyledOrderFooter>
          <StyledOrderLocation>
            <Icon type="location" />
            {location}
          </StyledOrderLocation>
          <StyledOrderDate>
            <Icon type="calendar" />
            {date}
          </StyledOrderDate>
          <Price amount={price} fontSize={FONT_SIZE.XXL} fontWeight={FONT_WEIGHT.BOLD} />
        </StyledOrderFooter>
      </StyledOrderContent>
      <ChevronRight />
    </StyledOrderCard>
  );
}

export default memo(OrderCard);