import { DESKTOP_SPACING, FONT_SIZE, FONT_WEIGHT, NATURAL } from "@/theme";

import styled from "styled-components";

export const StyledYearSelector = styled.input`
  border: 0;
  color: ${NATURAL.G};
  font-size: ${FONT_SIZE.XS};
  font-weight: ${FONT_WEIGHT.SEMIBOLD};
  margin-right: auto;
  max-width: 60px;

  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    opacity: 1;
  }
`;

export const StyledGrid = styled.div<{ activeMonths: number }>`
  display: grid;
  gap: 8px;
  grid-template-columns: ${({ activeMonths }) => `repeat(${activeMonths}, 1fr)`};
`;

export const StyledCalendar = styled.div`
  display: flex;
`;

export const StyledCalendarHeader = styled.div`
  display: flex;
  padding: ${DESKTOP_SPACING.XL}px 0;
  width: 100%;
`;