import { ApiUser } from "./interface.api";
import { User } from "@/types";

export const userApiMapper = (user: ApiUser): User => {
  return {
    active: user.isActive,
    admin: user.Roles && user.Roles.includes('Admin'),
    dayToPayInvoices: `${user.DaysToPayInvoices}`,
    email: user.Email,
    firstName: user.FirstName,
    id: user.Id,
    inOffice: false,
    joinDate: user.JoinDate,
    lastName: user.LastName,
    login: user.UserName,
    phoneNumber: user.AccountPhoneNumber,
    roles: user.Roles,
    userUpsellPrice: user.UserUpsellPrice,
  }
};

export const usersApiMapper = (api: ApiUser[]): User[] => {
  return api.map((user) => userApiMapper(user))
};
