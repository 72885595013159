import { Button, ButtonsGroup, Heading, PageHeader, PageWrapper, Typo } from "#/atoms";
import { CustomTable, FilterAction } from "#/molecules";
import { FC, memo, useMemo } from "react";
import { FONT_SIZE, NATURAL } from "@/theme";

import { Column } from "react-table";
import { UsersProps } from "./Users.interface";

const Users: FC<UsersProps> = ({ loading, numberOfFilters, onNewUser, onOpenFilter, onOpenEditModal, onDelete, users }) => {
  const data = useMemo(() => (users.map((user) => ({
    active: user.active ? 'yes' : 'no',
    admin: user.admin ? 'yes' : 'no',
    id: user.id,
    inOffice: user.inOffice ? 'yes' : 'no',
    login: user.login,
    name: [user.firstName, user.lastName].join(' '),
  }))), [users]);
  
  const columns: Column[] = useMemo(() => [
    {
      Header: 'Login Name',
      accessor: 'login',
    },
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Active',
      accessor: 'active',
    },
    {
      Header: 'System Admin',
      accessor: 'admin',
    },
    {
      Header: 'In Office',
      accessor: 'inOffice',
    },
    {
      Cell: ({ value }) => {
        
        return (
          <ButtonsGroup fullWidth>
            <Button
            actionType='solid'
            onClick={() => onOpenEditModal(value)}
            buttonSize="sm"
            >
              Edit
            </Button>
            <Button
              actionType='solid'
              onClick={() => onDelete(value)}
              buttonSize="sm"
              >
                Delete
            </Button>
          </ButtonsGroup>
        );
      },
      Header: '',
      accessor: 'id',
      id: 'button'
    },
  ], []);

  return (
    <PageWrapper>
      <PageHeader>
        <Heading scale="h1" fontColor={NATURAL.K}>System Users</Heading>
        <Typo fontColor={NATURAL.G} fontSize={FONT_SIZE.SM}>Description text here</Typo>
        <Button onClick={onNewUser}>Create New</Button>
      </PageHeader>

      <FilterAction numberOfFilters={numberOfFilters} onOpen={onOpenFilter} />
      <CustomTable columns={columns} data={data} loading={loading} pagination={{ pageIndex: 0, pageSize: 10 }} />
    </PageWrapper>
  );
};

export default memo(Users);