import { DESKTOP_SPACING, NATURAL, ZINDEX, media } from "@/theme";

import { StyledPackageName } from "@/components/molecules";
import styled from "styled-components";

export const StyledFooter = styled.div`
  bottom: 0;
  gap: ${DESKTOP_SPACING.XXL}px;
  left: 0;
  right: 0;
  position: fixed;
  width: 100%;
  z-index: ${ZINDEX.FOOTER_INDEX};

  ${StyledPackageName} {
    margin-right: auto;
  }
`;

export const StyledFooterContent = styled.div`
  align-items: center;
  background: ${NATURAL.A};
  border-radius: 0 0 0 20px;
  box-shadow: 0px -8px 20px rgba(12, 19, 78, 0.1);
  display: flex;
  gap: ${DESKTOP_SPACING.XXXL}px;
  padding: ${DESKTOP_SPACING.XXL}px 60px ${DESKTOP_SPACING.XXL}px 40px;
  margin-left: 266px;

  ${media.laptop} {
    margin-left: 0;
  }
`;
