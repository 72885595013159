import { DESKTOP_SPACING, FONT_SIZE, FONT_WEIGHT, NATURAL, PRIMARY_BLUE } from "@/theme";

import { StyledStepProps } from "./Steps.interface";
import styled from "styled-components";

export const StyledSteps = styled.ol`
  counter-reset: steps-counter;
  display: flex;
  flex-direction: row;
  justify-content: center;
  list-tyle: none;
  margin: 0;
  padding: 0;
`;

export const StyledStepBullet = styled.div<StyledStepProps>`
  align-items: center;
  background-color: ${({ state }) => state === 'idle' ? NATURAL.A : PRIMARY_BLUE.DARK};
  border-radius: 15px;
  border: 2px solid ${({ state }) => state === 'idle' ? NATURAL.C : PRIMARY_BLUE.DARK};
  color: ${({ state }) => state === 'idle' ? NATURAL.C : NATURAL.A};
  content: counter(steps-counter);
  display: flex;
  font-size: ${FONT_SIZE.SM};
  font-weight: ${FONT_WEIGHT.SEMIBOLD};
  height: 30px;
  justify-content: center;
  margin-right: ${DESKTOP_SPACING.MD}px;
  width: 30px;
`;

export const StyledStep = styled.li<StyledStepProps>`
  align-items: center;
  color: ${({ state }) => state === 'active' ? NATURAL.I : NATURAL.F};
  counter-increment: steps-counter;
  display: flex;
  font-size: ${FONT_SIZE.SM};
  font-weight: ${FONT_WEIGHT.SEMIBOLD};
  width: auto;

  :after {
    color: ${NATURAL.F};
    content: '.....';
    letter-spacing: 5px;
    margin: 0 ${DESKTOP_SPACING.LG}px 6px;
  }

  :last-child::after {
    display: none;
  }
`