import { 
  ApiSmsMessage,
  ApiVirtualTourExtensionItem,
  ListExpiredToursParams,
  NotificationsApi,
  ReplyToSmsParams,
  UpdateExpiredToursParams 
} from "./interface";
import { SmsMessage, VirtualTourExtensionItem } from "@/types";
import { smsMessagesApiMapper, virtualTourExtensionItemsApiMapper } from "./mappers"
import { useCallback, useState  } from "react";

import { ApiHooksProps } from "../interface";
import { request } from "../request";

const hook = ({ setError, setLoading, serverPath, token }: ApiHooksProps): NotificationsApi => {

  const [virtualTourExtensionItems, setVirtualTourExtensionItems] = useState<VirtualTourExtensionItem[]>([]);
  const [smsMessages, setSmsMessages] = useState<SmsMessage[]>([]);
  
    const listExpiredTours = useCallback(async (data?: ListExpiredToursParams) => {
      const path = `${serverPath}/order/list-expired-tours/${data?.userId ?? 'all'}/${data?.page ?? ''}`;
      
      setLoading(true);
      const res = await request<never, ApiVirtualTourExtensionItem[]>(path, {token });

      setLoading(false);
  
      const mappedRes = virtualTourExtensionItemsApiMapper(res);
      setVirtualTourExtensionItems(mappedRes);
      
      return mappedRes;
    }, [serverPath, token]);

    const updateExpiredTours = useCallback(async (data: UpdateExpiredToursParams) => {
        const path = `${serverPath}/order/update-expired-tour/`;
        
        setLoading(true);
        try {
            await request(path, { body: data.virtualTourExtensionItems, token });
        } catch (error) {
            if (typeof error === 'string') {
                setError(error);
            }
        }
        
        setLoading(false);
    
        return;
      }, [serverPath, token]);
    
    const getMessages = useCallback(async () => {
      const path = `${serverPath}/sms/all/`;

      setLoading(true);
      const res = await request<never, ApiSmsMessage[]>(path, {method: "GET", token });

      setLoading(false);

      const mappedRes = smsMessagesApiMapper(res);
      setSmsMessages(mappedRes);

      return;
    }, [serverPath, token]);
  
    const replyToSms = useCallback(async (data: ReplyToSmsParams) => {
      const path = `${serverPath}/sms/reply/${data.messageId}/`;
      
      setLoading(true);
      try {
        await request(path, { body: { Body: data.body }, token });
      } catch (error) {
          if (typeof error === 'string') {
              setError(error);
          }
      }
      
      setLoading(false);

      return;

    }, [serverPath, token]);
  
    return {
      getMessages,
      listExpiredTours,
      replyToSms,
      smsMessages,
      updateExpiredTours,
      virtualTourExtensionItems
    }
  }
  
  export default hook;