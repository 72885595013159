import { FC, memo } from "react";
import { StyledMain, StyledMainSection } from "./Main.styled";

import { MainProps } from "./Main.interface";
import { Navigation } from "#/organisms";
import { UserMenu } from "#/molecules";

const Main: FC<MainProps> = ({ children, navigation, onLogout, user }) => {
  return (
    <StyledMain>
      <Navigation {...navigation} />
      <StyledMainSection>
        <UserMenu firstName={user.firstName} lastName={user.lastName} onLogout={onLogout} />
        {children}
      </StyledMainSection>
    </StyledMain>
  );
}

export default memo(Main);