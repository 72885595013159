import { ApiSmsTemplate } from "./interface.api";
import { SmsTemplate } from "@/types";

export const smsTemplateMapper = (api: ApiSmsTemplate): SmsTemplate => {
  return {
    id: api.MessageTemplateId,
    subject: api.Title,
    text: api.MessageText
  }
}

export const smsTemplatesMapper = (api: ApiSmsTemplate[]): SmsTemplate[] => {
    return api.map((smsTemplate) => smsTemplateMapper(smsTemplate))
  }