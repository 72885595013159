import { FC, useCallback, useState } from "react";
import { Link, User } from "#/atoms";
import { StyledSubmenu, StyledUserMenu } from "./UserMenu.styled";

import { PRIMARY_BLUE } from "@/theme";
import { UserMenuProps } from "./UserMenu.interface";

const UserMenu: FC<UserMenuProps> = ({ onLogout, ...userProps}) => {
  const [showSubmenu, setShowSubmenu] = useState(false);

  const handleMouseOver = useCallback((ev: React.MouseEvent<HTMLDivElement>) => {
    ev.stopPropagation();
    setShowSubmenu(true)
  }, []);
  const handleMouseLeave = useCallback((ev: React.MouseEvent<HTMLDivElement>) => {
    ev.stopPropagation();
    setShowSubmenu(false)
  }, []);

  return (
    <StyledUserMenu onMouseLeave={handleMouseLeave}>
      <User {...userProps} onMouseOver={handleMouseOver} />
      {showSubmenu && (
        <StyledSubmenu>
          <Link fontColor={PRIMARY_BLUE.DARK} onClick={onLogout}>Logout</Link>
        </StyledSubmenu>
      )}
    </StyledUserMenu>
  );
};

export default UserMenu;
