import { FilterWorker, FilterWorkers, WorkerModal } from "#/organisms";
import { NewWorkerParams, WorkerCommonFilters } from "@/api/worker/interface";
import { useCallback, useEffect, useState } from "react";
import { useFilter, useModal } from "@/hooks";

import { Workers } from "#/pages";
import { useApi } from "@/api";
import { useNavigate } from 'react-router-dom';

const WorkersView = () => {
  const { worker } = useApi();
  const {
    filter,
    numberOfFilters,
    onOpenFilter,
    setShouldFilter,
    shouldFilter,
    showFilter,
    ...filterProps
  } = useFilter<FilterWorker>();
  const { showModal, onCloseModal, onOpenModal } = useModal();
  const [loadingWorkers, setLoadingWorkers] = useState(false);
  const [called, setCalled] = useState(false);
  const navigate = useNavigate();

  const getWorkersList = async (filterData?: WorkerCommonFilters) => {
    setLoadingWorkers(true);
    await worker.getList(filterData);
    setLoadingWorkers(false);
  }

  useEffect(() => {
    if (worker.workers.length === 0 && !called) {
      setCalled(true)
      getWorkersList();
    }
  }, [worker.workers, called]);

  useEffect(() => {
    if (shouldFilter) {
      if (filter) {
        getWorkersList({
          active: filter.active,
          email: filter.email,
          name: filter.name,
          postCode: filter.location,
          workerTypeId: '', // TODO
        });
      } else {
        getWorkersList();
      }
      setShouldFilter(false);
    }
  }, [filter, shouldFilter]);

  const handleNewWorker = useCallback(async (newWorker: NewWorkerParams) => {
    await worker.create(newWorker);
    onCloseModal();
  }, []);

  const handleOpenWorker = useCallback((workerId: string) => {
    navigate(`/account-worker/id=${workerId}`);
  }, []);

  return (
    <>
      <Workers
        loading={loadingWorkers}
        workers={worker.workers}
        numberOfFilters={numberOfFilters}
        onNewWorker={onOpenModal}
        onOpenFilter={onOpenFilter}
        onClickRow={handleOpenWorker}
      />
      {showFilter && <FilterWorkers {...filterProps} filter={filter} numberOfFilters={numberOfFilters} />}
      {showModal && (
        <WorkerModal onClose={onCloseModal} onSubmit={handleNewWorker} />
      )}
    </>
  );
}

export default WorkersView;