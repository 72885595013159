import { useCallback, useEffect } from "react";

import { ApiCustomerAddress } from "@/api/customer/interface.api";
import { BaseApiUser } from "@/api/user/interface.api";
import { CustomerDetails } from "#/pages";
import { useApi } from "@/api";
import { useNotes } from "@/hooks";
import { useParams } from 'react-router-dom';

const CustomerDetailsView = () => {
  const { customer, user } = useApi();
  const { id } = useParams<'id'>();

  const { notes, onAddNote, setNotes } = useNotes();

  const handleSaveAccountDetails = useCallback(async (data: BaseApiUser) => {
    user.updateUser(data);
  }, []);

  const handleChangePassword = useCallback(async (customerId: string) => {
    console.log(customerId);
  }, []);

  const handleSaveDetails = useCallback(async (customerId: string, data: ApiCustomerAddress) => {
    customer.updateDetails(data, customerId, "0");
  }, []);

  const customerId = id ?? '';

  const getDataAndLogo  = async () => {
    await customer.getData(customerId, '');
    await customer.getLogo(customerId);
    customer.getNotes(customerId)
      .then(() => {
        setNotes(customer.notes);
      });

    
  }

  const handleLogoUpload = async (data: Blob) => {
    await customer.uploadLogo(customerId, data);
    await getDataAndLogo();
  }

  useEffect(() => {
    getDataAndLogo();
  }, []);

  return (
    <>
      <CustomerDetails
      customerData={customer.customerData} 
      logoUrl={customer.logoUrl}
      notes={notes}
      onAddNote={onAddNote}
      onLogoUpload={handleLogoUpload}
      onChangePassword={handleChangePassword}
      onSaveAccountDetails={handleSaveAccountDetails}
      onSaveDetails={handleSaveDetails} />
    </>
  );
}

export default CustomerDetailsView;