import { ApiOrderType, ApiOrderTypeResponse } from "./interface.api";
import { OrderType } from "@/types";

export const mapOrderTypeSimpleResponse = (api: ApiOrderType): OrderType => {
  return {
    amendedDate: api.AmendedDateTime,
    createdDate: api.CreatedDateTime,
    id: api.OrderTypeId,
    imageUrl: api.ImageURL,
    name: api.Name,
    price: api.Price,
  }
}

export const mapOrderTypesSimpleResponse = (api: ApiOrderType[]): OrderType[] => {
  return api.map((item) => {
    return mapOrderTypeSimpleResponse(item)
  });
}

export const mapOrderTypeResponse = (api: ApiOrderTypeResponse): OrderType => {
  return {
    amendedDate: api.AmendedDateTime,
    createdDate: api.CreatedDateTime,
    id: api.ServiceId,
    imageUrl: api.Image,
    name: api.Name,
    price: api.Price,
  }
}

export const mapOrderTypesResponse = (api: ApiOrderTypeResponse[]): OrderType[] => {
  return api.map((item) => {
    return mapOrderTypeResponse(item)
  });
}