import { Button, Icon, Typo } from "#/atoms";
import { FC, memo } from "react";
import { FONT_SIZE, NATURAL } from "@/theme";
import { StyledCurrentPage, StyledPagination } from "./Pagination.styled";

import { PaginationProps } from "./Pagination.interface";

const Pagination: FC<PaginationProps> = ({ canNextPage, canPreviousPage, currentPage, onNext, onPrev, totalPages }) => {
  return (
    <StyledPagination>
      <Button actionType="icon" state={canPreviousPage ? 'default' : 'disabled'} buttonSize="md" onClick={onPrev}>
        <Icon type='chevronLeft' />
      </Button>
      <StyledCurrentPage>{currentPage}</StyledCurrentPage>
      <Button actionType="icon" state={canNextPage ? 'default' : 'disabled'} buttonSize="md" onClick={onNext}>
        <Icon type='chevronRight' />
      </Button>
      <Typo fontSize={FONT_SIZE.SM} fontColor={NATURAL.H}>of {totalPages} pages</Typo>
    </StyledPagination>
  );
};

export default memo(Pagination);