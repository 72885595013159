import { Button, Heading, PageHeader, PageWrapper, Typo } from "#/atoms";
import { CustomTable, FilterAction } from "#/molecules";
import { FC, memo, useMemo } from "react";
import { FONT_SIZE, NATURAL } from "@/theme";

import { Column } from "react-table";
import { WorkersProps } from "./Workers.interface";

const Workers: FC<WorkersProps> = ({ loading, numberOfFilters, onNewWorker, onOpenFilter, workers, onClickRow }) => {
  const data = useMemo(() => workers.map((worker) => ({
    active: worker.active ? 'yes' : 'no',
    email: worker.email,
    id: worker.id,
    name: worker.name,
    type: worker.type,
  })), [workers]);
  
  const columns: Column[] = useMemo(() => [
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Work Type',
      accessor: 'type',
    },
    {
      Header: 'E-mail Address',
      accessor: 'email',
    },
    {
      Header: 'Active',
      accessor: 'active',
    },
  ], []);

  const handleRowClick = (rawData: object) => {
    onClickRow?.(rawData['id']);
  }

  return (
    <PageWrapper>
      <PageHeader>
        <Heading scale="h1" fontColor={NATURAL.K}>Photographers</Heading>
        <Typo fontColor={NATURAL.G} fontSize={FONT_SIZE.SM}>Description text here</Typo>
        <Button onClick={onNewWorker}>Create New</Button>
      </PageHeader>
      <FilterAction numberOfFilters={numberOfFilters} onOpen={onOpenFilter} />
      <CustomTable columns={columns} data={data} rowProps={({ cursor: 'pointer' })}  loading={loading} pagination={{ pageIndex: 0, pageSize: 10 }} onClickRow={handleRowClick} />
    </PageWrapper>
  );
};

export default memo(Workers);