import { BORDER_RADIUS, DESKTOP_SPACING, FONT_SIZE, NATURAL } from "@/theme";

import { Card } from "#/atoms/Card";
import { StyledHeading } from "#/atoms/Typography/Heading";
import { StyledOrderState } from "#/atoms/OrderState";
import { StyledPrice } from "#/atoms/Price";
import styled from "styled-components";

export const StyledOrderDate = styled.div`
  align-items: center;
  color: ${NATURAL.G};
  display: flex;
  flex: 1;
  font-size: ${FONT_SIZE.SM};
  gap: ${DESKTOP_SPACING.XS}px;
`;

export const StyledOrderLocation = styled.div`
  align-items: center;
  color: ${NATURAL.G};
  display: flex;
  font-size: ${FONT_SIZE.SM};
  gap: ${DESKTOP_SPACING.XS}px;
  width: auto;
`;

export const StyledOrderLogo = styled.div`
  align-items: center;
  background-color: ${NATURAL.C};
  border-radius: ${BORDER_RADIUS.LG};
  color: ${NATURAL.G};
  display: flex;
  fill: ${NATURAL.G};
  height: 40px;
  justify-content: center;
  overflow: hidden;
  width: 40px;

  img {
    max-height: 30px;
    max-width: 30px;
  }
`;

export const StyledOrderContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-right: ${DESKTOP_SPACING.LG}px;
`;

export const StyledOrderHeader = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  gap: ${DESKTOP_SPACING.XL}px;
`;

export const StyledOrderFooter = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: row;
  gap: ${DESKTOP_SPACING.XL}px;
`;

export const StyledOrderCard = styled(Card)`
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: ${DESKTOP_SPACING.XL}px;

  ${StyledPrice} {
    width: auto;
  }
  
  ${StyledOrderState} {
    width: auto;
  }

  ${StyledHeading} {
    flex: 1;
  }
`;