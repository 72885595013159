import { Button, Heading, Link, Typo } from "#/atoms";
import { FC, memo } from "react";
import { FONT_SIZE, NATURAL, SECONDARY_RED } from "@/theme";
import { StyleFilterForm, StyleFilterFormFields, StyledCloseButton, StyledSidebar, StyledSidebarBackdrop } from "./FilterSidebar.styled";

import CrossIcon from "@/assets/icons/Cross";
import { FilterSidebarProps } from "./FilterSidebar.interface";

const FilterSidebar: FC<FilterSidebarProps> = ({
  children,
  numberOfFilters,
  onClearFilters,
  onClose,
  onFilter,
  title = 'Filter Criteria'
}) => {
  const filterText = numberOfFilters === 0 ? 'No filter applied' : `${numberOfFilters} Filters Applied`;

  return (
    <>
      <StyledSidebar>
        <StyledCloseButton onClick={onClose}>
          <CrossIcon />
        </StyledCloseButton>
        <Heading scale="h3">{title}</Heading>
        <Typo fontColor={NATURAL.G} fontSize={FONT_SIZE.XS}>
          {filterText} <Link fontColor={SECONDARY_RED.DEFAULT} fontSize={FONT_SIZE.XS} onClick={onClearFilters} underline>Clear All</Link>
        </Typo>
        <StyleFilterForm>
          <StyleFilterFormFields>
            {children}
          </StyleFilterFormFields>
          <Button onClick={onFilter}>Apply Filters</Button>
        </StyleFilterForm>
      </StyledSidebar>
      <StyledSidebarBackdrop onClick={onClose} />
    </>
  );
}

export default memo(FilterSidebar);
