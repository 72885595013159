import { ICON_COLOR } from "@/theme";
import { StyledIconProps } from "./Icon.interface";
import styled from "styled-components";

export const StyledIcon = styled.i<StyledIconProps>`
  align-items: center;
  color: ${({ fillColor }) => fillColor ? fillColor : ICON_COLOR};
  display: flex;
  fill: ${({ fillColor }) => fillColor ? fillColor : ICON_COLOR};
  height: 24px;
  justify-content: center;
  line-height: 0;
  width: 24px;
`;
