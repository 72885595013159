import { Accordion, Button, ButtonsGroup, Card, Empty, Icon } from "#/atoms";
import { Contacts, HeadingIcon } from "#/molecules";
import { FC, memo } from "react";

import { ContactsCardsProps } from "./Contacts.interface";
import { HPOSITION } from "@/theme";

const ContactsCard: FC<ContactsCardsProps> = ({
  accordionState = 'open',
  contacts,
  onAddCustomerContact,
  onAskToEditContact,
  onNewContact,
  onAskToRemoveContact
}) => {
  return (
    <Card>
      <Accordion
        header={<HeadingIcon icon="contacts" title="Contacts" />}
        showArrow
        state={accordionState}
      >
        {contacts.length === 0 ? (
          <Empty>No contacts have been added yet.</Empty>
        ) : (
          <Contacts list={contacts} onAskToEditContact={onAskToEditContact} onAskToRemoveContact={onAskToRemoveContact} />
        )}
        
        <ButtonsGroup align={HPOSITION.CENTER} fullWidth>
          <Button actionType='outline' onClick={onNewContact}>Create New Contact</Button>
          <Button onClick={onAddCustomerContact}>
            <Icon type='plus' />
            Add Client’s Contacts
          </Button>
        </ButtonsGroup>
      </Accordion>
    </Card>
  );
};

export default memo(ContactsCard);