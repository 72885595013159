import { Button, ButtonsGroup, Card, Empty, Heading, Icon, PageHeader, Typo } from "#/atoms";
import { DESKTOP_SPACING, FONT_SIZE, NATURAL } from "@/theme";
import { FC, memo } from "react";
import { StyledCustomer, StyledCustomerName, StyledOrderTemplates } from "./CustomerAndPackage.styled";

import { OrderCustomerAndPackagesProps } from "./CustomerAndPackage.interface";
import { Package } from "#/organisms";

const CustomerAndPackage: FC<OrderCustomerAndPackagesProps> = ({
  isAdmin,
  loadingOrderTemplates,
  onCreateCustomer,
  onFindCustomer,
  onRemoveCustomer,
  onSelectOrderTemplate,
  onShowMore,
  orderTemplates,
  selectedCustomer,
}) => {
  const groups = [
    {
      groupMatchRegExp: /^(\d)+ photos$/i,
      groupName: 'Photos Only',
    },
    {
      groupMatchRegExp: /^(\d)+ photos & floor plan$/i,
      groupName: 'Photos & Floor Plan',
    },
    {
      groupMatchRegExp: /^(\d)+ photos & floor plan & EPC$/i,
      groupName: 'Photos & Floor Plan & EPC',
      orderTemplates: []
    },
    {
      groupMatchRegExp: /^(\d)+ photos & floor plan & x(\d)+ 360 tour$/i,
      groupName: 'Photos & Floor Plan & 360 Tour',
    },
    {
      groupMatchRegExp: /\S+/i,
      groupName: 'Other',
    }
  ];

  const getOrderTemplates = () => {
    if (loadingOrderTemplates) {
      return 'Loading...'
    }

    if (orderTemplates.length > 0) {
      let ot = orderTemplates;

      const data = groups.map((group) => {
        const orderTemplatesGrouped = ot.filter((item) => group.groupMatchRegExp.test(item.description));
        ot = ot.filter((item) => !group.groupMatchRegExp.test(item.description));
        
        return (
          <Package
            groupName={group.groupName}
            icons={[]}
            id={group.groupName}
            key={group.groupName}
            onClick={onSelectOrderTemplate}
            orderTemplates={orderTemplatesGrouped}
          />
        );
      });

      const other = data.pop();
      
      if (other) {
        data.unshift(other);
      }

      return data;
    }

    return <Empty>No packages have been added yet.</Empty>;
  }

  return (
    <>
      {isAdmin && (
        <>
          <PageHeader>
            <Heading scale="h1" fontColor={NATURAL.K}>Select Customer</Heading>
          </PageHeader>
          <Card>
            {selectedCustomer ? (
                <StyledCustomer key={selectedCustomer.id}>
                  <StyledCustomerName>Customer: <strong>{selectedCustomer.firstName} {selectedCustomer.lastName}</strong></StyledCustomerName>
                  <Button actionType="icon" onClick={onRemoveCustomer}><Icon type="cross" /></Button>
                </StyledCustomer>
            ) : (
              <Empty>No customer have been added yet.</Empty>
            )}
            <ButtonsGroup fullWidth>
              <Button actionType='outline' onClick={onCreateCustomer}>Create Customer</Button>
              <Button onClick={onFindCustomer}>Find Customer</Button>
              <Button onClick={onShowMore}>Show More Temporary</Button>
            </ButtonsGroup>
          </Card>
        </>
      )}
      <PageHeader>
        <Heading scale="h1" fontColor={NATURAL.K} margin={`0 0 ${DESKTOP_SPACING.SM}px`}>Select Package</Heading>
        <Typo fontColor={NATURAL.G} fontSize={FONT_SIZE.SM}>Please select appropriate package of services for your new order.</Typo>
      </PageHeader>
      <StyledOrderTemplates>
        {getOrderTemplates()}
      </StyledOrderTemplates>
    </>
  );
}

export default memo(CustomerAndPackage);
