import { Button, ButtonsGroup, InputText, Toggle } from "#/atoms";
import { FC, memo, useCallback, useEffect } from "react";
import { useInput, useToggle } from "@/hooks";

import { DESKTOP_SPACING } from "@/theme";
import { Modal } from "#/molecules";
import { StyledUserModal } from "./User.styled";
import { UserModalProps } from "./User.interface";
import { getBoleanFromYesOrNo } from "@/helpers";

const User: FC<UserModalProps> = ({ onClose, onSubmit, type = 'create', userId }) => {
  const active = useToggle('active', true);
  const admin = useToggle('admin');
  const email = useInput();
  const firstName = useInput();
  const lastName = useInput();
  const login = useInput();
  const inOffice = useToggle('inOffice');
  const password = useInput();
  const confirmPassword = useInput();

  const handleCreate = useCallback(() => {
    onSubmit({
      confirmPassword: confirmPassword.value,
      email: email.value,
      firstName: firstName.value,
      inStaff: getBoleanFromYesOrNo(inOffice.value) || false,
      isActive: getBoleanFromYesOrNo(active.value) || false,
      isAdmin: getBoleanFromYesOrNo(admin.value) || false,
      lastName: lastName.value,
      password: password.value,
      userName: login.value,
    })
  }, [active, admin, confirmPassword, email, firstName, inOffice, lastName, login, password]);

  const title = type === 'create' ? 'Create New User' : 'Edit User';

  useEffect(() => {
    if (userId) {
      console.log('cuss');
    }
  }, []);

  return (
    <Modal onClose={onClose} title={title}>
      <StyledUserModal>
        <InputText type="text" label="Login Name" value={login.value} onChange={login.onChange} />
        <InputText type="text" label="E-mail Address" value={email.value} onChange={email.onChange} />
        <InputText type="text" label="First Name" value={firstName.value} onChange={firstName.onChange} />
        <InputText type="text" label="Last Name" value={lastName.value} onChange={lastName.onChange} />
        <InputText type="password" label="Password" value={password.value} onChange={password.onChange} />
        <InputText type="password" label="Confirm Password" value={confirmPassword.value} onChange={confirmPassword.onChange} />
        <Toggle checked={admin.checked} id={admin.value} label="System Administrator" labelPosition="left" onToggle={admin.onChange} />
        <Toggle checked={inOffice.checked} id={inOffice.value} label="In Office Staff" labelPosition="left" onToggle={inOffice.onChange} />
        <Toggle checked={active.checked} id={active.value} label="Active" labelPosition="left" onToggle={active.onChange} />
        <ButtonsGroup fullWidth margin={`${DESKTOP_SPACING.EL}px 0 0`}>
          <Button onClick={handleCreate}>Create</Button>
        </ButtonsGroup>
      </StyledUserModal>
    </Modal>
  );
};

export default memo(User);
