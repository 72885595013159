import { AccordionProps, AccordionState } from "./Accordion.interface";
import { FC, MouseEvent, memo, useCallback, useState } from "react";
import { StyledAccordion, StyledAccordionArrow, StyledAccordionBody, StyledAccordionHeader } from "./Accordion.styled";

import ChevronDownIcon from "@/assets/icons/ChevronDown";
import ChevronUpIcon from "@/assets/icons/ChevronUp";

const Accordion: FC<AccordionProps> = ({
  bodyDirection,
  children,
  header,
  headerMargin,
  onToggle,
  showArrow = false,
  state
}) => {
  const [accordionState, setAccordionState] = useState<AccordionState>(() => state || 'close');

  // useEffect(() => {
  //   if (state && state !== accordionState) {
  //     setAccordionState(state)
  //   }
  // }, [state]);

  const handleAccordionToggle = useCallback((ev: MouseEvent<HTMLDivElement>) => {
    ev.stopPropagation();
    ev.preventDefault();

    const newState = accordionState === 'close' ? 'open' : 'close';
    
    if (onToggle) {
      onToggle(newState);
    }
    setAccordionState(newState);
  }, [accordionState]);

  return (
    <StyledAccordion bodyDirection={bodyDirection}>
      <StyledAccordionHeader
        mb={accordionState === 'open' && headerMargin ? headerMargin : ''}
        onClick={handleAccordionToggle}
      >
        {header}
        {showArrow && (
          <StyledAccordionArrow>
            {accordionState === 'close' ? <ChevronUpIcon /> : <ChevronDownIcon />}
          </StyledAccordionArrow>
        )}
      </StyledAccordionHeader>
      <StyledAccordionBody state={accordionState}>{children}</StyledAccordionBody>
    </StyledAccordion>
  );
};

export default memo(Accordion);
