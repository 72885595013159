import { Button, Heading, Icon, PageHeader, PageWrapper, Typo } from "#/atoms";
import { FC, memo, useCallback, useMemo } from "react";
import { FONT_SIZE, NATURAL } from "@/theme";

import { Column } from "react-table";
import { CustomTable } from "#/molecules";
import { GridCell } from "#/atoms/Layout/Grid";
import { OrderTypesProps } from "./OrderTypes.interface";

import "./ordertypes.css";

const OrderTypes: FC<OrderTypesProps> = ({ loading, ordersType, onEdit, onNew }) => {
  const data = useMemo(() => (ordersType.map((order) => ({
    id: order.id,
    image: order.imageUrl,
    name: order.name,
    price: order.price,
  })))
  .sort((a, b) => a.name.localeCompare(b.name)), [ordersType]);

  const handleCreateNew = useCallback(() => {
    onNew();
  }, []);

  const handleEdit = useCallback((id: string) => {    
    onEdit(id);
  }, []);
  
  const columns: Column[] = useMemo(() => [
    {
      Cell: (props) => <span className="service-item" style={{ backgroundImage: `url(${props.value})` }} />,
      Header: '',
      accessor: 'image',
    },
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Price',
      accessor: 'price',
    },
    {
      Cell: ({ value }) => {
        return (
          <GridCell textAlign="right">
            <Button actionType='icon' onClick={() => handleEdit(value)}>
              <Icon type="edit" />
            </Button>
          </GridCell>
        );
      },
      Header: '',
      accessor: 'id',
      id: 'button'
    }
  ], []);
  
  return (
    <PageWrapper>
      <PageHeader>
        <Heading scale="h1" fontColor={NATURAL.K}>Order Types</Heading>
        <Typo fontColor={NATURAL.G} fontSize={FONT_SIZE.SM}>Description text here</Typo>
        <Button onClick={handleCreateNew}>Create New</Button>
      </PageHeader>

      <CustomTable columns={columns} data={data} loading={loading} />
    </PageWrapper>
  );
};

export default memo(OrderTypes);