import { FC, memo } from "react";
import { StyledPrice, StyledVat } from "./Price.styled";
import { convertPrice, getPriceArray } from "./utils";

import { PriceProps } from "./Price.interface";

const Price: FC<PriceProps> = ({ amount, currency, fontSize, fontWeight, locale, showVat = false }) => {
  const price = convertPrice(amount, currency, locale);
  const priceArray = getPriceArray(price);

  return (
    <StyledPrice fontSize={fontSize} fontWeight={fontWeight}>
      {priceArray[0]}
      {showVat && <StyledVat>ExVat</StyledVat>}
    </StyledPrice>
  );
};

export default memo(Price);