import { FC, memo, useEffect, useState } from "react";
import {
  StyledDateIcon,
  StyledSelectedDate
} from "./InputDatetime.styled";

import CalendarIcon from '@/assets/icons/Calendar';
import { InputDatetimeProps } from "./InputDatetime.interface";
import { InputLabel } from "../InputLabel";
import TimeIcon from '@/assets/icons/Time';
import { formatDate } from "@/helpers";

const getValue = (value: Date | string | null) => {
  if (!value) {
    return ''
  }

  if (typeof value === 'string') {
    return value
  }
  
  return formatDate(value);
}

const InputDate: FC<InputDatetimeProps> = ({ datetime, focus, iconType, label }) => {
  const [shrink, setShrink] = useState(false);

  useEffect(() => {
    if (focus) {
      setShrink(true)
    } else if (datetime == null) {
      setShrink(false)
    } else {
      setShrink(true)
    }
  }, [datetime, focus]);
  
  const value = getValue(datetime);

  return (
    <>
      <InputLabel shrink={shrink}>{label}</InputLabel>
      <StyledSelectedDate>
        {value}
        <StyledDateIcon>
          {iconType === 'calendar' && <CalendarIcon />}
          {iconType === 'time' && <TimeIcon />}
        </StyledDateIcon>
      </StyledSelectedDate>
    </>
  );
}

export default memo(InputDate);