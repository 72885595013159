import { DESKTOP_SPACING, NATURAL, PRIMARY_BLUE, media } from "@/theme";

import { NavLink } from "react-router-dom";
import { StyledIcon } from "#/atoms/Icon";
import { StyledLink } from "#/atoms/Link";
import { StyledNotification } from "#/atoms/Notification";
import styled from "styled-components";

export const StyledNavLink = styled(NavLink)<{ activeclassname?: string }>`
  align-items: center;
  color: ${NATURAL.C};
  display: flex;
  line-height: 24px;
  text-decoration: none;
  
  ${StyledIcon} {
    margin-right: ${DESKTOP_SPACING.XXL}px;
  }

  &.${({ activeclassname }) => activeclassname} {
    color: ${PRIMARY_BLUE.DEFAULT};

    ${StyledIcon} {
      fill: ${PRIMARY_BLUE.DEFAULT};
    }
  }
`;

export const StyledMenuLink = styled(StyledLink)`
  align-items: center;
  color: ${NATURAL.C};
  display: flex;
  line-height: 24px;
  text-decoration: none;
  
  ${StyledIcon} {
    margin-right: ${DESKTOP_SPACING.XXL}px;
  }
`;

export const StyledlinkLabel = styled.span``;

export const StyledMenu = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const StyledMenuHead = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  margin-top: ${DESKTOP_SPACING.EL}px;

  :fisrt-child {
    margin-top: 0;
  }

  ${StyledNavLink} {
    flex: 1;
  }

  ${StyledLink} {
    flex: 1;
  }

  ${StyledNotification} {
    margin-left: ${DESKTOP_SPACING.XXL}px;
  }

  ${media.laptop} {
    margin-top: ${DESKTOP_SPACING.XL}px;
  }
`;

export const StyledSubMenu = styled.div<{ show: boolean }>`
  padding-left: ${DESKTOP_SPACING.XXXL + DESKTOP_SPACING.XXL}px;
  padding-top: ${DESKTOP_SPACING.LG}px;
`;

export const StyledSubMenuItem = styled.div`
  display: flex;
  min-height: ${DESKTOP_SPACING.XXXL}px;
  margin-bottom: ${DESKTOP_SPACING.XL}px;

  &:last-child {
    margin-bottom: 0;
  }

  ${StyledNotification} {
    margin-left: ${DESKTOP_SPACING.XXL}px;
  }
`;

export const StyledArrowIcon = styled.div``;
