import styled, { css } from 'styled-components';

import { StyledCellProps } from './Grid.interface'

export const StyledCell = styled.div<StyledCellProps>`
  flex-grow: 1;
  box-sizing: border-box;
  margin: 0;
  text-align: ${({ textAlign }) => textAlign || ''};

  ${({ gridColumn }) =>
    gridColumn &&
    css`
      grid-column: ${gridColumn};
    `
  }
  
  ${({ order }) =>
    order &&
    css`
      order: ${order};
    `}

  ${({ align }) => {
    if (align) {
      switch (align) {
        case 'bottom':
          return `
            align-self: flex-end;
            `
        case 'middle':
          return `
            align-self: center;
          `
        case 'top':
          return `
            align-self: flex-start;
          `
        default:
          return ''
      }
    }
  }}

  
  ${({ justify }) => {
    if (justify) {
      switch (justify) {
        case 'flex-end':
          return `
            justify-self: flex-end;
            `
        case 'stretch':
          return `
            justify-self: stretch;
          `
        case 'center':
          return `
            justify-self: center;
          `
        case 'flex-start':
          return `
            justify-self: flex-start;
          `
        default:
          return ''
      }
    }
  }}

  ${({ alignHeight }) =>
    alignHeight &&
    `display: flex;
      > div {
        flex: 1
    }`
  }
`
