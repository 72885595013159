import { AlertType, StyledAlertProps } from "./Alert.interface";
import { BORDER_RADIUS, DESKTOP_SPACING, FONT_SIZE, FONT_WEIGHT, NATURAL, PRIMARY_BLUE, SECONDARY_RED, TERTIARY_GREEN, TERTIARY_ORANGE, ZINDEX } from "@/theme";
import styled, { keyframes } from "styled-components";

/* Animations to fade the snackbar in and out */
const fadeIn = keyframes`
  from {
    opacity: 0;
    top: 0;
  }
  to {
    opacity: 1;
    top: 40px;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
    top: 40px;
  }
  to {
    opacity: 0;
    top: 0;
  }
`;

const getAlertColors = (type: AlertType) => {
  switch (type) {
    case 'success': {
      return `
        background: ${TERTIARY_GREEN.LIGHTER};
        box-shadow: -2px 0px 0px ${TERTIARY_GREEN.DEFAULT};
        color: ${TERTIARY_GREEN.DARK};

        svg {
          color: ${TERTIARY_GREEN.DEFAULT};
          fill: ${TERTIARY_GREEN.DEFAULT};
        }
      `;
    }
    case 'error': {
      return `
        background: ${SECONDARY_RED.LIGHTER};
        box-shadow: -2px 0px 0px ${SECONDARY_RED.DEFAULT};
        color: ${SECONDARY_RED.DARK};

        svg {
          color: ${SECONDARY_RED.DARK};
          fill: ${SECONDARY_RED.DARK};
        }
      `;
    }
    case 'info': {
      return `
        background: ${PRIMARY_BLUE.LIGHT};
        box-shadow: -2px 0px 0px ${PRIMARY_BLUE.DARK};
        color: ${PRIMARY_BLUE.DARK};

        svg {
          color: ${PRIMARY_BLUE.DARK};
          fill: ${PRIMARY_BLUE.DARK};
        }
      `;
    }
    case 'warning': {
      return `
        background: ${TERTIARY_ORANGE.LIGHTER};
        box-shadow: -2px 0px 0px ${TERTIARY_ORANGE.DEFAULT};
        color: ${TERTIARY_ORANGE.DEFAULT};

        svg {
          color: ${TERTIARY_ORANGE.DEFAULT};
          fill: ${TERTIARY_ORANGE.DEFAULT};
        }
      `;
    }
  }
}

export const StyledAlert = styled.div<StyledAlertProps>`
  align-items: center;
  animation: ${fadeIn} 0.5s, ${fadeOut} 0.5s 2.5s forwards;
  border-radius: ${BORDER_RADIUS.MD};
  display: flex;
  flex-direction: row;
  font-size: ${FONT_SIZE.SM};
  font-weight: ${FONT_WEIGHT.SEMIBOLD};
  gap: 10px;
  min-height: 48px;
  left: 50%;
  line-height: 20px;
  padding: 12px 12px 12px 8px;
  position: fixed;
  top: 40px;
  transform: translateX(-50%);
  width: auto;
  z-index: ${ZINDEX.LOADING_INDEX};

  ${({ type }) => getAlertColors(type)}
`;

export const StyledCloseButton = styled.button`
  align-items: center;
  background-color: transparent;
  border: 0;
  display: flex;
  margin: 0 0 0 ${DESKTOP_SPACING.MD}px;
  padding: 0;

  svg {
    color: ${NATURAL.G};
    fill: ${NATURAL.G};
  }
`;
