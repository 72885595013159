import { DESKTOP_SPACING, FONT_SIZE, FONT_WEIGHT, NATURAL, PRIMARY_BLUE } from "@/theme";

import styled from "styled-components";

interface StyledDayProps {
  isSelected: boolean;
  isToday: boolean;
}

const getBackgroundColor = (isSelected: boolean, isToday: boolean) => {
  if (isSelected) {
    return PRIMARY_BLUE.DARK;
  }

  if (isToday) {
    return PRIMARY_BLUE.LIGHT;
  }

  return NATURAL.A;
}

export const StyledDay = styled.button<StyledDayProps>`
  background-color: ${({ isSelected, isToday }) => getBackgroundColor(isSelected, isToday)};
  border: 0;
  border-radius: 12px;
  color: ${({ isSelected }) => isSelected ? NATURAL.A : NATURAL.G};
  font-size: ${FONT_SIZE.XS};
  font-weight: ${FONT_WEIGHT.SEMIBOLD};
  height: 24px;
  margin: ${DESKTOP_SPACING.SM}px auto;
  padding: 0;
  text-align: center;
  width: 24px;

  :hover {
    background-color: ${({ isSelected, isToday }) => !isSelected && !isToday ? PRIMARY_BLUE.LIGHT : ''};
  }
`;