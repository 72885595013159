import { StyledTable, StyledTbody, StyledTd, StyledTh, StyledThead, StyledTr } from "./Table.styled";
import { memo, useMemo } from "react";
import { usePagination, useTable } from "react-table";

import Pagination from "./Pagination";
import { TableProps } from "./Table.interface";

const Table = <T extends object>({ cellProps, columns, data, headerCellProps, rowProps, loading = false, pagination, onClickRow }: TableProps<T>) => {
  const paginationProps = useMemo(() => pagination ? {
    initialState: { pageIndex: pagination.pageIndex || 0, pageSize: pagination.pageSize || 10 }
  } : {}, [pagination]);
  
  const tableInstance = useTable({ columns, data, ...paginationProps }, usePagination);
  const {
    canPreviousPage,
    canNextPage,
    pageOptions,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    nextPage,
    page,
    previousPage,
    rows,
    state: { pageIndex },
    prepareRow,
  } = tableInstance;

  const rowsData = pagination ? page : rows;
  
  return (
    <>
      <StyledTable {...getTableProps()} width="100%">
        <StyledThead>
          {headerGroups.map((headerGroup) => (
            <StyledTr {...headerGroup.getHeaderGroupProps()} key={headerGroup.getHeaderGroupProps().key}>
              {headerGroup.headers.map(column => (
                <StyledTh {...column.getHeaderProps()} key={column.getHeaderProps().key} {...headerCellProps}>
                  {column.render('Header')}
                </StyledTh>
              ))}
            </StyledTr>
          ))}
        </StyledThead>
        <StyledTbody {...getTableBodyProps()}>
          {!loading ? rowsData.length > 0 ? (
            rowsData.map((row) => {
              prepareRow(row);

              return (
                <StyledTr {...row.getRowProps()} key={row.getRowProps().key} {...rowProps} onClick={() => onClickRow?.(row.original)}>
                  {row.cells.map((cell) => {
                    return (
                      <StyledTd {...cell.getCellProps()} key={cell.getCellProps().key} {...cellProps}>
                        {cell.render('Cell')}
                      </StyledTd>
                    )
                  })}
                </StyledTr>
              )
            })
          ) : (
            <StyledTr>
              <StyledTd colSpan={columns.length}>Nothing to show!</StyledTd>
            </StyledTr>
          ) : (
            <StyledTr>
              <StyledTd colSpan={columns.length}>Loading...</StyledTd>
            </StyledTr>
          )}
        </StyledTbody>
      </StyledTable>
      {pagination && (
        <Pagination
          canNextPage={canNextPage}
          canPreviousPage={canPreviousPage}
          currentPage={`${pageIndex + 1}`}
          totalPages={`${pageOptions.length}`}
          onNext={nextPage}
          onPrev={previousPage}
        />
      )}
    </>
  );
}

export default memo(Table);