import { FC, memo, useCallback, useEffect, useState } from "react";
import { InputSelect, InputSelectChange } from "#/atoms";

import { DIRECTION } from "@/theme";
import { RadioButtonGroupProps } from "./RadioButtonGroup.interface";
import { StyledRadioButtonGroup } from "./RadioButton.styled";

const RadioButtonGroup: FC<RadioButtonGroupProps> = ({
  direction = DIRECTION.ROW,
  inputs,
  groupName,
  onChangeValue,
  selectedValue = ''
}) => {
  const [selected, setSelected] = useState(selectedValue);

  useEffect(() => {
    if (selected !== selectedValue) {
      setSelected(selectedValue);
    }
  }, [selectedValue]);

  const handleInputChange: InputSelectChange = useCallback((_, value) => {
    if (onChangeValue) {
      onChangeValue(groupName, value);
    }
    setSelected(value);
  }, []);
  
  return (
    <StyledRadioButtonGroup direction={direction}>
      {inputs.map(({ id, ...input }) => {
        const checked = input.value === selected;

        return (
          <InputSelect
            {...input}
            type="radio"
            key={id}
            id={id}
            name={groupName}
            onChangeValue={handleInputChange}
            checked={checked}
          />
        );
      })}
    </StyledRadioButtonGroup>
  );
}

export default memo(RadioButtonGroup);
