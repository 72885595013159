import { Button, ButtonsGroup, Heading, Link, PageHeader, PageWrapper, Typo } from "#/atoms";
import { CustomTable, FilterAction } from "#/molecules";
import { FC, memo, useMemo } from "react";
import { FONT_SIZE, NATURAL, PRIMARY_BLUE } from "@/theme";

import { Column } from 'react-table';
import { ExpiredVirtualToursProps } from "./ExpiredVirtualTours.interface";
import { useNavigate } from 'react-router-dom';

const ExpiredVirtualTours: FC<ExpiredVirtualToursProps> = ({
  expiredVirtualTours,
  loadingExpiredVirtualTours: loadingCustomers,
  numberOfFilters,
  onExtend,
  onOpenFilter,
  onRemove,
  onShowMore
}) => {
  const navigate = useNavigate();

  const data = useMemo(() => (expiredVirtualTours.map((expiredVirtualTour) => ({
    abandoned: expiredVirtualTour.abandoned,
    amendedDateTime: expiredVirtualTour.amendedDateTime,
    customerName: expiredVirtualTour.customerName,
    externalLink: expiredVirtualTour.externalLink,
    orderId: expiredVirtualTour.orderId,
    orderServiceId: expiredVirtualTour.orderServiceId,
    virtualTourExtensionId: expiredVirtualTour.virtualTourExtensionId,
  }))), [expiredVirtualTours]);
  
  const columns: Column[] = useMemo(() => [
    {
      Cell: ({ value }) => {
        return (
          <Button
            actionType='ghost'
            onClick={() => navigate(`/order-view-backend/order=${value}`, { replace: true })}
            buttonSize="md"
          >
            {value}
          </Button>
        );
      },
      Header: 'Job Number',
      accessor: 'orderId',
      id: 'orderId'
    },
    {
      Header: 'Customer',
      accessor: 'customerName',
    },
    {
      Header: 'Expiration Time',
      accessor: 'amendedDateTime', // TODO
    },
    {
      Cell: ({ data: rawData, row, value }) => {
        return (
          <Link
            href={value}
            download
            fontColor={PRIMARY_BLUE.DARK}
            target="_blank"
          >
            {rawData[row.index].externalLink}
          </Link>
        );
      },
      Header: 'External Link',
      accessor: 'externalLink',
      id: 'externalLink'
    },
    {
      Cell: ({ data: rawData, row, value }) => {
        
        return (
          <ButtonsGroup fullWidth>
            <Button
            actionType='outline'
            onClick={() => onExtend(value, rawData[row.index].virtualTourExtensionId)}
            buttonSize="sm"
            >
              Extend
            </Button>
            <Button
              actionType='solid'
              onClick={() => onRemove(value, rawData[row.index].virtualTourExtensionId)}
              buttonSize="sm"
              >
                Remove
            </Button>
          </ButtonsGroup>
        );
      },
      Header: '',
      accessor: 'orderServiceId',
      id: 'button'
    },
  ], []);

  return (
    <PageWrapper>
      <PageHeader>
        <Heading scale="h1" fontColor={NATURAL.K}>Expired Virtual Tour Links</Heading>
        <Typo fontColor={NATURAL.G} fontSize={FONT_SIZE.SM}>Virtual Tour service links listed here are past the default 1 year retention period</Typo>
      </PageHeader>
      <FilterAction numberOfFilters={numberOfFilters} onOpen={onOpenFilter} />
      <CustomTable columns={columns} data={data} loading={loadingCustomers} />
      <ButtonsGroup>
          <Button onClick={onShowMore}>Show More</Button>
        </ButtonsGroup>
    </PageWrapper>
  );
}

export default memo(ExpiredVirtualTours);