/* eslint-disable no-implicit-coercion */
import { FC, memo, useCallback, useEffect, useState } from "react";

import { InputLabel } from "../InputLabel";
import { StyledFieldGroup } from "../InputLabel/InputLabel";
import { StyledTextarea } from "./Textarea.styled";
import { TextareaProps } from "./Textarea.interface";

const Textarea: FC<TextareaProps> = ({
  fullWidth = true,
  label,
  name,
  onBlur,
  onChange,
  onClick,
  onFocus,
  onMouseEnter,
  onMouseLeave,
  value = '',
  ...textareaProps
}) => {
  const [inputValue, setTextareaValue] = useState(value);
  const [shrink, setShrink] = useState<boolean>(!!value);

  useEffect(() => {
    setTextareaValue(value);
    setShrink(!!value);
  }, [value])

  const onMouseEnterHandler = useCallback((ev: React.MouseEvent<HTMLTextAreaElement>) => {
    if (onMouseEnter) {
      onMouseEnter(ev);
    }
  }, []);

  const onMouseLeaveHandler = useCallback((ev: React.MouseEvent<HTMLTextAreaElement>) => {
    if (onMouseLeave) {
      onMouseLeave(ev);
    }
  }, []);

  const onFocusHandler = useCallback((ev: React.FocusEvent<HTMLTextAreaElement>) => {
    if (onFocus) {
      onFocus(ev);
    }
    setShrink(true);
  }, []);

  const onBlurHandler = useCallback((ev: React.FocusEvent<HTMLTextAreaElement>) => {
    if (onBlur) {
      onBlur(ev);
    }
    
    if (!inputValue) {
      setShrink(false);
    }
  }, [inputValue]);

  const onChangeHandler = useCallback((ev: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (onChange) {
      onChange(ev);
    }

    setTextareaValue(ev.target.value);
  }, []);

  const onClickHandler = useCallback((ev: React.MouseEvent<HTMLTextAreaElement>) => {
    ev.stopPropagation();

    if (onClick) {
      onClick(ev);
    }
  }, []);

  return (
    <StyledFieldGroup>
      <InputLabel shrink={shrink}>{label}</InputLabel>
      <StyledTextarea
        {...textareaProps}
        fullWidth={fullWidth}
        name={name}
        onBlur={onBlurHandler}
        onChange={onChangeHandler}
        onClick={onClickHandler}
        onFocus={onFocusHandler}
        onMouseEnter={onMouseEnterHandler}
        onMouseLeave={onMouseLeaveHandler}
        value={inputValue}
      />
    </StyledFieldGroup>
  );
}

export default memo(Textarea);
