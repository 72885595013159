import { FC, memo } from "react";

import { BORDER_RADIUS } from "@/theme";
import { ButtonProps } from "./Button.interface";
import { StyledButton } from "./Button.styled";

const Button: FC<ButtonProps> = ({
  actionType,
  borderRadius = BORDER_RADIUS.MD,
  buttonColor,
  buttonSize,
  children,
  fullWidth = false,
  state,
  ...buttonProps
}) => {
  return (
    <StyledButton
      {...buttonProps}
      actionType={actionType}
      borderRadius={borderRadius}
      buttonColor={buttonColor}
      fullWidth={fullWidth}
      buttonSize={buttonSize}
      state={state}
    >
      {children}
    </StyledButton>
  );
};

export default memo(Button);