import { Button, ButtonsGroup, Card, Grid, Heading, InputText, PageHeader, PageWrapper, Select, Typo } from "#/atoms";
import { DESKTOP_SPACING, FONT_SIZE, NATURAL, PRIMARY_BLUE } from "@/theme";
import { FC, memo, useCallback, useState } from "react";
import { useInput, useSelect } from "@/hooks";

import { RadioButtonGroup } from "@/components/molecules";
import { RadioButtonProps } from "@/components/molecules/RadioButtonGroup/RadioButtonGroup.interface";
import { SettingsProps } from "./Settings.interface";
import { SmsTemplate } from "@/types";
import { Textarea } from "@/components/atoms/Form/Textarea/Textarea.stories";

const Settings: FC<SettingsProps> = ({ 
  onSaveSettings,
  onSaveVirtualTourSettings,
  onSaveCancelFeeSettings,
  onSaveUpsell,
  onAddSmsTemplate,
  onEditSmsTemplate,
  onDeleteSmsTemplate,
  onReloadTemplates,
  settings,
  upsell,
  smsTemplates }) => {
  const upsellAmount = useInput(`${upsell?.defaultPhotos}` || '');
  const upsellPrice = useInput(`${upsell?.price}` || '');
  const floorAreaSurchargeAmount = useInput(`${settings?.floorAreaSurchargeAmount}` || '');
  const floorAreaSurchargeThreshold = useInput(`${settings?.floorAreaSurchargeThreshold}` || '');
  const virtualTourImagesSurchargeAmount = useInput(`${settings?.virtualTourImagesSurchargeAmount}` || '');
  const virtualTourImagesThreshold = useInput(`${settings?.virtualTourImagesThreshold}` || '');
  const cancelFeeAmount = useInput(`${settings?.cancelFeeAmount}` || '');
  const cancelFeeType = useInput(`${settings?.cancelFeeType}` || '');
  const smsTemplateSelect = useSelect('');
  const smsTemplateSubject = useInput('');
  const smsTemplateMessageText = useInput('');

  const [selectedTemplateId, setSelectedTemplateId] = useState<string | null>(null);

  const templates = [{
    id: '',
    label: '',
    text: ''
  }].concat(
  smsTemplates.map((s) => ({
    id: s.id,
    label: s.subject,
    text: s.text
  }))).concat([{
    id: 'new',
    label: '---New---',
    text: ''
  }]);

  const cancelFeeTypes : RadioButtonProps[] = [{
      id: "1",
      label: "Fixed Amount",
      value: "1"
    },
    {
      id: "2",
      label: "Percentage",
      value: "2"
    }
  ]

  const handleUpsellSave = useCallback(() => {
    if (!upsell) {
      return;
    }

    onSaveUpsell({
      defaultPhotos: Number(upsellAmount.value),
      id: upsell?.id,
      price: Number(upsellPrice.value),
    });
  }, [upsell, upsellAmount, upsellPrice]);

  const handleSettingsSave = useCallback(() => {
    if (!settings) {
      return;
    }

    onSaveSettings({
      floorAreaSurchargeAmount: Number(floorAreaSurchargeAmount.value),
      floorAreaSurchargeThreshold: Number(floorAreaSurchargeThreshold.value),
      id: settings?.id,
    });
  }, [settings, floorAreaSurchargeAmount, floorAreaSurchargeThreshold]);

  const handleVirtualTourSettingsSave = useCallback(() => {
    if (!settings) {
      return;
    }

    onSaveVirtualTourSettings({
      id: settings?.id,
      virtualTourImagesSurchargeAmount: Number(virtualTourImagesSurchargeAmount.value),
      virtualTourImagesThreshold: Number(virtualTourImagesThreshold.value),
    });
  }, [settings, virtualTourImagesSurchargeAmount, virtualTourImagesThreshold]);

  const handleCancelFeeTypeChange = useCallback((groupName: string, selectedValue: string) => {
    cancelFeeType.setValue(selectedValue);
  }, []);

  const handleCancelFeeSettingsSave = useCallback(() => {
    if (!settings) {
      return;
    }

    onSaveCancelFeeSettings({
      cancelFeeAmount: Number(cancelFeeAmount.value),
      cancelFeeType: Number(cancelFeeType.value),
      id: settings?.id,
    });
  }, [settings, cancelFeeAmount, cancelFeeType]);

  const handleSmsTemplateSelected = useCallback((e) => {
    let smsTemplate: SmsTemplate | undefined;
    
    switch(e.target.value){
      case '':
        setSelectedTemplateId(null);
        smsTemplateSubject.setValue('');
        smsTemplateMessageText.setValue('');
        break;
      case 'new':
        setSelectedTemplateId('new');
        smsTemplateSubject.setValue('');
        smsTemplateMessageText.setValue('');
        break;
      default:
        smsTemplate = smsTemplates.find(s => s.id === e.target.value);
        smsTemplateSubject.setValue(smsTemplate?.subject ?? '');
        smsTemplateMessageText.setValue(smsTemplate?.text ?? '');
        setSelectedTemplateId(smsTemplate?.id ?? '');
      break;
    }

  }, [smsTemplateSelect, smsTemplateSubject]);

  const handleSmsTemplateSave = useCallback(async () => {
    const smsTemplate = {
      id: selectedTemplateId ?? '',
      subject: smsTemplateSubject.value,
      text: smsTemplateMessageText.value
    };
    
    if (selectedTemplateId === 'new') {
      await onAddSmsTemplate(smsTemplate);
      onReloadTemplates();
    } else if (selectedTemplateId) {
      await onEditSmsTemplate(smsTemplate);
      onReloadTemplates();
    }
  }, [smsTemplateSelect, smsTemplateSubject]);

  const handleSmsTemplateDelete = useCallback(async () => {
    if (selectedTemplateId) {
      await onDeleteSmsTemplate(selectedTemplateId);
      onReloadTemplates();
      smsTemplateSubject.setValue('');
      smsTemplateMessageText.setValue('');
    }
  }, [smsTemplateSelect, smsTemplateSubject]);

  return (
    <PageWrapper>
      <PageHeader>
        <Heading scale="h1" fontColor={NATURAL.K}>Settings</Heading>
        <Typo fontColor={NATURAL.G} fontSize={FONT_SIZE.SM}>Global system settings</Typo>
      </PageHeader>

      <Card>
        <Heading display="flex" scale="h4" fontColor={PRIMARY_BLUE.DARK}>
          Common Upsell Price Per Photo
        </Heading>
        <Grid columnGap={DESKTOP_SPACING.XL} gridTemplateColumns="repeat(2, 1fr)" margin={`${DESKTOP_SPACING.XXL}px 0 0`}>
          <InputText type="number" label="Price (GBP)" value={upsellPrice.value} onChange={upsellPrice.onChange} />
          <InputText type="number" label="Default Photos Amount" value={upsellAmount.value} onChange={upsellAmount.onChange} />
        </Grid>
        <ButtonsGroup fullWidth>
          <Button onClick={handleUpsellSave}>Save</Button>
        </ButtonsGroup>
      </Card>

      <Card>
        <Heading display="flex" scale="h4" fontColor={PRIMARY_BLUE.DARK}>
          Extra Charge per sq ft
        </Heading>
        <Grid columnGap={DESKTOP_SPACING.XL} gridTemplateColumns="repeat(2, 1fr)" margin={`${DESKTOP_SPACING.XXL}px 0 0`}>
          <InputText type="number" label="Charge extra for floor plans above this sq ft" value={floorAreaSurchargeThreshold.value} onChange={floorAreaSurchargeThreshold.onChange} />
          <InputText type="number" label="Amount to charge per sq ft (GBP)" value={floorAreaSurchargeAmount.value} onChange={floorAreaSurchargeAmount.onChange} />
        </Grid>
        <ButtonsGroup fullWidth>
          <Button onClick={handleSettingsSave}>Save</Button>
        </ButtonsGroup>
      </Card>

      <Card>
        <Heading display="flex" scale="h4" fontColor={PRIMARY_BLUE.DARK}>
          Extra charge for Virtual Tour
        </Heading>
        <Grid columnGap={DESKTOP_SPACING.XL} gridTemplateColumns="repeat(2, 1fr)" margin={`${DESKTOP_SPACING.XXL}px 0 0`}>
          <InputText type="number" label="Charge extra for photos above this number" value={virtualTourImagesThreshold.value} onChange={virtualTourImagesThreshold.onChange} />
          <InputText type="number" label="Amount to charge per extra photos (GBP)" value={virtualTourImagesSurchargeAmount.value} onChange={virtualTourImagesSurchargeAmount.onChange} />
        </Grid>
        <ButtonsGroup fullWidth>
          <Button onClick={handleVirtualTourSettingsSave}>Save</Button>
        </ButtonsGroup>
      </Card>

      <Card>
        <Heading display="flex" scale="h4" fontColor={PRIMARY_BLUE.DARK}>
        Extra charge for cancelled orders
        </Heading>
        <Grid columnGap={DESKTOP_SPACING.XL} gridTemplateColumns="repeat(2, 1fr)" margin={`${DESKTOP_SPACING.XXL}px 0 0`}>
          <RadioButtonGroup groupName="cancelFeeType" inputs={cancelFeeTypes} selectedValue={cancelFeeType.value} onChangeValue={handleCancelFeeTypeChange} />
          <InputText type="number" label="Cancellation fee amount" value={cancelFeeAmount.value} onChange={cancelFeeAmount.onChange} />
        </Grid>
        <ButtonsGroup fullWidth>
          <Button onClick={handleCancelFeeSettingsSave}>Save</Button>
        </ButtonsGroup>
      </Card>

      <Card>
        <Heading display="flex" scale="h4" fontColor={PRIMARY_BLUE.DARK}>
        Message Templates
        </Heading>
        <Grid columnGap={DESKTOP_SPACING.XL} gridTemplateColumns="repeat(1, 1fr)" margin={`${DESKTOP_SPACING.XXL}px 0 0`}>
          <Select label="Choose" options={templates} onChange={handleSmsTemplateSelected} />
          <InputText type="text" label="Subject" value={smsTemplateSubject.value} onChange={smsTemplateSubject.onChange} />
          <Textarea label="Message text" value={smsTemplateMessageText.value} onChange={smsTemplateMessageText.onChange} />
        </Grid>
        <ButtonsGroup fullWidth>
          <Button onClick={handleSmsTemplateSave} state={ selectedTemplateId ? 'default' : 'disabled' } >Save</Button>
          <Button onClick={handleSmsTemplateDelete} state={ selectedTemplateId ? 'default' : 'disabled' } >Delete</Button>
        </ButtonsGroup>
      </Card>
      
    </PageWrapper>
  );
}

export default memo(Settings);