import { ApiProvider } from '@/api';
import { App } from './views/App';
import { HashRouter } from "react-router-dom";
import { createRoot } from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

root.render(
  <HashRouter>
    <ApiProvider>
      <App />
    </ApiProvider>
  </HashRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
