import { Accordion, Card, Grid, InputSelect, InputText, Select } from "#/atoms";
import { Datepicker, HeadingIcon, Timepicker } from "#/molecules";
import { FC, memo } from "react";

import { DESKTOP_SPACING } from "@/theme";
import { KeysCardProps } from "./Keys.interface";

const KeysCard: FC<KeysCardProps> = ({
  accordionState = 'open',
  keyNotes,
  keys,
  keysDate,
  keysTime,
  keysTimeOption,
  keysWith,
  keysWithDetail,
}) => {
  return (
    <Card>
      <Accordion
        header={<HeadingIcon icon="key" title="Keys" />}
        headerMargin={`${DESKTOP_SPACING.XXXL}px`}
        showArrow
        state={accordionState}
      >
        <InputSelect
          type="checkbox"
          checked={keys.checked}
          label="Keys will be needed."
          name="need-keys"
          value="need-keys"
          onChangeValue={keys.onChange}
        />
        <Grid columnGap={DESKTOP_SPACING.XL} gridTemplateColumns="repeat(2, 40%)" margin={`${DESKTOP_SPACING.XXL}px 0 0`}>
          <Select
            label="With"
            options={[{ id: 'Agent', label: 'Agent' }, { id: 'Porter', label: 'Porter' }]}
            selected={keysWith.value}
            onChange={keysWith.onChange}
          />
          <InputText
            type="text"
            label="Contact Details"
            value={keysWithDetail.value}
            onChange={keysWithDetail.onChange}
          />
          <Select
            label="Available"
            options={[{ id: 'Any time', label: 'Any time' }, { id: 'Specific time', label: 'Specific time' }]}
            selected={keysTimeOption.value}
            onChange={keysTimeOption.onChange}
          />
          <Datepicker label="At" date={keysDate.value} onChange={keysDate.onChange} />
          <Timepicker label="Time" time={keysTime.value} onChange={keysTime.onChange} />
          <InputText type="text" label="Notes" value={keyNotes.value} onChange={keyNotes.onChange} />
        </Grid>
      </Accordion>
    </Card>
  );
};

export default memo(KeysCard);