import { BORDER_RADIUS, DESKTOP_SPACING, FONT_SIZE, NATURAL, TERTIARY_PURPLE } from "@/theme";
import { CellProps, HeaderCellProps, RowProps } from "./Table.interface";

import styled from "styled-components";

export const StyledTable = styled.table`
  border: 1px solid ${NATURAL.C};
  border-radius: ${BORDER_RADIUS.LG};
  border-spacing: 0;
  margin: 0;
  padding: 0;
  table-layout: fixed;
`;

export const StyledTr = styled.tr<RowProps>`
  width: auto;
  cursor: ${({ cursor }) => cursor || 'default'};
`;

export const StyledTh = styled.th<HeaderCellProps>`
  background-color: ${({ backgroundColor }) => backgroundColor || TERTIARY_PURPLE.LIGHTER};
  color: ${NATURAL.I};
  font-size: ${FONT_SIZE.SM};
  max-width: ${({ width }) => width || ''};
  padding: ${DESKTOP_SPACING.XXXL}px;
  text-align: ${({ align }) => align || 'center'};

  :first-child {
    border-radius: ${BORDER_RADIUS.LG} 0 0 0;
  }

  :last-child {
    border-radius: 0 ${BORDER_RADIUS.LG} 0 0;
  }
`;

export const StyledTd = styled.td<CellProps>`
  color: ${NATURAL.H};
  font-size: ${FONT_SIZE.SM};
  max-width: ${({ width }) => width || ''};
  padding: ${DESKTOP_SPACING.XXXL}px;
  text-align: ${({ align }) => align || 'center'};
  text-overflow: ellipsis;
  word-wrap: break-word;
`;

export const StyledThead = styled.thead`
  width: auto;
`;

export const StyledTbody = styled.tbody`
  width: auto;

  ${StyledTd} {
    border-top: 1px solid ${NATURAL.C};
  }
`;
