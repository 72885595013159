import { FC, memo } from "react";

import { NotificationProps } from "./Notification.interface";
import { StyledNotification } from "./Notification.styled";

const Notification: FC<NotificationProps> = ({ children }) => {
  return (
    <StyledNotification>
      {children}
    </StyledNotification>
  );
};

export default memo(Notification);