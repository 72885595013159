import { FlexProps } from "./Flex.interface";
import styled from "styled-components";

export const StyledFlex = styled.div<FlexProps>`
  ${({ alignItems }) => alignItems && `align-items: ${alignItems}`};
  ${({ alignContent }) => alignContent && `align-content: ${alignContent}`};
  display: ${({ display }) => display};
  flex-direction: ${({ flexDirection }) => flexDirection};
  ${({ justifyContent }) => justifyContent && `justify-content: ${justifyContent}`};
  ${({ flexWrap }) => flexWrap && `flex-wrap: ${flexWrap}`};
  ${({ gap }) => gap && `gap: ${gap}`};
`;