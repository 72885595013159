import { FC } from "react";
import { PackageName } from "#/molecules";
import { PackageProps } from "./Package.interface";
import { StyledPackageHeader } from "./Package.styled";

const PackageHeader: FC<PackageProps> = ({ icons, id, groupName }) => {
  return (
    <StyledPackageHeader>
      <PackageName id={id} name={groupName} icons={icons} />
    </StyledPackageHeader>
  );
}

export default PackageHeader;