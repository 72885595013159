import { Button, ButtonsGroup, InputText, Typo } from "#/atoms";
import { FC, memo, useCallback, useEffect, useState } from "react";

import { DESKTOP_SPACING } from "@/theme";
import { FloorPlanSurchargeProps } from "./FloorPlanSurcharge.interface";
import { Modal } from "#/molecules";
import { useApi } from "@/api";
import { useInput } from "@/hooks";

const FloorPlanSurcharge: FC<FloorPlanSurchargeProps> = ({ onClose, onConfirm, floorArea }) => {
  const handleConfirm = useCallback(() => {
    onConfirm();
    onClose();
  }, []);

  const [description, setDescription] = useState('A surcharge of £%amount% per sq ft applies above %threshold%');
  const { settings } = useApi();
  const floorAreaInput = useInput(floorArea.toString());

  const getSettings = async () => {
    await settings.get();
  }

  useEffect(() => {
    getSettings().then(
      () => {
        setDescription(description.replace('%amount%', (settings.settings?.floorAreaSurchargeAmount ?? 0).toString())
        .replace('%threshold%', (settings.settings?.floorAreaSurchargeThreshold ?? 0).toString()));
      }
    );
  }, []);

  return (
    <Modal onClose={onClose} title="Add surcharge">
      <Typo>{description}</Typo>
      <InputText type="text" label="Floor Area" value={floorAreaInput.value} onChange={floorAreaInput.onChange}></InputText>
      <ButtonsGroup fullWidth margin={`${DESKTOP_SPACING.EL}px 0 0`}>
        <Button onClick={handleConfirm}>Add</Button>
        <Button onClick={onClose}>Cancel</Button>
      </ButtonsGroup>
    </Modal>
  );
};

export default memo(FloorPlanSurcharge);