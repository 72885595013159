import { BORDER_RADIUS, DESKTOP_SPACING, NATURAL } from "@/theme";

import { CardProps } from "./Card.interface";
import styled from "styled-components";

export const StyledCard = styled.div<CardProps>`
  background-color: ${NATURAL.A};
  border-radius: ${BORDER_RADIUS.LG};
  box-shadow: ${({ shadow = true }) => shadow ? '0px 10px 10px rgba(42, 12, 78, 0.05), 0px 0px 10px rgba(42, 12, 78, 0.05)' : ''};
  display: ${({ display }) => display || 'block'};
  margin-bottom: ${DESKTOP_SPACING.XXL}px;
  margin-left:${({ marginLeft }) => marginLeft || ''};
  padding: ${({ padding }) => padding || '30px'};
  width: ${({ width }) => width || ''};
`;