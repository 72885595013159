import { ButtonActionType, ButtonColor, ButtonSize, ButtonState, StyledButtonProps } from "./Button.interface";
import { DESKTOP_SPACING, FONT_SIZE, FONT_WEIGHT, NATURAL, PRIMARY_BLUE, SECONDARY_RED } from "@/theme";
import styled, { css } from "styled-components";

import { StyledIcon } from "../Icon/Icon.styled";

interface ButtonColors {
  activeColor: PRIMARY_BLUE | NATURAL | SECONDARY_RED;
  hoverColor: PRIMARY_BLUE | NATURAL | SECONDARY_RED;
  mainColor: PRIMARY_BLUE | NATURAL | SECONDARY_RED;
}

const getButtonColors = (actionType: ButtonActionType, buttonColor: ButtonColor): ButtonColors => {
  switch (actionType) {
    case 'solid': {
      if (buttonColor === 'primary') {
        return {
          activeColor: PRIMARY_BLUE.DARKER,
          hoverColor: PRIMARY_BLUE.HOVER,
          mainColor: PRIMARY_BLUE.DARK,
        };
      }

      return {
        activeColor: SECONDARY_RED.DARK,
        hoverColor: SECONDARY_RED.DARK,
        mainColor: SECONDARY_RED.DEFAULT,
      }
    }
    case 'outline': {
      if (buttonColor === 'primary') {
        return {
          activeColor: PRIMARY_BLUE.DARKER,
          hoverColor: PRIMARY_BLUE.LIGHT,
          mainColor: PRIMARY_BLUE.DARK,
        };
      }

      return {
        activeColor: SECONDARY_RED.DARK,
        hoverColor: SECONDARY_RED.LIGHT,
        mainColor: SECONDARY_RED.DARK,
      }
    }
    case 'ghost': {
      return {
        activeColor: PRIMARY_BLUE.DARKER,
        hoverColor: PRIMARY_BLUE.LIGHT,
        mainColor: PRIMARY_BLUE.DARK,
      };
    }
    case 'icon': {
      return {
        activeColor: PRIMARY_BLUE.LIGHT,
        hoverColor: PRIMARY_BLUE.DARK,
        mainColor: NATURAL.G,
      };
    }
  }
}

const getButtonStyle = (actionType: ButtonActionType, buttonColor: ButtonColor, state: ButtonState) => {
  const { activeColor, hoverColor, mainColor } = getButtonColors(actionType, buttonColor);

  switch (actionType) {
    // case 'secondary':
    case 'solid': {
      const defaultStyle = `
        background-color: ${state === 'active' ? activeColor : mainColor};
        border: solid 2px ${state === 'active' ? activeColor : mainColor};
        color: ${NATURAL.A};

        ${StyledIcon} {
          color: ${NATURAL.A};
          fill: ${NATURAL.A};
          margin-right: ${DESKTOP_SPACING.MD}px;
          margin-top: ${DESKTOP_SPACING.XS}px;
        }
      `;

      const hoverStyle = state === 'default' ? `
        :hover {
          background-color: ${hoverColor};
          border: solid 2px ${hoverColor};
        }
      ` : '';

      return css`
        ${defaultStyle}
        ${hoverStyle}
      `;
    }
    case 'outline': {
      const defaultStyle = `
        background-color: ${NATURAL.A};
        border: solid 2px ${state === 'active' ? activeColor : mainColor};
        color: ${state === 'active' ? activeColor : mainColor};
      `;

      const hoverStyle = state === 'default' ? `
        :hover {
          background-color: ${hoverColor};
        }
      ` : '';

      return css`
        ${defaultStyle}
        ${hoverStyle}
      `;
    }
    case 'ghost': {
      const defaultStyle = `
        background-color: transparent;
        border: solid 2px transparent;
        color: ${mainColor};
      `;

      const hoverStyle = state === 'default' ? `
        :hover {
          background-color: ${hoverColor};
        }
      ` : '';

      return css`
        ${defaultStyle}
        ${hoverStyle}
      `;
    }
    case 'icon': {
      const defaultStyle = `
        background-color: ${state === 'active' ? activeColor : NATURAL.A};
        border: solid 2px ${NATURAL.C};
        color: ${state === 'active' ? hoverColor : mainColor};
        gap: ${DESKTOP_SPACING.SM}px;

        ${StyledIcon} {
          color: ${state === 'active' ? hoverColor : mainColor};
          fill: ${state === 'active' ? hoverColor : mainColor};
        }
      `;

      const hoverStyle = state === 'default' ? `
        :hover {
          color: ${hoverColor};

          ${StyledIcon} {
            color: ${hoverColor};
            fill: ${hoverColor};
          }
        }
      ` : '';

      return css`
        ${defaultStyle}
        ${hoverStyle}
      `;
    }
  }
};

export const getButtonSize = (size?: ButtonSize) => {
  switch (size) {
    case 'sm': {
      return '30px';
    }
    case 'md': {
      return '36px';
    }
    case 'lg':
    default: {
      return '44px';
    }
  }
}

export const getButtonFontSize = (size?: ButtonSize) => {
  switch (size) {
    case 'sm': {
      return FONT_SIZE.XS;
    }
    case 'md': {
      return FONT_SIZE.SM;
    }
    case 'lg':
    default: {
      return FONT_SIZE.MD;
    }
  }
}

export const getButtonPadding = (actionType?: ButtonActionType, size?: ButtonSize) => {
  switch (size) {
    case 'sm': {
      return `${DESKTOP_SPACING.MD}px ${DESKTOP_SPACING.MD}px}`
    }
    case 'md': {
      if (actionType !== 'icon') {
        return `${DESKTOP_SPACING.MD}px ${DESKTOP_SPACING.XXL}px}`
      }

      return `${DESKTOP_SPACING.MD}px ${DESKTOP_SPACING.SM}px}`;
    }
    case 'lg':
    default: {
      if (actionType !== 'icon') {
        return `${DESKTOP_SPACING.MD}px ${DESKTOP_SPACING.XXL}px}`
      }

      return `${DESKTOP_SPACING.MD}px ${DESKTOP_SPACING.LG}px}`;
    }
  }
}

export const StyledButton = styled.button<StyledButtonProps>`
  align-items: center;
  align-self: flex-start;
  border: none;
  border-radius: ${({ borderRadius }) => borderRadius};
  content: none;
  cursor: ${({ state }) => state === 'disabled' ? 'not-allowed' : 'pointer'};
  display: inline-flex;
  font-size: ${({ buttonSize }) => getButtonFontSize(buttonSize)};
  font-weight: ${FONT_WEIGHT.SEMIBOLD};
  min-height: ${({ buttonSize }) => getButtonSize(buttonSize)};
  justify-content: center;
  opacity: ${({ state }) => state === 'disabled' ? '0.4' : '1'};
  outline: none;
  padding: ${({ actionType, buttonSize }) => getButtonPadding(actionType, buttonSize)};
  width: ${({ fullWidth }) => fullWidth ? '100%' : 'auto'};

  ${({
    actionType = 'solid',
    buttonColor = 'primary',
    state = 'default'
  }) => getButtonStyle(actionType, buttonColor, state)}
`;