import { DESKTOP_SPACING } from "@/theme";
import { StyledHeading } from "#/atoms/Typography/Heading";
import { StyledHeadingIconProps } from "./HeadingIcon.interface";
import { StyledIcon } from "#/atoms/Icon";
import { StyledTypo } from "#/atoms/Typography/Typo";
import styled from "styled-components";

export const StyledHeadingIcon = styled.div<StyledHeadingIconProps>`
  display: grid;
  grid-template-areas: "icon title" "icon text";
  grid-template-columns: 30px auto;
  grid-template-rows: auto;
  margin-bottom: ${({ mb }) => mb || '0'};

  ${StyledIcon} {
    grid-area: icon;
    margin-right: ${DESKTOP_SPACING.SM}px;
  }

  ${StyledHeading} {
    grid-area: title;
    flex: 1;
    margin-bottom: ${DESKTOP_SPACING.SM}px;
  }

  ${StyledTypo} {
    grid-area: text;
    width: 100%;
  }
`;