import { Alert, Button, ButtonsGroup, GlobalStyles, Heading, InputSelect, InputText, Link, Select, Typo } from "#/atoms";
import { DESKTOP_SPACING, HPOSITION, NATURAL, PRIMARY_BLUE } from "@/theme";
import { FC, memo, useCallback, useState } from "react";
import { RegistrationType, SignupProps } from "./Signup.interface";
import { StyledImage, StyledLoginForm, StyledLogo, StyledSignup, StyledSignupForm, StyledSignupImage, StyledTab, StyledTabGroup } from "./Signup.styled";
import { useInput, useInputSelect, useSelect } from "@/hooks";

import { PhotoplanLogo } from "#/atoms";
import bgImage from "@/assets/bg.jpeg";
import curve1 from "@/assets/curve1.svg";
import curve1Grey from "@/assets/curve1_grey.svg";
import curve2 from "@/assets/curve2.svg";
import curve2Grey from "@/assets/curve2_grey.svg";

const Login: FC<SignupProps> = ({ onLogin, onSignup }) => {
  const [registrationType, setRegistrationType] = useState<RegistrationType>('customer')
  const [error, setError] = useState<string | null>(null);
  const firstname = useInput();
  const lastname = useInput();
  const login = useInput();
  const password = useInput();
  const confirmPassword = useInput();
  const userType = useSelect('0')
  const agree = useInputSelect('agree')

  const handleRegister = useCallback(() => {
    setError(null);

    if (!agree.checked) {
      return;
    }
    
    onSignup({
      email: login.value,
      firstName: firstname.value,
      lastName: lastname.value,
      password: password.value,
      passwordConfirm: confirmPassword.value,
      type: registrationType === 'customer' ? '0' : '1',
    });
  }, [firstname, lastname, login, password, confirmPassword, userType]);
  
  return (
    <>
      <GlobalStyles backgroundColor={NATURAL.A} />
      {error && <Alert type="error">{error}</Alert>}
      <StyledSignup>
        <StyledLogo>
          <PhotoplanLogo color={NATURAL.K} />
        </StyledLogo>
        <StyledSignupForm>
          <StyledTabGroup>
            <StyledTab
              active={registrationType === 'customer'}
              bgActive={curve1}
              bgInactive={curve1Grey}
              onClick={() => setRegistrationType('customer')}
            >
              New Customer
            </StyledTab>
            <StyledTab
              active={registrationType === 'photoplanner'}
              bgActive={curve2}
              bgInactive={curve2Grey}
              onClick={() => setRegistrationType('photoplanner')}
            >
              New Photoplanner
            </StyledTab>
          </StyledTabGroup>
          <InputText type="text" label="First name" value={firstname.value} onChange={firstname.onChange} />
          <InputText type="text" label="Last name" value={lastname.value} onChange={lastname.onChange} />
          <InputText type="email" label="Login" value={login.value} onChange={login.onChange} />
          <InputText type="password" label="Password" value={password.value} onChange={password.onChange} />
          <InputText type="password" label="Confirm Password" value={confirmPassword.value} onChange={confirmPassword.onChange} />
          {registrationType === 'customer' && (
            <Select
              options={[
                { id: '0', label: 'Business' },
                { id: '1', label: 'Personal' },
              ]}
              onChange={userType.onChange}
              selected={userType.value}
            />
          )}
          <InputSelect
            type="checkbox"
            checked={agree.checked}
            label={(
              <Typo>
                Agree to <Link href="https://www.photoplan.co.uk/terms/" fontColor={PRIMARY_BLUE.DARK} target="_blank">Terms and Conditions</Link>
              </Typo>
            )}
            margin={`${DESKTOP_SPACING.XXL}px 0 0`}
            onChangeValue={agree.onChange}
            value={agree.value}
          />
          <ButtonsGroup align={HPOSITION.CENTER} fullWidth margin={`${DESKTOP_SPACING.XXL}px 0 0`}>
            <Button onClick={handleRegister}>Register</Button>
          </ButtonsGroup>
        </StyledSignupForm>
      </StyledSignup>
      <StyledImage>
        <StyledLoginForm>
          <Heading scale="h1" fontColor={NATURAL.A}>Already have an account?</Heading>
          <ButtonsGroup align={HPOSITION.CENTER} fullWidth>
            <Button onClick={onLogin}>Login</Button>
          </ButtonsGroup>
        </StyledLoginForm>
        <StyledSignupImage src={bgImage} />
      </StyledImage>
    </>
  );
}

export default memo(Login);
