/* eslint-disable no-implicit-coercion */
import { FC, memo, useCallback, useEffect, useState } from "react";

import { ErrorMessage } from "../ErrorMessage";
import { InputLabel } from "../InputLabel";
import { InputTextProps } from "./InputText.interface";
import { StyledFieldGroup } from "../InputLabel/InputLabel";
import { StyledInputText } from "./InputText.styled";

const InputText: FC<InputTextProps> = ({
  autofocus = false,
  errorMessage,
  fullWidth = true,
  label,
  name,
  onBlur,
  onChange,
  onClick,
  onFocus,
  onMouseEnter,
  onMouseLeave,
  state = 'idle',
  value = '',
  type = 'text',
  ...inputProps
}) => {
  const [inputValue, setInputValue] = useState(value);
  const [shrink, setShrink] = useState<boolean>(!!value);
  const [focus, setFocus] = useState<boolean>(autofocus);

  useEffect(() => {
    setInputValue(value);
    setShrink(focus || !!value);
  }, [value])

  const onMouseEnterHandler = useCallback((ev: React.MouseEvent<HTMLInputElement>) => {
    if (onMouseEnter) {
      onMouseEnter(ev);
    }
  }, []);

  const onMouseLeaveHandler = useCallback((ev: React.MouseEvent<HTMLInputElement>) => {
    if (onMouseLeave) {
      onMouseLeave(ev);
    }
  }, []);

  const onFocusHandler = useCallback((ev: React.FocusEvent<HTMLInputElement>) => {
    if (onFocus) {
      onFocus(ev);
    }
    setShrink(true);
    setFocus(true);
  }, []);

  const onBlurHandler = useCallback((ev: React.FocusEvent<HTMLInputElement>) => {
    if (onBlur) {
      onBlur(ev);
    }
    
    if (!inputValue) {
      setShrink(false);
    }
    setFocus(false);
  }, [inputValue]);

  const onChangeHandler = useCallback((ev: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(ev);
    }

    setInputValue(ev.target.value);
  }, []);

  const onClickHandler = useCallback((ev: React.MouseEvent<HTMLInputElement>) => {
    ev.stopPropagation();

    if (onClick) {
      onClick(ev);
    }
  }, []);

  const showError = state === 'error' && errorMessage;

  return (
    <StyledFieldGroup fullWidth={fullWidth}>
      <InputLabel shrink={shrink}>{label}</InputLabel>
      <StyledInputText
        {...inputProps}
        fullWidth={fullWidth}
        name={name}
        onBlur={onBlurHandler}
        onChange={onChangeHandler}
        onClick={onClickHandler}
        onFocus={onFocusHandler}
        onMouseEnter={onMouseEnterHandler}
        onMouseLeave={onMouseLeaveHandler}
        state={state}
        type={type}
        value={inputValue}
      />
      {showError && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </StyledFieldGroup>
  );
}

export default memo(InputText);
