import { Button, Heading, InputSelect, InputSelectChange, Link, PageHeader, Typo } from "#/atoms";
import { CustomTable, FilterAction } from "#/molecules";
import { FC, memo, useCallback, useMemo, useState } from "react";
import { FONT_SIZE, NATURAL, PRIMARY_BLUE } from "@/theme";

import { ButtonState } from "@/components/atoms/Button/Button.interface";
import { Column } from "react-table";
import { InvoicesProps } from "./Invoices.interface";
import { PageWrapper } from "#/atoms";

const Invoices: FC<InvoicesProps> = ({ invoices, loading, numberOfFilters, onMarkAsPaid, onOpenFilter }) => {
  const [invoicesToMark, setInvoicesToMark] = useState<string[]>([]);
  
  const handleInputChange: InputSelectChange = useCallback((isChecked, value) => {
    if (isChecked) {
      setInvoicesToMark([...invoicesToMark, value])
    } else {
      setInvoicesToMark(invoicesToMark.filter((item) => item !== value));
    }
  }, [invoicesToMark]);
  
  const handleMarkAsPaid = useCallback(() => {
    if (invoicesToMark.length == 0) {
      return;
    }
    
    onMarkAsPaid(invoicesToMark)
  }, []);
  
  const data = useMemo(() => (invoices.map((invoice) => ({
    amount: invoice.amount,
    companyName: invoice.companyName,
    externalUrl: invoice.externalUrl,
    finishDate: invoice.finishDate,
    id: invoice.id,
    invoiceNumber: invoice.invoiceNumber,
  }))), [invoices]);
  
  const columns: Column[] = useMemo(() => [
    {
      Cell: (props) => (
        <InputSelect
          type="checkbox"
          checked={invoicesToMark.includes(props.value)}
          name="invoices"
          value={props.value}
          onChangeValue={handleInputChange}
        />
      ),
      Header: '',
      accessor: 'invoiceNumber',
    },
    {
      Header: 'Company Name',
      accessor: 'companyName',
    },
    {
      Header: 'Billed Amount',
      accessor: 'amount',
    },
    {
      Cell: ({ data: rawData, row, value }) => {
        if (rawData[row.index].externalUrl) {
          return null;
        }

        return (
          <Link
            href={value}
            download
            fontColor={PRIMARY_BLUE.DARK}
            target="_blank"
          >
            Download
          </Link>
        );
      },
      Header: '',
      accessor: 'externalUrl',
      id: 'button'
    },
  ], [invoicesToMark]);

  const buttonState: ButtonState = invoicesToMark.length > 0 ? 'default' : 'disabled';

  return (
    <PageWrapper>
      <PageHeader>
        <Heading scale="h1" fontColor={NATURAL.K}>Invoices</Heading>
        <Typo fontColor={NATURAL.G} fontSize={FONT_SIZE.SM}>Payments are not updated automatically and may not reflect actual payment status.</Typo>
        <Button state={buttonState} onClick={handleMarkAsPaid}>Mark as Paid</Button>
      </PageHeader>
      <FilterAction numberOfFilters={numberOfFilters} onOpen={onOpenFilter} />
      <CustomTable columns={columns} data={data} loading={loading} />
    </PageWrapper>
  );
};

export default memo(Invoices);
