export const config = {
  contactUsEmail: 'orders@photoplanbookings.com',
  debug: false,
  googleAnalyticsAccount: 'UA-108572417-1',
  googleClientId: '582449076537-k670t2ahjn2kt559aoiphl0pgbq0n3qv.apps.googleusercontent.com',
  server: {
    api: 'api',
    clientUrl: 'https://test.photoplanbookings.com',
    googleAccountLinkUrl: 'oauth/authenticate',
    url: 'https://test.photoplanbookings.com/service/',
  }
}