import { FC, memo } from "react";
import { StyledActiveDate, StyledCloseIcon, StyledPopover, StyledPopoverBody, StyledPopoverFooter, StyledPopoverHeader } from "./Popover.styled";

import CloseIcon from '@/assets/icons/Close';
import { PopoverProps } from "./Popover.interface";

const Popover: FC<PopoverProps> = ({ children, footer, header, onClose }) => {
  return (
    <StyledPopover>
      <StyledPopoverHeader>
        <StyledActiveDate>
          {header}
          <StyledCloseIcon onClick={onClose}><CloseIcon /></StyledCloseIcon>
        </StyledActiveDate>  
      </StyledPopoverHeader>
      <StyledPopoverBody>{children}</StyledPopoverBody>
      {footer && <StyledPopoverFooter>{footer}</StyledPopoverFooter>}
    </StyledPopover>
  );
};

export default memo(Popover);
