import { Box, Button, Card, Grid, Select, Typo } from "#/atoms";
import { DESKTOP_SPACING, FONT_WEIGHT } from "@/theme";
import { FC, memo } from "react";

import { HeadingIcon } from "#/molecules";
import { OrderStatusCardProps } from "./OrderStatus.interface";
import { StyledSmallButtons } from "./OrderStatus.styled";

const OrderStatusCard: FC<OrderStatusCardProps> = ({ coordinator, onChangeRole, onTakeRole, orderState }) => {
  return (
    <Card padding="0">
      <Box padding={`${DESKTOP_SPACING.EL}px`}>
        <HeadingIcon
          icon="orders"
          mb={`${DESKTOP_SPACING.XXXL}px`}
          title="Order Status"
        />
        <Grid columnGap={DESKTOP_SPACING.XL} gridTemplateColumns="30% auto" margin={`${DESKTOP_SPACING.XXL}px 0 0`}>
          <Box>
            <Typo weight={FONT_WEIGHT.BOLD} margin="0">Booking Coordinator</Typo>
            {coordinator && <Typo  margin={`${DESKTOP_SPACING.SM}px 0 0`}>{coordinator.firstName} {coordinator.lastName}</Typo>}
          </Box>
          <StyledSmallButtons>
            <Button actionType='outline' buttonSize="md" onClick={onChangeRole}>Change Role</Button>
            <Button buttonSize="md" onClick={onTakeRole}>Take Role</Button>
          </StyledSmallButtons>
        </Grid>
        <Grid columnGap={DESKTOP_SPACING.XL} gridTemplateColumns="30% auto" margin={`${DESKTOP_SPACING.XXL}px 0 0`}>
          <Select
            label="Order Status"
            selected={orderState.value}
            onChange={orderState.onChange}
            options={[
              { id: '-1', label: 'Awaiting Confirmation of Date/Time' },
              { id: '0', label: 'Booking in Progress' },
              { id: '1', label: 'Booking Placed' },
              { id: '99', label: 'Sent for Selection' },
              { id: '2', label: 'Work Completed' },
              { id: '3', label: 'Content selected' },
              { id: '4', label: 'Awaiting additional Payment' },
              { id: '5', label: 'Order paid' },
              { id: '6', label: 'Return for Additional Work' },
            ]}
          />
        </Grid>
      </Box>
    </Card>
  );
};

export default memo(OrderStatusCard);