import { ModalActionType, OrderTypeModal } from "#/organisms";
import { useEffect, useState } from "react";

import { NewOrderTypeParam } from "@/api/orderTypes/interface";
import { OrderType } from "@/types";
import { OrderTypes } from "#/pages";
import { useApi } from "@/api";
import { useModal } from "@/hooks";

const OrderTypesView = () => {
  const { orderType } = useApi();
  const [editOrder, setEditOrder] = useState<OrderType | null>(null);
  const [modalType, setModalType] = useState<ModalActionType | null>(null);
  const [loadingOrderTypes, setLoadingOrderTypes] = useState(false);
  const { showModal, onCloseModal, onOpenModal } = useModal();

  const getOrderTypesList = async () => {
    setLoadingOrderTypes(true);
    await orderType.getEditList();
    setLoadingOrderTypes(false);
  }

  useEffect(() => {
    if (orderType.orderTypes.length === 0) {
      getOrderTypesList();
    }
  }, [orderType.orderTypes]);

  const handleNewOrderModal = () => {
    setModalType('create');
    setEditOrder(null);
    onOpenModal();
  };

  const handleEditOrderModal = (id: string) => {
    const orderToEdit = orderType.orderTypes.find((item) => item.id === id);

    if (orderToEdit) {
      setEditOrder(orderToEdit);
      setModalType('edit');
      onOpenModal();
    }
  };

  const handleCloseModal = () => {
    setModalType(null)
    onCloseModal();
  };

  const handleDelete = async (id: string) => {
    await orderType.deleteOrderType(id);
    await orderType.getEditList();
    onCloseModal();
  };

  const handleEdit = async (data: OrderType, file?: Blob | null) => {
    await orderType.setOrderType(data);

    if (file) {
      await orderType.uploadImage(file, data.id);
    }

    await orderType.getEditList();
    onCloseModal();
  };

  const handleSave = async (data: NewOrderTypeParam, file: Blob | null) => {
    const id = await orderType.createOrderType(data);

    if (file) {
      await orderType.uploadImage(file, id);
    }

    await orderType.getEditList();
    onCloseModal();

    return id;
  };

  return (
    <>
      <OrderTypes
        loading={loadingOrderTypes}
        ordersType={orderType.orderTypes}
        onEdit={handleEditOrderModal}
        onNew={handleNewOrderModal}
      />
      {showModal && modalType && (
        <OrderTypeModal
          actionType={modalType}
          onClose={handleCloseModal}
          onDelete={handleDelete}
          onEdit={handleEdit}
          onSave={handleSave}
          order={editOrder}
        />
      )}
    </>
  );
}

export default OrderTypesView;