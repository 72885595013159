import { ApiInvoice } from "./interface.api";
import { Invoice } from "@/types";

export const invoicesApiMapper = (api: ApiInvoice[]): Invoice[] => {
  return api.map((invoice) => ({
    amount: invoice.Amount,
    companyName: invoice.CustomerName,
    externalUrl: invoice.ExternalUrl,
    finishDate: invoice.CreatedDateTime,
    id: invoice.XeroInvoiceId,
    invoiceNumber: invoice.InvoiceNumber,
    paid: invoice.Paid,
  }));
};
