import { FC } from "react";
import { PhotoplanLogo as SVG } from "@/assets";
import { StyledPhotoplanLogo } from "./PhotoplanLogo.styled";

interface PhotoplanLogoProps {
  color?: string;
}

export const PhotoplanLogo: FC<PhotoplanLogoProps> = ({ color }) => {
  return (
    <StyledPhotoplanLogo title="Photoplan">
      <SVG color={color} />
    </StyledPhotoplanLogo>
  );
}

export default PhotoplanLogo;
