import { OrderTemplate } from "@/types";
import { useApi } from "@/api";
import { useState } from "react";

export interface OrderTemplateReturn {
  getOrderTemplateDetails: (orderTemplateId: string) => Promise<void>;
  selectedOrderTemplate: OrderTemplate | null;
  setSelectedOrderTemplate: (customer: OrderTemplate | null) => void;
}

const useNotes = (): OrderTemplateReturn => {
  const [selectedOrderTemplate, setSelectedOrderTemplate] = useState<OrderTemplate | null>(null);

  const { orderTemplate } = useApi();

  const getOrderTemplateDetails = async (orderTemplateId: string) => {
    const orderTemplateDetails = await orderTemplate.details(orderTemplateId);
    setSelectedOrderTemplate(orderTemplateDetails);
  }

  return {
    getOrderTemplateDetails,
    selectedOrderTemplate,
    setSelectedOrderTemplate,
  }
}

export default useNotes;