import { ApiContextProps, ApiHooksProps } from "./interface";
import { ReactNode, createContext, useContext, useEffect, useMemo, useState } from "react"

import { getServerPath } from "./request";
import useAccount from "./account";
import useCustomer from "./customer";
import useDashboard from "./dashboard";
import useInvoice from "./invoice";
import useNotifications from "./notifications";
import useOrder from "./order";
import useOrderTemplate from "./orderTemplate";
import useOrderTypes from "./orderTypes";
import useSession from "./session";
import useSettings from "./settings";
import useSupport from "./support";
import useUpsell from "./upsell";
import useUser from "./user";
import useWorker from "./worker";

const ApiContext = createContext<ApiContextProps>({} as ApiContextProps);

export const ApiProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const [errorMessage, setError] = useState<string | null>(null);
  const [successMessage, setSuccess] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingInitial, setLoadingInitial] = useState<boolean>(true);
  const [token, setToken] = useState<string>('');
  const [calledOnInit, setCalledOnInit] = useState(false);

  const serverPath = useMemo(() => getServerPath(), []);

  const apiParams: ApiHooksProps = { serverPath, setError, setLoading, setSuccess, token };

  const account = useAccount(apiParams);
  const customer = useCustomer(apiParams);
  const dashboard = useDashboard(apiParams);
  const invoice = useInvoice(apiParams);
  const notifications = useNotifications(apiParams);
  const order = useOrder(apiParams);
  const orderTemplate = useOrderTemplate(apiParams);
  const orderType = useOrderTypes(apiParams);
  const session = useSession(apiParams, setToken);
  const settings = useSettings(apiParams);
  const support = useSupport(apiParams);
  const upsell = useUpsell(apiParams);
  const user = useUser(apiParams);
  const worker = useWorker(apiParams);

  // Reset error message after a few seconds
  useEffect(() => {
    if (errorMessage) {
      setTimeout(() => {
        setError(null)
      }, 2000);
    };
  }, [errorMessage]);

  // Reset success message after a few seconds
  useEffect(() => {
    if (successMessage) {
      setTimeout(() => {
        setSuccess(null)
      }, 2000);
    };
  }, [successMessage]);

  useEffect(() => {
    session.getStoredUser();
    setLoadingInitial(false)
  }, []);

  const memoedValue = useMemo<ApiContextProps>(
    () => ({
      account,
      calledOnInit,
      customer,
      dashboard,
      errorMessage,
      invoice,
      loading,
      notifications,
      order,
      orderTemplate,
      orderType,
      session,
      setCalledOnInit,
      settings,
      successMessage,
      support,
      upsell,
      user,
      worker
    }),
    [
      account,
      calledOnInit,
      customer,
      dashboard,
      errorMessage,
      invoice,
      loading,
      notifications,
      order,
      orderTemplate,
      orderType,
      session,
      settings,
      successMessage,
      support,
      upsell,
      user,
      worker
    ]
  );

  return (
    <ApiContext.Provider value={memoedValue}>
      {!loadingInitial && children}
    </ApiContext.Provider>
  );
}

const useApi = () => {
  return useContext(ApiContext);
}

export {
  useApi
};