import { FC, RefObject, createRef, useEffect, useRef } from "react";
import { StyledMonthItem, StyledMonths } from "./Months.styled";

import { MonthsProps } from "./Datepicker.interface";

interface MonthsRefs {
  [key: number]: RefObject<HTMLButtonElement>;
}

const Months: FC<MonthsProps> = ({ activeMonth, show, onSelectMonth }) => {
  const monthsRefs = useRef<HTMLDivElement>(null);

  const monthsList: { id: number, name: string }[] = [
    { id: 0, name: 'January' },
    { id: 1, name: 'February' },
    { id: 2, name: 'March' },
    { id: 3, name: 'April' },
    { id: 4, name: 'May' },
    { id: 5, name: 'June' },
    { id: 6, name: 'July' },
    { id: 7, name: 'August' },
    { id: 8, name: 'September' },
    { id: 9, name: 'October' },
    { id: 10, name: 'November' },
    { id: 11, name: 'December' },
  ];

  const refs: MonthsRefs = monthsList.reduce((acc, value) => {
    return {
      ...acc,
      [value.id]: createRef(),
    };
  }, {});
  
  useEffect(() => {
    const activeRef = refs[activeMonth].current;

    if (show && monthsRefs.current && activeRef) {
      const top = activeRef.offsetTop - 97;
      monthsRefs.current.scrollTo({ top });
    }
  }, [show, refs, monthsRefs]);

  return (
    <StyledMonths ref={monthsRefs}>
      {monthsList.map(({ id, name }) => (
        <StyledMonthItem
          active={activeMonth === id}
          key={id}
          ref={refs[id]}
          onClick={() => onSelectMonth(id)}
        >
          {name}
        </StyledMonthItem>
      ))}
    </StyledMonths>
  );
};

export default Months;