import { ChangeEvent, useCallback, useState } from "react";

export interface SelectHookProps {
  error: string | null;
  value: string;
  onChange: (ev: ChangeEvent<HTMLSelectElement>) => void;
  setError: (message: string | null) => void;
  setValue: (value: string) => void;
}

const useSelect = (initialValue?: string): SelectHookProps => {
  const [error, setError] = useState<string | null>(null);
  const [value, setValue] = useState<string>(initialValue || '');

  const onChange = useCallback((ev: ChangeEvent<HTMLSelectElement>) => {
    setValue(ev.target.value);
  }, []);

  return {
    error,
    onChange,
    setError,
    setValue,
    value,
  }
};


export default useSelect;
