import { Button, Icon } from "#/atoms";
import { FC, memo } from "react";

import { FilterActionProps } from "./FilterAction.interface";
import { StyledFilterAction } from "./FilterAction.styled";

const FilterAction: FC<FilterActionProps> = ({ numberOfFilters, onOpen }) => {
  const num = numberOfFilters > 0 ? `(${numberOfFilters})` : '';

  return (
    <StyledFilterAction>
      <Button actionType="icon" onClick={onOpen}>
        <Icon type='filter' />
        Filters {num}
      </Button>
    </StyledFilterAction>
  );
}

export default memo(FilterAction);