import { Button, ButtonsGroup, InputText } from "#/atoms";
import { FC, memo, useCallback, useState } from "react";

import { HPOSITION } from "@/theme";
import { Modal } from "#/molecules";
import { OrderTypeModalProps } from "./OrderType.interface";
import { StyledFileUpload } from "@/components/molecules/CustomerLogoCard";
import { useInput } from "@/hooks";

const OrderType: FC<OrderTypeModalProps> = ({ actionType, onClose, onDelete, onEdit, onSave, order }) => {
  const name = useInput(order?.name);
  const price = useInput(`${order?.price ?? 0}`);

  const [file, setFile] = useState<Blob | null>(null);

  const handleDelete = () => {
    if (order) {
      onDelete(order.id);
    }
  };

  const handleFileUpload = useCallback((ev) => {
    setFile(ev.target.files[0]);
  }, []);

  const handleSave = () => {
    if (actionType === 'create') {
      onSave({
        imageUrl: '',
        name: name.value,
        price: Number(price.value),
      }, file);
    } else if (order) {
      onEdit({
        amendedDate: new Date().toString(),
        createdDate: new Date().toString(),
        id: order.id,
        imageUrl: '',
        name: name.value,
        price: Number(price.value),
      }, file);
    }
  };

  const title = actionType === 'create' ? 'Create' : 'Edit';

  return (
    <Modal onClose={onClose} title={`${title} Order Type`}>
      <InputText type="text" label="Name" value={name.value} onChange={name.onChange} />
      <InputText type="text" label="Price" value={price.value} onChange={price.onChange} />
      <ButtonsGroup fullWidth align={HPOSITION.LEFT} margin="20px 0px 40px">
        <Button onClick={() => { document.getElementById('serviceLogoUpload')?.click() }}>Upload</Button>
        <StyledFileUpload id="serviceLogoUpload" name="serviceLogoUpload" type="file" accept="image/png" multiple={false} onChange={handleFileUpload} />
      </ButtonsGroup>
      <ButtonsGroup fullWidth>
        <Button actionType='outline' onClick={onClose}>Cancel</Button>
        {actionType === 'edit' && <Button actionType="solid" buttonColor="secondary" onClick={handleDelete}>Delete</Button>}
        <Button onClick={handleSave}>Save</Button>
      </ButtonsGroup>
    </Modal>
  );
}

export default memo(OrderType);