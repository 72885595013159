import { BORDER_RADIUS, DESKTOP_SPACING, NATURAL, PRIMARY_BLUE } from "@/theme";

import styled from "styled-components";

export const StyledPerson = styled.div`
  align-items: center;
  background-color: ${NATURAL.A};
  border: ${NATURAL.C} 1px solid;
  border-radius: ${BORDER_RADIUS.LG};
  cursor: pointer;
  display: flex;
  margin-bottom: ${DESKTOP_SPACING.XL}px;
  padding: ${DESKTOP_SPACING.XL}PX;

  &:hover {
    border: ${PRIMARY_BLUE.DARK} 1px solid;
  }
`;

export const StyledPersonInfo = styled.div`
  flex: 1;
`;

export const StyledPersonName = styled.div``;

export const StyledPersonEmail = styled.div``;