export const DEFAULT_FONT_SIZE = '16px';

export enum FONT_SIZE {
  XS = '0.75rem',
  SM = '0.875rem',
  MD = '1rem',
  LG = '1.25rem',
  XL = '1.5rem',
  XXL = '2rem',
};
export const fontSizes: FONT_SIZE[] = [
  FONT_SIZE.XS,
  FONT_SIZE.SM,
  FONT_SIZE.MD,
  FONT_SIZE.LG,
  FONT_SIZE.XL
];

export const FONT_FAMILY = 'Montserrat, sans-serif';

export enum FONT_WEIGHT {
  REGULAR = '400',
  SEMIBOLD = '600',
  BOLD = '700',
}
export const fontWeights: FONT_WEIGHT[] = [
  FONT_WEIGHT.REGULAR,
  FONT_WEIGHT.SEMIBOLD,
  FONT_WEIGHT.BOLD
];