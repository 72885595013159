import { FC, memo } from "react";

import { LinkProps } from "./Link.interface";
import { StyledLink } from "./Link.styled";

const Link: FC<LinkProps> = ({
  children,
  fontColor,
  fontWeight,
  fontSize,
  href,
  underline = false,
  ...anchorProps
}) => {
  return (
    <StyledLink
      {...anchorProps}
      fontColor={fontColor}
      fontSize={fontSize}
      fontWeight={fontWeight}
      href={href}
      underline={underline}
    >
      {children}
    </StyledLink>
  );
};

export default memo(Link);