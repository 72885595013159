import { FC } from "react";

interface PhotoplanLogoProps {
  color?: string;
}

const PhotoplanLogo: FC<PhotoplanLogoProps> = ({ color = 'white' }) => {
  return (
    <svg width="129" height="24" viewBox="0 0 129 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M125.079 21.9131H109.658C108.797 21.9126 107.971 21.5702 107.362 20.9611C106.754 20.3521 106.412 19.5263 106.412 18.6653V3.24374C106.413 2.38343 106.755 1.55869 107.364 0.950545C107.972 0.342402 108.797 0.000540738 109.658 0H125.079C125.939 0.00108042 126.764 0.343114 127.372 0.95114C127.981 1.55917 128.323 2.38359 128.325 3.24374V18.6653C128.323 19.5254 127.981 20.3499 127.372 20.9579C126.764 21.5659 125.939 21.9079 125.079 21.909V21.9131ZM109.658 1.05263C109.076 1.05317 108.519 1.28413 108.108 1.69486C107.697 2.1056 107.466 2.6626 107.464 3.24374V18.6653C107.465 19.2468 107.696 19.8043 108.107 20.2155C108.519 20.6267 109.076 20.8579 109.658 20.8584H125.079C125.661 20.8579 126.218 20.6267 126.629 20.2155C127.041 19.8043 127.272 19.2468 127.272 18.6653V3.24374C127.271 2.6626 127.04 2.1056 126.629 1.69486C126.217 1.28413 125.66 1.05317 125.079 1.05263H109.658Z" fill="#DF1A22"/>
      <path d="M118.336 11.4175C117.744 11.4175 117.166 11.593 116.674 11.9218C116.182 12.2506 115.798 12.7179 115.572 13.2647C115.345 13.8115 115.286 14.4131 115.401 14.9936C115.517 15.5741 115.802 16.1072 116.22 16.5257C116.639 16.9442 117.172 17.2292 117.752 17.3447C118.333 17.4601 118.935 17.4009 119.481 17.1744C120.028 16.9479 120.495 16.5644 120.824 16.0723C121.153 15.5802 121.329 15.0016 121.329 14.4098C121.327 13.6165 121.012 12.8561 120.451 12.2951C119.89 11.7342 119.129 11.4186 118.336 11.4175Z" fill="#DF1A22"/>
      <path d="M125.153 9.92133H120.752V9.23866C120.752 9.04475 120.674 8.85895 120.537 8.72184C120.4 8.58473 120.214 8.50746 120.02 8.50692H116.556C116.46 8.50692 116.365 8.52586 116.276 8.56266C116.188 8.59946 116.107 8.65339 116.039 8.72136C115.971 8.78934 115.918 8.87002 115.881 8.95879C115.845 9.04755 115.826 9.14266 115.826 9.23866V9.92133H109.694C109.501 9.92133 109.315 9.99821 109.178 10.1351C109.042 10.2719 108.965 10.4575 108.965 10.651V18.7042C108.966 18.8972 109.043 19.0819 109.18 19.2182C109.316 19.3545 109.501 19.4313 109.694 19.4318H125.153C125.346 19.4318 125.532 19.3553 125.669 19.2189C125.806 19.0826 125.883 18.8975 125.884 18.7042V10.651C125.884 10.555 125.866 10.46 125.829 10.3713C125.792 10.2826 125.738 10.2021 125.67 10.1343C125.602 10.0665 125.521 10.0128 125.433 9.97628C125.344 9.93974 125.249 9.92106 125.153 9.92133ZM118.338 18.2667C117.575 18.2672 116.829 18.0413 116.195 17.6177C115.56 17.1942 115.066 16.592 114.773 15.8872C114.481 15.1825 114.405 14.4069 114.553 13.6586C114.702 12.9103 115.069 12.2229 115.608 11.6833C116.148 11.1437 116.835 10.7762 117.583 10.6272C118.331 10.4782 119.107 10.5544 119.812 10.8463C120.517 11.1382 121.119 11.6325 121.543 12.2668C121.967 12.9011 122.193 13.6469 122.193 14.4098C122.193 15.4326 121.786 16.4133 121.063 17.1365C120.34 17.8597 119.359 18.2662 118.336 18.2667H118.338Z" fill="#DF1A22"/>
      <path d="M113.234 9.17732C113.234 9.274 113.195 9.36654 113.127 9.43471C113.058 9.50289 112.965 9.54115 112.869 9.54115H110.028C109.931 9.54115 109.838 9.50289 109.77 9.43471C109.701 9.36654 109.662 9.274 109.662 9.17732C109.662 9.0801 109.7 8.98683 109.769 8.9179C109.837 8.84896 109.93 8.80996 110.028 8.80942H112.869C112.966 8.80996 113.059 8.84896 113.127 8.9179C113.196 8.98683 113.234 9.0801 113.234 9.17732Z" fill="#DF1A22"/>
      <path d="M120.768 2.25652H115.863C115.679 2.26657 115.507 2.34894 115.383 2.48565C115.26 2.62237 115.196 2.80232 115.205 2.98621V7.31528C115.197 7.49627 115.261 7.67296 115.382 7.80773C115.503 7.9425 115.672 8.02469 115.853 8.0368H120.758C120.943 8.0278 121.116 7.94588 121.24 7.80905C121.365 7.67222 121.429 7.49167 121.42 7.30711V2.98621C121.429 2.80345 121.365 2.62467 121.243 2.48825C121.121 2.35183 120.951 2.26863 120.768 2.25652ZM120.531 5.68013C120.516 5.79077 120.459 5.89123 120.371 5.96054C120.283 6.02985 120.172 6.06266 120.061 6.05212H116.568C116.457 6.06318 116.346 6.03057 116.258 5.96116C116.17 5.89176 116.113 5.79099 116.098 5.68013V3.46449C116.113 3.35363 116.17 3.25286 116.258 3.18346C116.346 3.11405 116.457 3.08144 116.568 3.0925H120.065C120.176 3.08196 120.287 3.11477 120.375 3.18408C120.463 3.25339 120.52 3.35385 120.535 3.46449L120.531 5.68013Z" fill="#DF1A22"/>
      <path d="M0 24V13.069C0 9.68217 2.34032 6.96373 5.72304 6.96373C9.06898 6.96373 11.4072 9.68217 11.4072 13.069C11.4072 16.1962 9.13643 19.1313 5.86816 19.1313C5.2427 19.1027 4.62395 18.99 4.02861 18.7961V15.5769C4.54053 15.8153 5.09908 15.9368 5.66377 15.9326C7.21512 15.9326 8.3352 14.7409 8.3352 13.0465C8.3352 11.3521 7.23147 10.1605 5.7864 10.1605C4.18804 10.1605 3.06387 11.3541 3.06387 13.0465V24H0Z" fill={color}/>
      <path d="M13.349 18.8452V2.1441H16.4149V8.13081H16.4619C17.1283 7.20081 18.0112 6.96372 19.0863 6.96372C20.9954 6.96372 22.9514 8.30046 22.9514 10.8288V18.8452H19.8753V11.8774C19.8753 10.6837 19.2621 10.0174 18.1359 10.0174C17.0097 10.0174 16.3945 10.6837 16.3945 11.8774V18.8452H13.349Z" fill={color}/>
      <path d="M36.603 13.0465C36.603 16.3638 34.3383 19.1313 30.7328 19.1313C27.1272 19.1313 24.8646 16.3638 24.8646 13.0465C24.8646 9.72919 27.1334 6.96373 30.7328 6.96373C34.3321 6.96373 36.603 9.72919 36.603 13.0465ZM27.9203 13.0465C27.9203 14.978 29.0404 16.0777 30.7328 16.0777C32.4251 16.0777 33.5493 14.978 33.5493 13.0465C33.5493 11.115 32.4313 10.0174 30.7328 10.0174C29.0342 10.0174 27.9203 11.1129 27.9203 13.0465Z" fill={color}/>
      <path d="M43.5462 7.24784V10.2075H41.3306V14.4997C41.3306 15.3582 41.7557 15.7895 42.3791 15.7895H43.5462V18.8431H41.9478C39.1333 18.8431 38.2687 17.1732 38.2687 14.5979V4.15331H41.3347V7.25397L43.5462 7.24784Z" fill={color}/>
      <path d="M56.5028 13.0465C56.5028 16.3638 54.236 19.1313 50.6346 19.1313C47.0332 19.1313 44.7644 16.3638 44.7644 13.0465C44.7644 9.72919 47.0311 6.96373 50.6346 6.96373C54.2381 6.96373 56.5028 9.72919 56.5028 13.0465ZM47.8201 13.0465C47.8201 14.978 48.9422 16.0777 50.6346 16.0777C52.327 16.0777 53.4491 14.978 53.4491 13.0465C53.4491 11.115 52.327 10.0174 50.6346 10.0174C48.9422 10.0174 47.8201 11.1129 47.8201 13.0465Z" fill={color}/>
      <path d="M58.2238 24V13.4492C58.2238 9.68216 60.6316 7.1538 63.9939 7.1538C67.7179 7.1538 69.9213 10.0153 69.9213 13.4042C69.9213 16.603 67.3439 19.0843 64.4027 19.0843C63.2684 19.1015 62.1496 18.8195 61.1589 18.2667V16.0777C61.9234 16.603 63.0904 17.0772 64.045 17.0772C66.3117 17.0772 67.816 15.5054 67.816 13.1426C67.816 10.7798 66.2422 9.15687 63.9959 9.15687C61.9949 9.15687 60.3168 10.6837 60.3168 13.8355V24H58.2238Z" fill={color}/>
      <path d="M72.1328 18.8452V2.1441H74.2319V18.8452H72.1328Z" fill={color}/>
      <path d="M86.0418 18.8452V12.4047C86.0418 10.2565 84.2289 9.15687 82.3198 9.15687C80.0061 9.15687 78.5528 10.9964 78.5528 13.165C78.5528 15.2887 80.1001 17.0771 82.2973 17.0771C83.493 17.0771 84.468 16.4108 85.1854 15.5054V17.9867C84.3515 18.5999 83.538 19.0843 82.0807 19.0843C78.792 19.0843 76.4517 16.3638 76.4517 12.9995C76.4517 9.72917 78.7 7.1538 82.3689 7.1538C85.9008 7.1538 88.143 9.29994 88.143 12.5948V18.8452H86.0418Z" fill={color}/>
      <path d="M90.1707 18.8452V12.1635C90.1707 9.15687 92.2718 7.1538 95.0639 7.1538C97.8559 7.1538 99.955 9.15687 99.955 12.1635V18.8452H97.8559V12.1635C97.8559 10.3955 96.5662 9.15687 95.0639 9.15687C93.5616 9.15687 92.2718 10.3955 92.2718 12.1635V18.8452H90.1707Z" fill={color}/>
    </svg>
  );
};

export default PhotoplanLogo;
