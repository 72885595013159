import { FC, useContext, useRef } from "react";
import { FONT_SIZE, FONT_WEIGHT, PRIMARY_BLUE } from "@/theme";

import DatepickerContext from "./Datepicker.context";
import { Link } from "#/atoms";
import { useDay } from "@datepicker-react/hooks";

const TodayButton: FC<{ date: Date }> = ({ date }) => {
  const dayRef = useRef(null);
  const {
    focusedDate,
    isDateBlocked,
    isDateFocused,
    isDateHovered,
    isDateSelected,
    isFirstOrLastSelectedDate,
    onDateFocus,
    onDateHover,
    onDateSelect,
  } = useContext(DatepickerContext);
  const {
    onClick,
  } = useDay({
    date,
    dayRef,
    focusedDate,
    isDateBlocked,
    isDateFocused,
    isDateHovered,
    isDateSelected,
    isFirstOrLastSelectedDate,
    onDateFocus,
    onDateHover,
    onDateSelect,
  });

  return (
    <Link
      fontColor={PRIMARY_BLUE.DARK}
      fontSize={FONT_SIZE.SM}
      onClick={onClick}
      fontWeight={FONT_WEIGHT.SEMIBOLD}
    >
      Today
    </Link>
  );
}

export default TodayButton;
