import { FC, memo, useCallback, useEffect, useState } from "react";
import { StyledTimeDivisor, StyledTimeSelect } from "./TimeSelect.styled";

import { TimeSelectProps } from "./TimeSelect.interface";
import Timer from "./Timer";
import { padTo2Digits } from "@/helpers";

const getTime = (time: string | null) => {
  if (!time) {
    return ['12', '00']
  }
  
  return time.split(':');
}

const convertTime = (hour: string, minutes: string) => {
  return `${hour}:${minutes}`;
}

const TimeSelect: FC<TimeSelectProps> = ({ time, onChange }) => {
  const [hour, setHour] = useState(() => getTime(time)[0]);
  const [minutes, setMinutes] = useState(() => getTime(time)[1]);

  useEffect(() => {
    const newTime = getTime(time);

    if (newTime[0] !== hour) {
      setHour(newTime[0]);
    }

    if (newTime[1] !== minutes) {
      setMinutes(newTime[1]);
    }
  }, [time]);

  useEffect(() => {
    const newTime = convertTime(hour, minutes);
    
    if (newTime !== time) {
      onChange(newTime)
    }
  }, [hour, minutes]);

  const handleHourChange = useCallback((newHour: number) => {
    const convertedHour = padTo2Digits(newHour);
    setHour(convertedHour);
  }, []);

  const handleMinutesChange = useCallback((newMinutes: number) => {
    const convertedMinutes = padTo2Digits(newMinutes);
    setMinutes(convertedMinutes);
  }, []);

  return (
    <StyledTimeSelect>
      <Timer max={24} value={Number(hour)} range={1} onChange={handleHourChange} />
      <StyledTimeDivisor>:</StyledTimeDivisor>
      <Timer max={59} value={Number(minutes)} range={5} onChange={handleMinutesChange} />
    </StyledTimeSelect>
  );
};

export default memo(TimeSelect);