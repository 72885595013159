import { useCallback, useState } from "react";

export interface TimepickerHookProps {
  value: string | null;
  onChange: (time: string) => void;
  setValue: (value: string) => void;
}

const useTimepicker = (initialTime?: string | null): TimepickerHookProps => {
  const [value, setValue] = useState<string | null>(initialTime || null);

  const onChange = useCallback((time: string) => {
    setValue(time);
  }, []);

  return {
    onChange,
    setValue,
    value,
  }
};


export default useTimepicker;
