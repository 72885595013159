import { BORDER_RADIUS, DESKTOP_SPACING, FONT_SIZE, FONT_WEIGHT, NATURAL } from "@/theme";

import styled from "styled-components";

export const StyledSelectedDate = styled.button`
  background: ${NATURAL.A};
  border: 1px solid ${NATURAL.C};
  border-radius: ${BORDER_RADIUS.MD};
  color: ${NATURAL.I};
  height: 44px;
  font-size: ${FONT_SIZE.SM};
  font-weight: ${FONT_WEIGHT.SEMIBOLD};
  line-height: 22px;
  margin: ${DESKTOP_SPACING.XXL}px 0;
  padding: ${DESKTOP_SPACING.LG}px;
  text-align: left;
  width: 100%;
`;

export const StyledDateIcon = styled.i`
  color: ${NATURAL.G};
  fill: ${NATURAL.G};
  pointer-events: none;
  position: absolute;
  right: ${DESKTOP_SPACING.MD}px;
  top: ${DESKTOP_SPACING.XXL + DESKTOP_SPACING.MD}px;
`;
