import { Button, ButtonsGroup, Heading, PageHeader, PageWrapper, Typo } from "#/atoms";
import { CustomTable, FilterAction } from "#/molecules";
import { FC, memo, useMemo } from "react";
import { FONT_SIZE, NATURAL } from "@/theme";

import { Column } from 'react-table';
import { CustomersProps } from "./Customers.interface";

const Customers: FC<CustomersProps> = ({
  customers,
  loadingCustomers,
  numberOfFilters,
  onCreateNewCustomer,
  onDownloadAddress,
  onOpenFilter,
  onOpenInviteModal,
  onShowMore,
  onClickRow
}) => {
  const data = useMemo(() => (customers.map((customer) => ({
    active: customer.active ? 'Yes' : 'No',
    address: customer.address,
    code: customer.code,
    companyName: customer.companyName,
    email: customer.email,
    id: customer.id,
    invited: customer.invited,
    name: `${customer.firstName} ${customer.lastName}`,
    upsell: customer.upsell,
  }))), [customers]);
  
  const columns: Column[] = useMemo(() => [
    {
      Header: 'Name',
      accessor: 'companyName',
    },
    {
      Header: 'Display Name',
      accessor: 'name',
    },
    {
      Header: 'Customer Code',
      accessor: 'code',
    },
    {
      Header: 'Address',
      accessor: 'address',
    },
    {
      Header: 'Email Address',
      accessor: 'email',
    },
    {
      Header: 'Active',
      accessor: 'active',
    },
    {
      Header: 'Upsell (GBP)',
      accessor: 'upsell',
    },
    {
      Cell: ({ data: rawData, row, value }) => {
        if (rawData[row.index].invited) {
          return null;
        }
        
        return (
          <Button
            actionType='outline'
            onClick={(ev) => { ev.stopPropagation(); onOpenInviteModal(value)}}
            buttonSize="sm"
          >
            Invite
          </Button>
        );
      },
      Header: '',
      accessor: 'id',
      id: 'id',
    },
  ], []);

  const handleRowClick = (rawData: object) => {
    onClickRow?.(rawData['id']);
  }

  return (
    <PageWrapper>
      <PageHeader>
        <Heading scale="h1" fontColor={NATURAL.K}>Customers</Heading>
        <Typo fontColor={NATURAL.G} fontSize={FONT_SIZE.SM}>Description text here.</Typo>
        <ButtonsGroup>
          <Button actionType='outline' onClick={onDownloadAddress}>Download Address List</Button>
          <Button onClick={onCreateNewCustomer}>Create New Customer</Button>
        </ButtonsGroup>
      </PageHeader>
      <FilterAction numberOfFilters={numberOfFilters} onOpen={onOpenFilter} />
      <CustomTable columns={columns} data={data} rowProps={({ cursor: 'pointer' })} loading={loadingCustomers} onClickRow={handleRowClick} />
      <ButtonsGroup>
          <Button onClick={onShowMore}>Show More</Button>
        </ButtonsGroup>
    </PageWrapper>
  );
}

export default memo(Customers);