import { ZINDEX } from "@/theme";
import styled from "styled-components";

export const StyledLoading = styled.div`
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: ${ZINDEX.LOADING_INDEX};
`;