import { Accordion, Box, Card, Grid, InputText } from "#/atoms";
import { FC, memo } from "react";

import { AccountDetailsCardProps } from "./AccountDetails.interface";
import { DESKTOP_SPACING } from "@/theme";
import { HeadingIcon } from "#/molecules/HeadingIcon";

const AccountDetailsCard: FC<AccountDetailsCardProps> = ({
  accordionState = 'open',
  daysToPayInvoices,
  email,
  firstName,
  lastName,
  login,
  phoneNumber,
}) => {
  return (
    <Card padding="0">
      <Box padding={`${DESKTOP_SPACING.EL}px`}>
        <Accordion
          header={
            <HeadingIcon
              icon="contacts"
              title="Account Details"
            />
          }
          headerMargin={`${DESKTOP_SPACING.XXXL}px`}
          showArrow
          state={accordionState}
        >
          <Grid columnGap={DESKTOP_SPACING.XL} gridTemplateColumns="repeat(2, 40%)" margin={`${DESKTOP_SPACING.XXL}px 0 0`}>
            <InputText type="text" label="Login" value={login.value} onChange={login.onChange} />
            <InputText type="text" label="E-mail Address" value={email.value} onChange={email.onChange} />
            <InputText type="text" label="First Name" value={firstName.value} onChange={firstName.onChange} />
            <InputText type="text" label="Last Name" value={lastName.value} onChange={lastName.onChange} />
            <InputText type="text" label="Account Phone Number" value={phoneNumber.value} onChange={phoneNumber.onChange} />
            <InputText type="text" label="Days to Pay Invoices" value={daysToPayInvoices.value} onChange={daysToPayInvoices.onChange} />
          </Grid>
        </Accordion>
      </Box>
    </Card>
  );
};

export default memo(AccountDetailsCard);