export enum HPOSITION {
  CENTER = 'center',
  LEFT = 'left',
  RIGHT = 'right'
}

export enum VPOSITION {
  BOTTOM = 'bottom',
  MIDDLE = 'middle',
  TOP = 'top'
}

export type LabelPosition = Omit<HPOSITION, 'center'> | 'none';

export enum DIRECTION {
  COL = 'column',
  ROW = 'row'
}

export enum ZINDEX {
  PAGE_INDEX = 10,
  MENU_INDEX = 100,
  FOOTER_INDEX = 1000,
  SIDEBAR_INDEX = 10000,
  MODAL_INDEX = 100000,
  LOADING_INDEX = 1000000,
}