import { FC, Fragment, memo, useEffect, useRef, useState } from "react";
import { MapProps, MapsProps } from "./Maps.interface";
import { Status, Wrapper } from "@googlemaps/react-wrapper";
import { getCurrentLocation, getLocationByAddress } from "./helpers";

import { StyledMap } from "./Maps.styled";

const GOOGLE_API_KEY = 'AIzaSyAdZ7Dmwgj3C01NphzJa0HHYC6UD941dQg';

const Map: FC<MapProps> = ({ address, minHeight }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<google.maps.Map>();
  const zoom = 17;

  const setLocationAddress = (code: string) => {
    const get = async () => {
      const location = await getLocationByAddress(code);

      if (location && map) {
        map.setCenter(location);
        google.maps.event.trigger(map, "resize");
      }
    }

    get();
  }

  const setCurretLocation = () => {
    const get = async () => {
      const location = await getCurrentLocation();

      if (location && map) {
        map.setCenter(location);
        google.maps.event.trigger(map, "resize");
      }
    }

    get();
  }
  useEffect(() => {
    if (ref.current && !map) {
      setMap(new window.google.maps.Map(ref.current, {
        // disableDefaultUI: true,
        fullscreenControl: false,
        mapTypeControl: false,
        mapTypeId: window.google.maps.MapTypeId.ROADMAP,
        panControl: false,
        streetViewControl: false,
        zoom,
        zoomControl: false,
      }));
    }
  }, [ref, map]);

  useEffect(() => {
    if (map) {
      if (address) {
        setLocationAddress(address)
      } else {
        setCurretLocation();
      }
    }
  }, [address, map])

  return <StyledMap ref={ref} id="map" minHeight={minHeight} />;
}

export const Maps: FC<MapsProps> = ({ minHeight, address }) => {
  const renderMap = (status: Status) => {
    switch (status) {
      case Status.LOADING: {
        return <>Loading...</>
      }
      case Status.FAILURE: {
        return <>Error...</>
      }
    }

    return <Fragment />;
  };

  return (
    <Wrapper apiKey={GOOGLE_API_KEY} render={renderMap}>
      <Map address={address} minHeight={minHeight} />
    </Wrapper>
  );
}

export default memo(Maps);
