import { ReactNode } from "react";
import styled from "styled-components";

interface BoxProps {
  background?: string;
  borderRadius?: string;
  children?: ReactNode;
  display?: string;
  height?: string;
  margin?: string;
  padding?: string;
  position?: string;
  textAlign?: string;
  width?: string;
}

const Box = styled.div<BoxProps>`
  background-color: ${({ background }) => background || ''};
  border-radius: ${({ borderRadius }) => borderRadius || ''};
  display: ${({ display }) => display || 'block'};;
  height: ${({ height }) => height || ''};
  margin: ${({ margin }) => margin || '0'};
  padding: ${({ padding }) => padding || '0'};
  position: ${({ position }) => position || ''};
  text-align: ${({ textAlign }) => textAlign || ''};
  width: ${({ width }) => width || '100%'};
`;

export default Box;