import { DESKTOP_SPACING, FONT_SIZE, NATURAL, PRIMARY_BLUE } from "@/theme";

import styled from "styled-components";

export const StyledNotification = styled.div`
  align-items: center;
  background: ${PRIMARY_BLUE.DEFAULT};
  border-radius: 30%;
  color: ${NATURAL.I};
  display: inline-flex;
  flex-direction: column;
  font-size: ${FONT_SIZE.SM};
  height: 24px;
  line-height: ${DESKTOP_SPACING.XXL}px;
  min-width: 24px;
  padding: ${DESKTOP_SPACING.XS}px;
`;
