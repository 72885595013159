import { Button, Heading, Icon, InputSelectChange, PageHeader, Typo } from "#/atoms";
import { DESKTOP_SPACING, FONT_SIZE, NATURAL, PRIMARY_BLUE } from "@/theme";
import { FC, memo, useCallback, useState } from "react";
import { FilterAction, OrderCard } from "#/molecules";

import { GenerateInvoicesProps } from "./GenerateInvoices.interface";
import { PageWrapper } from "#/atoms";

const GenerateInvoices: FC<GenerateInvoicesProps> = ({ loading, numberOfFilters, onGenerate, onOpenFilter, orders }) => {
  const [invoicesToGenerate, setInvoicesToGenerate] = useState<string[]>([]);
  
  const handleInputChange: InputSelectChange = useCallback((isChecked, value) => {
    if (isChecked) {
      setInvoicesToGenerate([...invoicesToGenerate, value])
    } else {
      setInvoicesToGenerate(invoicesToGenerate.filter((item) => item !== value));
    }
  }, [invoicesToGenerate]);
  
  const handleGenerate = useCallback(() => {
    if (invoicesToGenerate.length == 0) {
      return;
    }
    
    onGenerate(invoicesToGenerate)
  }, []);

  return (
    <PageWrapper>
      <PageHeader>
        <Heading scale="h1" fontColor={NATURAL.K}>Orders Ready to be Invoiced</Heading>
        <Typo fontColor={NATURAL.G} fontSize={FONT_SIZE.SM}>Invoices will go to your Xero Account</Typo>
        <Button onClick={handleGenerate}>Generate Invoices</Button>
      </PageHeader>
      <Typo display="flex" fontColor={NATURAL.G} fontSize={FONT_SIZE.SM} margin={`0 0 ${DESKTOP_SPACING.SM}px 0`}>
        <Icon type="info" fillColor={PRIMARY_BLUE.DARK} />
        You can send max 50 orders at a time, the process may take up to 2 mins
      </Typo>
      <Typo display="flex" fontColor={NATURAL.G} fontSize={FONT_SIZE.SM}>
        <Icon type="info" fillColor={PRIMARY_BLUE.DARK} />
        System loads 50 orders per page, to upload more you can scroll down
      </Typo>
      <FilterAction numberOfFilters={numberOfFilters} onOpen={onOpenFilter} />
      {loading ? (
          <div>Loading</div>
        ) : orders.map((order) => {
          return (
            <OrderCard {...order} key={order.id} onClick={() => handleGenerate()} />
          );
        })}
    </PageWrapper>
  );
};

export default memo(GenerateInvoices);
