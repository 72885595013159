export enum DESKTOP_SPACING {
  XS = 2,
  SM = 4,
  MD = 8,
  LG = 12,
  XL = 16,
  XXL = 20,
  XXXL = 24,
  EL = 30,
};

export const SIDEBAR_PADDING = '30px';