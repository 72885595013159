import { Accordion, Button, ButtonsGroup, Card, Empty, InputText } from "#/atoms";
import { DESKTOP_SPACING, HPOSITION } from "@/theme";
import { FC, memo, useCallback, useId, useMemo } from "react";
import { NoteProps, NotesCardProps } from "./Notes.interface";
import { StyledNote, StyledTime } from "./Notes.styled";

import { HeadingIcon } from "#/molecules";

const Note: FC<NoteProps> = ({ label, value, time }) => {

  return (
    <StyledNote>
      <InputText type="text" label={label} value={value} readOnly={true} />
      <StyledTime>{time}</StyledTime>
    </StyledNote>
  );
}

const NotesCard: FC<NotesCardProps> = ({ accordionState = 'open', notes, onAddNote }) => {
  const id = useId();
  const noteId = useMemo(() => `${id}-${notes.length}`, [notes, id]);

  const handleAddNewNote = useCallback(() => {
    onAddNote({
      content: '',
      id: noteId,
    })
  }, [noteId]);

  const shouldShowEmpty = notes.length === 0;

  return (
    <Card>
      <Accordion
        header={<HeadingIcon icon="note" title="Notes" />}
        headerMargin={`${DESKTOP_SPACING.XXXL}px`}
        showArrow
        state={accordionState}
      >
        {notes.length > 0 && notes.map((note, index) => {
          return (
            <Note
              key={`${noteId}-${index}`}
              id={note.id}
              value={note.content}
              label={`Note by ${note.owner}`}
              onChange={onAddNote}
              time={note.created}
            />
          );
        })}
        {shouldShowEmpty && (
          <Empty>No notes have been added yet.</Empty>
        )}
        <ButtonsGroup align={shouldShowEmpty ? HPOSITION.CENTER : HPOSITION.RIGHT} fullWidth>
          <Button onClick={handleAddNewNote}>
            Add Note
          </Button>
        </ButtonsGroup>
      </Accordion>
    </Card>
  );
};

export default memo(NotesCard);