import { Datepicker, FilterSidebar } from "#/molecules";
import { FC, memo, useCallback } from "react";
import { InputText, Select } from "#/atoms";
import { useDatepicker, useInput, useSelect } from "@/hooks";

import { FilterInvoicesProps } from "./Invoices.interface";
import { ORDER_STATE } from "@/types";
import { getOrderStateText } from "@/helpers";

const Invoices: FC<FilterInvoicesProps> = ({ filter, numberOfFilters, onCloseFilter, onClearFilter, onFilter }) => {
  const companyName = useInput(filter?.companyName);
  const customerCode = useInput(filter?.customerCode);
  const customerEmail = useInput(filter?.customerEmail);
  const dateTakenFrom = useDatepicker(filter?.dateTakenFrom ? new Date(filter?.dateTakenFrom) : null);
  const dateTakenTo = useDatepicker(filter?.dateTakenTo ? new Date(filter?.dateTakenTo) : null);
  const dateWorkDone = useDatepicker(filter?.dateWorkDone ? new Date(filter?.dateWorkDone) : null);
  const location = useInput(filter?.location);
  const state = useSelect(getOrderStateText(filter?.state));

  const handleFilter = useCallback(() => {
    onFilter({
      companyName: companyName.value,
      customerCode: customerCode.value,
      customerEmail: customerEmail.value,
      dateTakenFrom: dateTakenFrom.value?.toString() || '',
      dateTakenTo: dateTakenTo.value?.toString() || '',
      dateWorkDone: dateWorkDone.value?.toString() || '',
      location: location.value,
      state: state.value as unknown as ORDER_STATE,
    });
  }, [
    companyName,
    customerCode,
    customerEmail,
    dateTakenFrom,
    dateTakenTo,
    dateWorkDone,
    location,
    state
  ]);

  return (
    <FilterSidebar
      numberOfFilters={numberOfFilters}
      onClearFilters={onClearFilter}
      onClose={onCloseFilter}
      onFilter={handleFilter}
    >
      <InputText type="text" label="Customer Code" fullWidth value={customerCode.value} onChange={customerCode.onChange} />
      <InputText type="text" label="Company Name" fullWidth value={companyName.value} onChange={companyName.onChange} />
      <InputText type="text" label="Address Details" fullWidth value={location.value} onChange={location.onChange} />
      <InputText type="text" label="Customer E-mail" fullWidth value={customerEmail.value} onChange={customerEmail.onChange} />
      <Select label="Order Status" options={[]} selected={state.value} onChange={state.onChange} />
      <Datepicker label="Date Order Taken From" date={dateTakenFrom.value} onChange={dateTakenFrom.onChange} />
      <Datepicker label="Date Order Taken to" date={dateTakenTo.value} onChange={dateTakenTo.onChange} />
      <Datepicker label="Date Work to be Done" date={dateWorkDone.value} onChange={dateWorkDone.onChange} />
    </FilterSidebar>
  );
}

export default memo(Invoices);
