import styled from "styled-components";

const StyledIllustration = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const Success = () => {
  return (
    <StyledIllustration>
      <svg width="164" height="159" viewBox="0 0 164 159" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="79.7331" cy="90.794" r="57.8586" fill="#CECED6"/>
        <circle cx="6.96243" cy="108.314" r="5" transform="rotate(14.3984 6.96243 108.314)" fill="#EDEDF0"/>
        <circle cx="3.04323" cy="133.634" r="2.5" transform="rotate(14.3984 3.04323 133.634)" fill="#EDEDF0"/>
        <circle cx="159.736" cy="96.5" r="2.5" fill="#EDEDF0"/>
        <circle cx="44.2358" cy="21" r="5" fill="#EDEDF0"/>
        <circle cx="132.236" cy="154" r="5" fill="#EDEDF0"/>
        <circle cx="146.736" cy="122.5" r="2" stroke="#CECED6"/>
        <circle cx="71.7358" cy="4.5" r="3.5" stroke="#CECED6" strokeWidth="2"/>
        <path d="M66.9846 15.6237C66.9846 15.3199 66.7383 15.0737 66.4346 15.0737C66.1308 15.0737 65.8846 15.3199 65.8846 15.6237H66.9846ZM65.8846 24.8486C65.8846 25.1524 66.1308 25.3986 66.4346 25.3986C66.7383 25.3986 66.9846 25.1524 66.9846 24.8486H65.8846ZM65.8846 15.6237V17.9299H66.9846V15.6237H65.8846ZM65.8846 22.5424V24.8486H66.9846V22.5424H65.8846Z" fill="#B7B7C2"/>
        <path d="M71.0471 20.7861C71.3509 20.7861 71.5971 20.5399 71.5971 20.2361C71.5971 19.9324 71.3509 19.6861 71.0471 19.6861L71.0471 20.7861ZM61.8222 19.6861C61.5184 19.6861 61.2722 19.9324 61.2722 20.2361C61.2722 20.5399 61.5184 20.7861 61.8222 20.7861L61.8222 19.6861ZM71.0471 19.6861L68.7409 19.6861L68.7409 20.7861L71.0471 20.7861L71.0471 19.6861ZM64.1284 19.6861L61.8222 19.6861L61.8222 20.7861L64.1284 20.7861L64.1284 19.6861Z" fill="#B7B7C2"/>
        <path d="M157.68 108.649C157.68 108.346 157.433 108.099 157.13 108.099C156.826 108.099 156.58 108.346 156.58 108.649L157.68 108.649ZM156.58 117.874C156.58 118.178 156.826 118.424 157.13 118.424C157.433 118.424 157.68 118.178 157.68 117.874L156.58 117.874ZM156.58 108.649L156.58 110.956L157.68 110.956L157.68 108.649L156.58 108.649ZM156.58 115.568L156.58 117.874L157.68 117.874L157.68 115.568L156.58 115.568Z" fill="#B7B7C2"/>
        <path d="M161.742 113.812C162.046 113.812 162.292 113.566 162.292 113.262C162.292 112.958 162.046 112.712 161.742 112.712L161.742 113.812ZM152.517 112.712C152.214 112.712 151.967 112.958 151.967 113.262C151.967 113.566 152.214 113.812 152.517 113.812L152.517 112.712ZM161.742 112.712L159.436 112.712L159.436 113.812L161.742 113.812L161.742 112.712ZM154.824 112.712L152.517 112.712L152.517 113.812L154.824 113.812L154.824 112.712Z" fill="#B7B7C2"/>
        <path d="M27.9392 137.516C28.2207 137.402 28.3565 137.082 28.2424 136.8C28.1284 136.519 27.8077 136.383 27.5262 136.497L27.9392 137.516ZM18.9761 139.96C18.6945 140.074 18.5587 140.395 18.6728 140.676C18.7868 140.958 19.1075 141.094 19.389 140.98L18.9761 139.96ZM27.5262 136.497L25.3886 137.363L25.8016 138.382L27.9392 137.516L27.5262 136.497ZM21.1136 139.094L18.9761 139.96L19.389 140.98L21.5266 140.114L21.1136 139.094Z" fill="#B7B7C2"/>
        <path d="M24.6794 143.22C24.7935 143.501 25.1142 143.637 25.3957 143.523C25.6772 143.409 25.813 143.088 25.699 142.807L24.6794 143.22ZM22.2356 134.257C22.1216 133.975 21.8009 133.839 21.5194 133.953C21.2378 134.068 21.1021 134.388 21.2161 134.67L22.2356 134.257ZM25.699 142.807L24.8331 140.669L23.8136 141.082L24.6794 143.22L25.699 142.807ZM23.1015 136.394L22.2356 134.257L21.2161 134.67L22.0819 136.807L23.1015 136.394Z" fill="#B7B7C2"/>
        <circle cx="86.6415" cy="94.2482" r="57.8586" fill="#F7F8FC"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M113.601 74.5691C115.632 76.2484 115.906 79.2433 114.214 81.2584L84.3045 116.88C83.4437 117.905 82.1881 118.524 80.845 118.584C79.5018 118.645 78.195 118.142 77.2442 117.198L59.2984 99.3872C57.4295 97.5323 57.4295 94.5251 59.2984 92.6703C61.1673 90.8154 64.1973 90.8154 66.0662 92.6703L80.3065 106.804L106.861 75.1772C108.553 73.1621 111.571 72.8898 113.601 74.5691Z" fill="#9E9DAD"/>
      </svg>
    </StyledIllustration>
  );
};

export default Success;
