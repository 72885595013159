import { MENU_ICON_COLOR, NATURAL, SECONDARY_NAVY, SIDEBAR_WIDTH, ZINDEX, media } from "@/theme";

import styled from "styled-components";

export const StyledNavigation = styled.section`
  align-content: center;
  background-color: ${SECONDARY_NAVY.DEFAULT};
  bottom: 0;
  display: flex;
  flex-direction: column;
  left: 0;
  padding: 40px 50px 40px 30px;
  position: fixed;
  top: 0;
  width: ${SIDEBAR_WIDTH};
  z-index: ${ZINDEX.MENU_INDEX};

  :after {
    background-color: ${NATURAL.B};
    border-radius: 20px 0 0 20px;
    bottom: 0;
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    width: 20px;
    z-index: ${ZINDEX.MENU_INDEX};
  }

  ${media.laptop} {
    bottom: auto;
    display: flex;
    flex-direction: row;
    padding: 20px;
    right: 0;
    width: 100%;

    :after {
      display: none;
    }
  }
`;

export const StyledNav = styled.div`
  position: relative;
  width: auto;

  ${media.laptop} {
    display: flex;
    flex: 1;
    justify-content: end;
  }
`;

export const StyledNavigationNav = styled.nav<{ mobileShow: boolean }>`
  margin: auto 0;

  ${media.laptop} {
    background-color: ${SECONDARY_NAVY.DEFAULT};
    border-radius: 0 0 20x 20px;
    display: ${({ mobileShow }) => mobileShow ? 'block' : 'none'};
    padding: 0 20px 20px;
    right: 0;
    position: absolute;
    top: 42px;
  }
`;

export const StyledSandwitchIcon = styled.button`
  background: transparent;
  border: 0;
  display: none;
  padding: 0;

  :before {
    background-color: ${MENU_ICON_COLOR};
    display: block;
    content: '';
    height: 3px;
    margin: 0 0 3px;
    width: 24px;
  }

  span {
    background-color: ${MENU_ICON_COLOR};
    display: block;
    height: 3px;
    margin: 3px 0;
    width: 24px;
  }

  :after {
    background-color: ${MENU_ICON_COLOR};
    display: block;
    content: '';
    height: 3px;
    margin: 3px 0 0;
    width: 24px;
  }

  ${media.laptop} {
    display: inline-block;
  }
`;

export const StyledSupportMobile = styled.div`
  display: none;
  
  ${media.laptop} {
    display: block;
  }
`;

export const StyledSupportDesktop = styled.div`
  display: block;
  
  ${media.laptop} {
    display: none;
  }
`;
