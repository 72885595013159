import { BORDER_RADIUS, DESKTOP_SPACING, NATURAL } from "@/theme";

import { StyledButton } from "#/atoms/Button/Button.styled";
import { StyledCard } from "#/atoms/Card/Card.styled";
import { StyledPackage } from "#/organisms/Package/Package.styled";
import styled from "styled-components";

export const StyledCustomer = styled.div`
  align-content: center;
  display: flex;

  ${StyledButton} {
    border: 0;
    margin-top: ${DESKTOP_SPACING.SM}px;
  }
`;

export const StyledCustomerName = styled.div`
  border: 1px solid ${NATURAL.C};
  border-radius: ${BORDER_RADIUS.MD};
  flex: 1;
  margin-bottom: ${DESKTOP_SPACING.LG}px;
  padding: ${DESKTOP_SPACING.XL}px;

  strong {
    margin-left: ${DESKTOP_SPACING.EL}px;
  }

  :last-child {
    margin-bottom: 0;
  }
`;

export const StyledOrderTemplates = styled(StyledCard)`
  ${StyledPackage} {
    margin-top: ${DESKTOP_SPACING.XL}px;
  }
`