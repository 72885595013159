import { DESKTOP_SPACING, FONT_SIZE, NATURAL, PRIMARY_BLUE } from '@/theme';
import { StyledCheckboxProps, StyledToggleIconProps, StyledToggleLabelProps, StyledToggleProps } from './Toggle.interface';

import styled from 'styled-components';

export const StyledToggleIcon = styled.div<StyledToggleIconProps>`
  background-color: ${({ checked }) => checked ? PRIMARY_BLUE.DARK : NATURAL.E};
  border-radius: 20px;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: .4s;

  :before {
    background-color: ${NATURAL.A};
    border-radius: 50%;
    bottom: 2px;
    content: "";
    height: 16px;
    left: 2px;
    position: absolute;
    transition: .4s;
    width: 16px;

    ${({ checked }) => checked ? 'transform: translateX(16px);' : ''}
  }
`;

export const StyledToggleCheckbox = styled.input<StyledCheckboxProps>`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  height: 0;
  opacity: 0;
  width: 0;
`;

export const StyledToggleText = styled.div<StyledToggleLabelProps>`
  align-items: center;
  color: ${NATURAL.G};
  cursor: pointer;
  display: flex;
  flex: 1;
  font-size: ${FONT_SIZE.SM};
  height: 100%;
  margin-left: ${DESKTOP_SPACING.MD}px;
  position: relative;
  top: 0;
  white-space: nowrap;
`;

export const StyledToggleLabel = styled.div`
  display: inline-block;
  height: 20px;
  position: relative;
  width: 36px;
`;

export const StyledToggle = styled.label<StyledToggleProps>`
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  display: flex;
  flex-direction: ${({ labelPosition }) => labelPosition === 'left' ? 'row-reverse' : 'row'};
  opacity: ${({ disabled }) => disabled ? '0.4' : '1'};

  ${StyledToggleText} {
    margin-left: ${({ labelPosition }) => labelPosition === 'right' ? `${DESKTOP_SPACING.MD}px` : '0'};
    margin-right: ${({ labelPosition }) => labelPosition === 'left' ? `${DESKTOP_SPACING.MD}px` : '0'};
  }
`;
