import { Button, ButtonsGroup, Typo } from "#/atoms";
import { FC, memo } from "react";

import { DESKTOP_SPACING } from "@/theme";
import { InviteCustomerProps } from "./InviteCustomer.interface";
import { Modal } from "#/molecules";

const InviteCustomer: FC<InviteCustomerProps> = ({ onCancel, onConfirm }) => {
  return (
    <Modal onClose={onCancel} title="Invite Customer">
      <Typo>Customer will be invited via e-mail. This is one way action.</Typo>
      <ButtonsGroup fullWidth margin={`${DESKTOP_SPACING.EL}px 0 0`}>
        <Button actionType='outline' onClick={onCancel}>Cancel</Button>
        <Button onClick={onConfirm}>Confirm</Button>
      </ButtonsGroup>
    </Modal>
  );
}

export default memo(InviteCustomer);