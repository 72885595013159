import {useEffect, useState } from "react";

import { Dashboard } from "@/components/pages";
import { useApi } from "@/api";


const DashboardView = () => {
  const { dashboard, session } = useApi();
  const [called, setCalled] = useState(false);

  const getDashboardData = async () => {
    if (!called)
     {
        setCalled(true);
    
        await dashboard.getDashboardData();
     }
  }

  useEffect(() => {
    getDashboardData();
  }, [dashboard.dashboardData, called]);

  return (
    <Dashboard dashboardData={dashboard.dashboardData} firstName={session?.user?.firstName} />
  );
}

export default DashboardView;