import { DESKTOP_SPACING } from '@/theme';
import { StyledInputSelect } from '#/atoms';
import { StyledRadioButtonGroupProps } from './RadioButtonGroup.interface';
import styled from 'styled-components';

export const StyledRadioButtonGroup = styled.div<StyledRadioButtonGroupProps>`
  display: flex;

  ${({ direction }) => direction === 'row'
    ? `
      flex-direction: row;

      ${StyledInputSelect} {
        margin-right: ${DESKTOP_SPACING.LG}px;

        :last-child {
          margin-right: 0;
        }
      }
    `
    : `
      flex-direction: column;

      ${StyledInputSelect} {
        margin-bottom: ${DESKTOP_SPACING.LG}px;

        :last-child {
          margin-bottom: 0;
        }
      }
    `
  }
`;