import { Card, Flex, Heading, Typo } from "@/components/atoms";
import { DESKTOP_SPACING, PRIMARY_BLUE } from "@/theme";
import { StyledDd, StyledDl, StyledDt } from "./AwaitingConfirmation.styled";

import { AwaitingConfirmationProps } from "./AwaitingConfirmation.interface";
import { FC } from "react";
import { SuccessIllustration } from "@/assets";

const AwaitingConfirmation: FC<AwaitingConfirmationProps> = ({ orderDetails, selectedOrderTemplate }) => {
  if (!orderDetails || !selectedOrderTemplate) {
    return null;
  }
  
  return (
    <>
      <Card>
        <Flex flexDirection="column" gap={`${DESKTOP_SPACING.LG}px`} alignItems="center">
          <SuccessIllustration />
          <Heading scale="h3">Your order has been received!</Heading>
          <Typo textAlign="center" padding={`0 ${DESKTOP_SPACING.EL}px`}>Your booking request has been taken successfully. We will inform you when the date and time is confirmed.</Typo>
        </Flex>
      </Card>
      <Card>
        <Heading fontColor={PRIMARY_BLUE.DARK} scale="h4">Order Summary</Heading>
        <StyledDl>
          <StyledDt>Package:</StyledDt>
          <StyledDd>{selectedOrderTemplate.description}</StyledDd>
          <StyledDt>Address Details:</StyledDt>
          <StyledDd>{orderDetails.propertyAddress.addressString}</StyledDd>
          <StyledDt>Date:</StyledDt>
          <StyledDd>{orderDetails.common.appointmentDateTime}</StyledDd>
          <StyledDt>Time & Duration:</StyledDt>
          <StyledDd>{orderDetails.common.appointmentDuration}</StyledDd>
          <StyledDt>Keys:</StyledDt>
          <StyledDd>Will be taken from {orderDetails.keysWith}, {orderDetails.keysWithDetail}, at {orderDetails.keysTimeOption}</StyledDd>
          <StyledDt>Contact:</StyledDt>
          <StyledDd>{orderDetails.contacts.map((item) => item.name).join(', ')}</StyledDd>
          <StyledDt>Notes:</StyledDt>
          <StyledDd>12 Photos & 1 Floor Plan</StyledDd>
        </StyledDl>
      </Card>
    </>
  );
};

export default AwaitingConfirmation;