import { Button, ButtonsGroup, Footer, Price } from "#/atoms";
import { FC, memo } from "react";
import { FONT_SIZE, FONT_WEIGHT } from "@/theme";

import { ORDER_STEP } from "../Order/Order.interface";
import { PackageName } from "#/molecules";
import { PageType } from "@/types";
import { SelectedOrderProps } from "./SelectedOrder.interface";

const getButtonLabel = (activeStep: ORDER_STEP, type: PageType) => {
  switch (activeStep) {
    case ORDER_STEP.BOOKING: {
      if (type === 'edit') {
        return 'Save Order';
      }

      return 'Place Order';
    }
    case ORDER_STEP.PROGRESS: {
      return 'Save Order';
    }
    case ORDER_STEP.CUSTOMER_AND_PACKAGE: {
      return 'Go to Booking';
    }
    case ORDER_STEP.CONTENT_SELECTION: {
      return 'Continue with the selection';
    }
  }
}

const SelectedOrder: FC<SelectedOrderProps> = ({
  activeStep,
  description,
  id,
  isAdmin,
  price,
  onDeleteOrder,
  onCancelOrder,
  onSaveOrder,
  type,
}) => {
  if (activeStep === ORDER_STEP.PAYMENT || activeStep === ORDER_STEP.AWAITING_CONFIRMATION) {
    return null;
  }

  return (
    <Footer>
      <PackageName icons={[]} id={id} name={description} />
      <Price amount={price} currency="GBP" fontSize={FONT_SIZE.LG} fontWeight={FONT_WEIGHT.SEMIBOLD} />
      <ButtonsGroup fullWidth>
        {(isAdmin && (activeStep === ORDER_STEP.PROGRESS || type === 'edit')) && (
          <>
            <Button actionType="outline" buttonColor="secondary" onClick={onCancelOrder}>
              Cancel Order
            </Button>
            <Button actionType="outline" buttonColor="secondary" onClick={onDeleteOrder}>
              Delete Order
            </Button>
          </>
        )}
        <Button onClick={onSaveOrder}>{getButtonLabel(activeStep, type)}</Button>
      </ButtonsGroup>
    </Footer>
  );
};

export default memo(SelectedOrder);