import { FC, memo } from "react";

import { EmptyIllustration } from "@/assets";
import { EmptyProps } from "./Empty.interface";
import { StyledEmpty } from "./Empty.styled";

const Empty: FC<EmptyProps> = ({ children }) => {
  return (
    <StyledEmpty>
      <EmptyIllustration />
      {children}
    </StyledEmpty>
  );
}

export default memo(Empty);