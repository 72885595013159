import { Button, ButtonsGroup, Empty, Flex, GridCell, Heading, Icon, InputSelect, Typo } from "#/atoms";
import { DESKTOP_SPACING, FONT_WEIGHT, HPOSITION, NATURAL, PRIMARY_BLUE } from "@/theme";
import { FC, memo, useCallback, useState } from "react";
import { PhotoFile, PhotoProps } from "./OrderService.interface";
import { StyledAcceptButton, StyledDropzone, StyledExpandButton, StyledFullscreen, StyledImage, StyledImageSection, StyledPhotoImg, StyledPhotos, StyledThumb } from "./OrderService.styled";

import ExpandIcon from '@/assets/icons/Expand';
import { FloorPlanSurchargeModal } from "#/organisms";
import { OrderFile } from "@/types";
import { useApi } from "@/api";
import { useDropzone } from 'react-dropzone';
import { useModal } from "@/hooks";

const FloorPlan: FC<PhotoProps> = ({
  amount,
  draftFiles,
  isOffered = false,
  name,
  onDeleteFiles,
  onDownloadFiles,
  onUploadFiles,
  serviceId,
  uploadedFiles,
}) => {
  const { showModal: showFloorPlanModal, onCloseModal: onFloorPlanClose, onOpenModal: onFloorPlanOpen } = useModal();

  const [files, setFiles] = useState<PhotoFile[]>([]);
  const [selectedFiles, setSelectedFiles] = useState<string[]>([]);
  const [fullscreen, setFullscreen] = useState<OrderFile | null>(null);
  const [floorArea, setfloorArea] = useState<number>(0);

  const { worker } = useApi();

  const handleSelectFile = useCallback((checked: boolean, value: string) => {
    setSelectedFiles((state) => {
      if (checked) {
        return [...state, value];
      }

      console.log(state);

      return state.filter((item) => item !== value);
    });
  }, []);

  const handleSelectAll = useCallback(() => {
    if (!draftFiles) {
      return;
    }
    setSelectedFiles(draftFiles.map((item) => item.uploadFileId))
  }, [draftFiles]);

  const handleDeleteFiles = useCallback(() => {
    if (!draftFiles) {
      return;
    }
    const filesToDelete = selectedFiles.reduce((acc: string[], cur) => {
      const item : OrderFile | undefined = draftFiles.find((v) => v.uploadFileId === cur);

      if (item) {
        return [...acc, item.uploadFileId]
      }

      return acc;
    }, []);
    
    onDeleteFiles(serviceId, filesToDelete, isOffered)
  }, [selectedFiles, draftFiles]);

  const handleDownloadFiles = useCallback(() => {
    onDownloadFiles(serviceId, uploadedFiles.map((item) => item.uploadFileId));
  }, []);

  const handleFullscreenOpen = useCallback((id: string) => {
    const file = uploadedFiles.find((item) => item.uploadFileId === id) || (draftFiles && draftFiles.find((item) => item.uploadFileId === id));

    if (file) {
      setFullscreen(file);
    }
  }, [uploadedFiles, draftFiles]);

  const handleFullscreenClose = useCallback(() => {
    setFullscreen(null);
  }, []);

  const handleOpenFloorPlanSurcharge = useCallback(() => {
    worker.getFloorArea(serviceId).then(
      (area) => {
        setfloorArea(area);
        onFloorPlanOpen();
      }
    )
    
  }, []);

  const handleRecordFloorPlanSurcharge = useCallback(() => {
    onFloorPlanClose();
  }, []);

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    setFiles(acceptedFiles.map((file) =>
      Object.assign(file, {
          id: file.name,
          preview: URL.createObjectURL(file),
      })
    ));
    await onUploadFiles(serviceId, acceptedFiles);
    setFiles([]);
  }, []);

  const {
    getRootProps,
    getInputProps,
    open
  } = useDropzone({
    accept: {
      'image/*': ['.png', '.jpg', '.gif'],
    },
    noClick: true,
    onDrop
  });

  const getThumb = (id: string, img: string) => {
    const checked = selectedFiles.includes(id);

    return (
      <>
        <StyledAcceptButton type="button">
          <InputSelect type="checkbox" checked={checked} value={id} onChangeValue={handleSelectFile} labelPosition={HPOSITION.CENTER} />
        </StyledAcceptButton>
        <StyledExpandButton type="button" onClick={() => handleFullscreenOpen(id)}><ExpandIcon /></StyledExpandButton>
        <StyledPhotoImg img={img} />
      </>
    )
  }

  const getFilesThumb = (data: PhotoFile[]) => {
    return data.map(({ id, preview }) => {
      return (
        <StyledThumb key={id}>
          {getThumb(id, preview)}
        </StyledThumb>
      )
    });
  }

  const getUploadedThumb = (data: OrderFile[]) => {
    return data.map(({ uploadFileId, thumbURL }) => {
      return (
        <StyledThumb key={uploadFileId}>
          {getThumb(uploadFileId, thumbURL)}
        </StyledThumb>
      )
    });
  }

  return (
    <>
      <Flex alignItems="center" gap={`${DESKTOP_SPACING.MD}px`}>
        <Heading scale="h4" fontColor={PRIMARY_BLUE.DARK}>{name}</Heading>
        {amount && <Typo fontColor={NATURAL.G} weight={FONT_WEIGHT.SEMIBOLD}>X{amount}</Typo>}
      </Flex>

      {uploadedFiles && uploadedFiles.length > 0 && (
        <StyledImageSection>
          <Heading scale="h4" margin={`0 0 ${DESKTOP_SPACING.XXXL}px`}>Selected</Heading>
          <StyledPhotos>
            {getUploadedThumb(uploadedFiles)}
          </StyledPhotos>
          <ButtonsGroup fullWidth>
            <Button onClick={open}>Send Edited</Button>
          </ButtonsGroup>
        </StyledImageSection>
      )}
      
      <StyledDropzone {...getRootProps()}>
        <input type="file" {...getInputProps()} />
        {uploadedFiles && uploadedFiles.length > 0 && <Heading scale="h4" margin={`0 0 ${DESKTOP_SPACING.XXXL}px`}>Unselected</Heading>}
        {((draftFiles && draftFiles.length === 0) || !draftFiles) && files.length === 0 ? (
          <Empty>No photos are added yet. You can start adding new photos.</Empty>
        ) : (
          <StyledPhotos>
            {getFilesThumb(files)}
            {draftFiles && getUploadedThumb(draftFiles)}
          </StyledPhotos>
        )}
      </StyledDropzone>
      <ButtonsGroup fullWidth>
        <GridCell>
          <Button
            actionType="outline"
            buttonColor="secondary"
            state={selectedFiles.length > 0 ? 'default' : 'disabled'}
            onClick={handleDeleteFiles}
          >
            Delete Selected
          </Button>
        </GridCell>
        <Button actionType="outline" onClick={handleDownloadFiles}>Download All</Button>
        <Button actionType="outline" onClick={handleSelectAll}>Select All</Button>
        <Button onClick={open}>Upload New</Button>
        <Button onClick={handleOpenFloorPlanSurcharge}>Record Surcharge</Button>
      </ButtonsGroup>
      {fullscreen && (
        <StyledFullscreen onClick={handleFullscreenClose}>
          <Button actionType="ghost" onClick={handleFullscreenClose}><Icon type="cross" fillColor={NATURAL.A} /></Button>
          <StyledImage src={fullscreen.fullURL} />
        </StyledFullscreen>
      )}
      {showFloorPlanModal && (
        <FloorPlanSurchargeModal
          floorArea={floorArea}
          onClose={onFloorPlanClose}
          onConfirm={handleRecordFloorPlanSurcharge}
        />
      )}
    </>
  );
};

export default memo(FloorPlan);