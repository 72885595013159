import { useCallback, useState } from "react";

export interface ModalProps {
  onCloseModal: () => void;
}

interface ModalReturn extends ModalProps {
  onOpenModal: () => void;
  showModal: boolean;
}

const useModal = (): ModalReturn => {
  const [showModal, setShowModal] = useState(false);

  const onOpenModal = useCallback(() => {
    setShowModal(true);
  }, []);

  const onCloseModal = useCallback(() => {
    setShowModal(false);
  }, []);

  return {
    onCloseModal,
    onOpenModal,
    showModal,
  }
};


export default useModal;
