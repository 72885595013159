import { ApiSmsMessage, ApiVirtualTourExtensionItem  } from "./interface";
import { SmsMessage, VirtualTourExtensionItem } from "@/types";

export const virtualTourExtensionItemApiMapper = (api: ApiVirtualTourExtensionItem): VirtualTourExtensionItem => {
    return {
      abandoned: api.Abandoned,
      amendedDateTime: api.AmendedDateTime,
      customerName: api.CustomerName,
      externalLink: api.ExternalLink,
      orderId: api.OrderId,
      orderServiceId: api.OrderServiceId,
      virtualTourExtensionId: api.VirtualTourExtensionId,
    }
}

export const virtualTourExtensionItemsApiMapper = (api: ApiVirtualTourExtensionItem[]): VirtualTourExtensionItem[] => {
    return api.map((virtualTourExtensionItem) => virtualTourExtensionItemApiMapper(virtualTourExtensionItem))
};

export const smsMessageApiMapper = (api: ApiSmsMessage): SmsMessage => {
    return {
        createdDateTime: api.CreatedDateTime,
        id: api.Id,
        messageText: api.MessageText,
        reply: api.Reply,
        sender: api.Sender,
        senderName: api.SenderName,
    }
}

export const smsMessagesApiMapper = (api: ApiSmsMessage[]): SmsMessage[] => {
    return api.map((smsMessage) => smsMessageApiMapper(smsMessage))
};