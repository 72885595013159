import { OrderTemplate, PageType } from "@/types";

import { ReactNode } from "react";
import { SelectedOrderMethods } from "../SelectedOrder/SelectedOrder.interface";

export enum ORDER_STEP {
  CUSTOMER_AND_PACKAGE,
  BOOKING,
  PROGRESS,
  PAYMENT,
  CONTENT_SELECTION,
  AWAITING_CONFIRMATION,
}

export interface OrderProps extends SelectedOrderMethods {
  activeStep: ORDER_STEP;
  children: ReactNode;
  isAdmin: boolean;
  selectedOrder: OrderTemplate | null;
  type: PageType;
}

export interface OrderPublicProps extends SelectedOrderMethods {
  selectedOrder: OrderTemplate | null;
  type: PageType;
}

export interface StyledOrderProps {
  showFooter: boolean;
}