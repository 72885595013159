import { DESKTOP_SPACING, FONT_WEIGHT, NATURAL } from "@/theme";

import { StyledPrice } from "#/atoms";
import styled from "styled-components";

export const StyledPackage = styled.div``;

export const StyledPackageHeader = styled.div`
  align-items: center;
  border-bottom: 1px solid ${NATURAL.C};
  display: flex;
  padding-bottom: ${DESKTOP_SPACING.XL}px;
  width: 100%;
`;

export const StyledOrderTemplate = styled.div<{ disabled: boolean; }>`
  align-items: center;
  border-bottom: 1px solid ${NATURAL.C};
  cursor: pointer;
  display: flex;
  font-weight: ${FONT_WEIGHT.SEMIBOLD};
  opacity: ${({ disabled }) => disabled ? '0.6' : '1'};
  padding: ${DESKTOP_SPACING.XXL}px 0 ${DESKTOP_SPACING.XXL}px ${DESKTOP_SPACING.EL}px;

  ${StyledPrice} {
    margin-left: auto;
    margin-right: ${DESKTOP_SPACING.EL}px;
  }
`;
