import { DESKTOP_SPACING, NATURAL } from "@/theme";

import styled from "styled-components";

export const StyledUser = styled.div`
  display: flex;
`;

export const StyledUserAvatar = styled.div`
  align-items: center;
  border: 1px solid ${NATURAL.D};
  border-radius: 18px;
  display: flex;
  justify-content: center;
  height: 36px;
  width: 36px;
  overflow: hidden;
`;

export const StyledUserAvatarImage = styled.img`
  max-height: 100%;
  max-width: 100%;
`;

export const StyledUserName = styled.div`
  color: ${NATURAL.G};
  line-height: 36px;
  margin-left: ${DESKTOP_SPACING.LG}px;
`;