import { FC, lazy, memo } from "react";
import { StyledUser, StyledUserAvatar, StyledUserAvatarImage, StyledUserName } from "./User.styled";

import { UserProps } from "./User.interface";

const GenericAvatar = lazy(() => import('@/assets/icons/Contacts'));

const User: FC<UserProps> = ({ avatar, firstName, lastName, ...divProps }) => {
  const name = [firstName, lastName].join(' ');

  return (
    <StyledUser {...divProps}>
      <StyledUserAvatar>
        {avatar ? <StyledUserAvatarImage src={avatar} alt={name} /> : <GenericAvatar />}
      </StyledUserAvatar>
      <StyledUserName>{name}</StyledUserName>
    </StyledUser>
  );
};

export default memo(User);
