import { FONT_SIZE } from "@/theme";
import { StyledGridProps } from "./Grid.interface";
import styled from "styled-components";

export const StyledGrid = styled.div<StyledGridProps>`
  box-sizing: border-box;
  display: grid;
  font-size: ${({ fontSize }) => fontSize || FONT_SIZE.MD};
  gap: ${({ gap }) => gap ? `${gap}px` : '0'};
  grid-column-gap: ${({ columnGap }) => columnGap ? `${columnGap}px` : '0'};
  grid-template-columns: ${({ gridTemplateColumns }) => gridTemplateColumns || ''};
  grid-template-row: ${({ gridTemplateRows }) => gridTemplateRows || ''};
  grid-row-gap: ${({ rowGap }) => rowGap ? `${rowGap}px` : '0'};
  justifyContent: ${({ justifyContent }) => justifyContent || ''};
  margin: ${({ margin }) => margin || '0 auto'};
  max-width: ${({ maxWidth }) => maxWidth || '100%'};
  text-transform: ${({ textTransform }) => textTransform || ''};
`;