import { Button, ButtonsGroup, Price } from "#/atoms";
import { FC, memo, useCallback } from "react";
import { FONT_SIZE, FONT_WEIGHT } from "@/theme";

import CloneIcon from "@/assets/icons/Clone";
import CrossIcon from "@/assets/icons/Cross";
import EditIcon from "@/assets/icons/Edit";
import HideIcon from "@/assets/icons/Hide";
import { OrderTemplateProps } from "./Package.interface";
import ShowIcon from "@/assets/icons/Show";
import { StyledOrderTemplate } from "./Package.styled";

const OrderTemplate: FC<OrderTemplateProps> = ({ dea, description, hip, id, price, onClick }) => {
  const handleOrderTemplateClick = useCallback(() => {
    if (onClick) {
      onClick(id)
    }
  }, [onClick, id]);

  const canChoose = !dea && !hip

  return (
    <StyledOrderTemplate disabled={dea || true}>
      {description}
      <Price amount={price} currency="GBP" fontSize={FONT_SIZE.LG} fontWeight={FONT_WEIGHT.SEMIBOLD} showVat />
      <ButtonsGroup>
        {canChoose && <Button onClick={handleOrderTemplateClick}>Choose</Button>}
        {dea && (
          <>
            <Button actionType="icon">
              <EditIcon />
            </Button>
            <Button actionType="icon">
              <CrossIcon />
            </Button> 
          </>
        )}
        {!canChoose ? (
          <Button actionType="icon">
            <ShowIcon />
          </Button>
        ) : (
          <Button actionType="icon">
            <HideIcon />
          </Button>
        )}
        <Button actionType="icon">
          <CloneIcon />
        </Button>
      </ButtonsGroup>
    </StyledOrderTemplate>
  );
}

export default memo(OrderTemplate);
