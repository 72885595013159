import {
  ApiContact,
  ApiCustomer,
  ApiCustomerAccount,
  ApiCustomerAccountDetailsParams,
  ApiCustomerAddress,
  ApiCustomerContactParams,
  ApiCustomerData,
  ApiCustomerDataNote,
  ApiCustomerDataParams,
  ApiCustomerOrder,
} from "./interface.api";
import { Customer, CustomerAccountDetails, CustomerAddress, CustomerContacts, CustomerData, OrderNote } from "@/types";

import { OrderFilterParams } from "../order/interface";
import { mapOrderFilter } from "../order/mappers";

export const accountDetailsParamMapper = (accountDetails: CustomerAccountDetails): ApiCustomerAccountDetailsParams => {
  return {
    AccountType: accountDetails.accountType,
    ConfirmPassword: accountDetails.confirmPassword || '',
    Email: accountDetails.email,
    FirstName: accountDetails.firstName,
    LastName: accountDetails.lastName,
    Password: accountDetails.password || '',
    SendEmailNotification: accountDetails.sendEmailNotification || false,
  }
}

export const accountDetailsMapper = (api: ApiCustomerAccount): CustomerAccountDetails => {
  return {
    accountPhoneNumber: api.AccountPhoneNumber,
    accountType: '',
    daysToPayInvoices: api.DaysToPayInvoices,
    email: api.AccountsEmailAddress,
    firstName: api.FirstName,
    isActive: api.IsActive,
    lastName: api.LastName,
    login: api.Name,
    sendEmailNotification: api.SendEmailNotification
  }
}

export const addressMapper = (api: ApiCustomerAddress): CustomerAddress => {
  return {
    active: api.IsActive,
    area: api.Area,
    county: api.Country,
    customerCode: api.CustomerCode,
    houseName: api.HouseName,
    houseNumber: api.HouseNumber,
    name: api.Name,
    postcode: api.Postcode,
    road: api.Road,
    tax: api.IsTaxAdded,
    town: api.Town,
  }
}

export const customerContactsParamMapper = (contacts: CustomerContacts[]): ApiCustomerContactParams => {
  return {
    EmailAddress: contacts[0].email,
    Name: contacts[0].name,
    PhoneNumber1: contacts[0].phone1,
    PhoneNumber2: contacts[0].phone2,
  };
}

export const contactsParamMapper = (api: ApiContact[]): CustomerContacts[] => {
  return api.map((contact) => ({
    contactId: `${contact.ContactId}`,
    customerId: `${contact.CustomerId}`,
    email: contact.EmailAddress,
    name: contact.Name,
    phone1: contact.PhoneNumber1 || '',
    phone2: contact.PhoneNumber2 || '',
  }));
}

export const customerDetailsParamMapper = (details: CustomerAddress): ApiCustomerAddress => {
  return {
    Area: details.area,
    Country: details.county,
    CustomerCode: details.customerCode,
    HouseName: details.houseName,
    HouseNumber: details.houseNumber,
    IsActive: details.active,
    IsTaxAdded: details.tax,
    Name: details.name,
    Postcode: details.postcode,
    Road: details.road,
    Town: details.town,
  }
}

// export const customerNotesParamMapper = (notes: CustomerNotes): ApiCustomerNotes => {
//   return {
//     Notes: notes.notes,
//   }
// }

export const customerDataParamsMapper = (data: CustomerData): ApiCustomerDataParams => {
  return {
    AccountDetails: accountDetailsParamMapper(data.accountDetails),
    Contacts: customerContactsParamMapper(data.contacts),
    Details: customerDetailsParamMapper(data.address),
    Notes: {
      Notes: '',
    },
  }
}

export const customerDataApiMapper = (api: ApiCustomerData): CustomerData => {
  return {
    accountDetails: accountDetailsMapper(api.AccountDetails),
    address: addressMapper(api.Details),
    contacts: contactsParamMapper(api.Contacts),
  }
}

export const customerApiMapper = (api: ApiCustomer): Customer => {
  return {
    active: api.IsActive,
    address: api.Address,
    branchId: `${api.BranchId}`,
    code: api.Code,
    companyName: api.Name,
    email: api.EmailAddress,
    firstName: api.FirstName,
    id: api.ApplicationUserId,
    invited: api.Invited === 'yes',
    lastName: api.LastName,
    upsell: `${api.UpsellPrice}`,
    vat: false,
  }
}

export const customersApiMapper = (api: ApiCustomer[]): Customer[] => {
  return api.map((customer) => customerApiMapper(customer));
};

export const customerOrderMapper = (api: OrderFilterParams): ApiCustomerOrder => {
  return {
    ...mapOrderFilter(api),
    CustomerId: Number(api.customerId) || null,
  };
}

export const customerApiDataNoteMapper = (api: ApiCustomerDataNote): OrderNote => {
  return {
    content: api.Content,
    created: api.CreatedDateTime,
    id: api.NoteId.toString(),
    owner: api.OwnerName,
  };
}

export const customerApiDataNotesMapper = (api: ApiCustomerDataNote[]): OrderNote[] => {
  return api.map((note) => customerApiDataNoteMapper(note));
};