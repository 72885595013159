import { BORDER_RADIUS, DESKTOP_SPACING, FONT_SIZE, FONT_WEIGHT, NATURAL } from "@/theme";

import styled from "styled-components";

export const StyledPopover = styled.div`
  background: ${NATURAL.A};
  border-radius: ${BORDER_RADIUS.MD};
  box-shadow: 0px 0px 10px rgba(70, 134, 200, 0.16);
  display: flex;
  flex-direction: column;
  left: 0;
  position: absolute;
  top: 64px;
  max-width: 400px;
  width: 100%;
  z-index: 100;
`;

export const StyledPopoverHeader = styled.div`
  display: flex;
  flex: 1;
`;

export const StyledPopoverBody = styled.div`
  flex: 1;
  padding: 0 ${DESKTOP_SPACING.LG}px;
`;

export const StyledPopoverFooter = styled.div`
  flex: 1;
  margin-top: ${DESKTOP_SPACING.SM}px;
`;

export const StyledActiveDate = styled.div`
  border-bottom: 1px solid ${NATURAL.C};
  color: ${NATURAL.I};
  height: 48px;
  font-size: ${FONT_SIZE.SM};
  font-weight: ${FONT_WEIGHT.SEMIBOLD};
  line-height: 48px;
  padding: 0 ${DESKTOP_SPACING.XL}px;
  width: 100%;
`;

export const StyledCloseIcon = styled.button`
  background: ${NATURAL.A};
  border: 0;
  color: ${NATURAL.G};
  cursor: pointer;
  fill: ${NATURAL.G};
  height: 40px;
  padding: 0 ${DESKTOP_SPACING.SM}px;
  pointer-events: auto;
  position: absolute;
  right: ${DESKTOP_SPACING.MD}px;
  top: ${DESKTOP_SPACING.SM}px;
`;
