import { ApiChangePasswordParams, ApiCreateUserParams, ApiUser, BaseApiUser } from "./interface.api";
import { ChangePasswordParams, CreateUserParams, GetUsersParams, UserApi } from "./interface";
import { useCallback, useState } from "react";
import { userApiMapper, usersApiMapper } from "./mappers";

import { ApiHooksProps } from "../interface";
import { User } from "@/types";
import { request } from "../request";

const hook = ({ setError, setLoading, setSuccess, serverPath, token }: ApiHooksProps): UserApi => {
  const [user, setUser] = useState<User>();
  const [users, setUsers] = useState<User[]>([]);

  const changePassword = useCallback(async (id: string, data: ChangePasswordParams) => {
    const path =  `${serverPath}/accounts/change-password/`;
    const body: ApiChangePasswordParams = {
      ConfirmPassword: data.confirmPassword,
      NewPassword: data.newPassword,
      OldPassword: data.oldPassword,
      UserId: id,
    };

    setLoading(true);
    try {
      await await request(path, { body, token });
      setSuccess('Password updated!');
    } catch (error) {
      if (typeof error === 'string') {
        setError(error);
      }
    }
    setLoading(false);

    return;
  }, [serverPath, token]);

  const createUser = useCallback(async (data: CreateUserParams) => {
    const path = `${serverPath}/accounts/create`;
    const body: ApiCreateUserParams = {
      AccountType: data.accountType || '',
      ConfirmPassword: data.confirmPassword,
      Email: data.email,
      FirstName: data.firstName,
      InOfficeStaff: data.inStaff,
      IsActive: data.isActive,
      IsAdmin: data.isAdmin,
      LastName: data.lastName,
      Password: data.password,
      UserName: data.userName,
    };
    
    setLoading(true);
    try {
      const res = await request<ApiCreateUserParams, ApiUser>(path, { body, token });
      const newUser = userApiMapper(res);
      setUsers([...users, newUser]);
      setSuccess('User created!');
    } catch (error) {
      if (typeof error === 'string') {
        setError(error);
      }
    }
    setLoading(false);

    return;
  }, [serverPath, token, users]);

  const deleteUser = useCallback(async (userId: string) => {
    const path = `${serverPath}/accounts/user/delete/${userId}`;

    setLoading(true);
    try {
      await request(path, { token });
      setUsers(users.filter((item) => item.id !== userId))
      setSuccess('User deleted!');
    } catch (error) {
      if (typeof error === 'string') {
        setError(error);
      }
    }
    setLoading(false);

    return;
  }, [serverPath, token, users]);

  const getUser = useCallback(async (userId: string) => {
    const path = `${serverPath}/accounts/user/${userId}`;
    
    const res = await request<GetUsersParams, ApiUser>(path, { method: 'GET', token });
    setUser(userApiMapper(res));
  }, [serverPath, token]);

  const getUsers = useCallback(async (data?: GetUsersParams) => {
    const path = `${serverPath}/accounts/users`;
    const body = {
      displayName: data?.displayName || '',
      isActive: data?.isActive || null,
      isAdmin: data?.isAdmin || null,
      loginName: data?.loginName || '',
      page: data?.page || '',
    };
    const res = await request<GetUsersParams, ApiUser[]>(path, { body, token });
    setUsers(usersApiMapper(res));
  }, [serverPath, token]);

  const passwordReset = useCallback(async (login: string) => {
    const path = `${serverPath}/accounts/rest-password/`;
    const body = {
      AccountLogin: login,
    };

    setLoading(true);
    try {
      await request(path, { body, token });
      setSuccess('Password reset successful!');
    } catch (error) {
      if (typeof error === 'string') {
        setError(error);
      }
    }
    setLoading(false);

    return;
  }, [serverPath, token]);

  const setUpsell = useCallback(async (userId: string, price: number, photos: number) => {
    const path = `${serverPath}/accounts/set-upsell/${userId}`;
    const body = { DefaultPhotos: photos, UpsellPrice : price };
    setLoading(true);
    try {
      await request(path, { body, token });
    } catch (error) {
      if (typeof error === 'string') {
        setError(error);
      }
    }
    setLoading(false);
  }, [serverPath, token]);

  const updateUser = useCallback(async (data: BaseApiUser) => {
    const path = `${serverPath}/accounts/user`;
    const body = data;
    setLoading(true);
    try {
      await request<BaseApiUser, never>(path, { body, token });
      setSuccess('User updated!');
    } catch (error) {
      if (typeof error === 'string') {
        setError(error);
      }
    }
    setLoading(false);
  }, [serverPath, token]);

  return {
    changePassword,
    createUser,
    deleteUser,
    getUser,
    getUsers,
    passwordReset,
    setUpsell,
    updateUser,
    user,
    users,
  }
}

export default hook;