import { FC } from "react";
import { FlexProps } from "./Flex.interface";
import { StyledFlex } from "./Flex.styled";

const Flex: FC<FlexProps> = (props) => {
  const {
    display = 'flex',
    flexDirection = 'row',
    flexWrap,
    justifyContent,
    alignContent,
    alignItems,
    children,
    ...otherProps
  } = props

  return (
    <StyledFlex
      display={display}
      flexDirection={flexDirection}
      flexWrap={flexWrap}
      justifyContent={justifyContent}
      alignContent={alignContent}
      alignItems={alignItems}
      {...otherProps}>
      {children}
    </StyledFlex>
  )
}

export default Flex;
