import { Button, ButtonsGroup, Card } from "#/atoms";
import { FC, memo } from "react";
import { HeadingIcon, PackageName } from "#/molecules";

import { DESKTOP_SPACING } from "@/theme";
import { PackageCardProps } from "./Package.interface";

const PackageCard: FC<PackageCardProps> = ({ onNotifyCustomer, order }) => {
  return (
    <Card>
      <HeadingIcon
        icon="orders"
        mb={`${DESKTOP_SPACING.XXXL}px`}
        title="Package"
      />
      <PackageName icons={[]} id={order.id} name={order.description} />
      <ButtonsGroup fullWidth>
        <Button actionType="outline" onClick={onNotifyCustomer}>Notify Customer</Button>
      </ButtonsGroup>
    </Card>
  );
};

export default memo(PackageCard);