import { DESKTOP_SPACING, FONT_SIZE, NATURAL, PRIMARY_BLUE } from '@/theme';
import {
  StyledCheckboxProps,
  StyledInputSelectProps,
  StyledRadioProps,
} from './InputSelect.interface'

import styled from 'styled-components'

export const StyledCheckbox = styled.div<StyledCheckboxProps>`
  background-color: ${({ checked }) => checked ? PRIMARY_BLUE.DARK : NATURAL.A};
  border: 2px solid ${({ checked }) => checked ? PRIMARY_BLUE.DARK : NATURAL.D};
  border-radius: 4px;
  color: ${NATURAL.A};
  display: flex;
  fill: ${NATURAL.A};
  height: 20px;
  left: 0;
  position: absolute;
  top: 0;
  width: 20px;

  svg {
    height: 100%;
    width: 100%;
  }
`;

export const StyledRadioButton = styled.span<StyledRadioProps>`
  background-color: ${NATURAL.A};
  border-radius: 10px;
  border: 2px solid ${({ checked }) => checked ? PRIMARY_BLUE.DARK : NATURAL.D};
  display: flex;
  height: 20px;
  left: 0;
  position: absolute;
  top: 0;
  width: 20px;

  &:before {
    background: ${({ checked }) => checked ? PRIMARY_BLUE.DARK : NATURAL.A};
    border-radius: 50%;
    content: '';
    height: 10px;
    left: 3px;
    position: absolute;
    top: 3px;
    width: 10px;
  }
`;

export const StyledNativInputSelect = styled.input`
  align-items: center;
  display: flex;
  flex-shrink: 0;
  height: 20px;
  justify-content: center;
  margin: 0 ${DESKTOP_SPACING.MD}px 0 0;
  opacity: 0;
  padding: 0;
  position: relative;
  width: 20px;
  z-index: -1;
`;

export const StyledInputSelect = styled.label<StyledInputSelectProps>`
  align-items: center;
  align-self: flex-start;
  color: ${NATURAL.G};
  cursor: pointer;
  display: flex;
  flex-direction: ${({ labelPosition }) => labelPosition === 'left' ? 'row-reverse' : 'row'};
  font-size: ${FONT_SIZE.XS};
  height: auto;
  margin: ${({ margin }) => margin || '0'};
  position: relative;
  top: 0;
  white-space: nowrap;

  ${StyledNativInputSelect} {
    margin-left: ${({ labelPosition }) => labelPosition === 'right' ? `${DESKTOP_SPACING.MD}px` : '0'};
    margin-right: ${({ labelPosition }) => labelPosition === 'left' ? `${DESKTOP_SPACING.MD}px` : '0'};
  }

  ${({ state }) => state === 'disabled' &&
    `
      pointer-events: none;
      opacity: 0.4;
    `
  }
`;
