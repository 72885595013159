import { Box, Button, ButtonsGroup, Grid, InputSelect, InputText, Typo } from "#/atoms";
import { CustomTable, Modal } from "#/molecules";
import { DESKTOP_SPACING, NATURAL } from "@/theme";
import { FC, memo, useCallback, useMemo } from "react";
import { getBoleanFromYesOrNo, getYesOrNo } from "@/helpers";
import { useInput, useInputSelect, useSelect } from "@/hooks";

import { Column } from "react-table";
import { FindCustomerProps } from "./FindCustomer.interface";

const FindCustomer: FC<FindCustomerProps> = ({
  customers,
  filter,
  loading,
  onClearFilter,
  onClose,
  onFilter,
  onSelectCustomer
}) => {
  const customerSelect = useInputSelect();
  
  const active = useSelect(getYesOrNo(filter?.active));
  const companyName = useInput(filter?.companyName);
  const customerCode = useInput(filter?.customerCode);
  const customerEmail = useInput(filter?.customerEmail);
  const location = useInput(filter?.location);
  const login = useInput(filter?.login);

  const handleFilter = useCallback(() => {
    onFilter({
      active: getBoleanFromYesOrNo(active.value),
      companyName: companyName.value,
      customerCode: customerCode.value,
      customerEmail: customerEmail.value,
      location: location.value,
      login: login.value,
    });
  }, [active, companyName, customerCode, customerEmail, location, login]);

  const data = useMemo(() => (customers.map((customer) => ({
    address: customer.address,
    code: customer.code,
    email: customer.email,
    id: customer.id,
  }))), [customers]);
  
  const columns: Column[] = useMemo(() => [
    {
      Cell: ({ value }) => {
        return (
          <InputSelect
            type="radio"
            checked={customerSelect.value === value}
            name="customer"
            value={value}
            onChangeValue={customerSelect.onChange}
          />
        )
      },
      Header: '',
      accessor: 'id',
      id: 'radio',
    },
    {
      Header: 'Customer Code',
      accessor: 'code',
    },
    {
      Header: 'Address',
      accessor: 'address',
    },
    {
      Header: 'Email Address',
      accessor: 'email',
    },
  ], [customerSelect.value]);

  const handleSelectCustomer = useCallback(() => {
    onSelectCustomer(customerSelect.value);
    onClose();
  }, [customerSelect.value]);
  
  return (
    <Modal onClose={onClose} title="Find Customers" width="1000px">
      <Typo>Customer will be invited via e-mail. This is one way action.</Typo>
      <Box margin={`0 0 ${DESKTOP_SPACING.EL}px`}>
        <Grid columnGap={DESKTOP_SPACING.XXL} gridTemplateColumns="repeat(3, 1fr)">
          <InputText type="text" label="Customer Code" fullWidth value={customerCode.value} onChange={customerCode.onChange} />
          <InputText type="text" label="Company Name" fullWidth value={companyName.value} onChange={companyName.onChange} />
          {/* <InputText type="text" label="Address" fullWidth value={location.value} onChange={location.onChange} /> */}
          <InputText type="text" label="Customer E-mail" fullWidth value={customerEmail.value} onChange={customerEmail.onChange} />
          {/* <InputText type="text" label="Login" fullWidth value={login.value} onChange={login.onChange} /> */}
          {/* <Select
            label="Is Active"
            selected={active.value}
            options={[
              { id: '', label: '' },
              { id: 'no', label: 'No' },
              { id: 'yes', label: 'Yes' },
            ]}
            onChange={active.onChange}
          /> */}
        </Grid>
        <ButtonsGroup fullWidth>
          <Button actionType="ghost" onClick={onClearFilter}>Clear Filters</Button>
          <Button actionType="outline" onClick={handleFilter}>Filter</Button>
        </ButtonsGroup>
      </Box>
      <CustomTable columns={columns} data={data} headerCellProps={{ backgroundColor: NATURAL.B }} loading={loading} />
      <ButtonsGroup fullWidth margin={`${DESKTOP_SPACING.EL}px 0 0`}>
        <Button onClick={handleSelectCustomer}>Select</Button>
      </ButtonsGroup>
    </Modal>
  );
}

export default memo(FindCustomer);