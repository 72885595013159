import { Button, ButtonsGroup, Icon } from "#/atoms";
import { FC, memo } from "react";
import { StyledContact, StyledContactData, StyledContactInfo, StyledContactInfoItem, StyledContactTerm } from "./Contacts.styled";

import { ContactsProps } from "./Contacts.interface";

const Contacts: FC<ContactsProps> = ({ list, onAskToEditContact, onAskToRemoveContact }) => {
  return (
    <>
      {list.map(({ contactId, email, name, phone1 }) => {
        return (
          <StyledContact key={contactId}>
            <StyledContactInfo>
              <StyledContactInfoItem>
                <StyledContactTerm>Contact Name:</StyledContactTerm> <StyledContactData>{name}</StyledContactData>
              </StyledContactInfoItem>
              <StyledContactInfoItem>
                <StyledContactTerm>Email Address:</StyledContactTerm> <StyledContactData>{email}</StyledContactData>
              </StyledContactInfoItem>
              <StyledContactInfoItem>
                <StyledContactTerm>Phone Number:</StyledContactTerm> <StyledContactData>{phone1}</StyledContactData>
              </StyledContactInfoItem>
            </StyledContactInfo>
            <ButtonsGroup>
              <Button actionType="ghost" onClick={() => onAskToEditContact(contactId)}>
                <Icon type="edit" />
              </Button>
              <Button actionType="ghost" onClick={() => onAskToRemoveContact(contactId)}>
                <Icon type="cross" />
              </Button>
            </ButtonsGroup>
          </StyledContact>
        );
      })}
    </>
  );
};

export default memo(Contacts);
