import { FC, memo } from "react";

import { PackageNameProps } from "./PackageName.interface";
// import { Icon, IconType } from "#/atoms";
import { StyledPackageName } from "./PackageName.styled";

const PackageName: FC<PackageNameProps> = (props) => {
  // const { title, services } = props;
  // const icons = services.reduce((acc: IconType[], { image }) => {
  //   if (acc.includes(image)) {
  //     return acc;
  //   }

  //   return [...acc, image];
  // }, []);

  return (
    <StyledPackageName>
      {props.name}
      {/* <StyledPackageIcons> */}
        {/* {icons.map((icon, index) => <Icon key={`${icon}-${index}`} type={icon} />)} */}
      {/* </StyledPackageIcons> */}
    </StyledPackageName>
  );
}

export default memo(PackageName);
