import { FC, memo } from "react";
import { StepState, StepsProps } from "./Steps.interface";
import { StyledStep, StyledStepBullet, StyledSteps } from "./Steps.styled";

import { Card } from "../Card";
import CheckIcon from "@/assets/icons/Check";

const getStepState = (activeStep: number, stepId: number): StepState => {
  if (activeStep === stepId) {
    return 'active';
  }

  if (activeStep > stepId) {
    return 'visited';
  }

  return 'idle';
}

const Steps: FC<StepsProps> = ({ activeStep, steps }) => {
  return (
    <Card>
      <StyledSteps>
        {steps.map(({ id, name }, index) => {
          const state = getStepState(activeStep, id);

          return (
            <StyledStep key={id} state={state}>
              <StyledStepBullet state={state}>
                {state === 'visited' ? <CheckIcon /> : index+1}
              </StyledStepBullet>
              {name}
            </StyledStep>
          );
        })}
      </StyledSteps>
    </Card>
  );
};

export default memo(Steps);
