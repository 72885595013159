import { FC, memo } from "react";
import { FONT_SIZE, NATURAL, PRIMARY_BLUE } from "@/theme";
import { Heading, Icon, Typo } from "#/atoms";

import { HeadingIconProps } from "./HeadingIcon.interface";
import { StyledHeadingIcon } from "./HeadingIcon.styled";

const HeadingIcon: FC<HeadingIconProps> = ({ description, icon, mb, title }) => {
  return (
    <StyledHeadingIcon mb={mb}>
      <Icon type={icon} fillColor={PRIMARY_BLUE.DARK} />
      <Heading display="flex" scale="h4" fontColor={PRIMARY_BLUE.DARK}>
        {title}
      </Heading>
      {description && (
        <Typo fontColor={NATURAL.G} fontSize={FONT_SIZE.XS}>
          {description}
        </Typo>
      )}
    </StyledHeadingIcon>
  );
}

export default memo(HeadingIcon);
