import { BORDER_RADIUS, DESKTOP_SPACING, FONT_SIZE, FONT_WEIGHT, NATURAL } from "@/theme";

import styled from "styled-components";

export const StyledTimeSelect = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;

export const StyledTime = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledTimeDisplay = styled.div`
  border: 1px solid ${NATURAL.C};
  border-radius: ${BORDER_RADIUS.MD};
  color: ${NATURAL.G};
  font-size: ${FONT_SIZE.SM};
  font-weight: ${FONT_WEIGHT.SEMIBOLD};
  height: 36px;
  line-height: 34px;
  text-align: center;
  width: 36px;
`;

export const StyledTimeButton = styled.button`
  background: transparent;
  border: 0;
  color: ${NATURAL.E};
  display: block;
  height: 36px;
  fill: ${NATURAL.E};
  margin: 0;
  width: 36px;
`;

export const StyledTimeDivisor = styled.div`
  color: ${NATURAL.D};
  font-size: ${FONT_SIZE.XS};
  font-weight: ${FONT_WEIGHT.SEMIBOLD};
  margin: 0 ${DESKTOP_SPACING.MD}px;
`;