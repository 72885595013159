import { FilterCustomer, FilterCustomers } from "@/components/organisms";
import { useCallback, useEffect, useState } from "react";
import { useFilter, useModal } from "@/hooks";

import { Customers } from "#/pages";
import { GetListParams } from "@/api/customer/interface";
import { InviteCustomerModal } from "#/organisms";
import { useApi } from "@/api";
import { useNavigate } from 'react-router-dom';

const CustomersView = () => {
  const { customer } = useApi();
  const {
    filter,
    numberOfFilters,
    onOpenFilter,
    shouldFilter,
    setShouldFilter,
    showFilter,
    ...filterProps
  } = useFilter<FilterCustomer>();
  const { showModal, onCloseModal, onOpenModal } = useModal();
  const [customerInvite, setCustomerInvite] = useState<string | null>(null);
  const [loadingCustomers, setLoadingCustomers] = useState(false);
  const [called, setCalled] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();

  const getCustomerList = async (filterData?: GetListParams) => {
    setLoadingCustomers(true);
    await customer.getList(filterData);
    setLoadingCustomers(false);
  }

  useEffect(() => {
    if (customer.customers.length === 0 && !called) {
      setCalled(true);
      getCustomerList();
    }
  }, [customer.customers, called]);

  useEffect(() => {
    if (shouldFilter) {
      if (filter) {
        getCustomerList({
          accountName: filter.login,
          address: filter.location,
          code: filter.customerCode,
          email: filter.customerEmail,
          isActive: filter.active,
          name: filter.companyName,
          page: currentPage,
        });
      } else {
        getCustomerList();
      }
      setShouldFilter(false);
    }
  }, [filter, currentPage, shouldFilter]);

  const handleShowMore = useCallback(() => {
    setCurrentPage(cp => cp + 1);
    console.log(currentPage);
  }, [currentPage]);

  const handleOpenInviteModal = useCallback((customerId: string) => {
    setCustomerInvite(customerId);
    onOpenModal();
  }, []);

  const handleOpenCustomer = useCallback((customerId: string) => {
    navigate(`/account-customer/id=${customerId}`);
  }, []);

  const handleCancelInvite = useCallback(() => {
    setCustomerInvite(null);
    onCloseModal();
  }, []);

  const handleConfirmInvite = useCallback(() => {
    if (customerInvite) {
      customer.invite(customerInvite);
    }
    onCloseModal();
  }, []);

  const handleDownloadAddress = useCallback(() => {
    customer.getAddressList()
  }, []);

  const handleCreateNewCustomer = useCallback(() => {
    navigate('/customer-create', { replace: true });
  }, []);

  return (
    <>
      <Customers
        customers={customer.customers}
        loadingCustomers={loadingCustomers}
        numberOfFilters={numberOfFilters}
        onCreateNewCustomer={handleCreateNewCustomer}
        onDownloadAddress={handleDownloadAddress}
        onOpenFilter={onOpenFilter}
        onOpenInviteModal={handleOpenInviteModal}
        onShowMore={handleShowMore}
        onClickRow={handleOpenCustomer}
      />
      {showFilter && <FilterCustomers {...filterProps} filter={filter} numberOfFilters={numberOfFilters} />}
      {showModal && (
        <InviteCustomerModal
          onCancel={handleCancelInvite}
          onConfirm={handleConfirmInvite}
        />
      )}
    </>
  );
}

export default CustomersView;