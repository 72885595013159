import { Button, ButtonsGroup, Typo } from "#/atoms";
import { FC, memo, useCallback } from "react";

import { ConfirmProps } from "./Confirm.interface";
import { DESKTOP_SPACING } from "@/theme";
import { Modal } from "#/molecules";

const Confirm: FC<ConfirmProps> = ({ description, onClose, onConfirm, title }) => {
  const handleConfirm = useCallback(() => {
    onConfirm();
    onClose();
  }, []);

  return (
    <Modal onClose={onClose} title={title}>
      <Typo>{description}</Typo>
      <ButtonsGroup fullWidth margin={`${DESKTOP_SPACING.EL}px 0 0`}>
        <Button onClick={handleConfirm}>Confirm</Button>
      </ButtonsGroup>
    </Modal>
  );
};

export default memo(Confirm);