import { DESKTOP_SPACING, FONT_FAMILY, FONT_SIZE, FONT_WEIGHT } from "@/theme";
import styled, { css } from "styled-components";

import { StyledTextProps } from "../Typo/Typo.interface";

export const StyledHeading = styled.div<StyledTextProps>(
  ({
    display,
    fontColor,
    fontSize,
    margin,
    padding,
    textAlign,
    uppercase,
    underline,
    weight,
  }) => css`
    align-items: center;
    ${display ? `display: ${display};` : ''}
    ${fontColor ? `color: ${fontColor};` : ''}
    font-family: ${FONT_FAMILY};
    font-size: ${fontSize || FONT_SIZE.MD};
    font-weight: ${weight || FONT_WEIGHT.REGULAR};
    gap: ${DESKTOP_SPACING.MD}px;
    line-height: 1.4;
    ${margin ? `margin: ${margin};` : 'margin: 0;'}
    ${padding ? `padding: ${padding};` : 'padding: 0;'}
    ${textAlign ? `text-align: ${textAlign}` : ''};
    ${underline ? `text-decoration: underline;` : ''}
    ${uppercase && `text-transform: uppercase;`}
  `
)