import { DESKTOP_SPACING, FONT_SIZE, FONT_WEIGHT, NATURAL } from "@/theme";

import { StyledPriceProps } from "./Price.interface";
import styled from "styled-components";

export const StyledPrice = styled.div<StyledPriceProps>`
  color: ${NATURAL.I};
  font-size: ${({ fontSize }) => fontSize || FONT_SIZE.MD};
  font-weight: ${({ fontWeight }) => fontWeight || FONT_WEIGHT.REGULAR};
  text-align: center;
`;

export const StyledVat = styled.div`
  color: ${NATURAL.G};
  font-size: ${FONT_SIZE.XS};
  font-weight: ${FONT_WEIGHT.REGULAR};
  margin-top: ${DESKTOP_SPACING.SM}px;
`;