import {
  ApiBranch,
  ApiContact,
  ApiCustomer,
  ApiCustomerAddress,
  ApiCustomerData,
  ApiCustomerDataNote,
  ApiCustomerDataParams,
  ApiCustomerOrder,
  ApiGetDataParam,
  ApiGetListParams,
} from "./interface.api";
import {
  ApiFileDownloadRequestID,
  ApiHooksProps
} from "../interface";
import { Customer, CustomerData, Order, OrderNote } from "@/types";
import {
  CustomerApi,
  GetListParams
} from "./interface";
import {
  customerApiDataNotesMapper,
  customerDataApiMapper,
  customerDataParamsMapper,
  customerOrderMapper,
  customersApiMapper
} from "./mappers";
import { request, sendFile } from "../request";
import { useCallback, useState } from "react";

import { ApiOrder } from "../order/interface.api";
import { OrderFilterParams } from "../order/interface";
import { mapApiOrder } from "../order/mappers";

const hook = ({ serverPath, setError, setLoading, setSuccess, token }: ApiHooksProps): CustomerApi => {
  const [customerData, setCustomerData] = useState<CustomerData | null>(null);
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [customerOrders, setCustomerOrders] = useState<Order[]>([]);
  const [logoUrl, setLogoUrl] = useState<string>();
  const [notes, setNotes] = useState<OrderNote[]>([]);

  const addNote = useCallback(async (branchId: number, note: string) => {
    const path = `${serverPath}/notes/create`;
    const body = {
      Content: note,
      CustomerId: branchId
    }

    request(path, { body, token });
  }, [serverPath, token]);

  const createCustomer = useCallback(async (data: CustomerData) => {
    setLoading(true);
    const path = `${serverPath}/customers/customer`;
    const body = customerDataParamsMapper(data);

    setLoading(true);
    try {
      await request<ApiCustomerDataParams, ApiCustomerData>(path, { body, token });
      setSuccess('New customer created!');

      // Force the customers page to fetch a new list
      setCustomers([]);
    } catch (error) {
      if (typeof error === 'string') {
        setError(error);
      }
    }
    setLoading(false);
  }, [serverPath, token, customers]);

  const editContact = useCallback(async (data: ApiContact) => {
    const path = `${serverPath}/customers/${data.CustomerId}/updateBranchContact/${data.BranchId}/${data.ContactId}`;
    const body = data;
    request<ApiContact, never>(path, { body, token });
  }, [serverPath, token]);

  const getAddressList = useCallback(async () => {
    setLoading(true);
    const path = `${serverPath}/customers/address-list/`;
    const res = await request<never, ApiFileDownloadRequestID>(path, { token });
    const dlPath = `${serverPath}/customers/address-list-get/${res.FileDownloadRequestID}`;
    window.open(dlPath, '_blank');
  }, [serverPath, token]);

  const getBranches = useCallback(async (customerId: string) => {
    const path = `${serverPath}/customers/${customerId}/get-branches/`;
    
    return request<never, ApiBranch[]>(path, { token });
  }, [serverPath, token]);

  const getData = useCallback(async (id: string, branchId?: string) => {
    const path = `${serverPath}/customers/customer/getData/`;
    const body: ApiGetDataParam = {
      BranchId: branchId,
      Id: id,
    };

    setLoading(true);
    try {
      const res = await request<ApiGetDataParam, ApiCustomerData>(path, { body, token });
      const newCustomer = customerDataApiMapper(res);
      setCustomerData(newCustomer);
    } catch (error) {
      if (typeof error === 'string') {
        setError(error);
      }
    }
    setLoading(false);
  }, [serverPath, token]);

  const getList = useCallback(async (data?: GetListParams) => {
    const path = `${serverPath}/customers/list`;
    const body: ApiGetListParams = {
      AccountName: data?.accountName || '',
      Address: data?.address || '',
      Code: data?.code || '',
      EmailAddress: data?.email || '',
      IsActive: data?.isActive || null,
      Name: data?.name || '',
      page: data?.page || 1
    }

    try {
      const res = await request<ApiGetListParams, ApiCustomer[]>(path, { body, token });
      setCustomers(customersApiMapper(res));
    } catch (error) {
      if (typeof error === 'string') {
        setError(error);
      }
    }
  }, [serverPath, token]);

  const getLogo = useCallback(async (id: string) => {
    const path = `${serverPath}/customers/get-logo/`;
    const body = {
      Id: id
    }
    const res = await request<{ Id: string }, string>(path, { body, token });

    setLogoUrl(res);
  }, [serverPath, token]);

  const getNotes = useCallback(async (customerId: string) => {
    const path = `${serverPath}/customers/${customerId}/notes`;

    const res = await request<never, ApiCustomerDataNote[]>(path, { method: 'GET', token });

    setNotes(customerApiDataNotesMapper(res));
  }, [serverPath, token]);

  const getOrderList = useCallback(async (data: OrderFilterParams) => {
    const path = `${serverPath}/order/branch-orders/`;
    const body = customerOrderMapper(data)
    const res = await request<ApiCustomerOrder, ApiOrder[]>(path, { body, method: 'POST', token });

    return setCustomerOrders(mapApiOrder(res));
  }, [serverPath, token]);

  const invite = useCallback(async (id: string) => {
    setLoading(true);
    const path = `${serverPath}/customers/invite/${id}`;

    setLoading(true);
    try {
      await request(path, { token });
      setSuccess('Customer invited!!')
    } catch (error) {
      if (typeof error === 'string') {
        setError(error);
      }
    }
    setLoading(false);
    
    return;
  }, [serverPath, token]);

  const newContact = useCallback(async (data: ApiContact) => {
    const path = `${serverPath}/customers/${data.CustomerId}/createBranchContact/${data.BranchId}`;
    const body = data;
    request(path, { body, token });
  }, [serverPath, token]);

  const removeContact = useCallback(async (data: ApiContact) => {
    const path = `${serverPath}/customers/${data.CustomerId}/deleteBranchContact/${data.BranchId}/${data.ContactId}`;
    
    request(path, { method: 'DELETE', token });
  }, [serverPath, token]);

  const updateCustomer = useCallback(async (data: CustomerData) => {
    const path = `${serverPath}/customers/customer/`;
    const body = customerDataParamsMapper(data);

    setLoading(true);
    try {
      request(path, { body, token });
      setSuccess('Customer updated!!')
    } catch (error) {
      if (typeof error === 'string') {
        setError(error);
      }
    }
    setLoading(false);

    return;
  }, [serverPath, token]);

  const uploadLogo = useCallback(async (customerId: string, data: Blob) => {
    const path = `${serverPath}/customers/upload-logo/${customerId}`;
    setLoading(true);
    try {
      sendFile(path, data, token);
    } catch (error) {
      if (typeof error === 'string') {
        setError(error);
      }
    }
    setLoading(false);

  }, [serverPath, token]);

  const updateDetails = useCallback(async (data: ApiCustomerAddress, id: string, branchId: string) => {
    const path = `${serverPath}/customers/${id}/updateBranchDetails/${branchId}`;
    const body = data;
    request(path, { body, token });
  }, [serverPath, token]);

  return {
    addNote,
    createCustomer,
    customerData,
    customerOrders,
    customers,
    editContact,
    getAddressList,
    getBranches,
    getData,
    getList,
    getLogo,
    getNotes,
    getOrderList,
    invite,
    logoUrl,
    newContact,
    notes,
    removeContact,
    updateCustomer,
    updateDetails,
    uploadLogo,
  }
}

export default hook;
