import { FC } from "react";
import { Payment } from "#/pages";

const PaymentView: FC = () => {

  return (
    <Payment />
  );
}

export default PaymentView;