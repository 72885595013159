import { ApiOrderNote, ApiWorker, ApiWorkerAccountDetails, ApiWorkerData, ApiWorkerDetails, ApiWorkerPostCode, ApiWorkerRate, ApiWorkerService } from "./interface.api";
import { OrderNote, Worker, WorkerAccountDetails, WorkerData, WorkerDetails, WorkerPostCode, WorkerRate, WorkerService } from "@/types";

export const workerApiMapper = (api: ApiWorker): Worker => {
  return {
    active: api.IsActive,
    email: api.Email,
    id: `${api.ApplicationUserId}`,
    name: api.Name,
    type: api.WorkerType,
  }
};

export const workersApiMapper = (api: ApiWorker[]): Worker[] => {
  return api.map((worker) => workerApiMapper(worker));
};

export const workerAccountDetailsMapper = (api: ApiWorkerAccountDetails): WorkerAccountDetails => {
  return {
    accountPhoneNumber: api.AccountPhoneNumber,
    daysToPayInvoices: api.DaysToPayInvoices,
    email: api.AccountsEmailAddress,
    firstName: api.FirstName,
    isActive: api.IsActive,
    lastName: api.LastName,
    login: api.Name,
    sendEmailNotification: api.SendEmailNotification
  };
}

export const workerPostCodeMapper = (api: ApiWorkerPostCode): WorkerPostCode => {
  return {
    workerPostcodeId: api.WorkerPostcodeId
  };
}

export const workerPostCodesMapper = (api: ApiWorkerPostCode[]): WorkerPostCode[] => {
  return api.map((postcode) => workerPostCodeMapper(postcode));
}

export const workerRateMapper = (api: ApiWorkerRate): WorkerRate => {
  return {
    applicationUserId: api.ApplicationUserId,
    rate: api.Rate,
    workerId: api.WorkerId,
    workerRateId: api.WorkerRateId
  };
}

export const workerRatesMapper = (api: ApiWorkerRate[]): WorkerRate[] => {
  return api.map((rate) => workerRateMapper(rate));
}

export const workerDetailsMapper = (api: ApiWorkerDetails): WorkerDetails => {
  return {
    addressId: api.AddressId,
    addressString: api.AddressString,
    area: api.Area,
    county: api.Country,
    doorNumber: api.DoorNumber,
    flat: api.Flat,
    fullTimeType: api.FullTimeType,
    googleCalendarId: api.GoogleCalendarId,
    houseName: api.HouseName,
    houseNumber: api.HouseNumber,
    notes: api.Notes,
    phoneNumber: api.PhoneNumber,
    postCode: api.PostCode,
    rating: api.Rating,
    road: api.Road,
    town: api.Town
  };
}

export const workerDetailsApiMapper = (api: ApiWorkerData): WorkerData => {
  return {
    accountDetails: workerAccountDetailsMapper(api.AccountDetails),
    postcodes: workerPostCodesMapper(api.Postcodes),
    rates: workerRatesMapper(api.Rates),
    workerDetails: workerDetailsMapper(api.WorkerDetails),
    workerType: api.WorkerType
  }
};

export const workerServiceApiMapper = (api: ApiWorkerService): WorkerService => {
  return {
    checked: api.Checked,
    id: api.OrderTypeId,
    name: api.Name
  };
};

export const workerServicesApiMapper = (api: ApiWorkerService[]): WorkerService[] => {
  return api.map((service) => workerServiceApiMapper(service));
};

export const mapApiOrderNotes = (notes: ApiOrderNote[]): OrderNote[] => {
  return notes.map((note) => ({
    content: note.Content,
    created: note.CreatedDateTime,
    id: `${note.NoteId}`,
    owner: note.OwnerName,
  }));
}