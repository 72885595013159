import { LatLng } from "./Maps.interface";

export const getLocationByAddress = async (address: string) => {
  const geocoder = new window.google.maps.Geocoder();

  const { results } = await geocoder.geocode({ address: address });

  if (results) {
    return results[0].geometry.location;
  }

  return null;
}

export const getCurrentLocation = (): Promise<LatLng> => {
  const londonCentralLat = 51.528837;
  const londonCentralLng = 0.165653;

  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      const onNoGeolocation = () => {
        resolve(new google.maps.LatLng(londonCentralLat, londonCentralLng))
      }

      const timer = setTimeout(function(){
        onNoGeolocation();
      }, 10000);
  
      navigator.geolocation.getCurrentPosition((position) => {
        const location = new window.google.maps.LatLng(position.coords.latitude, position.coords.longitude);
        clearTimeout(timer);
        resolve(location);
      }, () => {
        onNoGeolocation();
      });
    } else {
      reject();
    }
  });
}