import { config } from "./contants";

type MethodType = 'GET' | 'POST' | 'DELETE' | 'PUT';

interface RequestOptions<T> {
  body?: T;
  method?: MethodType;
  token?: string;
}

export const getHeaders = (token?: string, accept = 'text/html', contentType = 'application/json') => {
  return {
    Accept: accept,
    'Access-Control-Allow-Origin': '*',
    Authorization: `Bearer ${token}` || '',
    'Content-Type': contentType,
  }
}

export const getServerPath = () => {
  return config.server.url + config.server.api;
}

export const getGooglePath = () => {
  return config.server.url + config.server.googleAccountLinkUrl;
}

export const request = async <T, U,>(path: string, options: RequestOptions<T>): Promise<U> => {
  const { body, method = 'POST', token } = options;
  const headers = getHeaders(token);

    return fetch(path, { body: JSON.stringify(body), headers, method })
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.statusText)
        }

        return res.json() as Promise<{ response: U }>
      })
      .then((res) => res.response)
      .catch(() => {
        throw new Error(`Request to ${path} failed!`);
      });
}

export const sendFile = async <T,>(path: string, file: Blob, token: string): Promise<T> => {
  const fData = new FormData();
  fData.append('file', file);

  const headers = {
    Accept: 'application/json',
    'Access-Control-Allow-Origin': '*',
    Authorization: `Bearer ${token}`,
  };

  const sendParams = {
    body: fData,
    headers,
    method: "POST",
  };

  return fetch(path, sendParams)
    .then((res) => res.json() as Promise<T>);
};

export const sendFiles = async <T,>(path: string, files: Blob[], token: string): Promise<T> => {
  const fData = new FormData();
  for(let i = 0; i < files.length; i++) {
      const file = files[i];
      fData.append('file', file);
  }

  const headers = {
    Accept: 'application/json',
    'Access-Control-Allow-Origin': '*',
    Authorization: `Bearer ${token}`,
  };

  const sendParams = {
    body: fData,
    headers,
    method: "POST",
  };

  return fetch(path, sendParams)
    .then((res) => res.json() as Promise<T>);
};