import { FC, Suspense, lazy, memo } from "react";
import { IconProps, IconType } from "./Icon.interface";

import { StyledIcon } from "./Icon.styled";

const getIcon = (type: IconType) => {
  switch(type) {
    case '360': {
      return lazy(() => import('@/assets/orderTypes/ThreeHundredSixty'));
    }
    case 'brochure': {
      return lazy(() => import('@/assets/icons/Brochure'));
    }
    case 'calendar': {
      return lazy(() => import('@/assets/icons/Calendar'));
    }
    case 'camera': {
      return lazy(() => import('@/assets/orderTypes/Camera'));
    }
    case 'check': {
      return lazy(() => import('@/assets/icons/Check'));
    }
    case 'chevronDown': {
      return lazy(() => import('@/assets/icons/ChevronDown'));
    }
    case 'chevronLeft': {
      return lazy(() => import('@/assets/icons/ChevronLeft'));
    }
    case 'chevronRight': {
      return lazy(() => import('@/assets/icons/ChevronRight'));
    }
    case 'chevronUp': {
      return lazy(() => import('@/assets/icons/ChevronUp'));
    }
    case 'close': {
      return lazy(() => import('@/assets/icons/Close'));
    }
    case 'contacts': {
      return lazy(() => import('@/assets/icons/Contacts'));
    }
    case 'cross': {
      return lazy(() => import('@/assets/icons/Cross'));
    }
    case 'cursor': {
      return lazy(() => import('@/assets/icons/Cursor'));
    }
    case 'customers': {
      return lazy(() => import('@/assets/icons/Customers'));
    }
    case 'dashboard': {
      return lazy(() => import('@/assets/icons/Dashboard'));
    }
    case 'done': {
      return lazy(() => import('@/assets/icons/Done'));
    }
    case 'edit': {
      return lazy(() => import('@/assets/icons/Edit'));
    }
    case 'epc': {
      return lazy(() => import('@/assets/orderTypes/Epc'));
    }
    case 'expand': {
      return lazy(() => import('@/assets/icons/Expand'));
    }
    case 'filter': {
      return lazy(() => import('@/assets/icons/Filter'));
    }
    case 'floorplan': {
      return lazy(() => import('@/assets/orderTypes/Floorplan'));
    }
    case 'floorplanLeft': {
      return lazy(() => import('@/assets/orderTypes/FloorplanLeft'));
    }
    case 'floorplanRight': {
      return lazy(() => import('@/assets/orderTypes/FloorplanRight'));
    }
    case 'folder': {
      return lazy(() => import('@/assets/icons/Folder'));
    }
    case 'help': {
      return lazy(() => import('@/assets/icons/Help'));
    }
    case 'info': {
      return lazy(() => import('@/assets/icons/Info'));
    }
    case 'invoices': {
      return lazy(() => import('@/assets/icons/Invoices'));
    }
    case 'key': {
      return lazy(() => import('@/assets/icons/Key'));
    }
    case 'language': {
      return lazy(() => import('@/assets/icons/Language'));
    }
    case 'location': {
      return lazy(() => import('@/assets/icons/Location'));
    }
    case 'logo': {
      return lazy(() => import('@/assets/icons/Logo'));
    }
    case 'note': {
      return lazy(() => import('@/assets/icons/Note'));
    }
    case 'notifications': {
      return lazy(() => import('@/assets/icons/Notifications'));
    }
    case 'orders': {
      return lazy(() => import('@/assets/icons/Orders'));
    }
    case 'photoEdit': {
      return lazy(() => import('@/assets/icons/PhotoEdit'));
    }
    case 'plus': {
      return lazy(() => import('@/assets/icons/Plus'));
    }
    case 'search': {
      return lazy(() => import('@/assets/icons/Search'));
    }
    case 'settings': {
      return lazy(() => import('@/assets/icons/Settings'));
    }
    case 'services': {
      return lazy(() => import('@/assets/icons/Services'));
    }
    case 'slideshow': {
      return lazy(() => import('@/assets/orderTypes/Slideshow'));
    }
    case 'sort': {
      return lazy(() => import('@/assets/icons/Sort'));
    }
    case 'status': {
      return lazy(() => import('@/assets/icons/Status'));
    }
    case 'time': {
      return lazy(() => import('@/assets/icons/Time'));
    }
    case 'video': {
      return lazy(() => import('@/assets/orderTypes/Video'));
    }
  }
}

const Icon: FC<IconProps> = ({ fillColor, type }) => {
  const Svg = getIcon(type);

  return (
    <StyledIcon fillColor={fillColor}>
      <Suspense>
        <Svg />
      </Suspense>
    </StyledIcon>
  );
}

export default memo(Icon);