import { useCallback, useState } from "react";

import { OnToggle } from "@/components/atoms/Form/Toggle/Toggle.interface";

export interface ToggleHookProps {
  checked: boolean;
  error: string | null;
  onChange: OnToggle;
  setError: (message: string | null) => void;
  value: string;
}

const useToggle = (initialValue?: string, isChecked?: boolean): ToggleHookProps => {
  const [checked, setChecked] = useState<boolean>(isChecked || false);
  const [error, setError] = useState<string | null>(null);
  const [value, setValue] = useState<string>(initialValue || '');

  const onChange: OnToggle = useCallback((c, v) => {
    setValue(v);
    setChecked(c);
  }, []);

  return {
    checked,
    error,
    onChange,
    setError,
    value,
  }
};


export default useToggle;
