import { Accordion, Card, Grid, InputText } from "#/atoms";
import { FC, memo } from "react";

import { AdditionalInfoCardProps } from "./AdditionalInfo.interface";
import { DESKTOP_SPACING } from "@/theme";
import { HeadingIcon } from "#/molecules/HeadingIcon";

const AdditionalInfoCard: FC<AdditionalInfoCardProps> = ({
  accordionState = 'open',
  bedrooms,
  floorArea,
  parking,
}) => {
  return (
    <Card>
      <Accordion
        header={<HeadingIcon icon="location" title="Additional Information" />}
        showArrow
        state={accordionState}
      >
        <Grid columnGap={DESKTOP_SPACING.XL} gridTemplateColumns="repeat(2, 40%)" margin="0">
          <InputText
            type="number"
            label="Number of bedrooms"
            value={bedrooms.value}
            onChange={bedrooms.onChange}
          />
          <InputText
            type="number"
            label="Sq ft. of property"
            value={floorArea.value}
            onChange={floorArea.onChange}
          />
          <InputText
            type="text"
            label="Parking available"
            value={parking.value}
            onChange={parking.onChange}
          />
        </Grid>
      </Accordion>
    </Card>
  );
};

export default memo(AdditionalInfoCard);