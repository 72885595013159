import { useCallback, useEffect, useState } from "react";

import { CustomerContacts } from "@/types";
import { useApi } from "@/api";
import useModal from "../useModal";

export interface ContactsMethods {
  onAddCustomerContact: () => void;
  onAskToEditContact: (id: string) => void;
  onAskToRemoveContact: (id: string) => void;
  onAskToAddNewContact: () => void;
}

export type ModalType = 'create' | 'edit';

export interface ContactsCommon {
  contactToEdit: CustomerContacts | null;
  contacts: CustomerContacts[];
  modalType: ModalType;
  onAddContact: (contact: CustomerContacts) => void;
  onAskToEditContact: (id: string) => void;
  onAskToRemoveContact: (id: string) => void;
  onCloseContactModal: () => void;
  onCloseRemoveModal: () => void;
  onEditContact: (contact: CustomerContacts) => void;
  onAskToAddNewContact: () => void;
  onRemoveContact: () => void;
  showContactModal: boolean;
  showRemoveModal: boolean;
  setContacts: (contacts: CustomerContacts[]) => void;
}

export interface ContactsPublic extends ContactsCommon {
  onAddCustomerContact: () => void;
}

export interface ContactsReturn extends ContactsCommon {
  onAddCustomerContact: (branchId: string, customerId: string) => void;
}

const useContacts = (): ContactsReturn => {
  const [contacts, setContacts] = useState<CustomerContacts[]>([]);
  const [contactToEdit, setContactToEdit] = useState<CustomerContacts | null>(null);
  const [contactToRemove, setContactToRemove] = useState<string | null>(null);
  const [modalType, setModalType] = useState<ModalType>('create');

  const {
    showModal: showRemoveModal,
    onCloseModal: onCloseRemoveModal,
    onOpenModal: onOpenRemoveModal
  } = useModal();
  const {
    showModal: showContactModal,
    onCloseModal: onCloseContactModal,
    onOpenModal: onOpenContactModal
  } = useModal();
  const { customer } = useApi();

  const addContact = useCallback((customerContacts: CustomerContacts[]) => {
    const result = customerContacts.filter(o1 => !contacts.some(o2 => o1.contactId === o2.contactId));
    setContacts([...contacts, ...result]);
  }, [contacts]);

  useEffect(() => {
    if (customer.customerData) {
      addContact(customer.customerData.contacts)
    }
  }, [customer.customerData]);
  
  const onAddContact = useCallback((newContact: CustomerContacts) => {
    setContacts([...contacts, newContact]);
    onCloseContactModal();
  }, [contacts]);
  
  const onAddCustomerContact = useCallback((branchId: string, customerId: string) => {
    customer.getData(branchId, customerId);
  }, [customer]);
  
  const onAskToAddNewContact = useCallback(() => {
    console.log('here');
    setModalType('create')
    setContactToEdit(null);
    onOpenContactModal();
  }, [contacts]);
  
  const onAskToEditContact = useCallback((id: string) => {
    const contact = contacts.find((item) => item.contactId === id);

    if (contact) {
      setModalType('edit')
      setContactToEdit(contact);
      onOpenContactModal();
    }
  }, [contacts]);
  
  const onAskToRemoveContact = useCallback((id: string) => {
    const contact = contacts.find((item) => item.contactId === id);

    if (contact) {
      setContactToRemove(id);
      onOpenRemoveModal();
    }
  }, [contacts]);
  
  const onEditContact = useCallback((contact: CustomerContacts) => {
    setContacts(contacts.map((item) => {
      if (item.contactId === contact.contactId) {
        return contact;
      }

      return item;
    }));

    onCloseContactModal();
  }, [contacts]);

  const onRemoveContact = useCallback(() => {
    if (contactToRemove) {
      setContacts(contacts.filter((contact) => contact.contactId !== contactToRemove));
      onCloseRemoveModal();
    }
  }, [contacts, contactToRemove]);

  return {
    contactToEdit,
    contacts,
    modalType,
    onAddContact,
    onAddCustomerContact,
    onAskToAddNewContact,
    onAskToEditContact,
    onAskToRemoveContact,
    onCloseContactModal,
    onCloseRemoveModal,
    onEditContact,
    onRemoveContact,
    setContacts,
    showContactModal,
    showRemoveModal,
  }
}

export default useContacts;