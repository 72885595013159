import { Accordion, AccordionState, Button, InputDatetime, Popover, TimeSelect } from "#/atoms";
import { FC, memo, useCallback, useEffect, useState } from "react";

import { TimepickerProps } from "./Timepicker.interface";

const Timepicker: FC<TimepickerProps> = ({ time, label, onChange }) => {
  const [focus, setFocus] = useState(false);
  const [selectedTime, setSelectedTime] = useState(time || '12:00');

  useEffect(() => {
    if (time) {
      setSelectedTime(time);
    }
  }, [time]);

  const handleToggleClick = useCallback((state: AccordionState) => {
    setFocus(state === 'open');
  }, [focus]);

  const handleTimeChange = useCallback((newTime: string) => {
    setSelectedTime(newTime)
  }, []);

  const handleApplySelectedDate = useCallback(() => {
    if (selectedTime) {
      onChange(selectedTime);
      handleToggleClick('close');
    }
  }, [selectedTime]);

  return (
    <Accordion
      header={<InputDatetime datetime={time} iconType="time" focus={focus} label={label} />}
      state={focus ? 'open' : 'close'}
      onToggle={handleToggleClick}
    >
      <Popover
        header={selectedTime}
        footer={(
          <Button fullWidth onClick={handleApplySelectedDate}>Apply Selection</Button>
        )}
        onClose={() => handleToggleClick('close')}
      >
        <TimeSelect time={selectedTime} onChange={handleTimeChange} />
      </Popover>
    </Accordion>
  );
}

export default memo(Timepicker);