import {
  StyledCheckbox,
  StyledInputSelect,
  StyledNativInputSelect,
  StyledRadioButton,
} from './InputSelect.styled'
import { memo, useCallback } from 'react'

import CheckIcon from '@/assets/icons/Check'
import { InputSelectProps } from './InputSelect.interface'

import * as React from 'react'

const InputSelect: React.FC<InputSelectProps> = ({
  checked = false,
  label,
  labelPosition = 'right',
  margin,
  name,
  onChangeValue,
  id,
  state = 'idle',
  type,
  value = '',
  ...otherProps
}) => {
  
  const onInputChange = useCallback((ev: React.ChangeEvent<HTMLInputElement>) => {
    onChangeValue?.(ev.target.checked, ev.target.value)
    
    //ev.stopPropagation();
    //ev.preventDefault();
    
  }, []);

  return (
    <StyledInputSelect
      htmlFor={id}
      key={id}
      labelPosition={labelPosition}
      margin={margin}
      state={state}
    >
      <StyledNativInputSelect
        {...otherProps}
        checked={checked}
        id={id}
        name={name}
        onChange={onInputChange}
        type={type}
        value={value}
      />
      {type === 'checkbox' && (
        <StyledCheckbox checked={checked} state={state}>
          {checked && <CheckIcon />}
        </StyledCheckbox>
      )}
      {type === 'radio' && <StyledRadioButton checked={checked} />}
      {label}
    </StyledInputSelect>
  )
}

export default memo(InputSelect);
