import { ChangeEvent, useCallback, useEffect, useState } from "react";

export interface InputHookProps {
  error: string | null;
  value: string;
  onChange: (ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  setError: (message: string | null) => void;
  setValue: (value: string) => void;
}

const useInput = (initialValue?: string | null): InputHookProps => {
  const [error, setError] = useState<string | null>(null);
  const [value, setValue] = useState<string>(initialValue || '');

  useEffect(() => {
    if (initialValue !== value) {
      setValue(initialValue || '');
    }
  }, [initialValue]);

  const onChange = useCallback((ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setValue(ev.target.value);
  }, []);

  return {
    error,
    onChange,
    setError,
    setValue,
    value,
  }
};


export default useInput;
