import { ApiInvoice, ApiXeroTokenData } from "./interface.api";
import { useCallback, useState } from "react";

import { ApiHooksProps } from "../interface";
import { Invoice } from "@/types";
import { invoicesApiMapper } from './mappers';
import { request } from "../request";

const hook = ({ setError, setLoading, setSuccess, serverPath, token }: ApiHooksProps) => {

  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [invoiceCount, setInvoiceCount] = useState(0);

  const countOwnUnpaidInvoices = useCallback(async () => {
    const path = `${serverPath}/invoices/count/`;
    request(path, { method: 'GET', token });
  }, [serverPath, token]);

  const generateInvoiceByOrderIds = useCallback(async (orderIds: string[]) => {
    const path = `${serverPath}/invoices/generate/`;

    setLoading(true);
    try {
      await request(path, { body: orderIds, token });
      setSuccess('Invoices generated!');
    } catch (error) {
      if (typeof error === 'string') {
        setError(error);
      }
    }
    setLoading(false);

    return;
  }, [serverPath, token, invoices]);

  const listOwnInvoices = useCallback(async (userId: string | null) => {
    const path = `${serverPath}/invoices/list/${userId}`;

    setLoading(true);
    try {
      const res = await request<never, ApiInvoice[]>(path, { method: 'GET', token });
      setInvoices(invoicesApiMapper(res))
      setSuccess('Invoices generated!')
    } catch (error) {
      if (typeof error === 'string') {
        setError(error);
      }
    }
    setLoading(false);

    return;
  }, [serverPath, token]);

  const getInvoiceCount = useCallback(async () => {
    const path = `${serverPath}/invoices/count/`;
    const res = await request<never, number>(path, { method: 'GET', token });
    setInvoiceCount(res);
  }, [serverPath, token]);

  const setPaidManual = useCallback(async (invoiceIds: string[]) => {
    const path = `${serverPath}/invoices/setPaidManual/`;

    setLoading(true);
    try {
      await request(path, { body: invoiceIds, token });
      setInvoices(invoices.map((invoice) => ({
        ...invoice,
        paid: true,
      })))
      setSuccess('Invoices set to paid!')
    } catch (error) {
      if (typeof error === 'string') {
        setError(error);
      }
    }
    setLoading(false);
  }, [serverPath, token, invoices]);

  const getAuthStatus = useCallback(async () => {
    const path = `${serverPath}/xero-oauth/status/`;
    const res = await request<never, boolean>(path, { method: 'GET', token });

    return res;
  }, [serverPath, token]);

  const getAuthUrl = useCallback(async () => {
    const currentUrl = `${window.location.protocol}//${window.location.host}`;

    const path = `${serverPath}/xero-oauth/auth-url/callbackUri=${currentUrl}&state=photoplanet`;
    const res = await request<never, string>(path, { method: 'GET', token });

    return res;
  }, [serverPath, token]);

  const connectXero = useCallback(async (code:string, callbackUrl: string) => {
    const path = `${serverPath}/xero-oauth/connect/`;
    const body : ApiXeroTokenData = {
      CallbackUri: callbackUrl,
      Code: code
    } 

    await request<ApiXeroTokenData, string>(path, { body, token });
  }, [serverPath, token]);

  // xero-auth service

  const appName = "photoplanet",
        rememberPageKey = "xero-remember-page",
        xeroFirstLoginKey = "xero-first-login";


  const redirectToOriginalPage = () => {
    const url = localStorage.getItem(rememberPageKey) || '';
    localStorage.removeItem(rememberPageKey);
    window.location.assign(url);
  }

  const requestAuthCode = async (errorFn: string) => {
    const url = await getAuthUrl();
    url ? localStorage.setItem(rememberPageKey, window.location.href)
    : console.log(errorFn);
  }

  const loginIfNeeded = async (callbackFn: string, errorFn:string) =>{
    const loggedIn = await getAuthStatus();

    if (loggedIn) {
      console.log(callbackFn);
    } else {
      const lastLogin = (localStorage.getItem(xeroFirstLoginKey) || 0) <= Date.now() - 3e4;
      lastLogin && localStorage.setItem(xeroFirstLoginKey, Date.now().toString());
      lastLogin 
      ? requestAuthCode(errorFn)
      : console.log("There is no connection. Please try to authorize later." + errorFn)
    }
  }

  const trySendAuthCodeToServer = async(errorFn:string) => {
    const url = window.location.href;
    
    const codeMatch = /code=(.+?)(&|$|#)/gi.exec(url);
    const code = codeMatch && codeMatch[1] || "";
    
    const stateMatch = /state=(.+?)(&|$|#)/gi.exec(url);
    const state = stateMatch && stateMatch[1] || "";

    const arg = code && state === appName ? code : "";

    if (arg) {
      await connectXero(arg, `${window.location.protocol}//${window.location.host}`);
      redirectToOriginalPage();
    } else {
      console.log(errorFn);
    }
  }

  return {
    connectXero,
    countOwnUnpaidInvoices,
    generateInvoiceByOrderIds,
    getAuthStatus,
    getAuthUrl,
    getInvoiceCount,
    invoiceCount,
    invoices,
    listOwnInvoices,
    loginIfNeeded,
    setPaidManual,
    trySendAuthCodeToServer
  }
}

export default hook;