import { Navigate, useLocation } from "react-router-dom";

import { Main } from "#/templates";
import { adminMenu } from "./constants";
import { useApi } from "@/api";

const RequireAdmin = ({ children }: { children: JSX.Element }) => {
  const { session } = useApi();
  const location = useLocation();

  if (!session.isLoggedIn() || !session.user?.admin) {
    return <Navigate to="/login" state={{ from: location }} replace />
  }

  return (
    <Main
      user={{
        firstName: session.user.firstName,
        lastName: session.user.lastName,
      }}
      navigation={{ menus: adminMenu }}
      onLogout={session.logout}
    >
      {children}
    </Main>
  );
}

export default RequireAdmin;
