import { DESKTOP_SPACING, FONT_SIZE, FONT_WEIGHT, NATURAL, PRIMARY_BLUE } from "@/theme";

import styled from "styled-components";

export const StyledMonths = styled.div`
  display: flex;
  flex-direction: column;
  height: 184px;
  margin-right: ${DESKTOP_SPACING.XXL}px;
  padding-right: ${DESKTOP_SPACING.XS}px;
  overflow-y: scroll;
  width: 140px;
`;

export const StyledMonthItem = styled.button<{ active?: boolean }>`
  background: transparent;
  border: 0;
  border-bottom: 1px solid ${NATURAL.C};
  color: ${({ active = false }) => active ? PRIMARY_BLUE.DARK : NATURAL.G};
  font-size: ${FONT_SIZE.SM};
  font-weight: ${FONT_WEIGHT.SEMIBOLD};
  padding: ${DESKTOP_SPACING.LG}px 0;
  text-align: left;
`;