import { DESKTOP_SPACING } from '@/theme';
import { StyledCheckboxGroupProps } from './CheckboxGroup.interface';
import { StyledInputSelect } from '#/atoms';
import styled from 'styled-components';

export const StyledCheckboxGroup = styled.div<StyledCheckboxGroupProps>`
  display: flex;

  ${({ direction }) => direction === 'row'
    ? `
      flex-direction: row;

      ${StyledInputSelect} {
        margin-right: ${DESKTOP_SPACING.LG}px;

        :last-child {
          margin-right: 0;
        }
      }
    `
    : `
      flex-direction: column;

      ${StyledInputSelect} {
        margin-bottom: ${DESKTOP_SPACING.LG}px;

        :last-child {
          margin-bottom: 0;
        }
      }
    `
  }
`;