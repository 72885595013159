import { FC, useCallback, useEffect, useState } from "react";
import { FilterCustomer, FindCustomerModal } from "#/organisms";
import { OrderTemplate, SelectedCustomer } from "@/types";
import { useFilter, useModal } from "@/hooks";

import { CustomerAndPackage } from "#/pages";
import { GetListParams } from "@/api/customer/interface";
import { useApi } from "@/api";
import { useNavigate } from "react-router-dom";

interface CustomerAndPackageViewProps {
  isAdmin: boolean;
  selectedCustomer: SelectedCustomer | null;
  selectedOrderTemplate: OrderTemplate | null;
  setSelectedCustomer: (value: SelectedCustomer | null) => void;
  setSelectedOrderTemplate: (orderTypeId: string) => void;
}

const CustomerAndPackageView: FC<CustomerAndPackageViewProps> = ({
  isAdmin,
  selectedCustomer,
  setSelectedCustomer,
  setSelectedOrderTemplate
}) => {
  const { customer, orderTemplate } = useApi();

  const {
    filter,
    numberOfFilters,
    setShouldFilter,
    shouldFilter,
    ...filterProps
  } = useFilter<FilterCustomer>();

  const [loadingCustomers, setLoadingCustomers] = useState(false);
  const [loadingOrderTemplates, setLoadingOrderTemplates] = useState(false);
  const { showModal, onCloseModal, onOpenModal } = useModal();
  const [currentPage, setcurrentPage] = useState(1);
  const navigate = useNavigate();

  const getCustomers = async (data?: GetListParams) => {
    setLoadingCustomers(true);
    await customer.getList(data);
    setLoadingCustomers(false);
  }

  const getOrderTemplates = async (customerId: string) => {
    setLoadingOrderTemplates(true);
    const branches = await customer.getBranches(customerId);
    orderTemplate.getList(branches);
    setLoadingOrderTemplates(false);
  }

  useEffect(() => {
    if (shouldFilter) {
      if (filter) {
        getCustomers({
          accountName: filter.login,
          address: filter.location,
          code: filter.customerCode,
          email: filter.customerEmail,
          isActive: filter.active,
          name: filter.companyName,
          page: currentPage
        });
      } else {
        getCustomers();
      }
      setShouldFilter(false);
    }
  }, [filter, shouldFilter]);

  useEffect(() => {
    if (selectedCustomer && orderTemplate.orderTemplates.length === 0) {
      getOrderTemplates(selectedCustomer.id);
    }
  }, [selectedCustomer, orderTemplate]);

  const handleShowMore = useCallback(() => {
    setcurrentPage(currentPage + 1);
  }, []);

  const handleCreateCustomer = useCallback(() => {
    navigate('/customer-create', { replace: true });
  }, []);

  const handleFindCustomerModal = useCallback(() => {
    if (customer.customers.length === 0) {
      getCustomers();
    }
    onOpenModal();
  }, [customer.customers]);

  const handleSelectCustomer = useCallback((customerId: string) => {
    const selected = customer.customers.find((item) => item.id === customerId);
    
    if (selected) {
      setSelectedCustomer(selected);
    }
  }, [customer.customers]);

  const handleRemoveCustomer = useCallback(() => {
    setSelectedCustomer(null);
  }, []);

  const handleSelectOrderTemplate = useCallback((orderTypeId: string) => {
    setSelectedOrderTemplate(orderTypeId);
  }, []);

  return (
    <>
      <CustomerAndPackage
        isAdmin={isAdmin}
        loadingOrderTemplates={loadingOrderTemplates}
        onCreateCustomer={handleCreateCustomer}
        onFindCustomer={handleFindCustomerModal}
        onSelectOrderTemplate={handleSelectOrderTemplate}
        onRemoveCustomer={handleRemoveCustomer}
        orderTemplates={orderTemplate.orderTemplates}
        selectedCustomer={selectedCustomer}
        onShowMore={handleShowMore}
      />
      {showModal && (
        <FindCustomerModal
          customers={customer.customers}
          loading={loadingCustomers}
          onClose={onCloseModal}
          onSelectCustomer={handleSelectCustomer}
          {...filterProps}
          filter={filter}
          numberOfFilters={numberOfFilters}
        />
      )}
    </>
  );
}

export default CustomerAndPackageView;