import { ApiHooksProps } from "../interface";
import { SupportApi } from "./interface";
import { request } from "../request";
import { useCallback } from "react";

const hook = ({ setError, setLoading, setSuccess, serverPath, token }: ApiHooksProps): SupportApi => {
  const createTicket = useCallback(async (subject: string, comment: string) => {
    const path = `${serverPath}/zendesk/new-ticket/`;
    const body = {
      comment,
      subject,
    };

    setLoading(true);
    try {
      await request(path, { body, token });
      setSuccess('Your support message was sent!');
    } catch (error) {
      if (typeof error === 'string') {
        setError(error);
      }
    }
    setLoading(false);

    return;
  }, [serverPath, token]);

  return {
    createTicket
  }
}

export default hook;