import { BigCalendar } from "@/components/pages/BigCalendar";


const BigCalendarView = () => {

    return (
        <BigCalendar></BigCalendar>
    );
}

export default BigCalendarView;