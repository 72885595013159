import { StyledStatelessAccordionProps } from "./StatelessAccordion.interface";
import styled from "styled-components";

export const StyledStatelessAccordion = styled.div<StyledStatelessAccordionProps>`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const StyledStatelessAccordionHeader = styled.div<{ mb?: string }>`
  align-items: center;
  cursor: pointer;
  display: flex;
  flex: 1;
  margin-bottom: ${({ mb }) => mb || '0'};
`;

export const StyledStatelessAccordionBody = styled.div<StyledStatelessAccordionProps>`
  display: ${({ state }) => state === 'close' ? 'none' : 'flex'};
  flex: 1;
  flex-direction: ${({ bodyDirection }) => bodyDirection || 'column'};
`;

export const StyledStatelessAccordionArrow = styled.i`
  margin-left: auto;
  position: absolute;
  right: 0;
  top: 4px;
`;