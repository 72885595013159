import styled from "styled-components";

const StyledIllustration = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const Empty = () => {
  return (
    <StyledIllustration>
      <svg width="137" height="100" viewBox="0 0 274 201" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M35.9846 86.3623V190.517C35.9846 196.04 40.4618 200.517 45.9846 200.517H179.608C185.131 200.517 189.608 196.04 189.608 190.517V100.855C189.608 95.3322 185.131 90.8551 179.608 90.8551H127.595C125.511 90.8551 123.479 90.2038 121.783 88.9924L106.708 78.225C105.012 77.0135 102.98 76.3623 100.896 76.3623H45.9846C40.4618 76.3623 35.9846 80.8395 35.9846 86.3623Z" fill="#CECED6"/>
        <path d="M63.4355 119.399C64.9448 115.583 68.6314 113.077 72.7342 113.077H209.68C216.739 113.077 221.576 120.192 218.979 126.756L192.108 194.679C190.599 198.494 186.913 201 182.81 201H45.8637C38.8053 201 33.9684 193.885 36.5649 187.321L63.4355 119.399Z" fill="#EDEDF0"/>
        <path d="M165.933 140.71L174.792 147.674L145.197 185.337L136.338 178.373L165.933 140.71Z" fill="#B7B7C2"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M201.772 155.782C177.047 158.748 154.6 141.109 151.634 116.384C148.669 91.6597 166.308 69.2125 191.033 66.247C215.757 63.2814 238.204 80.9206 241.17 105.645C244.135 130.37 226.496 152.817 201.772 155.782ZM200.765 147.388C180.676 149.798 162.438 135.466 160.028 115.377C157.619 95.2888 171.951 77.0504 192.039 74.6409C212.128 72.2314 230.366 86.5633 232.776 106.652C235.185 126.741 220.854 144.979 200.765 147.388Z" fill="#B7B7C2"/>
        <path d="M157.659 150.997L166.521 157.958L145.108 185.208L136.246 178.244L157.659 150.997Z" fill="#818095"/>
        <path d="M157.659 150.997L166.521 157.958L164.004 161.386L154.825 154.623L157.659 150.997Z" fill="#CECED6"/>
        <path opacity="0.7" d="M160.029 115.377C162.438 135.466 180.676 149.798 200.765 147.388C220.854 144.979 235.186 126.741 232.776 106.652C230.367 86.5632 212.128 72.2314 192.04 74.6409C171.951 77.0504 157.619 95.2887 160.029 115.377Z" fill="white"/>
        <path d="M214.946 128.375C219.972 122.947 222.49 115.549 221.584 107.995C220.678 100.44 216.481 93.8478 210.315 89.7624C209.062 88.7774 207.137 89.2922 206.431 90.512C205.446 91.7655 205.961 93.6905 207.181 94.3956C212.128 97.7759 215.283 102.79 215.988 108.666C216.693 114.542 214.812 120.16 210.805 124.614C210.313 125.241 209.854 126.147 209.955 126.986C210.022 127.546 210.402 128.352 210.716 128.598C211.969 129.583 213.928 129.348 214.946 128.375Z" fill="#B7B7C2"/>
        <circle cx="6.03294" cy="66.7005" r="5.7971" fill="#EDEDF0"/>
        <circle cx="11.8299" cy="153.657" r="2.89855" fill="#EDEDF0"/>
        <circle cx="270.767" cy="146.894" r="2.89855" fill="#EDEDF0"/>
        <circle cx="86.2263" cy="42.5459" r="5.7971" fill="#EDEDF0"/>
        <circle cx="214.729" cy="42.5459" r="5.7971" fill="#EDEDF0"/>
        <circle cx="23.4242" cy="39.6473" r="2.39855" stroke="#CECED6"/>
        <circle cx="151.444" cy="20.8068" r="4.31401" stroke="#CECED6" strokeWidth="2"/>
        <path d="M106.583 1C106.583 0.696243 106.337 0.45 106.033 0.45C105.729 0.45 105.483 0.696243 105.483 1H106.583ZM105.483 11.628C105.483 11.9318 105.729 12.178 106.033 12.178C106.337 12.178 106.583 11.9318 106.583 11.628H105.483ZM105.483 1V3.657H106.583V1H105.483ZM105.483 8.97101V11.628H106.583V8.97101H105.483Z" fill="#B7B7C2"/>
        <path d="M111.347 6.86401C111.651 6.86401 111.897 6.61777 111.897 6.31401C111.897 6.01025 111.651 5.76401 111.347 5.76401L111.347 6.86401ZM100.719 5.76401C100.415 5.76401 100.169 6.01025 100.169 6.31401C100.169 6.61777 100.415 6.86401 100.719 6.86401L100.719 5.76401ZM111.347 5.76401L108.69 5.76401L108.69 6.86401L111.347 6.86401L111.347 5.76401ZM103.376 5.76401L100.719 5.76401L100.719 6.86401L103.376 6.86401L103.376 5.76401Z" fill="#B7B7C2"/>
        <path d="M267.935 161.386C267.935 161.083 267.689 160.836 267.385 160.836C267.082 160.836 266.835 161.083 266.835 161.386L267.935 161.386ZM266.835 172.014C266.835 172.318 267.082 172.564 267.385 172.564C267.689 172.564 267.935 172.318 267.935 172.014L266.835 172.014ZM266.835 161.386L266.835 164.043L267.935 164.043L267.935 161.386L266.835 161.386ZM266.835 169.357L266.835 172.014L267.935 172.014L267.935 169.357L266.835 169.357Z" fill="#B7B7C2"/>
        <path d="M272.7 167.25C273.003 167.25 273.25 167.004 273.25 166.7C273.25 166.397 273.003 166.15 272.7 166.15L272.7 167.25ZM262.072 166.15C261.768 166.15 261.522 166.397 261.522 166.7C261.522 167.004 261.768 167.25 262.072 167.25L262.072 166.15ZM272.7 166.15L270.043 166.15L270.043 167.25L272.7 167.25L272.7 166.15ZM264.729 166.15L262.072 166.15L262.072 167.25L264.729 167.25L264.729 166.15Z" fill="#B7B7C2"/>
        <path d="M14.0157 113.744C14.2601 113.564 14.3118 113.219 14.1314 112.975C13.9509 112.731 13.6065 112.679 13.3622 112.859L14.0157 113.744ZM4.81322 119.174C4.56888 119.354 4.51711 119.698 4.69757 119.943C4.87804 120.187 5.22241 120.239 5.46674 120.058L4.81322 119.174ZM13.3622 112.859L11.225 114.438L11.8785 115.323L14.0157 113.744L13.3622 112.859ZM6.95046 117.595L4.81322 119.174L5.46674 120.058L7.60399 118.48L6.95046 117.595Z" fill="#B7B7C2"/>
        <path d="M12.1294 121.06C12.3098 121.305 12.6542 121.356 12.8985 121.176C13.1429 120.995 13.1947 120.651 13.0142 120.407L12.1294 121.06ZM6.69995 111.858C6.51948 111.613 6.17511 111.562 5.93077 111.742C5.68644 111.923 5.63466 112.267 5.81513 112.511L6.69995 111.858ZM13.0142 120.407L11.4356 118.269L10.5508 118.923L12.1294 121.06L13.0142 120.407ZM8.27851 113.995L6.69995 111.858L5.81513 112.511L7.39369 114.648L8.27851 113.995Z" fill="#B7B7C2"/>
      </svg>
    </StyledIllustration>
  );
};

export default Empty;
