import { FC, useCallback, useState } from "react";
import { Maintainer, Worker } from "@/types";

import { BookingAndProgress } from "#/pages";
import { FindPersonModal } from "#/organisms";
import { OrderBookingAndProgressProps } from "@/components/pages/Order/BookingAndProgress/BookingAndProgress.interface";
import { useApi } from "@/api";
import { useModal } from "@/hooks";

type ModalType = 'worker' | 'maintainer';

interface BookingAndProgressViewProps extends Omit<OrderBookingAndProgressProps, 'onChangeRole' | 'onAskToChangeWorker'> {
  isAdmin: boolean;
  onSelectMaintainer: (maintainer: Maintainer) => void;
  onSelectWorker: (worker: Worker) => void;
}

const BookingAndProgressView: FC<BookingAndProgressViewProps> = ({ onSelectMaintainer, onSelectWorker, ...props}) => {
  const { order } = useApi();

  const [modalType, setModalType] = useState<ModalType>('maintainer');
  const [workers, setWorkers] = useState<Worker[]>([]);
  const [maintainers, setMaintainers] = useState<Maintainer[]>([]);
  const { showModal, onCloseModal, onOpenModal } = useModal();

  const handleChangeRole = useCallback(() => {
    setModalType('maintainer');
    onOpenModal();
  }, []);

  const handleChangeWorker = useCallback(() => {
    setModalType('worker');
    onOpenModal();
  }, []);

  const handleFindPerson = async (search: string) => {
    if (modalType === 'maintainer') {
      const res = await order.findMaintainer(search);
      setMaintainers(res);
    } else {
      const res = await order.findWorker(search);
      setWorkers(res);
    }
  };

  const handleSelect = (id: string) => {

    if (modalType === 'maintainer') {
      const selected = maintainers.find((item) => item.id === id);

      if (selected) {
        onSelectMaintainer(selected)
      }
    } else {
      const selected = workers.find((item) => item.id === id);

      // console.log(workers)
      
      if (selected) {
        onSelectWorker(selected)
      }
    }
  };

  // console.log('workers', workers)

  return (
    <>
      <BookingAndProgress
        {...props}
        onChangeRole={handleChangeRole}
        onAskToChangeWorker={handleChangeWorker}
      />
      {showModal && (
        <FindPersonModal
          onFindPerson={handleFindPerson}
          onSelectPerson={handleSelect}
          persons={modalType === 'maintainer'
            ? maintainers.map((item) => ({
              email: item.email,
              id: item.id,
              name: `${item.firstName} ${item.lastName}`,
            }))
            : workers.map((item) => ({
              email: item.email,
              id: item.id,
              name: item.name,
            }))
          }
          onClose={onCloseModal}
        />
      )}
      
      {showModal && (
        <FindPersonModal
          onFindPerson={handleFindPerson}
          onSelectPerson={handleSelect}
          persons={modalType === 'maintainer'
            ? maintainers.map((item) => ({
              email: item.email,
              id: item.id,
              name: `${item.firstName} ${item.lastName}`,
            }))
            : workers.map((item) => ({
              email: item.email,
              id: item.id,
              name: item.name,
            }))
          }
          onClose={onCloseModal}
        />
      )}
    </>
  );
}

export default BookingAndProgressView;