import { Button, Heading, PageHeader, PageWrapper, Typo } from "#/atoms";
import { FC, memo, useMemo } from "react";
import { FONT_SIZE, NATURAL } from "@/theme";

import { Column } from 'react-table';
import { CustomTable } from "#/molecules";
import { SmsMessageProps } from "./OrderSMSs.interface";

const OrderSMSs: FC<SmsMessageProps> = ({
  smsMessages,
  loadingMessages: loadingMessages,
  onOpenReplyModal,
}) => {
  const data = useMemo(() => (smsMessages.map((smsMessage) => ({
    createdDateTime: smsMessage.createdDateTime,
    id: smsMessage.id,
    messageText: smsMessage.messageText,
    reply: smsMessage.reply,
    sender: smsMessage.sender,
    senderName: smsMessage.senderName,
  }))), [smsMessages]);
  
  const columns: Column[] = useMemo(() => [
    {
      Header: 'Sender',
      accessor: 'sender',
    },
    {
        Header: 'Sender Name',
        accessor: 'senderName',
    },
    {
      Header: 'Date',
      accessor: 'createdDateTime',
    },
    {
      Header: 'Message',
      accessor: 'messageText',
    },
    {
      Cell: ({ data: rawData, row, value }) => {
        if (rawData[row.index].reply) {
          return rawData[row.index].reply;
        }
        
        return (
          <Button
            actionType='outline'
            onClick={() => onOpenReplyModal(value)}
            buttonSize="sm"
          >
            Reply
          </Button>
        );
      },
      Header: 'Reply',
      accessor: 'id',
      id: 'button'
    },
  ], []);

  return (
    <PageWrapper>
      <PageHeader>
        <Heading scale="h1" fontColor={NATURAL.K}>Order SMS Messages</Heading>
        <Typo fontColor={NATURAL.G} fontSize={FONT_SIZE.SM}>SMS messages received from clients</Typo>
      </PageHeader>
      <CustomTable columns={columns} data={data} loading={loadingMessages} />
    </PageWrapper>
  );
}

export default memo(OrderSMSs);