import { DESKTOP_SPACING, NATURAL } from "@/theme";
import { FC, memo } from "react";
import { Heading, Step, Steps } from "#/atoms";
import { ORDER_STEP, OrderProps } from "./Order.interface";

import { SelectedOrder } from "../SelectedOrder";
import { StyledOrder } from "./Order.styled";

const Order: FC<OrderProps> = ({
  activeStep = ORDER_STEP.CUSTOMER_AND_PACKAGE,
  children,
  isAdmin,
  onCancelOrder,
  onDeleteOrder,
  onSaveOrder,
  selectedOrder,
  type
}) => {
  const steps: Step[] = isAdmin
  ? [
    { id: 0, name: 'Customer & Package' },
    { id: 1, name: 'Booking' },
    { id: 2, name: 'Progress' },
    { id: 3, name: 'Payment' },
  ] : [
    { id: 0, name: 'Package Selection' },
    { id: 1, name: 'Booking' },
    { id: 2, name: 'Progress' },
    { id: 3, name: 'Content Selection' },
    { id: 4, name: 'Payment' },
  ];

  const title = type === 'create' ? 'Create New Order' : 'Edit Order'
  const step = activeStep === ORDER_STEP.AWAITING_CONFIRMATION ? ORDER_STEP.PROGRESS : activeStep;
  
  return (
    <>
      <StyledOrder showFooter={selectedOrder != null}>
        <Heading scale="h3" fontColor={NATURAL.K} margin={`0 0 ${DESKTOP_SPACING.XXL}px`}>{title}</Heading>
        <Steps activeStep={step} steps={steps} />
        {children}
      </StyledOrder>
      {selectedOrder && (
        <SelectedOrder
          activeStep={activeStep}
          isAdmin={isAdmin}
          onCancelOrder={onCancelOrder}
          onDeleteOrder={onDeleteOrder}
          onSaveOrder={onSaveOrder}
          type={type}
          {...selectedOrder}
        />
      )}
    </>
  );
}

export default memo(Order);
