import { Box, Grid } from "#/atoms";
import { DESKTOP_SPACING, FONT_SIZE } from "@/theme";

import { CalendarProps } from "./Datepicker.interface";
import Day from "./Day";
import { FC } from "react";
import { useMonth } from "@datepicker-react/hooks";

const Calendar: FC<CalendarProps> = ({ firstDayOfWeek, month, year }) => {
  const { days, weekdayLabels } = useMonth({
    firstDayOfWeek,
    month,
    year,
  });

  return (
    <Box>
      <Grid
        gridTemplateColumns="repeat(7, 1fr)"
        margin={`0 0 ${DESKTOP_SPACING.LG}px 0`}
        fontSize={FONT_SIZE.SM}
        textTransform="uppercase"
        justifyContent="center"
      >
        {weekdayLabels.map(dayLabel => (
          <Box textAlign="center" key={dayLabel}>
            {dayLabel.charAt(0)}
          </Box>
        ))}
      </Grid>
      <Grid
        gridTemplateColumns="repeat(7, 1fr)"
        fontSize={FONT_SIZE.XS}
        justifyContent="center"
      >
        {days.map((day, index) => {
          if (typeof day === "object") {
            return (
              <Day
                date={day.date}
                key={day.date.toString()}
                dayLabel={day.dayLabel}
              />
            );
          }

          return <Box key={index} />;
        })}
      </Grid>
    </Box>
  );
}

export default Calendar;