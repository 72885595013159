import { FC, memo, useCallback, useEffect, useState } from "react";
import { InputSelect, InputSelectChange } from "#/atoms";

import { CheckboxGroupProps } from "./CheckboxGroup.interface";
import { DIRECTION } from "@/theme";
import { StyledCheckboxGroup } from "./CheckboxGroup.styled";

const CheckboxGroup: FC<CheckboxGroupProps> = ({
  direction = DIRECTION.ROW,
  groupName,
  inputs,
  onChangeValue,
  selectedValues = []
}) => {
  const [selected, setSelected] = useState(selectedValues);

  useEffect(() => {
    if (selected !== selectedValues) {
      setSelected(selectedValues);
    }
  }, [selectedValues]);

  const handleInputChange: InputSelectChange = useCallback((checked, value) => {
    let updatedSelection = selected;

    if (checked) {
      updatedSelection = [...selected, value];
      setSelected(updatedSelection);
    } else {
      updatedSelection = selected.filter((item) => item !== value);
      setSelected(updatedSelection);
    }

    if (onChangeValue) {
      onChangeValue(groupName, updatedSelection)
    }
  }, [selected]);

  return (
    <StyledCheckboxGroup direction={direction}>
      {inputs.map(({ id, ...input }) => {
        const checked = selected.includes(input.value)

        return (
          <InputSelect {...input} type="checkbox" key={id} id={id} checked={checked} onChangeValue={handleInputChange} />
        );
      })}
    </StyledCheckboxGroup>
  );
}

export default memo(CheckboxGroup);
