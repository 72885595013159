import { ApiBaseWorkerService, ApiWorkerDetails } from "@/api/worker/interface.api";
import { WorkerData, WorkerService } from "@/types";
import { useCallback, useEffect, useState } from "react";

import { BaseApiUser } from "@/api/user/interface.api";
import { WorkerDetails } from "#/pages";
import { useApi } from "@/api";
import { useParams } from 'react-router-dom';

const WorkerDetailsView = () => {
  const { id } = useParams<'id'>();
  const { user, worker } = useApi();

  const [workerData, setWorkerData] = useState<WorkerData>();
  const [workerServices, setWorkerServices] = useState<WorkerService[]>([]);

  const handleSaveAccountDetails = useCallback(async (data: BaseApiUser) => {
    user.updateUser(data);
  }, []);

  const handleChangePassword = useCallback(async (workerId: string) => {
    console.log(workerId);
  }, []);

  const handleSaveDetails = useCallback(async (workerId: string, data: ApiWorkerDetails) => {
    worker.updateDetails(workerId, data);
  }, []);

  const handleSaveServices = useCallback(async (workerId: string, data: ApiBaseWorkerService[]) => {
    worker.updateServices(workerId, data);
  }, []);

  const workerId = id?.replace('id=', '') ?? '';

  const getData = async () => {
    const wor = await worker.getData(workerId);
    
    return wor;
  }
  const getServices = async () => {
    const wor = await worker.getServices(workerId);
    
    return wor;
  }

  useEffect(() => {
    
    getData().then(work => {
      setWorkerData(work);
    });
    
    getServices().then(work => {
      setWorkerServices(work);
    });
    
    
  }, []);

  return (
    <>
      <WorkerDetails
      onChangePassword={handleChangePassword}
      onSaveAccountDetails={handleSaveAccountDetails}
      onSaveDetails={handleSaveDetails}
      onSaveServices={handleSaveServices}
      workerData={workerData}
      workerServices={workerServices} />
    </>
  );
}

export default WorkerDetailsView;