import { BORDER_RADIUS, DESKTOP_SPACING, FONT_SIZE, NATURAL, TERTIARY_PURPLE } from "@/theme";

import { CellProps } from "./Table.interface";
import styled from "styled-components";

export const Table = styled.table`
  border: 1px solid ${NATURAL.C};
  border-radius: ${BORDER_RADIUS.LG};
  border-spacing: 0;
  margin: 0;
  padding: 0;
  width: 100%;
`;

export const Tr = styled.tr``;

export const Th = styled.th<CellProps>`
  background-color: ${TERTIARY_PURPLE.LIGHTER};
  color: ${NATURAL.I};
  font-size: ${FONT_SIZE.SM};
  padding: ${DESKTOP_SPACING.XXXL}px;
  text-align: ${({ align }) => align || 'center'};
  width: ${({ width }) => width || 'auto'};

  :first-child {
    border-radius: ${BORDER_RADIUS.LG} 0 0 0;
  }

  :last-child {
    border-radius: 0 ${BORDER_RADIUS.LG} 0 0;
  }
`;

export const Td = styled.td<CellProps>`
  color: ${NATURAL.H};
  font-size: ${FONT_SIZE.SM};
  padding: ${DESKTOP_SPACING.XXXL}px;
  text-align: ${({ align }) => align || 'center'};
  width: ${({ width }) => width || 'auto'};
`;

export const Thead = styled.thead``;

export const Tbody = styled.tbody`
  ${Td} {
    border-top: 1px solid ${NATURAL.C};
  }
`;
export { default as CustomTable } from './Table';