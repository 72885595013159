import { DESKTOP_SPACING, FONT_SIZE, NATURAL } from "@/theme";

import styled from "styled-components";

export const StyledPayment = styled.div`
  color: ${NATURAL.I};
  display: flex;
  flex-direction: column;
  font-size: ${FONT_SIZE.SM};
  gap: ${DESKTOP_SPACING.XXL}px;
  margin: 48px 0;
  text-align: center;
`;